import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft, faPencil } from "@fortawesome/pro-light-svg-icons";
import {
    DetailContainer,
    DetailPanel,
    DetailInfo,
    InfoGroup,
    InfoName,
    InfoData,
    LoadingCover,
    Spinner,
    BackButton,
    ConfirmModal,
    ModalTitle,
    ModalText,
    ConfirmModalBackgroundCover,
    ConnectButton,
    ModalFinePrint,
    PricingBlock,
    TotalPrice,
    ConnectButtonWithDisabling,
    InputField
} from "./SharedStyles";
import {
    TerminateButton,
    CancelButton,
    PricingRow,
    PricingCategoryTitle,
    PricingCalc,
    SubSectionDivider
} from "./SharedStyles";
import { SessionStatusTag } from "./PortalDirectHome";
import { parseTimestamp } from "./utilities";

const PricingBlockCustom = styled(PricingBlock)`
  margin-top: 5px;
`;

const PricingRowCustom = styled(PricingRow)`
  opacity: ${props => props.opacity && props.opacity};
`;

const StopButton = styled(CancelButton)`
  border: none;
  background-color: #cfcfcf;
  color: #000;
  margin-bottom: ${props => props.marginBottom && props.marginBottom};
  &:hover {
    cursor: pointer;
    background-color: rgba(207, 207, 207, 0.9);
  }
`;

const RestartButton = styled(CancelButton)`
  border: none;
  background-color: #00cc00;
  color: #000;
  margin-bottom: ${props => props.marginBottom && props.marginBottom};
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 204, 0, 0.9);
  }
`;

const NameContainer = styled.div`
  display: flex;
`;

const EditNameButton = styled.div`
  margin-left: 8px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const ModalInputFieldWithFeedback = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0 20px 0;
`;

const ModalInputFeedbackText = styled.div`
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.7rem;
  font-weight: 300;
  right: -4px;
  bottom: -16px;
`;

const PortalDirectVirtualMachine = ({ setSelectedView, token, userEmail = null }) => {
    const [ vmData, setVmData ] = useState({});
    const [ portForwards, setPortForwards ] = useState([]);
    const [ vmIsLoading, setVmIsLoading ] = useState(true);
    const [ vmIsLoaded, setVmIsLoaded ] = useState(false);
    const [ sshKeyNameIsLoaded, setSshKeyNameIsLoaded ] = useState(false);
    const [ sshKeyName, setSshKeyName ] = useState('');
    const [ newVmName, setNewVmName ] = useState('');
    const [ newNameLength, setNewNameLength ] = useState(0);
    const [ newVmNameModalIsShown, setNewVmNameModalIsShown ] = useState(false);
    const [ nameIsUpdating, setNameIsUpdating ] = useState(false);
    const [ nameFailedToUpdate, setNameFailedToUpdate ] = useState(false);
    const [ vmIsTerminating, setVmIsTerminating ] = useState(false);
    const [ terminateModalIsShown, setTerminateModalIsShown ] = useState(false);
    const [ terminationSucceeded, setTerminationSucceeded ] = useState(false);
    const [ terminationFailed, setTerminationFailed ] = useState(false);
    const [ vmTimestamp, setVmTimestamp ] = useState('');
    const [ totalCost, setTotalCost ] = useState(0.0);
    const [ restartModalIsShown, setRestartModalIsShown ] = useState(false);
    const [ vmIsRestarting, setVmIsRestarting ] = useState(false);
    const [ restartSucceeded, setRestartSucceeded ] = useState(false);
    const [ restartFailed, setRestartFailed ] = useState(false);
    const [ stopModalIsShown, setStopModalIsShown ] = useState(false);
    const [ vmIsStopping, setVmIsStopping ] = useState(false);
    const [ stopSucceeded, setStopSucceeded ] = useState(false);
    const [ stopFailed, setStopFailed ] = useState(false);

    const { server } = useParams();
    const history = useHistory();
    const newNameInputRef = useRef();

    const maxNameLength = 50;

    useEffect(() => {
        if (userEmail) {
            setSelectedView('admin-vm-session');
        } else {
            setSelectedView('vm-session');
        }
    }, [setSelectedView]);

    useEffect(() => {
        if (vmIsLoaded && token && (
            vmData['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID ||
            vmData['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID
        )) {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/sshkeys/sshkey/${vmData['ssh_key_id']}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setSshKeyName(data['name']);
                    setSshKeyNameIsLoaded(true);
                })
                .catch(error => console.log(error));
        }
    }, [vmIsLoaded, token]);

    useEffect(() => {
        if (token && !vmIsLoaded && server) {
            fetchVmDetails();
        }
    }, [token, vmIsLoaded, server]);

    useEffect(() => {
        if (vmData['provider_id'] === process.env.REACT_APP_TENSORDOCK_ID) {
            setTotalCost(
                vmData['gpu_count']*vmData['gpu_cost'] +
                vmData['vcpus']*vmData['cpu_cost'] +
                vmData['ram']*vmData['ram_cost'] +
                vmData['storage']*vmData['storage_cost']
            )
        } else {
            setTotalCost(
                parseFloat(vmData['all_inclusive_running_cost'])
            )
        }
    }, [vmData]);

    useEffect(() => {
        if (newVmNameModalIsShown) {
            newNameInputRef.current.focus();
        }
    }, [newVmNameModalIsShown, newNameInputRef]);

    const handleNewVmNameEntry = e => {
        const enteredText = e.target.value;
        const currentLength = enteredText.length;
        if (currentLength <= maxNameLength) {
            setNewNameLength(currentLength);
            setNewVmName(enteredText);
        }
    }

    const dismissNewVmNameModal = () => {
        setNewVmNameModalIsShown(false);
        setNewVmName('');
    }

    const terminateVm = () => {
        setTerminateModalIsShown(false);
        setVmIsTerminating(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/destroy/${server}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] > 299) {
                    setVmIsTerminating(false);
                    setTerminationFailed(true);
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setVmIsTerminating(false);
                if (data && data['message'] === 'success') {
                    setTerminationSucceeded(true);
                } else {
                    setTerminationFailed(true);
                }
            })
            .catch(error => {
                console.log(error);
                setVmIsTerminating(false);
                setTerminationFailed(true);
            });
    }

    const restartVm = () => {
        setRestartModalIsShown(false);
        setVmIsRestarting(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/start/${server}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] > 299) {
                    setVmIsRestarting(false);
                    setRestartFailed(true);
                } else {
                    return response.json();
                }
            })
            .then(() => {
                setVmIsRestarting(false);
                setRestartSucceeded(true);
            })
            .catch(error => {
                console.log(error);
                setVmIsRestarting(false);
                setRestartFailed(true);
            });
    }

    const stopVm = () => {
        setStopModalIsShown(false);
        setVmIsStopping(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/stop/${server}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] > 299) {
                    setVmIsStopping(false);
                    setStopFailed(true);
                } else {
                    return response.json();
                }
            })
            .then(() => {
                setVmIsStopping(false);
                setStopSucceeded(true);
            })
            .catch(error => {
                console.log(error);
                setVmIsStopping(false);
                setStopFailed(true);
            });
    }

    function fetchVmDetails() {
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/${server}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] > 299) {
                    if (userEmail) {
                        history.push('dashboard/admin');
                    } else {
                        history.push('dashboard/direct?active=sessions');
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                if (data && data['server']) {
                    setVmData(data);
                    setPortForwards(Object.entries(JSON.parse(data['port_forwards'])));
                    let timestamp = data['created_at'] + 'Z';
                    let dateandtime = new Date(timestamp);
                    setVmTimestamp(dateandtime.toLocaleString());
                    setVmIsLoading(false);
                    setVmIsLoaded(true);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleNameChange = () => {
        setNewVmNameModalIsShown(false);
        setNameIsUpdating(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/name`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                server: vmData['server'],
                name: newVmName
            })
        })
            .then(response => {
                if (response.status === 200)  {
                    return response.json();
                } else {
                    setNameFailedToUpdate(true);
                }
            })
            .then(data => {
                if (data) {
                    if ('message' in data) {
                        if (data['message'] !== 'Successfully updated the name of the VM') {
                            setNameFailedToUpdate(true);
                        }
                    } else {
                        setNameFailedToUpdate(true);
                    }
                } else {
                    setNameFailedToUpdate(true);
                }
            })
            .catch(error => {
                setNameFailedToUpdate(true);
                console.log(error);
            })
            .finally(() => {
                setNameIsUpdating(false);
                setNewVmName('');
                setVmIsLoaded(false);
            })
    }

    return (
        <DetailContainer>
            <DetailPanel>
                {vmIsLoading ? (
                    <LoadingCover>
                        <Spinner>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </Spinner>
                    </LoadingCover>
                ) : (
                    <>
                        <Link to={userEmail ? '/dashboard/admin' : '/dashboard/direct?active=sessions'}>
                            <BackButton>
                                <FontAwesomeIcon icon={faAngleLeft} />
                                <span>Back</span>
                            </BackButton>
                        </Link>
                        <DetailInfo>
                            <InfoGroup>
                                <InfoName>
                                    Status
                                </InfoName>
                                <InfoData>
                                    {vmData['status'] === 'running' && (
                                        <SessionStatusTag bgColor="#00c424" color="#000000">
                                            Running
                                        </SessionStatusTag>
                                    )}
                                    {vmData['status'] === 'stopped' && (
                                        <SessionStatusTag bgColor="#cfcfcf" color="#000000">
                                            Stopped
                                        </SessionStatusTag>
                                    )}
                                    {vmData['status'] === 'requested' && (
                                        <SessionStatusTag bgColor="#f5cc00" color="#000000">
                                            Requested
                                        </SessionStatusTag>
                                    )}
                                    {vmData['status'] === 'scheduled' && (
                                        <SessionStatusTag bgColor="#2ed5ff" color="#000000">
                                            Scheduled
                                        </SessionStatusTag>
                                    )}
                                    {vmData['status'] === 'network_ready' && (
                                        <SessionStatusTag bgColor="#2effb2" color="#000000">
                                            Configuring
                                        </SessionStatusTag>
                                    )}
                                    {vmData['status'] === 'terminating' && (
                                        <SessionStatusTag bgColor="#ff0000" color="#ffffff">
                                            Terminating
                                        </SessionStatusTag>
                                    )}
                                    {vmData['status'] === 'error' && (
                                        <SessionStatusTag bgColor="#ed0000" color="#ffffff">
                                            Error
                                        </SessionStatusTag>
                                    )}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Virtual Machine ID
                                </InfoName>
                                <InfoData>
                                    {vmData['server']}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Name
                                </InfoName>
                                <InfoData>
                                    <NameContainer>
                                        {vmData['user_provided_name'] ? (
                                            vmData['user_provided_name']
                                        ) : (
                                            'Unnamed'
                                        )}
                                        <EditNameButton onClick={() => setNewVmNameModalIsShown(true)}>
                                            <FontAwesomeIcon icon={faPencil} />
                                        </EditNameButton>
                                    </NameContainer>
                                </InfoData>
                            </InfoGroup>
                            {userEmail && (
                                <InfoGroup>
                                    <InfoName>
                                        Assigned To
                                    </InfoName>
                                    <InfoData>
                                        {userEmail}
                                    </InfoData>
                                </InfoGroup>
                            )}
                            <InfoGroup>
                                <InfoName>
                                    Operating System
                                </InfoName>
                                <InfoData>
                                    {vmData['operating_system'] === 'TensorML 20 Everything' ? (
                                        'Ubuntu 20.04 + PyTorch + TensorFlow + MxNet + Jupyter'
                                    ) : (
                                        vmData['operating_system'] === 'TensorML 20 TensorFlow' ? (
                                            'Ubuntu 20.04 + TensorFlow + Jupyter'
                                        ) : (
                                            vmData['operating_system'] === 'TensorML 20 PyTorch' ? (
                                                'Ubuntu 20.04 + PyTorch + Jupyter'
                                            ) : (
                                                vmData['operating_system']
                                            )
                                        )
                                    )
                                    }
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    GPUs
                                </InfoName>
                                <InfoData>
                                    {vmData['gpu_count']}x {vmData['pretty_gpu_name']}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    IP Address
                                </InfoName>
                                <InfoData>
                                    {vmData['ip']}
                                </InfoData>
                            </InfoGroup>
                            {(
                                process.env.REACT_APP_TAIGACLOUD_ID === vmData['provider_id'] ||
                                process.env.REACT_APP_GENESIS_CLOUD_ID === vmData['provider_id']
                            ) && (
                                <InfoGroup>
                                    <InfoName>
                                        SSH Key Name
                                    </InfoName>
                                    {sshKeyNameIsLoaded ? (
                                        <InfoData>
                                            {sshKeyName}
                                        </InfoData>
                                    ) : (
                                        <InfoData>
                                            -
                                        </InfoData>
                                    )}
                                </InfoGroup>
                            )}
                            <InfoGroup>
                                <InfoName>
                                    vCPUs
                                </InfoName>
                                <InfoData>
                                    {vmData['vcpus']}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    RAM
                                </InfoName>
                                <InfoData>
                                    {vmData['ram']} GB
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Local Storage Volume
                                </InfoName>
                                <InfoData>
                                    {vmData['storage']} GB
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Port Forwards
                                </InfoName>
                                <InfoData>
                                    {portForwards.length > 0 && portForwards.map(pair => {
                                        return (
                                            <div key={pair[0]}>
                                                {pair[0]} to {pair[1]}
                                            </div>
                                        )
                                    })}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Created At
                                </InfoName>
                                <InfoData>
                                    {vmTimestamp}
                                </InfoData>
                            </InfoGroup>
                            {(vmData['status'] === 'running' && vmData['last_restarted_time'] != null) && (
                                <InfoGroup>
                                    <InfoName>
                                        Last Restarted At
                                    </InfoName>
                                    <InfoData>
                                        {parseTimestamp(vmData['last_restarted_time'])}
                                    </InfoData>
                                </InfoGroup>
                            )}
                            {vmData['status'] === 'stopped' && (
                                <InfoGroup>
                                    <InfoName>
                                        Stopped Since
                                    </InfoName>
                                    <InfoData>
                                        {parseTimestamp(vmData['last_stopped_time'])}
                                    </InfoData>
                                </InfoGroup>
                            )}
                            {(vmData['status'] === 'running' || vmData['status'] === 'stopped') && (
                                <InfoGroup>
                                    <InfoName>
                                        Billing Details
                                    </InfoName>
                                    <InfoData>
                                        <PricingBlockCustom>
                                            <TotalPrice>
                                                {vmData['status'] === 'running' && vmData['provider_id'] === process.env.REACT_APP_TENSORDOCK_ID && (
                                                    <>
                                                        ${totalCost.toFixed(4)}<span>/hour</span>
                                                    </>
                                                )}
                                                {vmData['status'] === 'stopped' && vmData['provider_id'] === process.env.REACT_APP_TENSORDOCK_ID && (
                                                    <>
                                                        ${vmData['storage']*vmData['storage_cost']}<span>/hour</span>
                                                    </>
                                                )}
                                                {vmData['status'] === 'running' && (
                                                    vmData['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID ||
                                                    vmData['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID ||
                                                    vmData['provider_id'] === process.env.REACT_APP_CUDO_CLOUD_ID
                                                ) && (
                                                    <>
                                                        ${parseFloat(totalCost.toFixed(4))}<span>/hour</span>
                                                    </>
                                                )}
                                                {vmData['status'] === 'stopped' && (
                                                    vmData['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID ||
                                                    vmData['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID ||
                                                    vmData['provider_id'] === process.env.REACT_APP_CUDO_CLOUD_ID
                                                ) && (
                                                    <>
                                                        ${parseFloat(parseFloat(vmData['all_inclusive_stopped_cost']).toFixed(4))}<span>/hour</span>
                                                    </>
                                                )}
                                                {(
                                                    vmData['provider_id'] !== process.env.REACT_APP_TAIGACLOUD_ID &&
                                                    vmData['provider_id'] !== process.env.REACT_APP_TENSORDOCK_ID &&
                                                    vmData['provider_id'] !== process.env.REACT_APP_GENESIS_CLOUD_ID &&
                                                    vmData['provider_id'] !== process.env.REACT_APP_CUDO_CLOUD_ID
                                                ) && (
                                                    <>
                                                        ${parseFloat(totalCost.toFixed(4))}<span>/hour</span>
                                                    </>
                                                )}
                                            </TotalPrice>
                                            <SubSectionDivider />
                                            {vmData['provider_id'] === process.env.REACT_APP_TENSORDOCK_ID && (
                                                <>
                                                    <PricingRowCustom opacity={vmData['status'] === 'stopped' ? '0.4' : '1.0'}>
                                                        <PricingCategoryTitle>
                                                            GPU cost:
                                                        </PricingCategoryTitle>
                                                        <PricingCalc>
                                                            ${parseFloat(vmData['gpu_cost']).toFixed(4)}<span>/GPU-hour</span> x {vmData['gpu_count']} = ${vmData['gpu_count']*vmData['gpu_cost']}<span>/hour</span>
                                                        </PricingCalc>
                                                    </PricingRowCustom>
                                                    <PricingRowCustom opacity={vmData['status'] === 'stopped' ? '0.4' : '1.0'}>
                                                        <PricingCategoryTitle>
                                                            CPU cost:
                                                        </PricingCategoryTitle>
                                                        <PricingCalc>
                                                            ${parseFloat(vmData['cpu_cost']).toFixed(4)}<span>/CPU-hour</span> x {vmData['vcpus']} = ${vmData['vcpus']*vmData['cpu_cost']}<span>/hour</span>
                                                        </PricingCalc>
                                                    </PricingRowCustom>
                                                    <PricingRowCustom opacity={vmData['status'] === 'stopped' ? '0.4' : '1.0'}>
                                                        <PricingCategoryTitle>
                                                            RAM cost:
                                                        </PricingCategoryTitle>
                                                        <PricingCalc>
                                                            ${parseFloat(vmData['ram_cost']).toFixed(4)}<span>/GB-hour</span> x {vmData['ram']} GB = ${vmData['ram']*vmData['ram_cost']}<span>/hour</span>
                                                        </PricingCalc>
                                                    </PricingRowCustom>
                                                    <PricingRow>
                                                        <PricingCategoryTitle>
                                                            Storage Cost:
                                                        </PricingCategoryTitle>
                                                        <PricingCalc>
                                                            ${parseFloat(vmData['storage_cost']).toFixed(2)}<span>/GB-hour</span> x {vmData['storage']} GB = ${vmData['storage']*vmData['storage_cost']}<span>/hour</span>
                                                        </PricingCalc>
                                                    </PricingRow>
                                                </>
                                            )}
                                            {(vmData['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID || vmData['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID || vmData['provider_id'] === process.env.REACT_APP_CUDO_CLOUD_ID) && vmData['status'] === 'running' && (
                                                <PricingRowCustom opacity='0.4'>
                                                    <PricingCalc>
                                                        This instance costs ${parseFloat(parseFloat(vmData['all_inclusive_stopped_cost']).toFixed(4))}/hour when stopped.
                                                    </PricingCalc>
                                                </PricingRowCustom>
                                            )}
                                            {(vmData['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID || vmData['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID || vmData['provider_id'] === process.env.REACT_APP_CUDO_CLOUD_ID) && vmData['status'] === 'stopped' && (
                                                <PricingRowCustom opacity='0.4'>
                                                    <PricingCalc>
                                                        This instance costs ${parseFloat(parseFloat(vmData['all_inclusive_running_cost']).toFixed(4))}/hour when running.
                                                    </PricingCalc>
                                                </PricingRowCustom>
                                            )}
                                            {vmData['provider_id'] !== process.env.REACT_APP_TAIGACLOUD_ID && vmData['provider_id'] !== process.env.REACT_APP_TENSORDOCK_ID && vmData['provider_id'] !== process.env.REACT_APP_GENESIS_CLOUD_ID && vmData['provider_id'] !== process.env.REACT_APP_CUDO_CLOUD_ID && (
                                                <PricingRowCustom opacity='0.4'>
                                                    <PricingCalc>
                                                        This instance cannot be stopped or terminated through the dashboard. Please contact VALDI support for assistance.
                                                    </PricingCalc>
                                                </PricingRowCustom>
                                            )}
                                        </PricingBlockCustom>
                                    </InfoData>
                                </InfoGroup>
                            )}
                        </DetailInfo>
                        {vmData['status'] === 'stopped' && (
                            <RestartButton onClick={() => setRestartModalIsShown(true)}>
                                Restart this VM
                            </RestartButton>
                        )}
                        {vmData['status'] === 'running' && (
                            <StopButton onClick={() => setStopModalIsShown(true)}
                                        disabled={vmData['provider_id'] !== process.env.REACT_APP_TAIGACLOUD_ID && vmData['provider_id'] !== process.env.REACT_APP_TENSORDOCK_ID && vmData['provider_id'] !== process.env.REACT_APP_GENESIS_CLOUD_ID && vmData['provider_id'] !== process.env.REACT_APP_CUDO_CLOUD_ID}
                            >
                                Stop this VM
                            </StopButton>
                        )}
                        {(vmData['status'] === 'stopped' || vmData['status'] === 'running') && (
                            <TerminateButton onClick={() => setTerminateModalIsShown(true)}
                                             disabled={vmData['provider_id'] !== process.env.REACT_APP_TAIGACLOUD_ID && vmData['provider_id'] !== process.env.REACT_APP_TENSORDOCK_ID && vmData['provider_id'] !== process.env.REACT_APP_GENESIS_CLOUD_ID && vmData['provider_id'] !== process.env.REACT_APP_CUDO_CLOUD_ID}
                            >
                                Terminate this VM
                            </TerminateButton>
                        )}
                    </>
                )}
            </DetailPanel>
            {newVmNameModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissNewVmNameModal} />
                    <ConfirmModal>
                        <ModalTitle>
                            New VM Name
                        </ModalTitle>
                        <ModalText>
                            Please enter a new name for your VM.
                        </ModalText>
                        <ModalInputFieldWithFeedback>
                            <InputField id="new-vm-name"
                                        customWidth="100%"
                                        value={newVmName}
                                        onChange={handleNewVmNameEntry}
                                        ref={newNameInputRef}
                            />
                            <ModalInputFeedbackText>
                                {newNameLength} / {maxNameLength}
                            </ModalInputFeedbackText>
                        </ModalInputFieldWithFeedback>
                        <ConnectButtonWithDisabling disabled={newVmName === ''}
                                                    onClick={handleNameChange}
                        >
                            Okay
                        </ConnectButtonWithDisabling>
                    </ConfirmModal>
                </>
            )}
            {nameFailedToUpdate && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setNameFailedToUpdate(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            An unexpected error was encountered when trying to update the name of the VM. Please try
                            again later.
                        </ModalText>
                        <ConnectButton onClick={() => setNameFailedToUpdate(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {terminateModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setTerminateModalIsShown(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Are you sure?
                        </ModalTitle>
                        <ModalText>
                            This action cannot be undone. All data stored locally on the device will be lost.
                        </ModalText>
                        <CancelButton onClick={() => setTerminateModalIsShown(false)}>Cancel</CancelButton>
                        <TerminateButton onClick={terminateVm}>Yes, terminate the VM.</TerminateButton>
                    </ConfirmModal>
                </>
            )}
            {(vmIsTerminating || vmIsRestarting || vmIsStopping || nameIsUpdating) && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </ModalTitle>
                    </ConfirmModal>
                </>
            )}
            {terminationSucceeded && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Your VM has been terminated
                        </ModalTitle>
                        <ModalText>
                            You will only be billed for the pro-rated time the VM was reserved, based on the
                            published hourly rates.
                        </ModalText>
                        <ModalFinePrint>
                            Note that it may take a few minutes for the session to disappear from your dashboard.
                        </ModalFinePrint>
                        <Link to="/dashboard/direct?active=sessions"><ConnectButton>Okay</ConnectButton></Link>
                    </ConfirmModal>
                </>
            )}
            {terminationFailed && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            We encountered a problem trying to terminate your VM. Please try again later.
                        </ModalText>
                        <Link to="/dashboard/direct?active=sessions"><ConnectButton>Okay</ConnectButton></Link>
                    </ConfirmModal>
                </>
            )}
            {restartModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setRestartModalIsShown(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Restart?
                        </ModalTitle>
                        <ModalText>
                            If you restart, your VM and data will be accessible again and full resource usage billing
                            will resume.
                        </ModalText>
                        <CancelButton onClick={() => setRestartModalIsShown(false)}>Cancel</CancelButton>
                        <RestartButton marginBottom="0" onClick={restartVm}>Yes, restart the VM.</RestartButton>
                    </ConfirmModal>
                </>
            )}
            {restartSucceeded && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Your VM is restarting
                        </ModalTitle>
                        {vmData['provider_id'] === process.env.REACT_APP_TENSORDOCK_ID && (
                            <ModalText>
                                Billing for all GPU, CPU, RAM, and storage you have allocated will now resume.
                            </ModalText>
                        )}
                        {(
                            vmData['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID ||
                            vmData['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID ||
                            vmData['provider_id'] === process.env.REACT_APP_CUDO_CLOUD_ID
                        ) && (
                            <ModalText>
                                Full resource billing at ${parseFloat(vmData['all_inclusive_running_cost']).toFixed(2)}/hour
                                will now resume.
                            </ModalText>
                        )}
                        <ModalFinePrint>
                            It may take a few minutes for the VM to become accessible again.
                        </ModalFinePrint>
                        <Link to="/dashboard/direct?active=sessions"><ConnectButton>Okay</ConnectButton></Link>
                    </ConfirmModal>
                </>
            )}
            {restartFailed && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Unavailable
                        </ModalTitle>
                        <ModalText>
                            Your VM is currently unavailable. (Don't worry, all data is intact.)<br/><br/>
                            It is likely that the GPU resources have been claimed by another user, in which case you
                            will have to wait until they become available again.<br/><br/>
                            Please check back later.
                        </ModalText>
                        <Link to="/dashboard/direct?active=sessions"><ConnectButton>Okay</ConnectButton></Link>
                    </ConfirmModal>
                </>
            )}
            {stopModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setStopModalIsShown(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Stop this VM?
                        </ModalTitle>
                        {vmData['provider_id'] === process.env.REACT_APP_TENSORDOCK_ID && (
                            <ModalText>
                                If you stop the VM, you will be billed only for storage.<br/><br/>
                                <b>NOTE:</b> Other users may claim the GPUs you are using while your VM is stopped (your
                                data is NOT accessible to them).<br/><br/>
                                However, you will not be able to restart your VM until there is at least one unused GPU
                                attached to this cluster.
                            </ModalText>
                        )}
                        {(
                            vmData['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID ||
                            vmData['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID ||
                            vmData['provider_id'] === process.env.REACT_APP_CUDO_CLOUD_ID
                        ) && (
                            <ModalText>
                                This VM will cost ${parseFloat(parseFloat(vmData['all_inclusive_stopped_cost']).toFixed(5))}/hour
                                while stopped.<br /><br/> You can restart it at any time and your data will be preserved.
                            </ModalText>
                        )}
                        <CancelButton onClick={() => setStopModalIsShown(false)}>Cancel</CancelButton>
                        <StopButton marginBottom="0" onClick={stopVm}>Yes, stop the VM.</StopButton>
                    </ConfirmModal>
                </>
            )}
            {stopSucceeded && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Your VM is stopping
                        </ModalTitle>
                        {vmData['provider_id'] === process.env.REACT_APP_TENSORDOCK_ID && (
                            <>
                                <ModalText>
                                    You will only be billed for storage until you restart the VM.
                                </ModalText>
                                <ModalFinePrint>
                                    You will not be able to restart your VM while its GPUs are claimed by another user.
                                </ModalFinePrint>
                            </>
                        )}
                        {(
                            vmData['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID ||
                            vmData['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID ||
                            vmData['provider_id'] === process.env.REACT_APP_CUDO_CLOUD_ID
                        ) && (
                            <>
                                <ModalText>
                                    You will be billed at ${parseFloat(parseFloat(vmData['all_inclusive_stopped_cost']).toFixed(5))}/hour
                                    until you restart the VM.
                                </ModalText>
                                <ModalFinePrint>
                                    You can restart it at any time and your data will be preserved.
                                </ModalFinePrint>
                            </>
                        )}
                        <Link to="/dashboard/direct?active=sessions"><ConnectButton>Okay</ConnectButton></Link>
                    </ConfirmModal>
                </>
            )}
            {stopFailed && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was a problem stopping your VM. Please contact VALDI support
                            using the chat widget in the lower-right corner, or try again later.
                        </ModalText>
                        <Link to="/dashboard/direct?active=sessions"><ConnectButton>Okay</ConnectButton></Link>
                    </ConfirmModal>
                </>
            )}
        </DetailContainer>
    );
}

export default PortalDirectVirtualMachine;