import styled from "styled-components";
import ValdiLogo from "./assets/logo.png";
import { LandingContainerLight } from "./LandingPricing";
import { ContentContainer } from "./LandingLearnMore";
import { faLinkedin, faMailchimp, faMedium, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterGrid = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 75px;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (max-width: 648px) {
    justify-content: center;
  }
`;

const FooterBrandColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const LogoAndCopyright = styled.div`
  @media screen and (max-width: 648px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Logo = styled.div`
  & img {
    width: 100%;
  }
  width: 190px;
`;

const Copyright = styled.div`
  margin-top: 5px;
  font-size: 0.7rem;
  opacity: 0.6;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 15px;
`;

const SocialLink = styled.a`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: #1a1c47;
  background-color: #fff;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:visited {
    color: #1a1c47;
  }
  &:active {
    color: #1a1c47;
  }
  &:hover {
    background: linear-gradient(to right, #9438c2, #ff1f82);
    color: #fff;
  }
`;

const LinkColumns = styled.div`
  display: flex;
  gap: 75px;
  flex-wrap: wrap;
  @media screen and (max-width: 373px) {
    justify-content: center;
    flex-direction: column;
    gap: 30px;
  }
`;

const FooterLinkColumn = styled.div`
  line-height: 2;
  @media screen and (max-width: 373px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLinkColumnTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

const FooterLink = styled.a`
  display: block;
  font-size: 0.8rem;
  color: #d2d2d2;
  &:visited {
    color: #d2d2d2;
  }
  &:active {
    color: #d2d2d2;
  }
  &:hover {
    color: #ff1f82;
  }
`;

const LandingFooter = ({ lang }) => {
    return (
        <LandingContainerLight>
            <ContentContainer>
                <FooterGrid>
                    <FooterBrandColumn>
                        <LogoAndCopyright>
                            <Logo>
                                <img src={ValdiLogo} />
                            </Logo>
                            <Copyright>
                                Copyright © 2024 VALDI. All rights reserved.
                            </Copyright>
                        </LogoAndCopyright>
                        <SocialLinks>
                            <SocialLink href="https://www.linkedin.com/company/valdilabs/"
                                        target="_blank"
                                        rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faLinkedin} />
                            </SocialLink>
                            <SocialLink href="http://eepurl.com/isSfIA" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faMailchimp} />
                            </SocialLink>
                            <SocialLink href="https://medium.com/@valdilabs" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faMedium} />
                            </SocialLink>
                            <SocialLink href="https://x.com/ValdiLabs" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faXTwitter} />
                            </SocialLink>
                        </SocialLinks>
                    </FooterBrandColumn>
                    <LinkColumns>
                        <FooterLinkColumn>
                            <FooterLinkColumnTitle>
                                Legal
                            </FooterLinkColumnTitle>
                            <FooterLink href="https://docs.valdi.ai/legal/terms-of-service/"
                                        target="_blank"
                                        rel="noreferrer"
                            >
                                Terms of Service
                            </FooterLink>
                            <FooterLink href="https://docs.valdi.ai/legal/privacy-policy/"
                                        target="_blank"
                                        rel="noreferrer"
                            >
                                Privacy Policy
                            </FooterLink>
                            <FooterLink href="https://docs.valdi.ai/legal/acceptable-use/"
                                        target="_blank"
                                        rel="noreferrer"
                            >
                                Acceptable Use Policy
                            </FooterLink>
                            <FooterLink href="https://docs.valdi.ai/legal/data-processing/"
                                        target="_blank"
                                        rel="noreferrer"
                            >
                                Data Processing Policy
                            </FooterLink>
                        </FooterLinkColumn>
                        <FooterLinkColumn>
                            <FooterLinkColumnTitle>
                                Support
                            </FooterLinkColumnTitle>
                            <FooterLink href="https://docs.valdi.ai/"
                                        target="_blank"
                                        rel="noreferrer"
                            >
                                Documentation
                            </FooterLink>
                            <FooterLink href="mailto:cloud@valdi.ai">
                                Contact Us
                            </FooterLink>
                        </FooterLinkColumn>
                    </LinkColumns>
                </FooterGrid>
            </ContentContainer>
        </LandingContainerLight>
    );
}

export default LandingFooter;