import styled from "styled-components";
import { LandingContainerDark } from "./LandingTeam";
import { ContentContainer } from "./LandingLearnMore";
import { SectionTitle } from "./LandingTeam";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const LandingContainerLight = styled(LandingContainerDark)`
  background-color: #121331;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const CustomSectionTitle = styled(SectionTitle)`
  margin-top: 200px;
`;

const PricingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 100px;
`;

const PricingRowBorder = styled.div`
  border-radius: 11px;
  background: linear-gradient(to right, #2bdffd, #a020f0);
  position: relative;
  padding: 0;
  &:before {
    content: "";
    position: absolute;
    border-radius: 10px;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: -1px;
    background: inherit;
    opacity: 0;
  }
  &:hover:before {
    opacity: 1;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

const PricingRow = styled(Link)`
  background-color: #121331;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100px;
  width: 650px;
  padding: 0 40px;
  justify-content: space-between;
  cursor: pointer;
  &:hover ${PricingRowBorder} {
    opacity: 1;
  }
  position: relative;
  z-index: 2;
  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 0 20px;
  }
  @media screen and (max-width: 570px) {
    height: 150px;
  }
  @media screen and (max-width: 410px) {
    height: 200px;
  }
`;

const DeviceName = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  margin-right: 15px;
`;

const Tags = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 700px) {
    & > :first-child {
      order: 2;
    }
    & > :last-child {
      order: 1;
    }
  }
`;

const PriceTag = styled.div`
  background: linear-gradient(to right, #9438c2, #ff1f82);
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  padding: 5px 0;
  @media screen and (max-width: 700px) {
    width: 100px;
  }
`;

const PriceTagOutlineOnly = styled(PriceTag)`
  background: #121331;
  position: relative;
  z-index: 2;
`;

const PriceTagOutline = styled.div`
  border-radius: 5px;
  background: linear-gradient(to right, #9438c2, #ff1f82); 
  position: relative;
  padding: 0;
  &:before {
    content: "";
    position: absolute;
    border-radius: 5px;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: -1px;
    background: inherit;
  }
`;

const PriceTagOutlineOnlyText = styled.div`
  background: linear-gradient(to right, #9438c2, #ff1f82);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  white-space: nowrap;
`;

const FlagTag = styled.div`
  background-color: #1a1c47;
  border-radius: 5px;
  padding: 5px 0;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LandingPricing = ({ scrollToRef, setActiveView }) => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setActiveView('pricing');
                }
            },
            { threshold: 0.3 }
        );

        if (scrollToRef.current) {
            observer.observe(scrollToRef.current);
        }

        return () => {
            if (scrollToRef.current) {
                observer.unobserve(scrollToRef.current);
            }
        }
    }, []);

    return (
        <LandingContainerLight ref={scrollToRef}>
            <ContentContainer>
                <CustomSectionTitle>
                    Pricing
                </CustomSectionTitle>
                <PricingList>
                    <PricingRowBorder>
                        <PricingRow to="/signup">
                            <DeviceName>
                                NVIDIA H100 (80 GB PCIe)
                            </DeviceName>
                            <Tags>
                                <FlagTag>🌎</FlagTag>
                                <PriceTagOutline>
                                    <PriceTagOutlineOnly>
                                        <PriceTagOutlineOnlyText>
                                            Coming soon!
                                        </PriceTagOutlineOnlyText>
                                    </PriceTagOutlineOnly>
                                </PriceTagOutline>
                            </Tags>
                        </PricingRow>
                    </PricingRowBorder>
                    <PricingRowBorder>
                        <PricingRow to="/signup">
                            <DeviceName>
                                NVIDIA A100 (80 GB PCIe)
                            </DeviceName>
                            <Tags>
                                <FlagTag>🇺🇸</FlagTag>
                                <PriceTag>
                                    $2.04/hr.
                                </PriceTag>
                            </Tags>
                        </PricingRow>
                    </PricingRowBorder>
                    <PricingRowBorder>
                        <PricingRow to="/signup">
                            <DeviceName>
                                NVIDIA RTX A6000 (48 GB PCIe)
                            </DeviceName>
                            <Tags>
                                <FlagTag>🇬🇧</FlagTag>
                                <PriceTag>
                                    $0.66/hr.
                                </PriceTag>
                            </Tags>
                        </PricingRow>
                    </PricingRowBorder>
                    <PricingRowBorder>
                        <PricingRow to="/signup">
                            <DeviceName>
                                NVIDIA Tesla V100 (16 GB PCIe)
                            </DeviceName>
                            <Tags>
                                <FlagTag>🇺🇸</FlagTag>
                                <PriceTag>
                                    $0.29/hr.
                                </PriceTag>
                            </Tags>
                        </PricingRow>
                    </PricingRowBorder>
                    <PricingRowBorder>
                        <PricingRow to="/signup">
                            <DeviceName>
                                NVIDIA RTX 3090 (24 GB PCIe)
                            </DeviceName>
                            <Tags>
                                <FlagTag>🇺🇸</FlagTag>
                                <PriceTag>
                                    $0.29/hr.
                                </PriceTag>
                            </Tags>
                        </PricingRow>
                    </PricingRowBorder>
                    <PricingRowBorder>
                        <PricingRow to="/signup">
                            <DeviceName>
                                NVIDIA A5000 (24 GB PCIe)
                            </DeviceName>
                            <Tags>
                                <FlagTag>🇮🇳</FlagTag>
                                <PriceTag>
                                    $0.28/hr.
                                </PriceTag>
                            </Tags>
                        </PricingRow>
                    </PricingRowBorder>
                    <PricingRowBorder>
                        <PricingRow to="/signup">
                            <DeviceName>
                                NVIDIA RTX 3080 Ti (12 GB PCIe)
                            </DeviceName>
                            <Tags>
                                <FlagTag>🇺🇸</FlagTag>
                                <PriceTag>
                                    $0.25/hr.
                                </PriceTag>
                            </Tags>
                        </PricingRow>
                    </PricingRowBorder>
                    <PricingRowBorder>
                        <PricingRow to="/signup">
                            <DeviceName>
                                NVIDIA RTX A4000 (16 GB PCIe)
                            </DeviceName>
                            <Tags>
                                <FlagTag>🇺🇸</FlagTag>
                                <PriceTag>
                                    $0.22/hr.
                                </PriceTag>
                            </Tags>
                        </PricingRow>
                    </PricingRowBorder>
                </PricingList>
            </ContentContainer>
        </LandingContainerLight>
    );
}

export default LandingPricing;