import { useLocation } from "react-router-dom";
import PortalDirectVirtualMachine from "./PortalDirectVirtualMachine";

const PortalAdminVM = ({ setSelectedView, token }) => {
    const location = useLocation();
    const userEmail = location.state.userEmail;
    return (
        <PortalDirectVirtualMachine setSelectedView={setSelectedView}
                                    token={token}
                                    userEmail={userEmail}
        />
    );
}

export default PortalAdminVM;