import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import {
    DetailContainer,
    DetailPanel,
    DetailInfo,
    InfoGroup,
    InfoName,
    InfoData,
    LoadingCover,
    Spinner,
    BackButton,
    SectionDivider,
    SubSectionTitle,
    DropDownListAlt
} from "./SharedStyles";
import {
    InputGroupHorizontal,
    InputLabel,
    InputField,
    ConnectButtonWithDisabling
} from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-light-svg-icons";

const SectionDividerLessMargin = styled(SectionDivider)`
  margin: 0;
`;

const InputSuperGroupDiv = styled.div`
  display:flex;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
  gap: 15px;
`;

const ButtonNoMargin = styled(ConnectButtonWithDisabling)`
  margin: 0 0 0 10px;
  width: 150px;
`;

const PortalAdminUser = ({ setSelectedView, token }) => {
    const [ userInfoIsLoading, setUserInfoIsLoading ] = useState(true);
    const [ userId, setUserId ] = useState(0);
    const [ userEmail, setUserEmail ] = useState('-');
    const [ username, setUsername ] = useState('-');
    const [ country, setCountry ] = useState('-');
    const [ stripeId, setStripeId ] = useState('-');
    const [ referralCode, setReferralCode ] = useState('-');
    const [ discountRate, setDiscountRate ] = useState(0.0);
    const [ registrationDate, setRegistrationDate ] = useState('');

    const [ emailIsVerified, setEmailIsVerified ] = useState(false);
    const [ isAdmin, setIsAdmin ] = useState(false);
    const [ isAdminApproved, setIsAdminApproved ] = useState(false);
    const [ isVip, setIsVip ] = useState(false);

    const [ userBalanceIsLoading, setUserBalanceIsLoading ] = useState(true);
    const [ userBalance, setUserBalance ] = useState(0.0);

    const [ targetUserStatus, setTargetUserStatus ] = useState('unapproved');
    const [ balanceAdjustmentAmount, setBalanceAdjustmentAmount ] = useState('0');
    const [ targetDiscountRate, setTargetDiscountRate ] = useState('0');

    const [ userStatusIsChanging, setUserStatusIsChanging ] = useState(false);
    const [ userBalanceIsUpdating, setUserBalanceIsUpdating ] = useState(false);
    const [ discountRateIsChanging, setDiscountRateIsChanging ] = useState(false);
    const [ vipStatusIsChanging, setVipStatusIsChanging ] = useState(false);

    const [ userStatusChangedSuccessfully, setUserStatusChangedSuccessfully ] = useState(false);
    const [ userBalanceChangedSuccessfully, setUserBalanceChangedSuccessfully ] = useState(false);
    const [ discountRateChangedSuccessfully, setDiscountRateChangedSuccessfully ] = useState(false);

    const [ issueChangingUserStatus, setIssueChangingUserStatus ] = useState(false);
    const [ issueChangingUserBalance, setIssueChangingUserBalance ] = useState(false);
    const [ issueChangingDiscountRate, setIssueChangingDiscountRate ] = useState(false);
    const [ issueChangingVipStatus, setIssueChangingVipStatus ] = useState(false);

    const { email } = useParams();

    useEffect(() => {
        setSelectedView('admin-customer');
    }, [setSelectedView]);

    useEffect(() => {
        if (token) {
            fetchUserInfo();
            fetchUserBalance();
        }
    }, [token]);

    useEffect(() => {
        if (userStatusChangedSuccessfully) {
            const clearFeedback = setTimeout(() => {
                setUserStatusChangedSuccessfully(false);
                fetchUserInfo();
            }, 2200);
            return () => clearTimeout(clearFeedback);
        }
    }, [userStatusChangedSuccessfully]);

    useEffect(() => {
        if (issueChangingUserStatus) {
            const clearFeedback = setTimeout(() => {
                setIssueChangingUserStatus(false);
            }, 2200);
            return () => clearTimeout(clearFeedback);
        }
    }, [issueChangingUserStatus]);

    useEffect(() => {
        if (userBalanceChangedSuccessfully) {
            const clearFeedback = setTimeout(() => {
                setUserBalanceChangedSuccessfully(false);
                fetchUserBalance();
            }, 2200);
            return () => clearTimeout(clearFeedback);
        }
    });

    useEffect(() => {
        if (issueChangingUserBalance) {
            const clearFeedback = setTimeout(() => {
                setIssueChangingUserBalance(false);
            }, 2200);
            return () => clearTimeout(clearFeedback);
        }
    }, [issueChangingUserBalance]);

    useEffect(() => {
        if (discountRateChangedSuccessfully) {
            const clearFeedback = setTimeout(() => {
                setDiscountRateChangedSuccessfully(false);
                fetchUserInfo();
            }, 2200);
            return () => clearTimeout(clearFeedback);
        }
    }, [discountRateChangedSuccessfully]);

    useEffect(() => {
        if (issueChangingDiscountRate) {
            const clearFeedback = setTimeout(() => {
                setIssueChangingDiscountRate(false);
            }, 2200);
            return () => clearTimeout(clearFeedback);
        }
    }, [issueChangingDiscountRate]);

    useEffect(() => {
        if (issueChangingVipStatus) {
            const clearFeedback = setTimeout(() => {
                setIssueChangingVipStatus(false);
            }, 2200);
            return () => clearTimeout(clearFeedback);
        }
    }, [issueChangingVipStatus]);

    function fetchUserInfo() {
        setUserInfoIsLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/user?email=${email}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setUserId(data['user_id']);
                    setUserEmail(data['email']);
                    setUsername(data['username']);
                    setCountry(data['country']);
                    setRegistrationDate(data['registration_date']);
                    setStripeId(data['stripe_id']);
                    setDiscountRate(data['discount_rate']);
                    setTargetDiscountRate(String(parseFloat(data['discount_rate'])));
                    setReferralCode(data['referral_code']);
                    setIsAdminApproved(data['is_admin_approved']);
                    setIsVip(data['is_vip']);

                    if (data['account_status'] === 'active') {
                        setEmailIsVerified(true);
                    }
                    if (data['role'] === 'admin') {
                        setIsAdmin(true);
                    }
                    if (data['is_admin_approved'] === true) {
                        setTargetUserStatus('approved');
                    }

                    setUserInfoIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function fetchUserBalance() {
        setUserBalanceIsLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/user/balance?email=${email}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setUserBalance(data['balance']);
                    setUserBalanceIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function formatDateTime(datetimeStr) {
        // Remove fractional seconds if present, as they are not supported by JavaScript's Date constructor
        datetimeStr = datetimeStr.replace(/\.\d+/, '');
        const dateObj = new Date(datetimeStr + 'Z'); // 'Z' is appended to make sure the datetime string is treated as UTC
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        const formattedDateStr = new Intl.DateTimeFormat('en-US', options).format(dateObj);
        return formattedDateStr;
    }

    function formatAccountBalance(balance) {
        balance = parseFloat(balance);
        if (balance < 0) {
            return `-$${Math.abs(balance)}`;
        } else {
            return `$${balance}`;
        }
    }

    function handleTargetDiscountRateEntry(e) {
        if (e.target.value === '' || (parseFloat(e.target.value) >= 0 && parseFloat(e.target.value) <= 1)) {
            setTargetDiscountRate(e.target.value);
        }
    }

    function handleUserStatusUpdate() {
        setUserStatusIsChanging(true);
        if (targetUserStatus === 'approved') {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/user/activate`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({email: userEmail})
            })
                .then(response => response.json())
                .then(data => {
                    setUserStatusIsChanging(false);
                    if ('message' in data) {
                        if (data['message'] === 'The user has been activated.') {
                            setUserStatusChangedSuccessfully(true);
                        } else {
                            setIssueChangingUserStatus(true);
                        }
                    } else {
                        setIssueChangingUserStatus(true);
                    }
                })
                .catch(error => {
                    setUserStatusIsChanging(false);
                    setIssueChangingUserStatus(true);
                    console.log(error);
                });
        } else if (targetUserStatus === 'unapproved') {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/user/deactivate`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({email: userEmail})
            })
                .then(response => response.json())
                .then(data => {
                    setUserStatusIsChanging(false);
                    if ('message' in data) {
                        if (data['message'] === 'The user has been deactivated.') {
                            setUserStatusChangedSuccessfully(true);
                        } else {
                            setIssueChangingUserStatus(true);
                        }
                    } else {
                        setIssueChangingUserStatus(true);
                    }
                })
                .catch(error => {
                    setUserStatusIsChanging(false);
                    setIssueChangingUserStatus(true);
                    console.log(error);
                });
        }
    }

    function handleBalanceAdjustment() {
        setUserBalanceIsUpdating(true);
        const payload = {
            amount: String(Math.abs(parseFloat(balanceAdjustmentAmount))),
            user_id: userId,
            type: parseFloat(balanceAdjustmentAmount) < 0 ? 'debit' : 'credit',
            memo: 'From Admin Console'
        }
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/balance/adjustment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                setUserBalanceIsUpdating(false);
                if ('message' in data) {
                    if (data['message'].includes('Successfully')) {
                        setUserBalanceChangedSuccessfully(true);
                        setBalanceAdjustmentAmount('0');
                    } else {
                        setIssueChangingUserBalance(true);
                    }
                } else {
                    setIssueChangingUserBalance(true);
                }
            })
            .catch(error => {
                setUserBalanceIsUpdating(false);
                setIssueChangingUserBalance(true);
                console.log(error);
            });
    }

    function handleDiscountRateChange() {
        setDiscountRateIsChanging(true);
        const payload = {
            email: userEmail,
            discount: parseFloat(targetDiscountRate)
        }
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/user/discount`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                setDiscountRateIsChanging(false);
                if ('message' in data) {
                    if (data['message'].includes('updated')) {
                        setDiscountRateChangedSuccessfully(true);
                        setTargetDiscountRate('0');
                    } else {
                        setIssueChangingDiscountRate(true);
                    }
                } else {
                    setIssueChangingDiscountRate(true);
                }
            })
            .catch(error => {
                setDiscountRateIsChanging(false);
                setIssueChangingDiscountRate(true);
                console.log(error);
            });
    }

    async function handleVipStatusChange() {
        try {
            setVipStatusIsChanging(true);
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/user/vip`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email: userEmail})
            });
            if (response.ok) {
                fetchUserInfo();
            } else {
                setIssueChangingVipStatus(true);
            }
        } catch (error) {
            console.log(error);
            setIssueChangingVipStatus(true);
        } finally {
            setVipStatusIsChanging(false);
        }
    }

    return (
        <DetailContainer>
            <DetailPanel>
                {userInfoIsLoading ? (
                    <LoadingCover>
                        <Spinner>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </Spinner>
                    </LoadingCover>
                ) : (
                    <>
                        <Link to="/dashboard/admin">
                            <BackButton>
                                <FontAwesomeIcon icon={faAngleLeft} />
                                <span>Back</span>
                            </BackButton>
                        </Link>
                        <DetailInfo>
                            <InfoGroup>
                                <InfoName>
                                    User Email
                                </InfoName>
                                <InfoData>
                                    {userEmail}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Username
                                </InfoName>
                                <InfoData>
                                    {username}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Account Balance
                                </InfoName>
                                <InfoData>
                                    {userBalanceIsLoading ? (
                                        '-'
                                    ) : (
                                        formatAccountBalance(userBalance)
                                    )}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Country
                                </InfoName>
                                <InfoData>
                                    {country}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Stripe ID
                                </InfoName>
                                <InfoData>
                                    {stripeId}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Referral code
                                </InfoName>
                                <InfoData>
                                    {referralCode}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Discount Rate
                                </InfoName>
                                <InfoData>
                                    {100*discountRate}%
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Registered on
                                </InfoName>
                                <InfoData>
                                    {formatDateTime(registrationDate)}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Is email verified?
                                </InfoName>
                                <InfoData>
                                    {emailIsVerified ? 'Yes' : 'No'}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Is admin approved?
                                </InfoName>
                                <InfoData>
                                    {isAdminApproved ? 'Yes' : 'No'}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Is user an admin?
                                </InfoName>
                                <InfoData>
                                    {isAdmin ? 'Yes' : 'No'}
                                </InfoData>
                            </InfoGroup>
                            <SectionDividerLessMargin />
                            <SubSectionTitle>Control Center</SubSectionTitle>
                            <InputSuperGroupDiv>
                                <InputGroupHorizontal>
                                    <InputLabel htmlFor="user-status">Approval status: </InputLabel>
                                    <DropDownListAlt value={targetUserStatus}
                                                     id="user-status"
                                                     onChange={e => setTargetUserStatus(e.target.value)}
                                    >
                                        <option value="approved">Approved</option>
                                        <option value="unapproved">Unapproved</option>
                                    </DropDownListAlt>
                                    <ButtonNoMargin
                                        disabled={(targetUserStatus === 'approved' && isAdminApproved) ||
                                            (targetUserStatus === 'unapproved' && !isAdminApproved)}
                                        onClick={handleUserStatusUpdate}
                                    >
                                        Update
                                    </ButtonNoMargin>
                                    {userStatusIsChanging && (
                                        <FontAwesomeIcon icon={faCircleNotch}
                                                         style={{marginLeft: "4px"}}
                                                         spin
                                    />)}
                                    {userStatusChangedSuccessfully && (
                                        <FontAwesomeIcon icon={faCheck}
                                                         style={
                                            {color: "#00dd00", fontSize: "1.2rem", marginLeft: "4px"}
                                        }/>
                                    )}
                                    {issueChangingUserStatus && (
                                        <FontAwesomeIcon icon={faXmark}
                                                         style={
                                            {color: "#ee0000", fontSize: "1.2rem", marginLeft: "4px"}
                                        }/>
                                    )}
                                </InputGroupHorizontal>
                                <InputGroupHorizontal>
                                    <InputLabel htmlFor="balance-adjustment">Balance adjustment amount: </InputLabel>
                                    <InputField id="balance-adjustment"
                                                type="number"
                                                customWidth="100px"
                                                value={balanceAdjustmentAmount}
                                                onChange={e => setBalanceAdjustmentAmount(e.target.value)}
                                    />
                                    <ButtonNoMargin
                                        disabled={parseFloat(balanceAdjustmentAmount) === 0 ||
                                            balanceAdjustmentAmount === ''}
                                        onClick={handleBalanceAdjustment}
                                    >
                                        Update
                                    </ButtonNoMargin>
                                    {userBalanceIsUpdating && (
                                        <FontAwesomeIcon icon={faCircleNotch}
                                                         style={{marginLeft: "4px"}}
                                                         spin
                                        />)}
                                    {userBalanceChangedSuccessfully && (
                                        <FontAwesomeIcon icon={faCheck}
                                                         style={
                                                             {color: "#00dd00", fontSize: "1.2rem", marginLeft: "4px"}
                                                         }/>
                                    )}
                                    {issueChangingUserBalance && (
                                        <FontAwesomeIcon icon={faXmark}
                                                         style={
                                                             {color: "#ee0000", fontSize: "1.2rem", marginLeft: "4px"}
                                                         }/>
                                    )}
                                </InputGroupHorizontal>
                                <InputGroupHorizontal>
                                    <InputLabel htmlFor="dr-adjustment">Discount rate: </InputLabel>
                                    <InputField id="dr-adjustment"
                                                type="number"
                                                customWidth="100px"
                                                value={targetDiscountRate}
                                                onChange={handleTargetDiscountRateEntry}
                                                min="0"
                                                max="1"
                                                step="0.01"
                                    />
                                    <ButtonNoMargin
                                        disabled={parseFloat(targetDiscountRate) === discountRate ||
                                            targetDiscountRate === ''}
                                        onClick={handleDiscountRateChange}
                                    >
                                        Update
                                    </ButtonNoMargin>
                                    {discountRateIsChanging && (
                                        <FontAwesomeIcon icon={faCircleNotch}
                                                         style={{marginLeft: "4px"}}
                                                         spin
                                        />
                                    )}
                                    {discountRateChangedSuccessfully && (
                                        <FontAwesomeIcon icon={faCheck}
                                                         style={
                                                             {color: "#00dd00", fontSize: "1.2rem", marginLeft: "4px"}
                                                         }/>
                                    )}
                                    {issueChangingDiscountRate && (
                                        <FontAwesomeIcon icon={faXmark}
                                                         style={
                                                             {color: "#ee0000", fontSize: "1.2rem", marginLeft: "4px"}
                                                         }/>
                                    )}
                                </InputGroupHorizontal>
                                <InputGroupHorizontal>
                                    <InputLabel htmlFor="vip-toggle">VIP? </InputLabel>
                                    <input id="vip-toggle"
                                           type="checkbox"
                                           checked={isVip}
                                           onChange={handleVipStatusChange}
                                           style={{marginLeft: "2px"}}
                                    />
                                    {vipStatusIsChanging && (
                                        <FontAwesomeIcon icon={faCircleNotch}
                                                         style={{marginLeft: "4px"}}
                                                         spin
                                        />
                                    )}
                                    {issueChangingVipStatus && (
                                        <FontAwesomeIcon icon={faXmark}
                                                         style={
                                                             {color: "#ee0000", fontSize: "1.2rem", marginLeft: "4px"}
                                                         }/>
                                    )}
                                </InputGroupHorizontal>
                            </InputSuperGroupDiv>
                        </DetailInfo>
                    </>
                )}
            </DetailPanel>
        </DetailContainer>
    );
}

export default PortalAdminUser;