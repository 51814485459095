import styled from "styled-components";

const FooterSuperContainer = styled.div`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
`;

const FooterContainer = styled.div`
  background-color: #1a1c47;
  width: 100%;
  display: flex;
  max-width: 1300px;
  align-items: center;
  padding: 30px 50px;
  & a {
    margin-left: 40px;
  }
  & a:first-child {
    margin-left: 0;
  }
  @media screen and (max-width: 400px) {
    padding: 30px 25px;
  }
`;

const FooterLink = styled.div`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
`;

const FooterCopyright = styled.div`
  font-size: 0.6rem;
  color: rgba(255, 255, 255, 0.6);
  padding-left: 40px;
`;

const PortalFooter = () => {
    return (
        <FooterSuperContainer>
            <FooterContainer>
                <a href="https://docs.valdi.ai/legal/terms-of-service/" target="_blank" rel="noreferrer">
                    <FooterLink>
                        Terms of Service
                    </FooterLink>
                </a>
                <a href="https://docs.valdi.ai/legal/privacy-policy/" target="_blank" rel="noreferrer">
                    <FooterLink>
                        Privacy Policy
                    </FooterLink>
                </a>
                <FooterCopyright>
                    © 2024 Valdi Labs PBC
                </FooterCopyright>
            </FooterContainer>
        </FooterSuperContainer>
    )
}

export default PortalFooter;