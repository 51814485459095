import styled from "styled-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    LandingComputeContainer,
    InventoryContainer,
    HeroMessage,
    HeroMessageBold,
    HeroMessageHighlightLineAlt,
    HeroMessageRegularLine,
    HeroSubtext,
    DirectContainer,
    HeroButton
} from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRightArrowLeft,
    faLock,
    faCircleDollar,
    faSquareCode,
    faRocketLaunch,
    faFloppyDisk,
    faShareNodes,
    faCloudBinary
} from "@fortawesome/sharp-regular-svg-icons";
import StorageCalculator from "./StorageCalculator";
import CliCode from "./assets/valdi-cli-code.png";

const CalculatorSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: 60px;
`;

export const FeaturesSection = styled.div`
  background-color: #121331;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 40px 120px 40px;
`;

export const FeaturesContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  align-items: center;
`;

export const FeaturesTitle = styled.div`
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const FeaturesDescription = styled.div`
  font-size: 1.2rem;
  text-align: center;
  max-width: 600px;
  & span {
    color: #2bdffd;
  }
`;

export const FeaturesGrid = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 50px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 525px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #292c6e;
  border-radius: 10px;
  padding: 20px 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  gap: 20px;
  @media screen and (max-width: 425px) {
    padding: 10px 10px 40px 10px;
  }
`;

export const GridItemGraphic = styled.div`
  font-size: 7rem;
  @media screen and (max-width: 425px) {
    margin-bottom: -20px;
  }
`;

export const GridItemContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

export const GridItemTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: #2bdffd;
`;

export const GridItemDescription = styled.div`
`;

export const CliSection = styled.div`
  background-color: #1a1c47;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 40px;
  flex-direction: column;
  overflow: hidden;
`;

export const CliSectionTitle = styled.div`
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  background: linear-gradient(to right, #2bdffd, #a020f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const CliSectionContent = styled.div`
  display: flex;
  max-width: 1100px;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

export const CliSectionCopy = styled.div`
  font-size: 1.6rem;
  flex: 1;
  text-align: right;
  min-width: 280px;
  & span {
    color: #2bdffd;
  }
  @media screen and (max-width: 800px) {
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  min-width: 441px;
  & img {
    width: 100%;
  }
  @media screen and (max-width: 530px) {
    min-width: 280px;
  }
`;

export const UseCasesSection = styled(FeaturesSection)`
  //background-color: #292c6e;
  background-color: #121331;
  padding: 60px 40px 60px 40px;
`;

export const UseCasesTitle = styled(FeaturesTitle)`
  background: linear-gradient(to right, #2bdffd, #a020f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  & span {
    background: none;
    color: #fff;
    -webkit-background-clip: border-box;
    -webkit-text-fill-color: currentcolor;
  }
`;

export const FeaturesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
`;

export const ListItem = styled.div`
  padding: 20px 25px;
  background-color: #1a1c47;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListItemIcon = styled.div`
  font-size: 3rem;
`;

export const ListItemTitle = styled.div`
  font-size: 1.7rem;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
`;

export const ListItemContent = styled.div`
  font-size: 1.05rem;
  text-align: center;
`;

const LandingStorage = ({ setActiveView, bannerHeight }) => {
    useEffect(() => {
        setActiveView('storage');
    }, [setActiveView]);

    return (
        <LandingComputeContainer>
            <DirectContainer>
                <InventoryContainer topMarginOffset={bannerHeight}>
                    <HeroMessage>
                        <HeroMessageBold>
                            <HeroMessageHighlightLineAlt>
                                Storage & Compute.
                            </HeroMessageHighlightLineAlt>
                            <HeroMessageRegularLine>
                                Two peas in a pod.
                            </HeroMessageRegularLine>
                        </HeroMessageBold>
                        <HeroSubtext>
                            Access your data across all of your VMs at once. Infinitely scalable file system storage for
                            only a penny per GB.
                        </HeroSubtext>
                    </HeroMessage>
                    <CalculatorSection>
                        <StorageCalculator />
                    </CalculatorSection>
                </InventoryContainer>
            </DirectContainer>
            <FeaturesSection>
                <FeaturesContent>
                    <FeaturesTitle>
                        Infinite space. Infinite possibilities.
                    </FeaturesTitle>
                    <FeaturesDescription>
                        With VALDI storage, you can upload and download files with ease, either through the dashboard
                        or with the easy-to-use VALDI CLI. Spend less time migrating data and more time innovating.
                    </FeaturesDescription>
                    <FeaturesGrid>
                        <GridItem>
                            <GridItemGraphic>
                                <FontAwesomeIcon icon={faLock} />
                            </GridItemGraphic>
                            <GridItemContent>
                                <GridItemTitle>
                                    Enterprise-grade security.
                                </GridItemTitle>
                                <GridItemDescription>
                                    Massively scalable, globally distributed, and all your data and metadata is
                                    encrypted with AES-256.
                                </GridItemDescription>
                            </GridItemContent>
                        </GridItem>
                        <GridItem>
                            <GridItemGraphic>
                                <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                            </GridItemGraphic>
                            <GridItemContent>
                                <GridItemTitle>
                                    Integration with VMs.
                                </GridItemTitle>
                                <GridItemDescription>
                                    Save your work. Quickly migrate to any new instance. Ideal for storing large
                                    datasets and model weights.
                                </GridItemDescription>
                            </GridItemContent>
                        </GridItem>
                        <GridItem>
                            <GridItemGraphic>
                                <FontAwesomeIcon icon={faCircleDollar} />
                            </GridItemGraphic>
                            <GridItemContent>
                                <GridItemTitle>
                                    Straightfoward pricing.
                                </GridItemTitle>
                                <GridItemDescription>
                                    Only pay for storage and egress costs with cost savings of over 50%. No contracts.
                                    No minimums.
                                </GridItemDescription>
                            </GridItemContent>
                        </GridItem>
                        <GridItem>
                            <GridItemGraphic>
                                <FontAwesomeIcon icon={faSquareCode} />
                            </GridItemGraphic>
                            <GridItemContent>
                                <GridItemTitle>
                                    User-friendly CLI.
                                </GridItemTitle>
                                <GridItemDescription>
                                    Use the VALDI CLI to mount and unmount volumes to your VMs, upload, download, and
                                    explore data, and more.
                                </GridItemDescription>
                            </GridItemContent>
                        </GridItem>
                    </FeaturesGrid>
                </FeaturesContent>
            </FeaturesSection>
            <CliSection>
                <CliSectionTitle>
                    Command and conquer with the VALDI CLI.
                </CliSectionTitle>
                <CliSectionContent>
                    <CliSectionCopy>
                        Easily install the VALDI CLI using pip. Use it to <span>mount and unmount your detachable
                        storage volumes</span>, and eventually, to manage all your VALDI cloud resources.
                    </CliSectionCopy>
                    <ImageContainer>
                        <img src={CliCode} />
                    </ImageContainer>
                </CliSectionContent>
                <a href="https://docs.valdi.ai/cli/installation/" target="_blank" rel="noreferrer">
                    <HeroButton>
                        Get the CLI
                    </HeroButton>
                </a>
            </CliSection>
            <UseCasesSection>
                <FeaturesContent>
                    <UseCasesTitle>
                        <span>Build</span> all the things.
                    </UseCasesTitle>
                    <FeaturesList>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faRocketLaunch} style={{"color": "#2bdffd"}} />
                            </ListItemIcon>
                            <ListItemTitle>
                                Scale storage independently
                            </ListItemTitle>
                            <ListItemContent>
                                Attach additional volumes to scale storage capacity for databases without scaling
                                compute resources.
                            </ListItemContent>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faFloppyDisk} style={{"color": "#2bdffd"}} />
                            </ListItemIcon>
                            <ListItemTitle>
                                Archive your data
                            </ListItemTitle>
                            <ListItemContent>
                                Move cold data to detachable volumes to reduce storage costs for data rarely needed,
                                while still keeping it readily accessible.
                            </ListItemContent>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faShareNodes} style={{"color": "#2bdffd"}} />
                            </ListItemIcon>
                            <ListItemTitle>
                                Share data across VMs
                            </ListItemTitle>
                            <ListItemContent>
                                Attach a volume to multiple VMs, allowing all instances to access and modify the same
                                data set. Streamline your data processing pipelines.
                            </ListItemContent>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faCloudBinary} style={{"color": "#2bdffd"}} />
                            </ListItemIcon>
                            <ListItemTitle>
                                Store logging and test data
                            </ListItemTitle>
                            <ListItemContent>
                                Allocate separate volumes for application or test data, logs, and temporary files,
                                allowing independent scaling based on specific needs.
                            </ListItemContent>
                        </ListItem>
                    </FeaturesList>
                    <Link to="/signup">
                        <HeroButton>
                            Get started
                        </HeroButton>
                    </Link>
                </FeaturesContent>
            </UseCasesSection>
        </LandingComputeContainer>
    )
}

export default LandingStorage;