import { useEffect, useState, useRef } from "react";
import {
    DirectContainer,
    ContentContainer,
    ContentTitleContainer,
    ContentTitle,
    ContentDescription,
    ServerList,
    ServerListItemPlaceholder,
    ConfirmModalBackgroundCover,
    ConfirmModal,
    ModalTitle,
    ModalText,
    ConnectButtonWithDisabling,
    RefreshIcon,
    CancelButton,
    TerminateButton,
    ConnectButton,
    ModalFinePrint,
    LightBgTextInput,
    EmptyKeyPlaceholder,
    LargeIcon,
    NoKeysDescription,
    KeyName,
    KeyDetails,
    GenerateKeyButton,
    LargeGenerateKeyButton,
    InputContainer
} from "./SharedStyles";
import {
    InputLabel
} from "./Signup"
import { faKey, faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { faTrashCan, faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseTimestamp } from "./utilities";

const PortalSshKeys = ({ setSelectedView, token }) => {
    const [ keysAreLoading, setKeysAreLoading ] = useState(true);
    const [ sshKeys, setSshKeys ] = useState([]);
    const [ generateKeyModalIsVisible, setGenerateKeyModalIsVisible ] = useState(false);
    const [ deleteKeyModalIsVisible, setDeleteKeyModalIsVisible ] = useState(false);
    const [ keyName, setKeyName ] = useState('');
    const [ keyIsGenerating, setKeyIsGenerating ] = useState(false);
    const [ keyGenerated, setKeyGenerated ] = useState(false);
    const [ keyFailedToGenerate, setKeyFailedToGenerate ] = useState(false);
    const [ keyStagedForDeletion, setKeyStagedForDeletion ] = useState('');
    const [ keyIsDeleting, setKeyIsDeleting ] = useState(false);
    const [ keyDeleted, setKeyDeleted ] = useState(false);
    const [ keyFailedToDelete, setKeyFailedToDelete ] = useState(false);
    const [ generatedPrivateKey, setGeneratedPrivateKey ] = useState('');

    const nameInputRef = useRef();

    useEffect(() => {
        if (token) {
            fetchSshKeys();
        }
    }, [token]);

    useEffect(() => {
        setSelectedView('keys');
    }, [setSelectedView]);

    useEffect(() => {
        if (generateKeyModalIsVisible) {
            nameInputRef.current.focus();
        }
    }, [generateKeyModalIsVisible, nameInputRef]);

    const displayDeleteKeyModal = keyId => {
        setKeyStagedForDeletion(keyId);
        setDeleteKeyModalIsVisible(true);
    }

    const exitDeleteKeyModal = () => {
        setKeyStagedForDeletion('');
        setDeleteKeyModalIsVisible(false);
    }

    const dismissSuccessfulGenerationModal = () => {
        const blob = new Blob([generatedPrivateKey], { type: 'application/x-pem-file' });
        const downloadUrl = URL.createObjectURL(blob);

        const privateKeyDownloadLink = document.createElement('a');
        privateKeyDownloadLink.href = downloadUrl;
        privateKeyDownloadLink.download = `${keyName}.pem`;
        document.body.appendChild(privateKeyDownloadLink);
        privateKeyDownloadLink.click();

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(privateKeyDownloadLink);
        }, 500);

        setKeyGenerated(false);
        setKeyName('');
        fetchSshKeys();
    }

    const dismissGenerateKeyModal = () => {
        setKeyName('');
        setGenerateKeyModalIsVisible(false);
    }

    const dismissSuccessfulDeletionModal = () => {
        setKeyDeleted(false);
        fetchSshKeys();
    }

    function fetchSshKeys() {
        setKeysAreLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/sshkeys`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    if ('ssh_keys' in data) {
                        setSshKeys(data['ssh_keys']);
                    }
                }
            })
            .catch(error => console.log(error))
            .finally(() => setKeysAreLoading(false))
    }

    const generateSshKey = () => {
        setGenerateKeyModalIsVisible(false);
        setKeyIsGenerating(true);
        const payload = {
            name: keyName
        };
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/sshkeys/sshkey`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (response.status === 201) {
                    return response.json();
                } else {
                    setKeyFailedToGenerate(true);
                }
            })
            .then(data => {
                if (data) {
                    if ('private_key' in data) {
                        setKeyGenerated(true);
                        setGeneratedPrivateKey(data['private_key'].replace(/\\n/g, '\n'));
                    } else {
                        setKeyFailedToGenerate(true);
                    }
                } else {
                    setKeyFailedToGenerate(true);
                }
            })
            .catch(error => {
                console.log(error);
                setKeyFailedToGenerate(true);
            })
            .finally(() => setKeyIsGenerating(false))
    }

    const deleteSshKey = () => {
        setDeleteKeyModalIsVisible(false);
        setKeyIsDeleting(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/sshkeys/sshkey/${keyStagedForDeletion}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setKeyFailedToDelete(true);
                }
            })
            .then(data => {
                if (data) {
                    if ('message' in data) {
                        if (data['message'] === 'Successfully deleted SSH Key.') {
                            setKeyDeleted(true);
                        } else {
                            setKeyFailedToDelete(true);
                        }
                    } else {
                        setKeyFailedToDelete(true);
                    }
                } else {
                    setKeyFailedToDelete(true);
                }
            })
            .catch(error => {
                setKeyFailedToDelete(true);
                console.log(error);
            })
            .finally(() => setKeyIsDeleting(false))
    }

    return (
        <DirectContainer>
            <ContentContainer>
                <ContentTitleContainer>
                    <ContentTitle>
                        Your SSH Keys
                    </ContentTitle>
                    <GenerateKeyButton onClick={() => setGenerateKeyModalIsVisible(true)}>
                        Generate key
                    </GenerateKeyButton>
                </ContentTitleContainer>
                <ContentDescription>
                    SSH keys you've generated are listed below. SSH keys are used to securely access virtual machines
                    that support key-based authentication.
                </ContentDescription>
            </ContentContainer>
            <ServerList>
                {keysAreLoading ? (
                    <EmptyKeyPlaceholder>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </EmptyKeyPlaceholder>
                ) : (
                    sshKeys.length === 0 ? (
                        <EmptyKeyPlaceholder>
                            <LargeIcon>
                                <FontAwesomeIcon icon={faKey} />
                            </LargeIcon>
                            <NoKeysDescription>
                                You do not have any SSH keys yet. Generate one by clicking the button below.
                            </NoKeysDescription>
                            <LargeGenerateKeyButton onClick={() => setGenerateKeyModalIsVisible(true)}>
                                Generate key
                            </LargeGenerateKeyButton>
                        </EmptyKeyPlaceholder>
                    ) : (
                        sshKeys.map(sshKey => {
                            return (
                                <ServerListItemPlaceholder key={sshKey['ssh_key_id']}>
                                    <div>
                                        <KeyName>
                                            {sshKey['name']}
                                        </KeyName>
                                        <KeyDetails>
                                            Generated on {parseTimestamp(sshKey['created_at']).replace(',', ' at')}
                                        </KeyDetails>
                                    </div>
                                    <RefreshIcon onClick={() => displayDeleteKeyModal(sshKey['ssh_key_id'])}>
                                        <FontAwesomeIcon icon={faTrashCan} />
                                    </RefreshIcon>
                                </ServerListItemPlaceholder>
                            )
                        })
                    )
                )}
            </ServerList>
            {generateKeyModalIsVisible && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissGenerateKeyModal} />
                    <ConfirmModal maxWidth="400px">
                        <ModalTitle>
                            Generate SSH Key
                        </ModalTitle>
                        <InputContainer>
                            <InputLabel htmlFor="key-name-input">SSH key name</InputLabel>
                            <LightBgTextInput id="key-name-input"
                                              onChange={e => setKeyName(e.target.value)}
                                              value={keyName}
                                              ref={nameInputRef}
                            />
                        </InputContainer>
                        <ConnectButtonWithDisabling disabled={keyName === ''}
                                                    onClick={generateSshKey}
                        >
                            Generate
                        </ConnectButtonWithDisabling>
                    </ConfirmModal>
                </>
            )}
            {deleteKeyModalIsVisible && (
                <>
                    <ConfirmModalBackgroundCover onClick={exitDeleteKeyModal} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Are you sure?
                        </ModalTitle>
                        <ModalText>
                            Please confirm that you wish to delete this key. This action cannot be undone.
                        </ModalText>
                        <CancelButton onClick={exitDeleteKeyModal}>Cancel</CancelButton>
                        <TerminateButton onClick={deleteSshKey}>Delete key</TerminateButton>
                    </ConfirmModal>
                </>
            )}
            {keyIsDeleting && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </ModalTitle>
                    </ConfirmModal>
                </>
            )}
            {keyDeleted && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setKeyDeleted(false)} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Success
                        </ModalTitle>
                        <ModalText>
                            The key was successfully removed from your account.
                        </ModalText>
                        <ConnectButton onClick={dismissSuccessfulDeletionModal}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {keyFailedToDelete && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setKeyFailedToDelete(false)} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an unexpected issue deleting the key. Try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setKeyFailedToDelete(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {keyIsGenerating && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </ModalTitle>
                    </ConfirmModal>
                </>
            )}
            {keyGenerated && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Success
                        </ModalTitle>
                        <ModalText>
                            The new key was successfully generated. Download the private key file by clicking the button
                            below.
                        </ModalText>
                        <ModalFinePrint>
                            VALDI does not store the private key and this is the only time you will be able to
                            download it.
                        </ModalFinePrint>
                        <ConnectButton onClick={dismissSuccessfulGenerationModal}>
                            Download .pem file <FontAwesomeIcon icon={faDownload} />
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {keyFailedToGenerate && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setKeyFailedToGenerate(false)} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an unexpected issue with generating a new key. Try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setKeyFailedToGenerate(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
        </DirectContainer>
    );
}

export default PortalSshKeys;