import styled from "styled-components";
import { useState, useEffect } from "react";
import {
    PortalSettingsContainer,
    SettingsSectionGroup,
    TitleText,
    SettingsSection,
    EntryGroup,
    EntryTitle,
    EntryValue,
    AccountBalanceValue,
    AccountBalanceDollars,
    AccountBalanceCents,
    TransactionsContainer,
    TransactionsLoadingContainer,
    TransactionsList,
    NonInteractiveTransactionRow,
    PagingControls,
    DisabledPagingController,
    NarrowInputField,
    PagingController
} from "./PortalSettings";
import { ClipboardIcon, Tooltip, InputLabel } from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleNotch, faAngleLeft, faAngleRight, faHourglass } from "@fortawesome/pro-regular-svg-icons";
import { faCopy } from "@fortawesome/pro-light-svg-icons";

const InPlaceClipboard = styled(ClipboardIcon)`
  position: static;
`;

const ReferralLink = styled.div`
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 460px;
  word-break: break-all;
`;

const ReferralLinkFinePrint = styled.div`
  font-size: 0.7rem;
  margin-top: 5px;
  max-width: 460px;
  opacity: 0.7;
`;

const HorizontalEntryGroup = styled.div`
  display: flex;
  align-items: center;
`;

const EntryGroupHorizontalMargin = styled(EntryGroup)`
  margin: 0 30px 0 0;
`;

const ReferredEmail = styled.div`
  font-size: 0.85rem;
`;

const InProgressBadge = styled.div`
  font-size: 0.75rem;
  background-color: rgba(255, 255, 255, 0.1);
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover div {
    visibility: visible;
    opacity: 1;
  }
`;

const CompletedBadge = styled(InProgressBadge)`
  background-color: #00c424;
`;

const PortalReferrals = ({ setSelectedView, token }) => {
    const [ referralLinkIsLoading, setReferralLinkIsLoading ] = useState(true);
    const [ referralLink, setReferralLink ] = useState('');
    const [ linkWasCopied, setLinkWasCopied ] = useState(false);
    const [ totalBonuses, setTotalBonuses ] = useState(0);
    const [ totalBonusesAreLoading, setTotalBonusesAreLoading ] = useState(true);
    const [ currentTimestamp, setCurrentTimestamp ] = useState(new Date().toISOString());

    const [ referralsAreLoading, setReferralsAreLoading ] = useState(true);
    const [ referralCountIsLoading, setReferralCountIsLoading ] = useState(true);
    const [ numReferrals, setNumReferrals ] = useState(0);
    const [ numReferralsPages, setNumReferralsPages ] = useState(0);
    const [ currentPageOfReferrals, setCurrentPageOfReferrals ] = useState([]);
    const [ targetRefPageNumber, setTargetRefPageNumber ] = useState('1');

    useEffect(() => {
        setSelectedView('referrals')
        if (token) {
            getReferralLink();
            getTotalBonuses();
            initializeReferralList();
            fetchReferrals();
        }
    }, [token]);

    useEffect(() => {
        if (linkWasCopied) {
            const copyReset = setTimeout(() => {
                setLinkWasCopied(false);
            }, 2000);
            return () => clearTimeout(copyReset);
        }
    }, [linkWasCopied]);

    useEffect(() => {
        if (token && targetRefPageNumber) {
            fetchReferrals(5, parseInt(targetRefPageNumber)-1);
        }
    }, [token, targetRefPageNumber, currentTimestamp])

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink);
        setLinkWasCopied(true);
    }

    function getReferralLink() {
        setReferralLinkIsLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/referral-code`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && 'referral_code' in data) {
                    if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
                        setReferralLink(`https://stage.valdi.ai/signup?ref=${data['referral_code']}`);
                    } else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                        setReferralLink(`https://valdi.ai/signup?ref=${data['referral_code']}`);
                    } else if (process.env.REACT_APP_ENVIRONMENT === 'local') {
                        setReferralLink(`http://localhost:3000/signup?ref=${data['referral_code']}`);
                    }
                    setReferralLinkIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function getTotalBonuses() {
        setTotalBonusesAreLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/referral-bonus`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && 'total_bonuses' in data) {
                    setTotalBonuses(data['total_bonuses']);
                    setTotalBonusesAreLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function currencyDisplay(amount) {
        let dollarAmount = Math.floor(amount);
        let centAmount = 100*(amount - dollarAmount);
        centAmount = centAmount < 10 ? '0' + centAmount : String(centAmount);
        dollarAmount = dollarAmount.toLocaleString();
        return (
            <AccountBalanceValue>
                <AccountBalanceDollars>${dollarAmount}</AccountBalanceDollars>
                <AccountBalanceCents>.{centAmount.slice(0,2)}</AccountBalanceCents>
            </AccountBalanceValue>
        )
    }

    function initializeReferralList(pageSize = 5) {
        setReferralCountIsLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/referrals/count?page_size=${pageSize}&max_timestamp=${encodeURIComponent(currentTimestamp)}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] <= 299) {
                    return response.json();
                }
            })
            .then(data => {
                if (data && 'num_pages' in data && 'num_referrals' in data) {
                    setNumReferralsPages(data['num_pages']);
                    setNumReferrals(data['num_referrals']);
                    setReferralCountIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function fetchReferrals(pageSize = 5, pageNumber = 0) {
        setReferralsAreLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/referrals?page_size=${pageSize}&page=${pageNumber}&max_timestamp=${encodeURIComponent(currentTimestamp)}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] <= 299) {
                    return response.json();
                }
            })
            .then(data => {
                if (data && 'referrals' in data) {
                    setCurrentPageOfReferrals(data['referrals']);
                    setReferralsAreLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function handleRefPageNumberChange(e) {
        if (!e.target.value) {
            setTargetRefPageNumber(e.target.value);
        } else {
            if (e.target.value >= 1 && e.target.value <= numReferralsPages) {
                setTargetRefPageNumber(e.target.value);
            }
        }
    }

    function handleRefPageIncrement(direction) {
        if (direction === 'up') {
            if (parseInt(targetRefPageNumber) < numReferralsPages) {
                setTargetRefPageNumber(prev => String(parseInt(prev) + 1));
            }
        } else if (direction === 'down') {
            if (parseInt(targetRefPageNumber) > 1) {
                setTargetRefPageNumber(prev => String(parseInt(prev) - 1));
            }
        }
    }

    return (
        <PortalSettingsContainer>
            <SettingsSectionGroup>
                <TitleText>
                    Your Referral Info
                </TitleText>
                <SettingsSection>
                    <EntryGroup>
                        <EntryTitle>
                            Your Referral Link
                        </EntryTitle>
                        <ReferralLink>
                            {referralLinkIsLoading ? (
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            ) : (
                                referralLink
                            )}
                            {linkWasCopied ? (
                                <InPlaceClipboard><FontAwesomeIcon icon={faCheck} /></InPlaceClipboard>
                            ) : (
                                <InPlaceClipboard onClick={copyToClipboard}><FontAwesomeIcon icon={faCopy} /></InPlaceClipboard>
                            )}
                        </ReferralLink>
                        <ReferralLinkFinePrint>
                            Share this link with friends and colleagues. For each person who signs up with your link and
                            deposits at least $10, you will be awarded a $5 referral bonus.
                        </ReferralLinkFinePrint>
                    </EntryGroup>
                    <HorizontalEntryGroup>
                        <EntryGroupHorizontalMargin>
                            <EntryTitle>
                                Users referred
                            </EntryTitle>
                            {referralCountIsLoading ? (
                                <AccountBalanceDollars>
                                    <FontAwesomeIcon icon={faCircleNotch} spin />
                                </AccountBalanceDollars>
                            ) : (
                                <AccountBalanceDollars>
                                    {numReferrals}
                                </AccountBalanceDollars>
                            )}
                        </EntryGroupHorizontalMargin>
                        <EntryGroupHorizontalMargin>
                            <EntryTitle>
                                Total bonuses earned
                            </EntryTitle>
                            {totalBonusesAreLoading ? (
                                <AccountBalanceValue>
                                    <FontAwesomeIcon icon={faCircleNotch} spin />
                                </AccountBalanceValue>
                            ) : (
                                currencyDisplay(totalBonuses)
                            )}
                        </EntryGroupHorizontalMargin>
                    </HorizontalEntryGroup>
                </SettingsSection>
            </SettingsSectionGroup>
            <SettingsSectionGroup>
                <TitleText>
                    Referral History
                </TitleText>
                <SettingsSection>
                    <EntryGroup>
                        <EntryTitle>
                            Your Referrals
                        </EntryTitle>
                        <EntryValue>
                            <TransactionsContainer>
                                {referralsAreLoading ? (
                                    <TransactionsLoadingContainer>
                                        <FontAwesomeIcon icon={faCircleNotch} spin />
                                    </TransactionsLoadingContainer>
                                ) : (
                                    numReferralsPages === 0 ? (
                                        <TransactionsList>
                                            <NonInteractiveTransactionRow>
                                                No referrals yet.
                                            </NonInteractiveTransactionRow>
                                        </TransactionsList>
                                    ) : (
                                        <TransactionsList>
                                            {currentPageOfReferrals.map(referral => {
                                                return (
                                                    <NonInteractiveTransactionRow key={referral['email']}>
                                                        <ReferredEmail>
                                                            {referral['email']}
                                                        </ReferredEmail>
                                                        {referral['referral_bonus_paid'] ? (
                                                            <CompletedBadge>
                                                                <FontAwesomeIcon icon={faCheck} />
                                                                <Tooltip>You've received credits for this referral. Congratulations!</Tooltip>
                                                            </CompletedBadge>
                                                        ) : (
                                                            <InProgressBadge>
                                                                <FontAwesomeIcon icon={faHourglass} />
                                                                <Tooltip>You will receive your referral bonus when this user deposits at least $10.</Tooltip>
                                                            </InProgressBadge>
                                                        )}
                                                    </NonInteractiveTransactionRow>
                                                )
                                            })}
                                        </TransactionsList>
                                    )
                                )}
                                <PagingControls>
                                    {targetRefPageNumber === '1' || !targetRefPageNumber ? (
                                        <DisabledPagingController>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </DisabledPagingController>
                                    ): (
                                        <PagingController onClick={() => handleRefPageIncrement('down')}>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </PagingController>
                                    )}
                                    <NarrowInputField id="page-input"
                                                      type="number"
                                                      customWidth="40px"
                                                      value={targetRefPageNumber}
                                                      min="1"
                                                      disabled={numReferralsPages === 0}
                                                      onChange={handleRefPageNumberChange}
                                    />
                                    <InputLabel htmlFor="page-input">
                                        of {numReferralsPages === 0 ? '1' : numReferralsPages}
                                    </InputLabel>
                                    {(parseInt(targetRefPageNumber) === numReferralsPages || numReferralsPages === 0) ? (
                                        <DisabledPagingController>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </DisabledPagingController>
                                    ) : (
                                        <PagingController onClick={() => handleRefPageIncrement('up')}>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </PagingController>
                                    )}
                                </PagingControls>
                            </TransactionsContainer>
                        </EntryValue>
                    </EntryGroup>
                </SettingsSection>
            </SettingsSectionGroup>
        </PortalSettingsContainer>
    );
}

export default PortalReferrals;