import styled from "styled-components";
import BWSLogo from "./assets/image-bws-logo-no-text.png";
import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faXmark,
    faCircleCheck,
    faTriangleExclamation,
    faSpinnerThird
} from "@fortawesome/pro-solid-svg-icons";
import { faEyeSlash, faEye, faWifiSlash } from "@fortawesome/pro-light-svg-icons";
import { InputErrorText } from "./SharedStyles";

const ResetContainer = styled.div`
  background-color: #1a1c47;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 50px;
  @media screen and (max-width: 550px) {
    padding: 0;
  }
`;

const EntryPanel = styled.div`
  background-color: #121331;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  border-radius: 10px;
  min-width: 500px;
  @media screen and (max-width: 550px) {
    width: 100%;
    border-radius: 0;
    min-height: 100vh;
    justify-content: start;
    padding: 50px;
    min-width: 250px;
  }
`;

const Logo = styled.div`
  width: 70px;
  height: 70px;
  & img {
    width: 100%;
  }
  margin: -10px 0 -30px 0;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 40px 0 30px 0;
`;

const TextInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 15px;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #1a1c47;
  color: #fff;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
`;

const InputFeedbackIcon = styled.div`
  position: absolute;
  top: 9px;
  right: 10px;
`;

const InputFeedbackBlock = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const InputFeedbackIcon2 = styled.div`
  position: absolute;
  top: 9px;
  right: 20px;
`;

const InputLabel = styled.label`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
`;

const ResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormSubmitButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  background-color: #ff1f82;
  padding: 10px 20px;
  margin: 15px auto 0 auto;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  &:hover {
    background-color: rgba(255, 31, 130, 0.9);
    cursor: pointer;
  }
`;

const LoadingCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 101vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 3rem;
  backdrop-filter: blur(1px);
`;

const ResponseModal = styled.div`
  position: absolute;
  align-self: center;
  z-index: 10;
  padding: 40px 25px;
  border-radius: 10px;
  background-color: #121331;
  font-size: 1.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResponseModalTitle = styled.div`
  font-size: 2rem;
  font-weight: 400;
  margin: 15px 0;
`;

const ResponseModalText = styled.div`
  font-size: 1rem;
  font-weight: 300;
  max-width: 300px;
`;

const ResponseModalButton = styled.div`
  padding: 8px 20px;
  margin: 25px auto 0 auto;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const ResetPassword = () => {
    const [ password, setPassword ] = useState('');
    const [ rPassword, setRPassword ] = useState('');
    const [ resetToken, setResetToken ] = useState();

    const [ passwordIsVisible, setPasswordIsVisible ] = useState(false);
    const [ rPasswordIsVisible, setRPasswordIsVisible ] = useState(false);

    const [ passwordValidationReceived, setPasswordValidationReceived ] = useState(false);
    const [ passwordError, setPasswordError ] = useState(false);
    const [ passwordIsEmpty, setPasswordIsEmpty ] = useState(false);
    const [ passwordIsMalformed, setPasswordIsMalformed ] = useState(false);
    const [ passwordIsTooShort, setPasswordIsTooShort ] = useState(false);

    const [ rPasswordValidationReceived, setRPasswordValidationReceived ] = useState(false);
    const [ rPasswordError, setRPasswordError ] = useState(false);
    const [ rPasswordIsEmpty, setRPasswordIsEmpty ] = useState(false);
    const [ rPasswordIsMismatched, setRPasswordIsMismatched ] = useState(false);

    const [ waitingForEndpoint, setWaitingForEndpoint ] = useState(false);
    const [ successfulResponse, setSuccessfulResponse ] = useState(false);
    const [ failureResponse, setFailureResponse ] = useState(false);
    const [ networkError, setNetworkError ] = useState(false);

    const passwordInputRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        passwordInputRef.current.focus();
    }, [passwordInputRef]);

    useEffect(() => {
        if (params.has('token')) {
            const authToken = params.get('token');
            if (authToken.length === 0) {
                history.push('/');
            } else {
                setResetToken(authToken);
            }
        } else {
            history.push('/');
        }
    }, [params]);

    

    const handlePasswordInput = e => {
        const passwordInput = e.target.value;

        setPasswordError(false);
        setPasswordIsEmpty(false);
        setPasswordIsMalformed(false);
        setPasswordIsTooShort(false);
        setPasswordValidationReceived(false);

        setRPasswordError(false);
        setRPasswordIsMismatched(false);
        setRPasswordValidationReceived(false);

        setPassword(passwordInput);

        const capLetterCheck = /[A-Z]/g;
        const lowerLetterCheck = /[a-z]/g;
        const numCheck = /[0-9]/g;
        const specCheck = /[!@#$%^&*()]/g;
        if (passwordInput) {
            setPasswordValidationReceived(true);
            if (passwordInput.length < 8) {
                setPasswordError(true);
                setPasswordIsTooShort(true);
            } else if (!capLetterCheck.test(passwordInput) || !lowerLetterCheck.test(passwordInput) || !numCheck.test(passwordInput) || !specCheck.test(passwordInput)) {
                setPasswordError(true);
                setPasswordIsMalformed(true);
            }
        }
        if (rPassword) {
            setRPasswordValidationReceived(true);
            if (rPassword !== passwordInput) {
                setRPasswordError(true);
                setRPasswordIsMismatched(true);
            }
        }
    }

    const handlePasswordRepeatInput = e => {
        const rPasswordInput = e.target.value;

        setRPasswordError(false);
        setRPasswordIsEmpty(false);
        setRPasswordIsMismatched(false);
        setRPasswordValidationReceived(false);

        setRPassword(rPasswordInput);

        if (rPasswordInput) {
            setRPasswordValidationReceived(true);
            if (rPasswordInput !== password) {
                setRPasswordError(true);
                setRPasswordIsMismatched(true);
            }
        }
    }

    const handleFormSubmit = e => {
        e.preventDefault();

        if (!password) {
            setPasswordError(true);
            setPasswordIsEmpty(true);
        }
        if (!rPassword) {
            setRPasswordError(true);
            setRPasswordIsEmpty(true);
        }

        if (password && rPassword) {
            if (!passwordError && !rPasswordError) {
                setWaitingForEndpoint(true);
                fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/reset_password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token: resetToken,
                        new_password: password
                    })
                })
                    .then(response => {
                        if (response['status'] < 299) {
                            setSuccessfulResponse(true);
                        } else {
                            setFailureResponse(true);
                        }
                    })
                    .catch(() => setNetworkError(true))
                    .finally(() => setWaitingForEndpoint(false));
            }
        }
    }

    return (
        <ResetContainer>
            <EntryPanel>
                <Logo>
                    <img src={BWSLogo} alt="" />
                </Logo>
                <Title>
                    Reset Your Password
                </Title>
                <ResetPasswordForm onSubmit={handleFormSubmit}>

                    <InputLabel htmlFor="password-input">Create New Password</InputLabel>
                    <InputFeedbackBlock>
                        <TextInput value={password}
                                   type={passwordIsVisible ? "text": "password"}
                                   onChange={handlePasswordInput}
                                   id="password-input"
                                   ref={passwordInputRef}
                        />
                        <InputFeedbackIcon>
                            {passwordValidationReceived && (
                                passwordError ? (
                                    <FontAwesomeIcon icon={faXmark} style={{color: "red"}} />
                                ) : (
                                    <FontAwesomeIcon icon={faCheck} style={{color: "05cd00"}} />
                                )
                            )}
                        </InputFeedbackIcon>
                        <InputFeedbackIcon2>
                            {passwordValidationReceived && (
                                passwordIsVisible ? (
                                    <FontAwesomeIcon icon={faEyeSlash}
                                                     onClick={() => setPasswordIsVisible(false)}
                                                     style={{
                                                         cursor: "pointer",
                                                         marginRight: "15px",
                                                         color: "rgba(255, 255, 255, 0.9)"
                                                     }}
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={faEye}
                                                     onClick={() => setPasswordIsVisible(true)}
                                                     style={{
                                                         cursor: "pointer",
                                                         marginRight: "16px",
                                                         color: "rgba(255, 255, 255, 0.9)"
                                                     }}
                                    />
                                )
                            )}
                        </InputFeedbackIcon2>
                    </InputFeedbackBlock>
                    <InputErrorText visible={passwordError}>
                        {passwordIsEmpty ? (
                            `Please enter a new password.`
                        ) : (passwordIsTooShort ? (
                            `Password must be at least 8 characters long.`
                        ) : (passwordIsMalformed ? (
                            `Must include at least one A-z, #, and special character.`
                        ) : `There is no error`))
                        }
                    </InputErrorText>

                    <InputLabel htmlFor="repeat-password-input">Repeat New Password</InputLabel>
                    <InputFeedbackBlock>
                        <TextInput value={rPassword}
                                   type={rPasswordIsVisible ? "text" : "password"}
                                   onChange={handlePasswordRepeatInput}
                                   id="repeat-password-input"
                        />
                        <InputFeedbackIcon>
                            {rPasswordValidationReceived && (
                                rPasswordError ? (
                                    <FontAwesomeIcon icon={faXmark} style={{color: "red"}} />
                                ) : (
                                    <FontAwesomeIcon icon={faCheck} style={{color: "05cd00"}} />
                                )
                            )}
                        </InputFeedbackIcon>
                        <InputFeedbackIcon2>
                            {rPasswordValidationReceived && (
                                rPasswordIsVisible ? (
                                    <FontAwesomeIcon icon={faEyeSlash}
                                                     onClick={() => setRPasswordIsVisible(false)}
                                                     style={{
                                                         cursor: "pointer",
                                                         marginRight: "15px",
                                                         color: "rgba(255, 255, 255, 0.9)"
                                                     }}
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={faEye}
                                                     onClick={() => setRPasswordIsVisible(true)}
                                                     style={{
                                                         cursor: "pointer",
                                                         marginRight: "16px",
                                                         color: "rgba(255, 255, 255, 0.9)"
                                                     }}
                                    />
                                )
                            )}
                        </InputFeedbackIcon2>
                    </InputFeedbackBlock>
                    <InputErrorText visible={rPasswordError}>
                        {rPasswordIsMismatched ? (
                            `Passwords don't match.`
                        ) : (rPasswordIsEmpty ? (
                            `Please re-enter your new password.`
                        ) : `There is no error.`)}
                    </InputErrorText>

                    <FormSubmitButton type="submit" value="Reset" />
                </ResetPasswordForm>
            </EntryPanel>
            {waitingForEndpoint && <LoadingCover><FontAwesomeIcon icon={faSpinnerThird} spin /></LoadingCover>}
            {successfulResponse ? (
                <>
                    <LoadingCover />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faCircleCheck} style={{fontSize: "4rem", color: "#05cd00"}} />
                        <ResponseModalTitle>
                            Done.
                        </ResponseModalTitle>
                        <ResponseModalText>
                            Your password has been reset successfully. You can now log in.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => history.push('/login')}>
                            Log In
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (failureResponse ? (
                <>
                    <LoadingCover />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{fontSize: "4rem", color: "#f5a142"}} />
                        <ResponseModalTitle>
                            Error.
                        </ResponseModalTitle>
                        <ResponseModalText>
                            Something went wrong. Please try again later.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => history.push('/login')}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (networkError && (
                <>
                    <LoadingCover onClick={() => setNetworkError(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faWifiSlash} style={{fontSize: "4rem", color: "red"}} />
                        <ResponseModalTitle>
                            Uh-oh!
                        </ResponseModalTitle>
                        <ResponseModalText>
                            We're having trouble sending your information. Please check your internet connection.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => setNetworkError(false)}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            )))}
        </ResetContainer>
    );
}

export default ResetPassword;