import styled from "styled-components";
import {Link} from "react-router-dom";
import {TextInput} from "./Signup";
import CircuitBackgroundSvg from "./assets/circuit-background.svg";

/* migrated from LaunchNewService.js */

export const InputLabel = styled.label`
  font-size: 0.9rem;
  font-weight: 500;
`;

export const InputField = styled.input`
  -webkit-appearance: none;
  color: #fff;
  font-family: inherit;
  padding: 10px;
  margin: 3px 0 0 6px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  width: ${props => props.customWidth && props.customWidth};
  outline: ${props => props.error && "2px solid red"};
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
`;

export const InputSuperGroup = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
  gap: 15px;
`;

export const InputGroupHorizontal = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: ${props => props.disabled && 0.4};
  padding: 12px 0;
`;

/* migrated from PortalDirectServer.js */

export const DetailContainer = styled.div`
  background-color: #1a1c47;
  width: 100%;
  max-width: 1300px;
  padding: 0 50px 30px 50px;
  min-height: 75vh;
  @media screen and (max-width: 400px) {
    padding: 0 25px 30px 25px;
  }
  @media screen and (max-width: 350px) {
    padding: 0 10px 30px 10px;
`;

export const DetailPanel = styled.div`
  background-color: #292c6e;
  padding: 20px 30px;
  border-radius: 10px;
  width: 100%;
  gap: 10px;
  min-width: 0;
  min-height: 0;
`;

export const DetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
  overflow-wrap: break-word;
`;

export const InfoGroup = styled.div`
`;

export const InfoName = styled.div`
  font-size: 0.8rem;
  opacity: 0.7;
`;

export const InfoData = styled.div`
  font-size: 0.9rem;
  opacity: ${props => props.light ? '0.3': '1.0'};
`;

export const LoadingCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
`;

export const Spinner = styled.div`
  font-size: 1.4rem;
`;

export const BackButton = styled.div`
  display: inline-flex;
  opacity: 0.7;
  align-items: center;
  gap: 5px;
  font-size: 1.4rem;
  padding: 5px 20px 5px 0;
  cursor: pointer;
  & span {
    font-size: 1rem;
  }
  &:hover {
    opacity: 0.5;
  }
`;

export const ConnectButton = styled.div`
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  border: none;
  color: #fff;
  border-radius: 10px;
  width: 250px;
  text-align:center;
  padding: 10px 0;
  margin-top: 20px;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

export const ConfirmModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 20px;
  background-color: #1a1c47;
  z-index: 44;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${props => props.maxWidth ? props.maxWidth : "300px"};
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const ModalTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
`;

export const ModalText = styled.div`
  font-size: 0.9rem;
  text-align: center;
  margin: 15px 0 25px 0;
  & span {
    font-weight: 600;
  }
  & a {
    text-decoration: underline;
  }
`;

export const ModalFinePrint = styled.div`
  font-size: 0.6rem;
  opacity: 0.8;
  text-align: center;
`;

export const ConfirmModalBackgroundCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 43;
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
  height: 100vh;
`;

export const CodeBlock = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  padding: 10px;
  text-align: left;
  overflow-x: scroll;
  white-space: nowrap;
  font-size: 0.8rem;
  margin-top: 20px;
  border-radius: 10px;
  position: relative;
`;

export const ClipboardIcon = styled.div`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
  padding: 2px 4px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 5px;
  right: 5px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
`;

/* migrated from PortalDirectServerSession.js */

export const TerminateButton = styled.div`
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  background-color: rgb(255, 0, 0);
  color: #fff;
  border-radius: 10px;
  width: 250px;
  text-align:center;
  padding: 10px 0;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 0, 0, 0.9);
  }
  opacity: ${props => props.disabled && '0.6'};
  pointer-events: ${props => props.disabled && 'none'};
`;

export const CancelButton = styled(TerminateButton)`
  background-color: transparent;
  border: 1px solid #fff;
  margin-bottom: -10px;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const Tooltip = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 30px;
  left: 50%;
  margin-left: -80px;
  width: 160px;
  background-color: #fff;
  color: #000;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid #000;
`;

export const SubSectionTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 20px 0;
`;

export const DropDownList = styled.select`
  font-family: inherit;
  width: 100%;
  font-size: 1rem;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1a1c47;
  color: #fff;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  &:hover {
    cursor: pointer;
  }
`;

export const DropDownListAlt = styled(DropDownList)`
  background-color: rgba(255, 255, 255, 0.1);
  max-width: 200px;
  margin-left: 6px;
  font-size: 0.9rem;
  width: ${props => props.customWidth && props.customWidth};
  & > option {
    color: #000;
  }
`;

export const PricingBlock = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-top: 15px;
`;

export const TotalPrice = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  & span {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
  }
`;

export const FeatureBadge = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  background-color: ${props => props.bgColor ? props.bgColor : '#2bdffd'};
  color: #000000;
  font-size: 0.65rem;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover div {
    visibility: visible;
    opacity: 1;
  }
`;

export const NewBadge = styled.div`
  background-color: #f7d235;
  padding: 3px;
  width: 35px;
  border-radius: 5px;
  color: #000000;
  font-size: 0.6rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConnectButtonWithDisabling = styled(ConnectButton)`
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  opacity: ${props => props.disabled ? "0.7" : "1.0"};
`;


export const ModalTotalPrice = styled(TotalPrice)`
  margin-top: -7px;
  margin-bottom: 8px;
`;

export const LaunchButton = styled.input`
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  border: none;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  color: #fff;
  border-radius: 10px;
  width: 250px;
  text-align:center;
  padding: 10px 0;
  margin-top: 20px;
  cursor: ${props => props.disabled ? "default" : "pointer"};
  opacity: ${props => props.disabled && 0.5};
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
`;

export const DashboardContainer = styled.div`
  background-color: #1a1c47;
  width: 100%;
  height: 100%;
  padding: 0 40px 30px 50px;
  @media screen and (max-width: 400px) {
    padding: 0 25px 30px 25px;
  }
  @media screen and (max-width: 350px) {
    padding: 0 10px 30px 10px;
  }
`;

export const DashboardPanel = styled.div`
  background-color: #292c6e;
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  flex-direction: column;
`;

export const DirectContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1300px;
  padding: 0 50px 30px 50px;
  @media screen and (max-width: 400px) {
    padding: 0 25px 30px 25px;
  }
  @media screen and (max-width: 350px) {
    padding: 0 10px 30px 10px;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 15px;
`;

export const ContentTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const ContentDescription = styled.div`
  margin-top: 5px;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ServerList = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const ServerListItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292c6e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
  }
  &:hover {
    background-color: rgba(41, 44, 100, 0.8);
  }
  opacity: ${props => props.inactive === 'true' && '0.5'};
  cursor: ${props => props.inactive === 'true' ? 'not-allowed' : 'pointer'};
  pointer-events: ${props => props.inactive === 'true' && 'none'};
`;

export const ServerListItemPlaceholder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292c6e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
  }
  &:hover {
    cursor: auto;
    background-color: #292c6e;
  }
`;

export const RefreshIcon = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 5px 8px 4px 8px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.9);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
`;

export const InputErrorText = styled.div`
  font-size: 0.7rem;
  color: red;
  font-weight: 300;
  margin-top: -12px;
  margin-left: auto;
  visibility: ${props => props.visible ? "visible" : "hidden"};
`;


export const LightBgTextInput = styled(TextInput)`
  background-color: #292c6e;
`;

export const InputFieldWithFeedback = styled.div`
  width: 265px;
  text-align: left;
  position: relative;
  @media screen and (max-width: 500px) {
    width: 160px;
  }
`;

export const InputFeedbackText = styled(InputErrorText)`
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  position: absolute;
  right: -4px;
  bottom: -16px;
`;

export const EmptyKeyPlaceholder = styled.div`
  min-height: 400px;
  width: 100%;
  border-radius: 10px;
  background-color: #292c6e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

export const LargeIcon = styled.div`
  font-size: 4rem;
  color: #fff;
`;

export const NoKeysDescription = styled.div`
  margin-top: 10px;
  max-width: 300px;
  font-size: 0.9rem;
  text-align: center;
`;

export const KeyName = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
`;

export const KeyDetails = styled.div`
  font-size: 0.8rem;
  margin-top: 5px;
  opacity: 0.8;
`;

export const GenerateKeyButton = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 15px;
  padding: 4px 12px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
  font-size: 0.8rem;
`;

export const SmallHollowActionButton = styled(GenerateKeyButton)`
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
`;

export const LargeGenerateKeyButton = styled(GenerateKeyButton)`
  font-size: 1rem;
  padding: 8px 16px;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Button = styled.div`
  height: 40px;
  width: 120px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
  }
  transition: background-color 0.2s, color 0.2s;
  @media screen and (max-width: 596px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 330px) {
    margin-left: 0;
  }
`;

export const SecondaryButtonBorder = styled.div`
  border-radius: 5px;
  background: linear-gradient(to right, #2bdffd, #a020f0);
  position: relative;
  padding: 0;
  &:before {
    content: "";
    position: absolute;
    border-radius: 5px;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: -1px;
    background: inherit;
  }
  margin-right: 17px;
  @media screen and (max-width: 430px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 350px) {
    display: none;
  }
`;

export const SecondaryButton = styled.div`
  font-size: 0.9rem;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  height: 40px;
  width: 100px;
  background-color: #121331;
  &:hover {
    background-color: #1a1c47;
  }
  @media screen and (max-width: 430px) {
    width: 50px;
    font-size: 0.8rem;
  }
  @media screen and (max-width: 350px) {
    display: none;
  }
`;

export const ServerInfo = styled.div`
`;

export const ListItemName = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`;

export const ListItemDescription = styled.div`
  margin-top: 5px;
  font-size: 0.8rem;
`;

export const HeroMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 1083px) {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

export const HeroMessageBold = styled.div`
  font-size: 5.5rem;
  font-weight: 800;
  @media screen and (max-width: 1004px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 493px) {
    font-size: 2.4rem;
  }
  @media screen and (max-width: 478px) {
    font-size: 2.15rem;
  }
  @media screen and (max-width: 438px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 414px) {
    font-size: 1.9rem;
  }
  @media screen and (max-width: 388px) {
    font-size: 1.85rem;
  }
  @media screen and (max-width: 380px ) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: 371px) {
    font-size: 1.73rem;
  }
  @media screen and (max-width: 360px) {
    font-size: 1.7rem;
  }
  @media screen and (max-width: 354px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 337px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 286px) {
    font-size: 1.25rem;
  }
`;

export const HeroMessageRegularLine = styled.div``;

export const HeroMessageHighlightLine = styled.div`
  background: linear-gradient(to right, #9438c2, #ff1f82);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const HeroMessageHighlightLineAlt = styled.div`
  background: linear-gradient(to right, #2bdffd, #a020f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const HeroSubtext = styled.div`
  font-size: 1.6rem;
  line-height: 160%;
  max-width: 600px;
  margin: 15px 0 40px 0;
  @media screen and (max-width: 491px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 337px) {
    font-size: 1.1rem;
  }
`;

export const LandingComputeContainer = styled.div`
  background-color: #1a1c47;
  min-height: 100vh;
  background-image: url(${CircuitBackgroundSvg});
  background-position: top left;
  background-repeat: repeat;
  @media screen and (min-width: 768px) {
    background-size: cover;
    background-attachment: fixed;
  }
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const InventoryContainer = styled.div`
  width: 100%;
  margin-top: ${props => `${props.topMarginOffset + 150}px`};
  @media screen and (max-width: 560px) {
    margin-top: ${props => `${props.topMarginOffset + 135}px`};
  }
  @media screen and (max-width: 360px) {
    margin-top: ${props => `${props.topMarginOffset + 100}px`};
  }
`;

export const HeroButton = styled(Button)`
  font-size: 1.2rem;
  height: 50px;
  width: 150px;
`;

export const ServerTags = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 880px) {
    max-width: 150px;
    flex-direction: column-reverse;
  }
`;

export const ServerPrice = styled.div`
  padding: 5px 10px;
  width: 140px;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  border-radius: 5px;
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
`;

export const ServerBandwidth = styled.div`
  padding: 5px 10px;
  width: 80px;
  border-radius: 5px;
  color: #fff;
  font-size: 0.7rem;
  text-align: center;
  background-color: rgba(160, 32, 240, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QuantityTag = styled.div`
  border: 1px solid #2bdffd;
  padding: 5px 10px;
  font-size: 0.7rem;
  color: #2bdffd;
  border-radius: 5px;
  min-width: 120px;
  text-align: center;
`;

export const RegistrationDate = styled.div`
  font-size: 0.7rem;
  opacity: 0.6;
  text-align: right;
`;

export const StatsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const StatBoxContainer = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 20px;
  flex-wrap: wrap;
`;

export const StatBox = styled.div`
  background-color: #292c6e;
  border-radius: 10px;
  padding: 25px;
  width: 300px;
`;

export const StatTitle = styled.div`
  font-size: 0.8rem;
  opacity: 0.7;
`;

export const StatValue = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
`;

export const InputQualifier = styled.div`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.4);
  margin-left: 5px;
  margin-top: 3px;
`;

export const PricingRow = styled.div`
  display: flex;
  margin-top: 3px;
`;

export const PricingCategoryTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`;

export const PricingCalc = styled.div`
  font-size: 0.8rem;
  margin-left: 5px;
  & span {
    font-size: 0.6rem;
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const SubSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 5px 0;
`;

export const NetworkingContainer = styled.div`
  margin-top: 10px;
`;

export const PortSelectorRowSuper = styled.div`
  display: flex;
  max-width: 320px;
  align-items: center;
`;

export const PortSelectorRow = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const DropDownListAltNoMargin = styled(DropDownListAlt)`
  margin: 0;
`;

export const InputFieldNoMargin = styled(InputField)`
  margin: 0;
  height: 43px;
  outline: ${props => props.error && "2px solid red"};
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
`;

export const RowDeleteButton = styled.div`
  font-size: 0.65rem;
  opacity: 0.5;
  cursor: pointer;
`;

export const PortSelectorGroup = styled.div`
  width: 100%;
`;

export const PortSelectorInputLabel = styled.div`
  font-size: 0.7rem;
  margin-bottom: 7px;
`;

export const AddSelectorRowButton = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 10px 12px;
  width: ${props => props.customWidth ? props.customWidth : "265px"};
  &:hover {
    background-color: rgba(255, 255, 255, 0.13);
    cursor: pointer;
  }
`;

export const AddSelectorIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  color: #fff;
  background-color: #ff1f82;
  margin-right: 7px;
`;

export const AddSelectorText = styled.div`
  font-size: 0.8rem;
`;

export const KeyPairDescription = styled.div`
  font-size: 0.7rem;
  margin-top: 7px;
  max-width: 420px;
`;

export const InputFieldContainer = styled.div`
  position: relative;
  &:hover div {
    visibility: visible;
    opacity: 1;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  font-size: 0.7rem;
  bottom: 45px;
  width: 140px;
  margin-left: -70px;
`;

export const DisabledDropDownList = styled(DropDownListAlt)`
  &:hover {
    cursor: not-allowed;
  }
`;

export const PlaceholderContainer = styled.div`
  margin-top: 10px;
  max-width: 420px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 0.9rem;
`;
