import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import {
    faFile,
    faFolder,
    faDownload,
    faTrashCan,
    faUpload,
    faFolderPlus,
    faArrowUp,
    faArrowDown,
    faCaretDown,
    faCaretUp,
    faAngleLeft,
    faAngleRight
} from "@fortawesome/pro-light-svg-icons";
import {
    DetailContainer,
    LoadingCover,
    Spinner,
    ServerList,
    ServerListItemPlaceholder,
    ConfirmModalBackgroundCover,
    ConfirmModal,
    ModalTitle,
    ModalText,
    ConnectButton,
    InfoGroup,
    InfoName,
    InfoData,
    RefreshIcon,
    GenerateKeyButton,
    SmallHollowActionButton,
    InputContainer,
    LightBgTextInput,
    ConnectButtonWithDisabling, ModalFinePrint
} from "./SharedStyles";
import { InputLabel } from "./Signup";
import {
    parseTimestamp,
    parseFileSize,
    urlSafeBase64Encode
} from "./utilities";

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const VolumeName = styled.div`
  font-size: 1.6rem;
  margin-right: 15px;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #292c6e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const ListFooter = styled.div`
  background-color: rgba(41, 44, 100, 0.8);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px 20px;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ObjectCount = styled.div`
  width: 50px;
  text-wrap: nowrap;
`;

const PagingControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Placeholder = styled.div`
  width: 50px;
  height: 10px;
  background-color: #fff;
  visibility: hidden;
`;

const ListRow = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #292c6e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(41, 44, 100, 0.8);
  }
`;

const NonLinkListRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #292c6e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(41, 44, 100, 0.8);
  }
`;

const SelectorSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 20px;
`;

const LabelsSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.9rem;
  opacity: 0.9;
  padding: 0 10px 0 20px;
`;

const DetailsSection = styled(LabelsSection)`
  opacity: 1;
  font-size: 1rem;
  display: flex;
  overflow: hidden;
`;

const FileName = styled.div`
  flex: 0 1 50%;
  font-weight: 500;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 20px;
`;

const NameColumnLabel = styled.div`
  flex: 0 1 50%;
  font-weight: 500;
  display: flex;
`;

const SizeColumnLabel = styled.div`
  flex: 0 1 25%;
  font-weight: 500;
  display: flex;
`;

const DateColumnLabel = styled.div`
  flex: 0 1 25%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DateColumnLabelText = styled.div`
  display: flex;
`;

const FileNameText = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const FileSize = styled.div`
  flex: 1 1 25%;
  font-size: 0.9rem;
`;

const FileDate = styled.div`
  flex: 1 1 25%;
  font-size: 0.85rem;
  opacity: 0.9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomCheckbox = styled.input`
  &:hover {
    cursor: pointer;
  }
`;

const NavigationBar = styled.div`
  display: flex;
  font-size: 1rem;
  opacity: 0.8;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  & a:hover {
    color: #2bdffd;
  }
`;

const SubPathElement = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & a:hover {
    color: #2bdffd;
  }
`;

const NavItemSeparator = styled.div`
  margin-right: 10px;
  font-size: 0.9rem;
  opacity: 0.5;
`;

const LeftAlignedModalText = styled(ModalText)`
  text-align: left;
  & > :first-child {
    margin-bottom: 10px;
  }
  width: 100%;
`;

const TrashIcon = styled(RefreshIcon)`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-size: 0.85rem;
`;

const SimpleFileList = styled.div`
  font-size: 0.9rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const FixedWidthGenerateKeyButton = styled(GenerateKeyButton)`
  width: 120px;
  margin-left: 0;
  margin-right: 15px;
  justify-content: space-between;
  background: transparent;
  &:hover {
    background: transparent;
  }
  padding: 0;
  height: 30px;
`;

const LeftSideUploadButton = styled.div`
  flex: 0 1 80%;
  background-color: ${props => props.flatten ? "rgba(255, 31, 130, 0.9)" : "#ff1f82"};
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  border-top-left-radius: 5px;
  border-bottom-left-radius: ${props => props.flatten ? "0" : "5px"};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgba(255, 31, 130, 0.9);
  }
`;

const RightSideUploadButton = styled.div`
  flex: 0 1 20%;
  background-color: ${props => props.flatten ? "rgba(255, 31, 130, 0.9)" : "#ff1f82"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: ${props => props.flatten ? "0" : "5px"};
  height: 100%;
  display: flex; justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgba(255, 31, 130, 0.9);
  }
`;

const DropDownMenu = styled.div`
  width: 120px;
  position: absolute;
  z-index: 44;
`;

const DropDownMenuItem = styled.div`
  background-color: #ff1f82;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 5px 0;
  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 31, 130, 0.9);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
`;

const FixedWidthHollowButton = styled(SmallHollowActionButton)`
  width: 120px;
  margin-left: 0;
  margin-right: 15px;
`;

const FileIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const FileUploadStatusModal = styled(ConfirmModal)`
  width: 215px;
  height: 215px;
`;

const FileUploadStatusSpinner = styled(ModalTitle)`
  margin-top: 10px;
`;

const FileUploadStatusText = styled(ModalFinePrint)`
  font-size: 0.8rem;
  color: ${props => props.color ? props.color : "#ffffff"};
  visibility: ${props => props.invisible && "hidden"};
  opacity: ${props => props.opacity ? props.opacity : "0.8"};
`;

const SortArrow = styled.div`
  margin-left: 7px;
  opacity: ${props => props.opacity && props.opacity};
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const DropDownContainer = styled.div`
  position: relative;
`;

const InvisibleBackgroundCover = styled(ConfirmModalBackgroundCover)`
  background-color: transparent;
`;

const DecrementButton = styled.div`
  font-size: 1.2rem;
  margin-right: 5px;
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  opacity: ${props => props.disabled ? "0.5" : "1"};
`;

const IncrementButton = styled.div`
  font-size: 1.2rem;
  margin-left: 5px;
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  opacity: ${props => props.disabled ? "0.5" : "1"};
`;

const PageIndexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageSelector = styled.input`
  -webkit-appearance: none;
  font-size: 0.8rem;
  margin-right: 5px;
  color: #fff;
  font-family: inherit;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  width: ${props => props.customWidth && props.customWidth};
  outline: ${props => props.error && "2px solid red"};
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
`;

const PortalStorageVolume = ({ volumeId, subPath, token, setSelectedView, location }) => {
    const [ volumeDetails, setVolumeDetails ] = useState({});
    const [ volumeContents, setVolumeContents ] = useState([]);
    const [ currentPageOfContents, setCurrentPageOfContents ] = useState([]);
    const [ pageSize, setPageSize ] = useState(10);
    const [ currentPageNumber, setCurrentPageNumber ] = useState(1);
    const [ numPages, setNumPages ] = useState(1);
    const [ fileTypes, setFileTypes ] = useState({});
    const [ volumeIsLoading, setVolumeIsLoading ] = useState(true);
    const [ volumeFailedToLoad, setVolumeFailedToLoad ] = useState(false);
    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [ expandedObjectDetails, setExpandedObjectDetails ] = useState({});
    const [ objectDetailsAreShown, setObjectDetailsAreShown ] = useState(false);
    const [ downloadUrlIsGenerating, setDownloadUrlIsGenerating ] = useState(false);
    const [ downloadUrlFailedToGenerate, setDownloadUrlFailedToGenerate ] = useState(false);
    const [ filesAreStagedForDeletion, setFilesAreStagedForDeletion ] = useState(false);
    const [ filesAreDeleting, setFilesAreDeleting ] = useState(false);
    const [ filesThatFailedToDelete, setFilesThatFailedToDelete ] = useState([]);
    const [ newFolderModalIsVisible, setNewFolderModalIsVisible ] = useState(false);
    const [ newFolderName, setNewFolderName ] = useState('');
    const [ newFolderIsGenerating, setNewFolderIsGenerating ] = useState(false);
    const [ newFolderCreationFailed, setNewFolderCreationFailed ] = useState(false);
    const [ filesAreUploading, setFilesAreUploading ] = useState(false);
    const [ filesThatFailedToUpload, setFilesThatFailedToUpload ] = useState([]);
    const [ numFilesUploaded, setNumFilesUploaded ] = useState(0);
    const [ numFilesToUpload , setNumFilesToUpload ] = useState(0);
    const [ uploadComplete, setUploadComplete ] = useState(false);
    const [ sortingBy, setSortingBy ] = useState('name'); // can be "name", "size" or "date"
    const [ sortingDirections, setSortingDirections ] = useState({
        'name': true,
        'size': true,
        'date': true
    });
    const [ uploadDropDownIsVisible, setUploadDropDownIsVisible ] = useState(false);

    const folderNameRef = useRef(null);
    const fileInputRef = useRef(null);
    const folderInputRef = useRef(null);

    useEffect(() => {
        setSelectedView('storage');
    }, [setSelectedView]);

    useEffect(() => {
        setSelectedFiles([]);
    }, [subPath]);

    useEffect(() => {
        if (token && volumeId) {
            getVolumeContents();
        }
    }, [token, volumeId, subPath]);

    useEffect(() => {
        if (newFolderModalIsVisible) {
            folderNameRef.current.focus()
        }
    }, [newFolderModalIsVisible]);

    // useEffect(() => {
    //     console.log(currentPageOfContents);
    // }, [currentPageOfContents]);

    // const extractLabelsForSort = (a, b, label) => {
    //     let aName = a[label];
    //     let bName = b[label];
    //     if (label === 'name') {
    //         aName = aName.toLowerCase();
    //         bName = bName.toLowerCase();
    //     } else if (label === 'date') {
    //         aName = new Date(aName);
    //         bName = new Date(bName);
    //     }
    //     return { aName, bName };
    // }

    const alphabetize = (a, b) => {
        const aName = a['name'].toLowerCase();
        const bName = b['name'].toLowerCase();
        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }
        return 0;
    }

    const reverseAlphabetize = (a, b) => {
        const aName = a['name'].toLowerCase();
        const bName = b['name'].toLowerCase();
        if (aName > bName) {
            return -1;
        }
        if (aName < bName) {
            return 1;
        }
        return 0;
    }

    const orderNumbers = (a, b) => {
        const aName = a['size'];
        const bName = b['size'];
        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }
        return 0;
    }

    const reverseOrderNumbers = (a, b) => {
        const aName = a['size'];
        const bName = b['size'];
        if (aName > bName) {
            return -1;
        }
        if (aName < bName) {
            return 1;
        }
        return 0;
    }

    const orderDates = (a, b) => {
        const aName = new Date(a['created_at']);
        const bName = new Date(b['created_at']);
        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }
        return 0;
    }

    const reverseOrderDates = (a, b) => {
        const aName = new Date(a['created_at']);
        const bName = new Date(b['created_at']);
        if (aName > bName) {
            return -1;
        }
        if (aName < bName) {
            return 1;
        }
        return 0;
    }

    const updateCurrentPageContents = page => {
        setCurrentPageOfContents(volumeContents.slice((page-1)*pageSize, page*pageSize));
    }

    const handlePageNumberChange = newPage => {
        setCurrentPageNumber(newPage);
        updateCurrentPageContents(newPage);
    }

    const setNewPageNumber = e => {
        if (e.target.value === '') {
            setCurrentPageNumber(e.target.value);
        } else {
            if (e.target.value >= 1 && e.target.value <= numPages) {
                setCurrentPageNumber(parseInt(e.target.value));
                updateCurrentPageContents(parseInt(e.target.value));
            }
        }
    }

    const getVolumeContents = () => {
        setVolumeIsLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/volume/${volumeId}?include_contents=True&subdirs=${subPath}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setVolumeFailedToLoad(true);
                }
            })
            .then(data => {
                if (data) {
                    if ('contents' in data) {
                        const sortedContents = data['contents'].sort(alphabetize);
                        setVolumeContents(sortedContents);
                        setFileTypes(data['contents'].reduce((acc, fileObj) => {
                            acc[fileObj['name']] = fileObj['type'];
                            return acc;
                        }, {}));

                        const numObjects = sortedContents.length;
                        if (numObjects !== 0) {
                            setNumPages(Math.ceil(numObjects/pageSize));
                            setCurrentPageOfContents(sortedContents.slice(0, pageSize));
                        }

                        delete data.contents;
                        setVolumeDetails(data);
                        setVolumeIsLoading(false);
                    } else {
                        setVolumeFailedToLoad(true);
                    }
                } else {
                    setVolumeFailedToLoad(true);
                }
            })
            .catch(error => {
                console.log(error);
                setVolumeFailedToLoad(true);
            })
            .finally(() => setVolumeIsLoading(false));
    }

    const handleCheckboxClick = (e, filename) => {
        e.stopPropagation();
        if (selectedFiles.includes(filename)) {
            setSelectedFiles(prev => prev.filter(elem => elem !== filename));
        } else {
            setSelectedFiles(prev => [...prev, filename]);
        }
    }

    const handleSelectAll = () => {
        if (selectedFiles.length === volumeContents.length) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(volumeContents.map(obj => obj['name']));
        }
    }

    const handleObjectExpansion = obj => {
        setExpandedObjectDetails(obj);
        setObjectDetailsAreShown(true);
    }

    const dismissObjectExpansion = () => {
        setObjectDetailsAreShown(false);
        setExpandedObjectDetails({});
    }

    const dismissDeletionConfirmationModal = () => {
        setFilesAreStagedForDeletion(false);
    }

    const handleFileDownload = filename => {
        setObjectDetailsAreShown(false);
        setDownloadUrlIsGenerating(true);
        setExpandedObjectDetails({});

        let targetPath = '';
        if (subPath === '') {
            targetPath = filename;
        } else {
            targetPath = `${subPath}/${filename}`;
        }
        const encodedPath = urlSafeBase64Encode(targetPath);

        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/volume/${volumeId}/object/${encodedPath}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setDownloadUrlFailedToGenerate(true);
                }
            })
            .then(data => {
                if (data) {
                    if ('download_link' in data) {
                        setDownloadUrlIsGenerating(false);
                        window.open(data['download_link'], '_blank');
                    } else {
                        setDownloadUrlFailedToGenerate(true);
                    }
                } else {
                    setDownloadUrlFailedToGenerate(true);
                }
            })
            .catch(error => {
                setDownloadUrlFailedToGenerate(true);
                console.log(error);
            })
            .finally(() => setDownloadUrlIsGenerating(false));
    }

    const deleteSelectedFiles = async () => {
        setFilesAreStagedForDeletion(false);
        setFilesAreDeleting(true);
        const deletePromises = [];
        for ( let i = 0; i < selectedFiles.length; i++ ) {
            const targetPath = subPath === '' ? selectedFiles[i] : `${subPath}/${selectedFiles[i]}`;
            const encodedPath = urlSafeBase64Encode(targetPath);
            const fetchPromise = fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/volume/${volumeId}/object/${encodedPath}?path_type=${fileTypes[selectedFiles[i]]}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        setFilesThatFailedToDelete(prev => [...prev, selectedFiles[i]]);
                    }
                })
                .then(data => {
                    if (!data) {
                        setFilesThatFailedToDelete(prev => [...prev, selectedFiles[i]]);
                    } else if (!('message' in data)) {
                        setFilesThatFailedToDelete(prev => [...prev, selectedFiles[i]]);
                    } else if (data['message'] !== 'Successfully deleted') {
                        setFilesThatFailedToDelete(prev => [...prev, selectedFiles[i]]);
                    }
                })
                .catch(error => {
                    setFilesThatFailedToDelete(prev => [...prev, selectedFiles[i]]);
                    console.log(error);
                });
            deletePromises.push(fetchPromise);
        }
        Promise.all(deletePromises).then(() => {
            setFilesAreDeleting(false);
            setSelectedFiles([]);
            getVolumeContents();
        });
    }

    const createNewFolder = () => {
        setNewFolderIsGenerating(true);
        const targetPath = subPath === '' ? newFolderName : `${subPath}/${newFolderName}`;
        const encodedPath = urlSafeBase64Encode(targetPath);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/volume/${volumeId}/folder/${encodedPath}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setNewFolderCreationFailed(true);
                }
            })
            .then(data => {
                if (data) {
                    if ('message' in data) {
                        if (data['message'] === 'Successfully created') {
                            setNewFolderIsGenerating(false);
                            getVolumeContents();
                        } else {
                            setNewFolderCreationFailed(true);
                        }
                    } else {
                        setNewFolderCreationFailed(true);
                    }
                } else {
                    setNewFolderCreationFailed(true);
                }
            })
            .catch(error => {
                setNewFolderCreationFailed(true);
                console.log(error);
            })
    }

    const updateFolderName = e => {
        const regex = /^[a-z0-9][a-z0-9-.]*$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            setNewFolderName(e.target.value);
        }
    }

    const dismissNewFolderModal = () => {
        setNewFolderModalIsVisible(false);
        setNewFolderName('');
    }

    const handleNewFolderCreateClick = () => {
        setNewFolderModalIsVisible(false);
        setNewFolderName('');
        createNewFolder();
    }

    const handleFilesToUpload = async (e) => {
        const files = e.target.files;
        setNumFilesToUpload(files.length);
        if (files.length) {
            setFilesAreUploading(true);
            for (let i = 0; i < files.length; i++) {
                try {
                    const fullFileName = files[i].webkitRelativePath || files[i].name;
                    const targetPath = subPath === '' ? fullFileName : `${subPath}/${fullFileName}`;
                    const encodedPath = urlSafeBase64Encode(targetPath);
                    const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/volume/${volumeId}/upload/${encodedPath}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.status !== 200) {
                        throw new Error(`Failed to get upload link for ${files[i].name}`);
                    }

                    const data = await response.json();
                    if ( !data || !('upload_link' in data)) {
                        throw new Error(`Unexpected response when fetching pre-signed URL for ${files[i].name}`);
                    }
                    await fetch(data['upload_link'], {
                        method: 'PUT',
                        body: files[i],
                        redirect: 'follow',
                        headers: {
                            'Accept': '*/*',
                            'Content-Type': ''
                        }
                    })
                    setNumFilesUploaded(i+1);
                } catch (error) {
                    setFilesThatFailedToUpload(prev => [...prev, files[i].name]);
                }
            }
            setFilesAreUploading(false);
            setUploadComplete(true);
        }
    }

    const triggerFileInput = () => {
        setUploadDropDownIsVisible(false);
        fileInputRef.current.click();
    }

    const triggerFolderInput = () => {
        setUploadDropDownIsVisible(false);
        folderInputRef.current.innerHTML = '';

        const inputElement = document.createElement('input');
        inputElement.type = 'file';
        inputElement.setAttribute('webkitdirectory', 'true');
        inputElement.style.display = 'none';
        inputElement.onchange = handleFilesToUpload;

        folderInputRef.current.appendChild(inputElement);
        inputElement.click();
    }

    const dismissUploadCompleteModal = () => {
        setNumFilesUploaded(0);
        setNumFilesToUpload(0);
        setFilesThatFailedToUpload([]);
        setUploadComplete(false);
        getVolumeContents();
    }

    const handleSortOrderChange = label => {
        let newSortingDirections;
        if (label === sortingBy) {
            newSortingDirections = {...sortingDirections};
            newSortingDirections[label] = !newSortingDirections[label];
            setSortingDirections(newSortingDirections);
        } else {
            newSortingDirections = {
                'name': true,
                'size': true,
                'date': true
            }
            setSortingDirections(newSortingDirections);
            setSortingBy(label);
        }

        let sortedContents;
        if (label === 'name') {
            if (newSortingDirections['name']) {
                sortedContents = [...volumeContents].sort(alphabetize);
            } else {
                sortedContents = [...volumeContents].sort(reverseAlphabetize);
            }
        }
        if (label === 'size') {
            if (newSortingDirections['size']) {
                sortedContents = [...volumeContents].sort(orderNumbers);
            } else {
                sortedContents = [...volumeContents].sort(reverseOrderNumbers);
            }
        }
        if (label === 'date') {
            if (newSortingDirections['date']) {
                sortedContents = [...volumeContents].sort(orderDates);
            } else {
                sortedContents = [...volumeContents].sort(reverseOrderDates);
            }
        }
        setVolumeContents(sortedContents);
        setCurrentPageOfContents(sortedContents.slice(0, pageSize));
        setCurrentPageNumber(1);
    }

    return (
        <DetailContainer>
            <HiddenFileInput type="file"
                             ref={fileInputRef}
                             onChange={handleFilesToUpload}
                             multiple
            />
            <div ref={folderInputRef} />
            {volumeIsLoading ? (
                <LoadingCover>
                    <Spinner>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </Spinner>
                </LoadingCover>
            ) : (
                volumeFailedToLoad ? (
                    <LoadingCover>
                        Volume contents failed to load.
                    </LoadingCover>
                ) : (
                    <>
                        <NavigationBar>
                            <Link to='/dashboard/storage'>
                                Volumes
                            </Link>
                            <SubPathElement>
                                <NavItemSeparator>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </NavItemSeparator>
                                {subPath === '' ? (
                                    <div>
                                        {volumeDetails['bucket_name']}
                                    </div>
                                ) : (
                                    <Link
                                        onClick={() => setVolumeIsLoading(true)}
                                        to={{
                                        pathname: `/dashboard/storage/volume/${volumeId}`,
                                        state: {type: 'PRE'}
                                    }}>
                                        {volumeDetails['bucket_name']}
                                    </Link>
                                )}
                            </SubPathElement>
                            {subPath !== '' && (
                                subPath.split('/').map((subPart, index) => {
                                    const key = (subPath.split('/', index + 1)).join('/');
                                    return (
                                        <SubPathElement key={key}>
                                            <NavItemSeparator>
                                                <FontAwesomeIcon icon={faAngleRight} />
                                            </NavItemSeparator>
                                            {(index === subPath.split('/').length - 1) ? (
                                                <div>
                                                    {subPart}
                                                </div>
                                            ) : (
                                                <Link
                                                    onClick={() => setVolumeIsLoading(true)}
                                                    to={{
                                                    pathname: `/dashboard/storage/volume/${volumeId}/${key}`,
                                                    state: {type: 'PRE'}
                                                }}>
                                                    {subPart}
                                                </Link>
                                            )}
                                        </SubPathElement>
                                    )
                                })
                            )}
                        </NavigationBar>
                        <TitleSection>
                            <VolumeName>
                                {volumeDetails['bucket_name']}
                            </VolumeName>
                            <ActionButtons>
                                {fileInputRef && (
                                    <>
                                        <DropDownContainer>
                                            <FixedWidthGenerateKeyButton
                                                onClick={() => setUploadDropDownIsVisible(prev => !prev)}
                                            >
                                                <LeftSideUploadButton flatten={uploadDropDownIsVisible}>
                                                    <FontAwesomeIcon icon={faUpload} style={{marginRight: "5px"}} />
                                                    Upload
                                                </LeftSideUploadButton>
                                                <RightSideUploadButton flatten={uploadDropDownIsVisible}>
                                                    {uploadDropDownIsVisible ? (
                                                        <FontAwesomeIcon icon={faCaretUp} />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faCaretDown} />
                                                    )}
                                                </RightSideUploadButton>
                                            </FixedWidthGenerateKeyButton>
                                            {uploadDropDownIsVisible && (
                                                <>
                                                    <InvisibleBackgroundCover onClick={() => setUploadDropDownIsVisible(false)} />
                                                    <DropDownMenu>
                                                        <DropDownMenuItem onClick={triggerFileInput}>
                                                            Upload file
                                                        </DropDownMenuItem>
                                                        <DropDownMenuItem onClick={triggerFolderInput}>
                                                            Upload folder
                                                        </DropDownMenuItem>
                                                    </DropDownMenu>
                                                </>
                                            )}
                                        </DropDownContainer>
                                        <FixedWidthHollowButton onClick={() => setNewFolderModalIsVisible(true)}>
                                            <FontAwesomeIcon icon={faFolderPlus} style={{marginRight: "5px"}} />
                                            New folder
                                        </FixedWidthHollowButton>
                                    </>
                                )}
                            </ActionButtons>
                        </TitleSection>
                        <ServerList>
                            <ListHeader>
                                <SelectorSection>
                                    <CustomCheckbox
                                        type="checkbox"
                                        id="select-all"
                                        checked={(selectedFiles.length === volumeContents.length && volumeContents.length !== 0)}
                                        onChange={handleSelectAll}
                                    />
                                </SelectorSection>
                                <LabelsSection>
                                    <NameColumnLabel>
                                        Name
                                        {sortingDirections['name'] ? (
                                            <SortArrow opacity={sortingBy === 'name' ? "1" : "0.4"}
                                                       onClick={() => handleSortOrderChange('name')}
                                            >
                                                <FontAwesomeIcon icon={faArrowUp} />
                                            </SortArrow>
                                        ) : (
                                            <SortArrow opacity={sortingBy === 'name' ? "1" : "0.4"}
                                                       onClick={() => handleSortOrderChange('name')}
                                            >
                                                <FontAwesomeIcon icon={faArrowDown} />
                                            </SortArrow>
                                        )}
                                    </NameColumnLabel>
                                    <SizeColumnLabel>
                                        Size
                                        {sortingDirections['size'] ? (
                                            <SortArrow opacity={sortingBy === 'size' ? "1" : "0.4"}
                                                       onClick={() => handleSortOrderChange('size')}
                                            >
                                                <FontAwesomeIcon icon={faArrowUp} />
                                            </SortArrow>
                                        ) : (
                                            <SortArrow opacity={sortingBy === 'size' ? "1" : "0.4"}
                                                       onClick={() => handleSortOrderChange('size')}
                                            >
                                                <FontAwesomeIcon icon={faArrowDown} />
                                            </SortArrow>
                                        )}
                                    </SizeColumnLabel>
                                    <DateColumnLabel>
                                        <DateColumnLabelText>
                                            Uploaded at
                                            {sortingDirections['date'] ? (
                                                <SortArrow opacity={sortingBy === 'date' ? "1" : "0.4"}
                                                           onClick={() => handleSortOrderChange('date')}
                                                >
                                                    <FontAwesomeIcon icon={faArrowUp} />
                                                </SortArrow>
                                            ) : (
                                                <SortArrow opacity={sortingBy === 'date' ? "1" : "0.4"}
                                                           onClick={() => handleSortOrderChange('date')}
                                                >
                                                    <FontAwesomeIcon icon={faArrowDown} />
                                                </SortArrow>
                                            )}
                                        </DateColumnLabelText>
                                        {selectedFiles.length !== 0 && (
                                            <TrashIcon onClick={() => setFilesAreStagedForDeletion(true)}>
                                                <FontAwesomeIcon icon={faTrashCan} />
                                            </TrashIcon>
                                        )}
                                    </DateColumnLabel>
                                </LabelsSection>
                            </ListHeader>
                            {volumeContents.length === 0 ? (
                                <ServerListItemPlaceholder>This volume is currently empty.</ServerListItemPlaceholder>
                            ) : (
                                currentPageOfContents.map(obj => {
                                    if (obj['type'] === 'PRE') {
                                        return (
                                            <ListRow
                                                key={obj['name']}
                                                to={{
                                                    pathname: `${location.pathname}/${obj['name']}`,
                                                    state: { type: obj['type'] }
                                                }}>
                                                <SelectorSection>
                                                    <CustomCheckbox
                                                        type="checkbox"
                                                        id={obj['name']}
                                                        onClick={e => handleCheckboxClick(e, obj['name'])}
                                                        onChange={() => {}}
                                                        checked={selectedFiles.includes(obj['name'])}
                                                    />
                                                </SelectorSection>
                                                <DetailsSection>
                                                    <FileName>
                                                        <FileIcon><FontAwesomeIcon icon={faFolder} /></FileIcon>
                                                        <FileNameText>{obj['name']}</FileNameText>
                                                    </FileName>
                                                </DetailsSection>
                                            </ListRow>
                                        )
                                    } else if (obj['type'] === 'OBJ') {
                                        return (
                                            <NonLinkListRow key={obj['name']}
                                                            onClick={() => handleObjectExpansion(obj)}
                                            >
                                                <SelectorSection>
                                                    <CustomCheckbox
                                                        type="checkbox"
                                                        id={obj['name']}
                                                        onClick={e => handleCheckboxClick(e, obj['name'])}
                                                        onChange={() => {}}
                                                        checked={selectedFiles.includes(obj['name'])}
                                                    />
                                                </SelectorSection>
                                                <DetailsSection>
                                                    <FileName>
                                                        <FileIcon><FontAwesomeIcon icon={faFile} /></FileIcon>
                                                        <FileNameText>{obj['name']}</FileNameText>
                                                    </FileName>
                                                    <FileSize>
                                                        {parseFileSize(obj['size'])}
                                                    </FileSize>
                                                    <FileDate>
                                                        {parseTimestamp(obj['created_at'], false).replace(',', ' at')}
                                                    </FileDate>
                                                </DetailsSection>
                                            </NonLinkListRow>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                            )}
                            <ListFooter>
                                <ObjectCount>
                                    {volumeContents.length.toLocaleString()} Objects
                                </ObjectCount>
                                <PagingControls>
                                    <DecrementButton onClick={() => handlePageNumberChange(currentPageNumber - 1)}
                                                     disabled={currentPageNumber === 1 || currentPageNumber === ''}
                                    >
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </DecrementButton>
                                    <PageIndexContainer>
                                        <PageSelector type="number"
                                                      min="1"
                                                      max={numPages}
                                                      step="1"
                                                      value={currentPageNumber}
                                                      onChange={setNewPageNumber}
                                        />
                                        of {numPages}
                                    </PageIndexContainer>
                                    <IncrementButton onClick={() => handlePageNumberChange(currentPageNumber + 1)}
                                                     disabled={currentPageNumber === numPages || currentPageNumber === ''}
                                    >
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </IncrementButton>
                                </PagingControls>
                                <Placeholder />
                            </ListFooter>
                        </ServerList>
                    </>
                )
            )}
            {objectDetailsAreShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissObjectExpansion} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            {expandedObjectDetails['name']}
                        </ModalTitle>
                        <LeftAlignedModalText>
                            <InfoGroup>
                                <InfoName>
                                    Size
                                </InfoName>
                                <InfoData>
                                    {parseFileSize(expandedObjectDetails['size'])}
                                </InfoData>
                            </InfoGroup>
                            <InfoGroup>
                                <InfoName>
                                    Uploaded at
                                </InfoName>
                                <InfoData>
                                    {parseTimestamp(expandedObjectDetails['created_at'], false).replace(',', ' at')}
                                </InfoData>
                            </InfoGroup>
                        </LeftAlignedModalText>
                        <ConnectButton onClick={() => handleFileDownload(expandedObjectDetails['name'])}>
                            Download <FontAwesomeIcon icon={faDownload} />
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {(downloadUrlIsGenerating || filesAreDeleting || newFolderIsGenerating) && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </ModalTitle>
                    </ConfirmModal>
                </>
            )}
            {filesAreUploading && (
                <>
                    <ConfirmModalBackgroundCover />
                    <FileUploadStatusModal>
                        <FileUploadStatusSpinner>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </FileUploadStatusSpinner>
                        <FileUploadStatusText>
                            {numFilesUploaded} of {numFilesToUpload} files uploaded successfully.
                        </FileUploadStatusText>
                        <FileUploadStatusText color="#ff0000"
                                              invisible={filesThatFailedToUpload.length === 0}
                                              opacity="1"
                        >
                            {filesThatFailedToUpload.length} of {numFilesToUpload} files failed to upload.
                        </FileUploadStatusText>
                    </FileUploadStatusModal>
                </>
            )}
            {downloadUrlFailedToGenerate && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setDownloadUrlFailedToGenerate(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an unexpected issue with downloading your file. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setDownloadUrlFailedToGenerate(false)}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {newFolderCreationFailed && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setNewFolderCreationFailed(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an unexpected issue with creating the new folder. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setNewFolderCreationFailed(false)}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {(filesThatFailedToDelete.length > 0 && !filesAreDeleting) && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setFilesThatFailedToDelete([])} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            The following files or directories failed to be deleted. Please try again later.
                        </ModalText>
                        <SimpleFileList>
                            {filesThatFailedToDelete.map(file => <div key={file}>{file}</div>)}
                        </SimpleFileList>
                        <ConnectButton onClick={() => setFilesThatFailedToDelete([])}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {filesAreStagedForDeletion && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissDeletionConfirmationModal} />
                    <ConfirmModal>
                        <ModalTitle>
                            Are you sure?
                        </ModalTitle>
                        <ModalText>
                            Are you sure you wish to permanently delete the following files or directories?
                            <br />
                            <br />
                            This destructive action is irreversible.
                        </ModalText>
                        <SimpleFileList>
                            {selectedFiles.map(file => <div key={file}>{file}</div>)}
                        </SimpleFileList>
                        <ConnectButton onClick={deleteSelectedFiles}>
                            Yes, proceed.
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {newFolderModalIsVisible && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissNewFolderModal} />
                    <ConfirmModal maxWidth="400px">
                        <ModalTitle>
                            New folder
                        </ModalTitle>
                        <InputContainer>
                            <InputLabel htmlFor="folder-name-input">New folder name</InputLabel>
                            <LightBgTextInput id="folder-name=input"
                                              ref={folderNameRef}
                                              value={newFolderName}
                                              onChange={updateFolderName}
                            />
                        </InputContainer>
                        <ConnectButtonWithDisabling disabled={newFolderName === ''}
                                                    onClick={handleNewFolderCreateClick}
                        >
                            Create
                        </ConnectButtonWithDisabling>
                    </ConfirmModal>
                </>
            )}
            {uploadComplete && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissUploadCompleteModal} />
                    <ConfirmModal>
                        <ModalTitle>
                            Success
                        </ModalTitle>
                        <ModalText>
                            Your files were uploaded successfully.
                        </ModalText>
                        <ConnectButton onClick={dismissUploadCompleteModal}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
        </DetailContainer>
    );
}

export default PortalStorageVolume;