import { useEffect } from "react";
import styled from "styled-components";
import {
    HeroButton,
    HeroMessage,
    HeroMessageBold,
    HeroMessageHighlightLineAlt,
    HeroMessageHighlightLine,
    HeroMessageRegularLine,
    HeroSubtext
} from "./SharedStyles";
import {
    FeaturesSection,
    FeaturesContent,
    FeaturesTitle,
    FeaturesDescription,
    FeaturesGrid,
    GridItem,
    GridItemGraphic,
    GridItemContent,
    GridItemTitle,
    GridItemDescription,
    CliSection,
    CliSectionContent,
    UseCasesSection,
    UseCasesTitle,
    FeaturesList,
    ListItem,
    ListItemIcon,
    ListItemTitle,
    ListItemContent
} from "./LandingStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStripeS } from "@fortawesome/free-brands-svg-icons";
import {
    faCircleDollarToSlot,
    faBook,
    faFileInvoice, faRocketLaunch, faFloppyDisk, faShareNodes, faCloudBinary
} from "@fortawesome/sharp-regular-svg-icons";
import { Link } from "react-router-dom";
import CircuitBackgroundSvg from "./assets/circuit-background.svg";
import CaltechLogo from "./assets/image-logo-caltech-gray.png";
import UIUCLogo from "./assets/image-logo-uiuc-gray.png";
import USCLogo from "./assets/image-logo-usc-gray.png";
import NortheasternLogo from "./assets/image-logo-northeastern.png";
import HarvardLogo from "./assets/image-logo-harvard.png";
import YaleLogo from "./assets/image-logo-yale.png";
import CMULogo from "./assets/image-logo-cmu.png";
import GH200 from "./assets/image-gpu-gh200.png";
import MI250 from "./assets/image-gpu-mi250.png";
import H100 from "./assets/image-gpu-h100.png";
import A100 from "./assets/image-gpu-a100.png";
import L40 from "./assets/image-gpu-l40.png";
import A6000 from "./assets/image-gpu-a6000.png";
import GpuDalle from "./assets/image-gpu-abstract-fade.png";

const MainContainer = styled.div`
`;

const HeroMain = styled.div`
  display: flex;
  justify-content: center;
  background-color: #1a1c47;
  width: 100%;
  position: relative;
  background-image: url(${CircuitBackgroundSvg});
  background-position: top left;
  background-repeat: repeat;
  background-size: cover;
  @media screen and (min-width: 768px) {
    background-attachment: fixed;
  }
`;

const HeroContent = styled.div`
  margin-top: ${props => `${props.topMarginOffset + 150}px`};
  margin-bottom: 160px;
  max-width: 1500px;
  padding: 0 32px;
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 1083px) {
    justify-content: center;
  }
  @media screen and (max-width: 560px) {
    margin-top: ${props => `${props.topMarginOffset + 135}px`};
  }
  @media screen and (max-width: 360px) {
    margin-top: ${props => `${props.topMarginOffset + 100}px`};
  }
`;

const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  flex-wrap: nowrap;
  @media screen and (max-width: 1083px) {
    align-items: center;
  }
`;

const TestimonialLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  gap: 30px;
  flex-wrap: wrap;
  @media screen and (max-width: 1083px) {
    justify-content: center;
  }
`;

const TestimonialLogo = styled.div`
  height: 50px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    height: 100%;
    width: auto;
    max-width: none;
  }
`;

const FeaturesTitleFlat = styled(FeaturesTitle)`
  color: #2bdffd;
  background: none;
  -webkit-text-fill-color: currentColor;
  -webkit-background-clip: border-box;
`;

const GpuImageContainer = styled.div`
  max-width: 350px;
  & img {
    width: 100%;
    filter: drop-shadow(0 20px 1rem rgba(43, 223, 253, 0.5));
  }
`;

const GpuDescription = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  text-align: ${props => props.align && props.align};
  align-items: ${props => props.align === "right" ? 'flex-end': 'flex-start'};
  @media screen and (max-width: 959px) {
    align-items: center;
    text-align: center;
  }
`;

const GpuTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
`;

const GpuDescriptionBody = styled.div`
  font-size: 0.9rem;
`;

const GpuSectionContent = styled(CliSectionContent)`
  width: 100%;
  gap: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 0 40px 0;
  justify-content: center;
  @media screen and (max-width: 959px) {
    &:nth-child(2n + 1) {
      flex-direction: column-reverse;
    }
  }
`;

const GpuPricingButton = styled(HeroButton)`
  width: 200px;
  font-size: 1rem;
  margin-top: 20px;
`;

const StorageSectionTitle = styled(UseCasesTitle)`
  background: linear-gradient(to right, #2bdffd, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StorageSectionDescription = styled(FeaturesDescription)`
  margin-bottom: 45px;
`;

const CustomHeroButton = styled(HeroButton)`
  position: relative;
  z-index: 2;
`;

const GpuGraphic = styled.div`
  width: 500px;
  & img {
    width: 100%;
  }
`;

const LandingMain = ({ setActiveView, bannerHeight }) => {
    useEffect(() => {
        setActiveView('home');
    }, [setActiveView]);

    return (
        <MainContainer>
            <HeroMain>
                <HeroContent topMarginOffset={bannerHeight}>
                    <HeroMessage>
                        <HeroMessageBold>
                            <HeroMessageHighlightLineAlt>
                                Global Cloud Storage.
                            </HeroMessageHighlightLineAlt>
                            <HeroMessageHighlightLine>
                                Cutting-edge GPUs.
                            </HeroMessageHighlightLine>
                            <HeroMessageRegularLine>
                                50% Lower Cost.
                            </HeroMessageRegularLine>
                        </HeroMessageBold>
                        <HeroSubtext>
                            On-demand GPUs and scalable storage for AI training and inference. Get started with just $10.
                        </HeroSubtext>
                        <Link to="/signup">
                            <HeroButton>
                                Sign Up
                            </HeroButton>
                        </Link>
                        <Testimonials>
                            <TestimonialLogos>
                                <TestimonialLogo>
                                    <img src={CaltechLogo} alt="" />
                                </TestimonialLogo>
                                <TestimonialLogo>
                                    <img src={UIUCLogo} alt="" />
                                </TestimonialLogo>
                                <TestimonialLogo>
                                    <img src={USCLogo} alt="" />
                                </TestimonialLogo>
                                <TestimonialLogo>
                                    <img src={NortheasternLogo} alt="" />
                                </TestimonialLogo>
                                <TestimonialLogo>
                                    <img src={HarvardLogo} alt="" style={{height: "55px"}} />
                                </TestimonialLogo>
                                <TestimonialLogo>
                                    <img src={CMULogo} alt="" style={{height: "55px"}} />
                                </TestimonialLogo>
                                <TestimonialLogo>
                                    <img src={YaleLogo} alt="" />
                                </TestimonialLogo>
                            </TestimonialLogos>
                        </Testimonials>
                    </HeroMessage>
                </HeroContent>
            </HeroMain>
            <FeaturesSection>
                <FeaturesContent>
                    <FeaturesTitleFlat>
                        Get started with as little as $10.
                    </FeaturesTitleFlat>
                    <FeaturesDescription>
                        VALDI uses a pay-as-you-go model. No contracts. No minimums. No hidden fees. Deposit funds to
                        start spinning up compute and storage resources. Usage is billed by the second.
                    </FeaturesDescription>
                    <FeaturesGrid>
                        <GridItem>
                            <GridItemGraphic>
                                <FontAwesomeIcon icon={faCircleDollarToSlot} />
                            </GridItemGraphic>
                            <GridItemContent>
                                <GridItemTitle>
                                    Autopay.
                                </GridItemTitle>
                                <GridItemDescription>
                                    Never worry about your account balance. Set the amount you want to
                                    automatically deposit, and we'll take care of it when your balance gets low.
                                </GridItemDescription>
                            </GridItemContent>
                        </GridItem>
                        <GridItem>
                            <GridItemGraphic>
                                <FontAwesomeIcon icon={faStripeS} />
                            </GridItemGraphic>
                            <GridItemContent>
                                <GridItemTitle>
                                    Stripe payments.
                                </GridItemTitle>
                                <GridItemDescription>
                                    All of our payments are processed through Stripe, so VALDI doesn't know or store any
                                    credit card information. All major credit cards accepted.
                                </GridItemDescription>
                            </GridItemContent>
                        </GridItem>
                        <GridItem>
                            <GridItemGraphic>
                                <FontAwesomeIcon icon={faBook} />
                            </GridItemGraphic>
                            <GridItemContent>
                                <GridItemTitle>
                                    Educational discount.
                                </GridItemTitle>
                                <GridItemDescription>
                                    Automatically earn a 10% discount on all services when you sign up with a .edu email
                                    address. Prices will automatically reflect the discount.
                                </GridItemDescription>
                            </GridItemContent>
                        </GridItem>
                        <GridItem>
                            <GridItemGraphic>
                                <FontAwesomeIcon icon={faFileInvoice} />
                            </GridItemGraphic>
                            <GridItemContent>
                                <GridItemTitle>
                                    Post-pay options.
                                </GridItemTitle>
                                <GridItemDescription>
                                    Anticipating a large spend? Interested in reserving instances for a discount?
                                    Contact us using the chat widget in the lower-right hand corner.
                                </GridItemDescription>
                            </GridItemContent>
                        </GridItem>
                    </FeaturesGrid>
                </FeaturesContent>
            </FeaturesSection>
            <CliSection>
                <FeaturesTitle>
                    All the best cards. All in one place.
                </FeaturesTitle>
                {/*<GpuSectionContent>*/}
                {/*    <GpuImageContainer>*/}
                {/*        <img src={GH200} />*/}
                {/*    </GpuImageContainer>*/}
                {/*    <GpuDescription>*/}
                {/*        <GpuTitle>*/}
                {/*            NVIDIA GH200 Grace Hopper Superchip*/}
                {/*        </GpuTitle>*/}
                {/*        <GpuDescriptionBody>*/}
                {/*            The NVIDIA GH200 Grace Hopper Superchip a powerhouse in the world of computing,*/}
                {/*            especially designed for tasks that need a ton of computing muscle, like AI, deep*/}
                {/*            learning, and high-performance computing. Picture this as a dynamic duo, where NVIDIA has*/}
                {/*            cleverly combined their Grace CPU with the Hopper GPU onto a single chip.*/}
                {/*        </GpuDescriptionBody>*/}
                {/*        <Link to="/signup">*/}
                {/*            <GpuPricingButton>*/}
                {/*                Starting at $4.50/hr*/}
                {/*            </GpuPricingButton>*/}
                {/*        </Link>*/}
                {/*    </GpuDescription>*/}
                {/*</GpuSectionContent>*/}
                <GpuSectionContent>
                    <GpuImageContainer>
                        <img src={H100} />
                    </GpuImageContainer>
                    <GpuDescription>
                        <GpuTitle>
                            NVIDIA H100 (PCIe or SXM5 IB)
                        </GpuTitle>
                        <GpuDescriptionBody>
                            The NVIDIA H100 is the heavyweight champ of GPUs for artificial intelligence and
                            high-performance computing, making short work of even the most complex data crunching tasks.
                            It's like having a supercomputer at your fingertips, ready to tackle the future of tech.
                        </GpuDescriptionBody>
                        <Link to="/signup">
                            <GpuPricingButton>
                                Starting at $2.35/hr
                            </GpuPricingButton>
                        </Link>
                    </GpuDescription>
                </GpuSectionContent>
                <GpuSectionContent>
                    <GpuDescription align="right">
                        <GpuTitle>
                            NVIDIA A100 80GB (PCIe or SXM4 IB)
                        </GpuTitle>
                        <GpuDescriptionBody>
                            NVIDIA's A100 is a beast for AI and high-performance tasks, designed to power through
                            massive datasets and machine learning workloads like a hot knife through butter. It's the
                            secret sauce behind the world's most advanced data centers.
                        </GpuDescriptionBody>
                        <Link to="/signup">
                            <GpuPricingButton>
                                Starting at $1.95/hr
                            </GpuPricingButton>
                        </Link>
                    </GpuDescription>
                    <GpuImageContainer>
                        <img src={A100} />
                    </GpuImageContainer>
                </GpuSectionContent>
                <GpuSectionContent>
                    <GpuImageContainer>
                        <img src={L40} />
                    </GpuImageContainer>
                    <GpuDescription>
                        <GpuTitle>
                            NVIDIA L40 & L40S
                        </GpuTitle>
                        <GpuDescriptionBody>
                            The NVIDIA L40 is the go-to GPU for creators and data scientists looking for top-notch
                            performance without breaking the bank. It's a solid all-rounder that brings serious
                            computing power to the table, making it a great choice for a wide range of applications.
                        </GpuDescriptionBody>
                        <Link to="/signup">
                            <GpuPricingButton>
                                Starting at $1.30/hr
                            </GpuPricingButton>
                        </Link>
                    </GpuDescription>
                </GpuSectionContent>
                <GpuSectionContent>
                    <GpuDescription align="right">
                        <GpuTitle>
                            NVIDIA RTX A6000
                        </GpuTitle>
                        <GpuDescriptionBody>
                            The NVIDIA RTX A6000 is a dream come true for creative professionals, offering unmatched
                            graphics and computational horsepower to bring the most intricate designs to life. Whether
                            you're rendering complex 3D scenes or crunching through video editing, the A6000 is your
                            reliable powerhouse.
                        </GpuDescriptionBody>
                        <Link to="/signup">
                            <GpuPricingButton>
                                Starting at $0.60/hr
                            </GpuPricingButton>
                        </Link>
                    </GpuDescription>
                    <GpuImageContainer>
                        <img src={A6000} />
                    </GpuImageContainer>
                </GpuSectionContent>
                <GpuSectionContent>
                    <GpuImageContainer>
                        <img src={MI250} />
                    </GpuImageContainer>
                    <GpuDescription>
                        <GpuTitle>
                            AMD Instinct MI250
                        </GpuTitle>
                        <GpuDescriptionBody>
                            The AMD Instinct MI250 is AMD's answer to the high demands of supercomputing and AI. Think
                            of it as a supercharged engine designed to power through the most intense data analysis,
                            machine learning, and scientific simulations. It's part of AMD's lineup of GPUs that are
                            specifically built not for gaming, but for crunching numbers at incredible speeds.
                        </GpuDescriptionBody>
                        <Link to="/signup">
                            <GpuPricingButton>
                                Starting at $1.35/hr
                            </GpuPricingButton>
                        </Link>
                    </GpuDescription>
                </GpuSectionContent>
                <Link to="/compute">
                    <HeroButton style={{marginTop: "35px"}}>
                        See all GPUs
                    </HeroButton>
                </Link>
            </CliSection>
            <UseCasesSection>
                <FeaturesContent>
                    <StorageSectionTitle>
                        Need storage too? <span>We've got you covered.</span>
                    </StorageSectionTitle>
                    <StorageSectionDescription>
                        With VALDI storage, you can upload and download files with ease, either through the dashboard
                        or with the easy-to-use VALDI CLI. Spend less time migrating data and more time innovating.
                    </StorageSectionDescription>
                    <FeaturesList>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faRocketLaunch} style={{"color": "#2bdffd"}} />
                            </ListItemIcon>
                            <ListItemTitle>
                                Scale storage independently
                            </ListItemTitle>
                            <ListItemContent>
                                Attach additional volumes to scale storage capacity for databases without scaling
                                compute resources.
                            </ListItemContent>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faFloppyDisk} style={{"color": "#2bdffd"}} />
                            </ListItemIcon>
                            <ListItemTitle>
                                Archive your data
                            </ListItemTitle>
                            <ListItemContent>
                                Move cold data to detachable volumes to reduce storage costs for data rarely needed,
                                while still keeping it readily accessible.
                            </ListItemContent>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faShareNodes} style={{"color": "#2bdffd"}} />
                            </ListItemIcon>
                            <ListItemTitle>
                                Share data across VMs
                            </ListItemTitle>
                            <ListItemContent>
                                Attach a volume to multiple VMs, allowing all instances to access and modify the same
                                data set. Streamline your data processing pipelines.
                            </ListItemContent>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faCloudBinary} style={{"color": "#2bdffd"}} />
                            </ListItemIcon>
                            <ListItemTitle>
                                Store logging and test data
                            </ListItemTitle>
                            <ListItemContent>
                                Allocate separate volumes for application or test data, logs, and temporary files,
                                allowing independent scaling based on specific needs.
                            </ListItemContent>
                        </ListItem>
                    </FeaturesList>
                </FeaturesContent>
            </UseCasesSection>
            <CliSection>
                <StorageSectionTitle>
                    <span>
                        Create your first VM or storage volume today.
                    </span>
                </StorageSectionTitle>
                <GpuGraphic>
                    <img src={GpuDalle} />
                </GpuGraphic>
                <StorageSectionDescription>
                    <Link to="/signup">
                        <CustomHeroButton>
                            Sign up now
                        </CustomHeroButton>
                    </Link>
                </StorageSectionDescription>
            </CliSection>
        </MainContainer>
    )
}

export default LandingMain;