import styled from "styled-components";
import VALDILogo from "./assets/image-bws-logo-no-text.png";
import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import PortalPaymentMethodForm from "./PortalPaymentMethodForm";
import { ConfirmModalBackgroundCover, ConfirmModal, ModalTitle, BackButton } from "./SharedStyles";
import { faAngleLeft } from "@fortawesome/pro-light-svg-icons";
import {Link} from "react-router-dom";

const PaymentMethodSuperContainer = styled.div`
  width: 100%;
  max-width: 1300px;
`;

const DetailsPanel = styled.div`
  background-color: #121331;
  padding: 40px;
  border-radius: 10px;
  height: 643px;
  max-width: 372px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const DetailsListItem = styled.div`
  background-color: #1a1c47;
  padding: 15px;
  font-size: 0.85rem;
  border-radius: 10px;
  border: 1px solid #2a2a66;
  & span {
    font-weight: 600;
  }
`;

const CheckoutContainer = styled.div`
  position: relative;
  background-color: #1a1c47;
  min-height: 100vh;
  display: flex;
  padding: 0 40px 30px 50px;
  justify-content: start;
  align-items: start;
  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  @media screen and (max-width: 550px) {
    padding: 0;
  }
`;

const FormContainer = styled.div`
  position: relative;
  background-color: #121331;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 643px;
  @media screen and (max-width: 550px) {
    width: 100%;
    border-radius: 0;
    min-height: 100vh;
    padding: 50px;
    min-width: 0;
    top: -30px;
  }
`;

const Logo = styled.div`
  width: 70px;
  height: 70px;
  & img {
    width: 100%;
  }
  margin: -20px 0 10px 0;
`;

const Title = styled.div`
  font-size: 1.1rem;
  max-width: 292px;
  text-align: center;
  margin-bottom: 20px;
`;

const PanelsContainer = styled.div`
  display: flex;
`;

const ModifiedBackButton = styled(BackButton)`
  position: relative;
  left: 50px;
  margin-bottom: 25px;
  @media screen and (max-width: 550px) {
    display: none;
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PortalPaymentMethod = ({ token, setSelectedView }) => {
    const [ stripeOptions, setStripeOptions ] = useState({});
    const [ cardProcessing, setCardProcessing ] = useState(false);

    useEffect(() => {
        setSelectedView('payment-method');
        if (token) {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/create_secret`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data['client_secret']) {
                        setStripeOptions({
                            clientSecret: data['client_secret'],
                            appearance: {
                                theme: 'stripe',
                                variables: {
                                    colorText: '#ffffff',
                                    colorBackground: '#1a1c47'
                                }
                            }
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [token]);

    return (
        <>
            {stripeOptions.hasOwnProperty('clientSecret') && stripePromise ? (
                <PaymentMethodSuperContainer>
                    <Link to="/dashboard/billing">
                        <ModifiedBackButton>
                            <FontAwesomeIcon icon={faAngleLeft} />
                            <span>Back</span>
                        </ModifiedBackButton>
                    </Link>
                    <PanelsContainer>
                        <Elements stripe={stripePromise} options={stripeOptions}>
                            <CheckoutContainer>
                                <FormContainer>
                                    <Logo>
                                        <img src={VALDILogo} alt="" />
                                    </Logo>
                                    <Title>
                                        Set up a payment method to get started
                                    </Title>
                                    <PortalPaymentMethodForm setWaitingForResponse={setCardProcessing} />
                                </FormContainer>
                            </CheckoutContainer>
                        </Elements>
                        <DetailsPanel>
                            <DetailsList>
                                <DetailsListItem>
                                    We will <span>never</span> charge your card without your prior authorization.
                                </DetailsListItem>
                                <DetailsListItem>
                                    You must always maintain a sufficient balance in your account to use VALDI services.
                                </DetailsListItem>
                                <DetailsListItem>
                                    You can add or remove payment methods at any time.
                                </DetailsListItem>
                                <DetailsListItem>
                                    Your payment information is securely stored with Stripe and is never seen by VALDI.
                                </DetailsListItem>
                                <DetailsListItem>
                                    Refunds are processed on a case-by-case basis. Reach out to <a href="mailto:contact@valdi.ai">contact@valdi.ai</a> for requests.
                                </DetailsListItem>
                            </DetailsList>
                        </DetailsPanel>
                    </PanelsContainer>
                </PaymentMethodSuperContainer>
            ) : (
                <CheckoutContainer>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </CheckoutContainer>
            )}
            {cardProcessing && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </ModalTitle>
                    </ConfirmModal>
                </>
            )}
        </>
    );
}

export default PortalPaymentMethod;