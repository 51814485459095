import { useState, useEffect } from "react";
import styled from "styled-components";
import {
    ServerList,
    ServerInfo,
    ServerListItemPlaceholder,
    ListItemName,
    ListItemDescription,
    ConfirmModal,
    ConfirmModalBackgroundCover,
    ModalTitle,
    ModalText,
    ConnectButton,
    ServerTags,
    RegistrationDate
} from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { formatDateTime } from "./utilities";

const InventoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`;

const CloseButton = styled.div`
  border: ${props => props.reopen ? "1px solid #00cc00" : "1px solid #ff0000"};
  border-radius: 5px;
  color: ${props => props.reopen ? "#00cc00" : "#ff0000"};
  cursor: pointer;
  &:hover {
    background-color: ${props => props.reopen ? "rgba(0, 255, 0, 0.1)" : "rgba(255, 0, 0, 0.1)"};
  }
  padding: 5px 10px;
  font-size: 0.8rem;
`;

const HeaderArea = styled.div`
  display: flex;
  gap: 15px;
`;

const TicketTypeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${props => props.selected && "2px solid white"};
  background-color: #292c6e;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    background-color: rgba(41, 44, 110, 0.8);
  }
`;

const PortalAdminRequests = ({ token }) => {
    const [ requestsAreLoading, setRequestsAreLoading ] = useState(false);
    const [ requestList, setRequestList ] = useState([]);
    const [ errorLoadingRequests, setErrorLoadingRequests ] = useState(false);
    const [ changeRequestStatusModalIsShown, setChangeRequestStatusModalIsShown ] = useState(false);
    const [ requestStatusIsChanging, setRequestStatusIsChanging ] = useState(false);
    const [ errorChangingRequestStatus, setErrorChangingRequestStatus ] = useState(false);
    const [ requestStagedForStatusChange, setRequestStagedForStatusChange ] = useState(null);
    const [ ticketsOfStatus, setTicketsOfStatus ] = useState('open');

    useEffect(() => {
        const fetchNewRequests = async () => {
            await fetchRequests();
        }

        fetchNewRequests();
    }, [ticketsOfStatus]);

    async function fetchRequests() {
        setRequestsAreLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/request?request_status=${ticketsOfStatus}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                if ('requests' in data) {
                    setRequestList(data['requests']);
                } else {
                    setErrorLoadingRequests(true);
                }
            } else {
                setErrorLoadingRequests(true);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setRequestsAreLoading(false);
        }
    }

    async function changeRequestStatus(newStatus) {
        setChangeRequestStatusModalIsShown(false);
        setRequestStatusIsChanging(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/request/status`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    request_id: requestStagedForStatusChange,
                    new_status: newStatus
                })
            });

            if (response.ok) {
                await fetchRequests();
            } else {
                setErrorChangingRequestStatus(true);
            }
        } catch (error) {
            console.log(error);
            setErrorChangingRequestStatus(true);
        } finally {
            setRequestStagedForStatusChange(null);
            setRequestStatusIsChanging(false);
        }
    }

    const stageRequestForChange = requestId => {
        setRequestStagedForStatusChange(requestId);
        setChangeRequestStatusModalIsShown(true);
    }

    const unstageRequestForChange = () => {
        setRequestStagedForStatusChange(null);
        setChangeRequestStatusModalIsShown(false);
    }

    return (
        <InventoryContainer>
            <HeaderArea>
                <TicketTypeButton selected={ticketsOfStatus === 'open'}
                                  onClick={() => setTicketsOfStatus('open')}
                >
                    Open requests
                </TicketTypeButton>
                <TicketTypeButton selected={ticketsOfStatus === 'closed'}
                                  onClick={() => setTicketsOfStatus('closed')}
                >
                    Closed requests
                </TicketTypeButton>
            </HeaderArea>
            <ServerList>
                {requestsAreLoading ? (
                    <ServerListItemPlaceholder>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </ServerListItemPlaceholder>
                ) : (
                    errorLoadingRequests ? (
                        <ServerListItemPlaceholder>Error.</ServerListItemPlaceholder>
                    ) : (
                        requestList.length === 0 ? (
                            <ServerListItemPlaceholder>No open requests.</ServerListItemPlaceholder>
                        ) : (
                            requestList.map(request => {
                                return (
                                    <ServerListItemPlaceholder key={request['request_id']}>
                                        <ServerInfo>
                                            <ListItemName>
                                                {request['email']}
                                            </ListItemName>
                                            <ListItemDescription>
                                                for {request['gpu_count']}x {request['gpu_type']} from {request['provider']}
                                            </ListItemDescription>
                                        </ServerInfo>
                                        <ServerTags>
                                            <RegistrationDate>
                                                #{request['request_id']} submitted on {formatDateTime(request['requested_at'])}
                                            </RegistrationDate>
                                            {ticketsOfStatus === 'open' && (
                                                <CloseButton onClick={() => stageRequestForChange(request['request_id'])}>
                                                    Close
                                                </CloseButton>
                                            )}
                                            {ticketsOfStatus === 'closed' && (
                                                <CloseButton reopen={true}
                                                             onClick={() => stageRequestForChange(request['request_id'])}
                                                >
                                                    Re-open
                                                </CloseButton>
                                            )}
                                        </ServerTags>
                                    </ServerListItemPlaceholder>
                                )
                            })
                        )
                    )
                )}
            </ServerList>
            {changeRequestStatusModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={unstageRequestForChange} />
                    <ConfirmModal>
                        <ModalTitle>
                            Are you sure?
                        </ModalTitle>
                        {ticketsOfStatus === 'open' && (
                            <>
                                <ModalText>
                                    Are you sure you want to close Request #{requestStagedForStatusChange}?
                                </ModalText>
                                <ConnectButton onClick={() => changeRequestStatus('closed')}>
                                    Confirm
                                </ConnectButton>
                            </>
                        )}
                        {ticketsOfStatus === 'closed' && (
                            <>
                                <ModalText>
                                    Are you sure you want to re-open Request #{requestStagedForStatusChange}?
                                </ModalText>
                                <ConnectButton onClick={() => changeRequestStatus('open')}>
                                    Confirm
                                </ConnectButton>
                            </>
                        )}
                    </ConfirmModal>
                </>
            )}
            {errorChangingRequestStatus && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setErrorChangingRequestStatus(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an unexpected error changing the request's status. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setErrorChangingRequestStatus(false)}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {requestStatusIsChanging && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </ConfirmModal>
                </>
            )}
        </InventoryContainer>
    );
}

export default PortalAdminRequests;