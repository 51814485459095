import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { LoadingCover, Spinner, DetailContainer, DetailPanel } from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import PortalDirectTensorDockServer from "./PortalDirectTensorDockServer";
import PortalDirectTaigaCloudServer from "./PortalDirectTaigaCloudServer";
import PortalDirectGenesisCloudServer from "./PortalDirectGenesisCloudServer";
import PortalDirectCudoCloudServer from "./PortalDirectCudoCloudServer";

const PortalDirectServer = ({ setSelectedView, token }) => {
    const [ serverInfo, setServerInfo ] = useState({});
    const [ serverInfoIsLoaded, setServerInfoIsLoaded ] = useState(false);
    const { providerId, serverId } = useParams();
    const history = useHistory();

    useEffect(() => {
        setSelectedView('vm');
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/devices/available/${providerId}/${serverId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] > 299) {
                    history.push('/dashboard/direct?active=vms');
                } else {
                    return response.json();
                }
            })
            .then(data => {
                if (data) {
                    setServerInfo(data);
                    setServerInfoIsLoaded(true);
                }
            })
    }, []);

    function routeToProviderServer() {
        if (providerId === process.env.REACT_APP_TENSORDOCK_ID) {
            return <PortalDirectTensorDockServer token={token} serverInfo={serverInfo} providerId={providerId} />
        } else if (providerId === process.env.REACT_APP_TAIGACLOUD_ID) {
            return <PortalDirectTaigaCloudServer token={token} serverInfo={serverInfo} providerId={providerId} />
        } else if (providerId === process.env.REACT_APP_GENESIS_CLOUD_ID) {
            return <PortalDirectGenesisCloudServer token={token} serverInfo={serverInfo} providerId={providerId} />
        } else if (providerId === process.env.REACT_APP_CUDO_CLOUD_ID) {
            return <PortalDirectCudoCloudServer token={token} serverInfo={serverInfo} providerId={providerId} />
        }
    }

    return (
        <>
            {serverInfoIsLoaded ? (
                routeToProviderServer()
            ) : (
                <DetailContainer>
                    <DetailPanel>
                        <LoadingCover>
                            <Spinner>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            </Spinner>
                        </LoadingCover>
                    </DetailPanel>
                </DetailContainer>
            )}
        </>
    );
}

export default PortalDirectServer;