import  styled from "styled-components";
import {
    FeatureBadge,
    NewBadge,
    ServerList,
    ServerListItem,
    ServerListItemPlaceholder,
    Tooltip,
    ServerInfo,
    ListItemName,
    ListItemDescription,
    ServerTags,
    ServerPrice,
    ServerBandwidth
} from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import {
    faBolt,
    faBox,
    faGear,
    faICursor,
    faKey
} from "@fortawesome/pro-regular-svg-icons";
import { insertFlagEmoji } from "./utilities";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"

const ServerLocation = styled.div`
  padding: 5px 10px;
  width: 140px;
  background-color: #1a1c47;
  border-radius: 5px;
  color: #fff;
  font-size: 0.7rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 980px) {
    display: none;
  }
`;

const ServerLocationMobile = styled(ServerLocation)`
  display: none;
  width: 100px;
  @media screen and (max-width: 980px) {
    display: block;
  }
`;

const FormattedTooltip = styled(Tooltip)`
  width: 170px;
  & div:first-child {
    font-weight: 600;
    font-size: 0.7rem;
  }
`;

const LoadingListItem = styled.div`
  background-color: #292c6e;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
`;

const LiveInventory = (
    {
        token = null,
        triggerFetch = false,
        showLoading = false,
        onStateChange = (newState) => {},
        sortBy = null,
        sortOrder = null,
        gpuType = null,
        minStorage = null,
        maxStorage = null,
        minGpu = null,
        maxGpu = null,
        minCpu = null,
        maxCpu = null,
        minRam = null,
        maxRam = null
    }) => {
    const [ externalDevices, setExternalDevices ] = useState([]);
    const [ vmListIsLoaded, setVmListIsLoaded ] = useState(false);

    useEffect(() => {
        setVmListIsLoaded(false);
        onStateChange(false);
    }, [showLoading]);

    function fetchAvailableDevices(signal) {
        setVmListIsLoaded(false);
        onStateChange(false);
        const headers = {};
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        let fetch_params = '?';
        if (sortBy !== null) {
            fetch_params += `sort_by=${sortBy}&`;
        }
        if (sortOrder !== null) {
            fetch_params += `sort_order=${sortOrder}&`;
        }
        if (gpuType !== null && gpuType !== '') {
            fetch_params += `gpu_type=${gpuType}&`;
        }
        fetch_params = fetch_params.slice(0, -1);

        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v2/devices/available${fetch_params}`, {
            method: 'GET',
            headers: headers,
            signal: signal
        })
            .then(response => response.json())
            .then(data => {
                const allDevices = data['devices'];
                setExternalDevices(allDevices);
                setVmListIsLoaded(true);
                onStateChange(true);
            })
            .catch(error => {
                if (error.name !== 'AbortError') {
                    console.log(error);
                }
            });
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchAvailableDevices(controller.signal);

        return () => controller.abort();
    }, [triggerFetch]);

    function gpuPriority(gpu_string) {
        if (gpu_string.includes('GH200')) {
            return 0;
        } else if (gpu_string.includes('H100')) {
            return 1;
        } else if (gpu_string.includes('A100')) {
            return 2;
        } else if (gpu_string.includes('L40')) {
            return 3;
        } else if (gpu_string.includes('A6000')) {
            return 4;
        } else if (gpu_string.includes('4090')) {
            return 5;
        } else if (gpu_string.includes('3090')) {
            return 6;
        } else if (gpu_string.includes('A5000')) {
            return 7;
        } else if (gpu_string.includes('A4000')) {
            return 8;
        } else if (gpu_string.includes('V100')) {
            return 9;
        } else if (gpu_string.includes('3080')) {
            return 10;
        } else if (gpu_string.includes('3060')) {
            return 11;
        } else {
            return 12;
        }
    }

    function orderVms(a, b) {
        const a_p = gpuPriority(a['gpu_type']);
        const b_p = gpuPriority(b['gpu_type']);

        if (a_p < b_p) {
            return -1;
        } else if (a_p > b_p) {
            return 1;
        } else {
            return 0;
        }
    }

    function returnServerListEntry(
        providerId,
        serverId,
        name,
        details,
        bandwidth,
        loc,
        locMobile,
        cost,
        inactive = false,
        {
            sshAuth = false,
            passwordAuth = false,
            customizable = false,
            allInclusive = false,
            highBandwidth = false,
            newListing = false
        } = {}
    ) {
        return (
            <ServerListItem
                key={serverId}
                inactive={inactive.toString()}
                to={token ? `/dashboard/direct/vm/${providerId}/${serverId}` : '/signup'}
            >
                <ServerInfo>
                    <ListItemName>
                        {name}
                    </ListItemName>
                    <ListItemDescription>
                        {details}
                    </ListItemDescription>
                </ServerInfo>
                <ServerTags>
                    {newListing && (
                        <NewBadge>
                            NEW
                        </NewBadge>
                    )}
                    {highBandwidth && (
                        <FeatureBadge>
                            <FontAwesomeIcon icon={faBolt} />
                            <FormattedTooltip>
                                <div>
                                    Ultra-high bandwidth
                                </div>
                                <div>
                                    This server features ultra-fast upload and download speeds of up to 10 Gbps.
                                </div>
                            </FormattedTooltip>
                        </FeatureBadge>
                    )}
                    {passwordAuth && (
                        <FeatureBadge>
                            <FontAwesomeIcon icon={faICursor} />
                            <FormattedTooltip>
                                <div>
                                    Password authentication
                                </div>
                                <div>
                                    This server currently only supports password-based authentication.
                                </div>
                            </FormattedTooltip>
                        </FeatureBadge>
                    )}
                    {sshAuth && (
                        <FeatureBadge>
                            <FontAwesomeIcon icon={faKey} />
                            <FormattedTooltip>
                                <div>
                                    Key-based authentication
                                </div>
                                <div>
                                    This server requires SSH key-based authentication for added security.
                                </div>
                            </FormattedTooltip>
                        </FeatureBadge>
                    )}
                    {allInclusive && (
                        <FeatureBadge>
                            <FontAwesomeIcon icon={faBox} />
                            <FormattedTooltip>
                                <div>
                                    All-inclusive pricing
                                </div>
                                <div>
                                    Specifications for this server are fixed and the listed hourly rate includes all
                                    GPUs, CPUs, RAM, and storage.
                                </div>
                            </FormattedTooltip>
                        </FeatureBadge>
                    )}
                    {customizable && (
                        <FeatureBadge>
                            <FontAwesomeIcon icon={faGear} />
                            <FormattedTooltip>
                                <div>
                                    Fully customizable
                                </div>
                                <div>
                                    Specifications for this server can be adjusted to cater to your specific needs and
                                    use case.
                                </div>
                            </FormattedTooltip>
                        </FeatureBadge>
                    )}
                    <ServerBandwidth>
                        {bandwidth ? (
                            `${bandwidth}`
                        ) : (
                            `500 Mbps`
                        )}
                    </ServerBandwidth>
                    <ServerLocation>
                        {loc} {insertFlagEmoji(loc)}
                    </ServerLocation>
                    <ServerLocationMobile>
                        {locMobile} {insertFlagEmoji(loc)}
                    </ServerLocationMobile>
                    <ServerPrice>
                        {cost}
                    </ServerPrice>
                </ServerTags>
            </ServerListItem>
        );
    }

    return (
        <ServerList>
            {vmListIsLoaded ? (
                externalDevices.length === 0 ? (
                    <ServerListItemPlaceholder>No servers currently available.</ServerListItemPlaceholder>
                ) : (
                    externalDevices.map(device => {
                        if (device['provider_id'] === process.env.REACT_APP_TENSORDOCK_ID) {
                            const cpuName = device['cpu_type'];
                            const primaryName = `${device['gpu_count']}x ${device['gpu_type']}`;
                            const deviceLocation = `${device['location'].substring(device['location'].lastIndexOf(',') + 2)}`;
                            const deviceLocationMobile = `${device['location'].substring(device['location'].lastIndexOf(',') + 2).replace('United States', 'USA').replace('United Kingdom', 'UK')}`
                            const deviceDetails = `${device['cpu_core_count']}x ${cpuName} | ${device['main_ram']} GB RAM | ${device['storage']} GB NVMe SSD`;
                            const deviceBw = device['download_bw'] ? `${parseInt(device['download_bw'])/1000} Gbps` : '500 Mbps';
                            const gpuCost = `$${parseFloat(device['gpu_cost']).toFixed(2)}/GPU-hour`;
                            if (device['hostnode'] !== '03065d45-6da5-4232-a8db-ee0e5bc76110' &&
                                device['hostnode'] !== '05503c22-3dcb-4c61-9f7d-8d08807d8ce4' &&
                                device['hostnode'] !== '6d76afc5-2230-4f97-9e08-28465bf333f5' &&
                                device['gpu_count'] > 0 &&
                                device['main_ram'] >= 4 &&
                                device['cpu_core_count'] >= 2 &&
                                device['storage'] >= 20 &&
                                !device['location'].includes('Russia')
                            ) {
                                return (
                                    returnServerListEntry(
                                        device['provider_id'],
                                        device['hostnode'],
                                        primaryName,
                                        deviceDetails,
                                        deviceBw,
                                        deviceLocation,
                                        deviceLocationMobile,
                                        gpuCost,
                                        false,
                                        {
                                            customizable: true,
                                            passwordAuth: true
                                        }
                                    )
                                );
                            }
                        } else if (device['provider_id'] === process.env.REACT_APP_TAIGACLOUD_ID) {
                            const cpuName = 'vCPUs';
                            const primaryName = `${device['gpu_count']}x ${device['gpu_type']}`;
                            const deviceLocation = 'Norway';
                            const deviceLocationMobile = 'Norway';
                            const deviceDetails = `${device['cpu_core_count']}x ${cpuName} | ${device['main_ram']} GB RAM | ${device['storage']} GB NVMe SSD`;
                            const deviceBw = '10 Gbps';
                            const gpuCost = `$${parseFloat(device['running_cost']).toFixed(2)}/hour`;
                            return (
                                returnServerListEntry(
                                    device['provider_id'],
                                    device['flavor_id'],
                                    primaryName,
                                    deviceDetails,
                                    deviceBw,
                                    deviceLocation,
                                    deviceLocationMobile,
                                    gpuCost,
                                    false,
                                    {
                                        sshAuth: true,
                                        allInclusive: true,
                                        highBandwidth: true,
                                        newListing: false
                                    }
                                )
                            );
                        } else if (device['provider_id'] === process.env.REACT_APP_GENESIS_CLOUD_ID) {
                            const cpuName = device['cpu_type'];
                            const primaryName = `${device['gpu_count']}x ${device['gpu_type']}`;
                            const deviceLocation = 'Iceland';
                            const deviceLocationMobile = 'Iceland';
                            const deviceDetails = `${device['cpu_core_count']}x ${cpuName} | ${device['main_ram']} GB RAM | ${device['storage']} GB NVMe SSD (scalable)`;
                            const deviceBw = '1 Gbps';
                            const gpuCost = `$${parseFloat(device['running_cost']).toFixed(2)}/hour`;
                            return (
                                returnServerListEntry(
                                    device['provider_id'],
                                    device['shape_id'],
                                    primaryName,
                                    deviceDetails,
                                    deviceBw,
                                    deviceLocation,
                                    deviceLocationMobile,
                                    gpuCost,
                                    false,
                                    {
                                        sshAuth: true,
                                        allInclusive: true,
                                        newListing: false
                                    }
                                )
                            )
                        } else if (device['provider_id'] === process.env.REACT_APP_CUDO_CLOUD_ID) {
                            const cpuName = 'vCPUs';
                            const primaryName = `${device['gpu_count']}x NVIDIA ${device['gpu_type']}`;
                            const deviceLocation = device['location'];
                            const deviceLocationMobile = device['location'];
                            const computedMaxRam = Math.min(
                                parseInt(device['main_ram']),
                                parseInt(parseFloat(parseInt(device['gpu_count'])*parseInt(device['max_vcpu_per_gpu']))/parseFloat(device['min_vcpu_per_memory_gib']))
                            )
                            const deviceDetails = `${device['cpu_core_count']}x ${cpuName} | ${computedMaxRam.toString()} GiB RAM | ${device['storage']} GiB NVMe SSD`;
                            const deviceBw = '10 Gbps'; // TODO Ask Cudo what the DC bandwidth is
                            const gpuCost = `$${parseFloat(device['gpu_cost']).toFixed(2)}/GPU-hour`;
                            return (
                                returnServerListEntry(
                                    device['provider_id'],
                                    device['machine_type'] + '_' + device['data_center_id'],
                                    primaryName,
                                    deviceDetails,
                                    deviceBw,
                                    deviceLocation,
                                    deviceLocationMobile,
                                    gpuCost,
                                    false, {
                                        sshAuth: true,
                                        customizable: true,
                                        newListing: true
                                    }
                                )
                            )
                        } else {
                            return null;
                        }
                    })
                )
            ) : (
                <SkeletonTheme baseColor="#1a1c47" highlightColor="#121331">
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                    <LoadingListItem>
                        <Skeleton count={2} />
                    </LoadingListItem>
                </SkeletonTheme>
            )
            }
        </ServerList>
    );
}

export default LiveInventory;