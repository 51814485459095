import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import ConfettiLeft from "./assets/announcement-banner-confetti-valdi-left.svg";
import AnnouncementArrow from "./assets/announcement-arrow-right.svg";
import ConfettiRight from "./assets/announcement-banner-confetti-valdi-right.svg";

const AnnouncementBannerContainer = styled.div`
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BannerGraphicLeft = styled.div`
  align-self: flex-end;
  position: relative;
  top: 7px;
`;

const BannerGraphicRight = styled.div`
  align-self: flex-start;
`;

const BannerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 20px;
  gap: 8px;
  color: #001c4a;
`;

const BannerText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
`;

const BannerEmphText = styled.div`
  font-weight: 800;
  font-size: 0.85rem;
`;

const BannerLightText = styled.div`
  font-weight: 300;
  font-size: 0.85rem;
  margin-left: 4px;
  @media screen and (max-width: 697px) {
    display: none;
  }
`

const BannerButton = styled.div`
  border-radius: 3px;
  border: 1px solid #001c4a;
  font-weight: 800;
  display: flex;
  font-size: 0.85rem;
  padding: 3px 17px;
  &:hover {
    background-color: rgba(0, 28, 74, 0.1);
  }
`;

const ButtonText = styled.div`
  margin-right: 5px;
`;

const XMark = styled.div`
  font-size: 0.6rem;
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AnnouncementBanner = ({ updateBannerHeight, setBannerIsShown }) => {
    const bannerRef = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            const { height } = entries[0].contentRect;
            updateBannerHeight(height);
        });

        if (bannerRef.current) {
            resizeObserver.observe(bannerRef.current);
        }

        return () => {
            if (bannerRef.current) {
                resizeObserver.unobserve(bannerRef.current);
            }
        }
    }, []);

    return (
        <AnnouncementBannerContainer ref={bannerRef}>
            <BannerGraphicLeft>
                <img src={ConfettiLeft} alt="" />
            </BannerGraphicLeft>
            <BannerContent>
                <BannerText>
                    <BannerEmphText>
                        Storj and Valdi join forces
                    </BannerEmphText>
                    <BannerLightText>
                        — Adding on-demand GPUs to the distributed cloud.
                    </BannerLightText>
                </BannerText>
                <ButtonGroup>
                    <a href="https://www.prnewswire.com/news-releases/storj-acquires-leading-ai-compute-provider-valdi-302192158.html" target="_blank" rel="noreferrer">
                        <BannerButton>
                            <ButtonText>
                                Learn more
                            </ButtonText>
                            <img src={AnnouncementArrow} alt="" />
                        </BannerButton>
                    </a>
                    <XMark onClick={() => setBannerIsShown(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </XMark>
                </ButtonGroup>
            </BannerContent>
            <BannerGraphicRight>
                <img src={ConfettiRight} alt="" />
            </BannerGraphicRight>
        </AnnouncementBannerContainer>
    );
}

export default AnnouncementBanner;