import { useState, useEffect } from "react";
import styled from "styled-components"
import {
    TitleText,
    SettingsSectionGroup,
    SettingsSection,
    EntryGroup,
    EntryValue
} from "./PortalSettings";
import {
    StatsContainer,
    StatBoxContainer,
    StatBox,
    StatTitle,
    StatValue
} from "./SharedStyles";
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";

const RevenuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
`;

const LargerTitleText = styled(TitleText)`
  font-size: 1.4rem;
  font-weight: 500;
`;

const SmallerTitleText = styled(TitleText)`
  font-size: 0.7rem;
  opacity: 0.9;
  font-weight: 300;
`;

const BarChartTooltip = styled.div`
  outline: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #000;
`;

const TooltipLabel = styled.div`
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.5);
`;

const TooltipValue = styled.div``;

const PortalAdminRevenue = ({ token }) => {
    const [ revenueIsLoading, setRevenueIsLoading ] = useState(true);
    const [ customerRevenuesAreLoading, setCustomerRevenuesAreLoading ] = useState(true);
    const [ hourlyRevenue, setHourlyRevenue ] = useState(0);
    const [ weeklyRevenue, setWeeklyRevenue ] = useState(0);
    const [ customerRevenues, setCustomerRevenues ] = useState([]);

    useEffect(() => {
        if (token) {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/revenue`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setHourlyRevenue(parseFloat(data['amount']));
                        setWeeklyRevenue(7*24*parseFloat(data['amount']));
                        setRevenueIsLoading(false);
                    }
                })
                .catch(error => console.log(error));

            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/revenue/customers?hours_ago=168&min_total=100`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        const formattedData = data['revenues'].map(revenue => ({
                            ...revenue,
                            amount: parseFloat(revenue.amount)
                        }));
                        setCustomerRevenues(formattedData);
                        setCustomerRevenuesAreLoading(false);
                    }
                })
        }
    }, [token]);

    useEffect(() => {
        console.log(customerRevenues);
    }, [customerRevenues]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <BarChartTooltip>
                    <TooltipLabel>
                        {label}
                    </TooltipLabel>
                    <TooltipValue>
                        ${parseFloat(payload[0].value).toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    </TooltipValue>
                </BarChartTooltip>
            );
        } else {
            return null;
        }
    }

    return(
        <RevenuesContainer>
            <StatsContainer>
                <SettingsSectionGroup>
                    <LargerTitleText>
                        Global Revenue Statistics
                    </LargerTitleText>
                    <StatBoxContainer>
                        <StatBox>
                            <StatTitle>
                                Hourly Revenue
                            </StatTitle>
                            {revenueIsLoading ? (
                                <StatValue>
                                    -
                                </StatValue>
                            ) : (
                                <StatValue>
                                    ${hourlyRevenue.toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </StatValue>
                            )}
                        </StatBox>
                        <StatBox>
                            <StatTitle>
                                Weekly Revenue
                            </StatTitle>
                            {revenueIsLoading ? (
                                <StatValue>
                                    -
                                </StatValue>
                            ) : (
                                <StatValue>
                                    ${weeklyRevenue.toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </StatValue>
                            )}
                        </StatBox>
                    </StatBoxContainer>
                </SettingsSectionGroup>
            </StatsContainer>
            <SettingsSectionGroup>
                <LargerTitleText>
                    Revenue by customer
                </LargerTitleText>
                <SmallerTitleText>
                    Past 7 days, min $100 spend
                </SmallerTitleText>
                <SettingsSection>
                    <EntryGroup>
                        <EntryValue>
                            {customerRevenuesAreLoading ? (
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            ) : (
                                <ResponsiveContainer width="100%" height={800}>
                                    <BarChart
                                        data={customerRevenues}
                                        layout="vertical"
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 30 ,
                                            bottom: 5
                                        }}
                                        barCategoryGap="35%"
                                    >
                                        <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.2)" />
                                        <XAxis
                                            type="number"
                                            stroke="#fff"
                                            tick={{ fontSize: "0.8rem" }}
                                            tickFormatter={value => `$${value.toLocaleString()}`}
                                        />
                                        <YAxis
                                            type="category"
                                            dataKey="email"
                                            stroke="#fff"
                                            tick={{ fontSize: 8, fill: "#fff" }}
                                            tickFormatter={value => value.split('@')[0]}
                                        />
                                        <Tooltip
                                            cursor={{ fill: 'none' }}
                                            content={<CustomTooltip />}
                                        />
                                        <Bar
                                            dataKey="amount"
                                            fill="#05daff"
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                        </EntryValue>
                    </EntryGroup>
                </SettingsSection>
            </SettingsSectionGroup>
        </RevenuesContainer>
    );
}

export default PortalAdminRevenue;