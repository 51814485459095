import { useState, useEffect } from "react";
import styled from "styled-components";
import { InputLabel } from "./Signup";

const CalculatorBody = styled.div`
  border-radius: 10px;
  background-color: #292c6e;
  max-width: 500px;
  min-width: 300px;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const CalculatorTitle = styled.div`
  font-size: 2rem;
  font-weight: 500;
  @media screen and (max-width: 492px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 424px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 392px) {
    font-size: 1.3rem;
  }
`;

const InputAndDropDownGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const DenserTextInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  border: none;
  background-color: #1a1c47;
  color: #fff;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  @media screen and (max-width: 493px) {
    font-size: 1.2rem;
    padding: 8px;
  }
  @media screen and (max-width: 439px) {
    font-size: 0.9rem;
  }
`;

const DenserInputLabel = styled(InputLabel)`
  margin: 0;
`;

const DenserDropDownList = styled.select`
  font-family: inherit;
  width: 25%;
  font-size: 1.6rem;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1a1c47;
  color: #fff;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 493px) {
    font-size: 1.2rem;
    padding: 8px;
  }
  @media screen and (max-width: 439px) {
    font-size: 0.9rem;
  }
`;

const CostContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const StoragePre = styled.div`
  font-size: 1.1rem;
  @media screen and (max-width: 493px) {
    font-size: 0.8rem;
  }
`;

const StorageCost = styled.div`
  font-size: 2rem;
  font-weight: 500;
`;

const StorageCalculator = () => {
    const [ storageSize, setStorageSize ] = useState(100);
    const [ storageUnits, setStorageUnits ] = useState('TB');
    const [ egressAmount, setEgressAmount ] = useState(5);
    const [ egressUnits, setEgressUnits ] = useState('TB');
    const [ storageOnlyCost, setStorageOnlyCost ] = useState(5);
    const [ egressOnlyCost, setEgressOnlyCost ] = useState(0.05);
    const [ totalStorageCost, setTotalStorageCost ] = useState(5.05);

    useEffect(() => {
        if (storageUnits === 'GB') {
            setStorageOnlyCost(storageSize * 0.01);
        } else if (storageUnits === 'TB') {
            setStorageOnlyCost(storageSize * 10);
        } else if (storageUnits === 'PB') {
            setStorageOnlyCost(storageSize * 10000);
        }
    }, [storageSize, storageUnits]);

    useEffect(() => {
        if (egressUnits === 'GB') {
            setEgressOnlyCost(egressAmount * 0.01);
        } else if (egressUnits === 'TB') {
            setEgressOnlyCost(egressAmount * 10);
        } else if (egressUnits === 'PB') {
            setEgressOnlyCost(egressAmount * 10000);
        }
    }, [egressAmount, egressUnits]);

    useEffect(() => {
        setTotalStorageCost(storageOnlyCost + egressOnlyCost);
    }, [storageOnlyCost, egressOnlyCost]);

    const handleSetStorageSize = e => {
        const newValue = e.target.value;
        if (!newValue || newValue >= 0) {
            setStorageSize(newValue);
        }
    }

    const handleSetEgressAmount = e => {
        const newValue = e.target.value;
        if (!newValue || newValue >= 0) {
            setEgressAmount(newValue);
        }
    }

    return (
        <CalculatorBody>
            <CalculatorTitle>
                Calculate your savings.
            </CalculatorTitle>
            <DenserInputLabel htmlFor="storage-input">
                Storage usage
            </DenserInputLabel>
            <InputAndDropDownGroup>
                <DenserTextInput id="storage-input"
                                 value={storageSize}
                                 type="number"
                                 onChange={handleSetStorageSize}
                                 min={0}
                                 onKeyDown={e => e.key === '-' && e.preventDefault()}
                />
                <DenserDropDownList value={storageUnits}
                                    required={true}
                                    onChange={e => setStorageUnits(e.target.value)}
                >
                    <option value='GB'>GB</option>
                    <option value='TB'>TB</option>
                    <option value='PB'>PB</option>
                </DenserDropDownList>
            </InputAndDropDownGroup>
            <DenserInputLabel htmlFor="egress-input">
                Data egress
            </DenserInputLabel>
            <InputAndDropDownGroup>
                <DenserTextInput id="egress-input"
                                 value={egressAmount}
                                 type="number"
                                 onChange={handleSetEgressAmount}
                                 min={0}
                                 onKeyDown={e => e.key === '-' && e.preventDefault()}
                />
                <DenserDropDownList value={egressUnits}
                                    required={true}
                                    onChange={e => setEgressUnits(e.target.value)}
                >
                    <option value='GB'>GB</option>
                    <option value='TB'>TB</option>
                    <option value='PB'>PB</option>
                </DenserDropDownList>
            </InputAndDropDownGroup>
            <CostContainer>
                <StoragePre>
                    Approx. monthly cost:
                </StoragePre>
                <StorageCost>
                    ${totalStorageCost.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </StorageCost>
            </CostContainer>
        </CalculatorBody>
    );
}

export default StorageCalculator;