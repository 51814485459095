import styled from "styled-components";
import { useLocation} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faServer,
    faFileSignature,
    faBrowser,
    faCircleNotch,
    faArrowsRotate,
    faWandMagicSparkles,
    faArrowUpShortWide,
    faArrowDownWideShort,
    faSliders
} from "@fortawesome/pro-light-svg-icons";
import {
    DirectContainer,
    ContentContainer,
    ContentTitleContainer,
    ContentTitle,
    ContentDescription,
    ServerList,
    ServerListItem,
    ServerListItemPlaceholder,
    RefreshIcon,
    ServerInfo,
    ListItemName,
    ListItemDescription
} from "./SharedStyles";
import { TextInput } from "./Signup";
import LiveInventory from "./LiveInventory";
import ReservedInventory from "./ReservedInventory";

export const SelectorContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const SelectorIcon = styled.div`
  margin-right: 5px;
  font-size: 1.2rem;
`;

export const SelectorText = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
`;

export const SelectorButton = styled.div`
  display: flex;
  padding: 15px;
  border-radius: 10px;
  background-color: #292c6e;
  width: 220px;
  justify-content: center;
  align-items: center;
  box-shadow: ${props => props.outline ? "0 0 0 2px #fff" : "none"};
  &:hover {
    cursor: pointer;
    background-color: rgba(41, 44, 110, 0.8);
  }
`;

const SessionTag = styled.div`
  font-size: 0.7rem;
  background-color: #a020f0;
  padding: 2px;
  width: 100px;
  border-radius: 5px;
  text-align: center;
`;

export const SessionStatusTag = styled(SessionTag)`
  background-color: ${props => props.bgColor ? props.bgColor : "#39ff14"};
  color: ${props => props.color ? props.color : "#ffffff"};
`;

const SessionLabels = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const UnnamedItemName = styled(ListItemName)`
  opacity: 0.8;
  font-style: italic;
`;

const NonLinkServerListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292c6e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
  }
`;

const DiscountRateCallout = styled.div`
  background: linear-gradient(to right, #2bdffd 60%, #a020f0 100%);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  border-radius: 5px 5px 0 0;
  font-size: 0.7rem;
  margin-left: auto;
  margin-right: 10px;
  position: relative;
  top: 15px;
  white-space: nowrap;
  max-width: 175px;
`;

const DiscountRateGradientText = styled.span`
  color: #000000;
`;

const CDText = styled.div`
  padding-right: 25px;
`;

const SortingAndFilteringControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

const InputLabel = styled.label`
  font-size: 0.8rem;
  font-weight: 600;
  margin-right: 5px;
`;

const DropDownList = styled.select`
  font-family: inherit;
  font-size: 0.8rem;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #292c6e;
  color: #fff;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  &:hover {
    background-color: rgba(41, 44, 110, 0.9);
  }
  cursor: pointer;
  margin-right: 10px;
`;

const SortOrderButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #292c6e;
  padding: 13px;
  border-radius: 5px;
  font-size: 0.8rem;
  margin-right: 10px;
  &:hover {
    background-color: rgba(41, 44, 110, 0.9);
  }
  opacity: ${props => props.inactive ? 0.5 : 1.0};
  cursor: ${props => props.inactive ? 'not-allowed' : 'pointer'};
  pointer-events: ${props => props.inactive ? 'none': 'auto'};
`;

const TitleDescriptionContainer = styled.div`
`;

const TitleAndControlsHorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const LightTextInput = styled(TextInput)`
  background-color: #292c6e;
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 0.9rem;
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const PortalDirectHome = ({ setSelectedView, token, discountRate, updateUserInfo }) => {
    let query = new URLSearchParams(useLocation().search);
    const getInitialState = () => {
        const activeValue = query.get('active');
        if (activeValue === 'vms') return 'vms';
        if (activeValue === 'reserved') return 'reserved';
        if (activeValue === 'sessions') return 'sessions';
        return 'vms';
    };

    const [ activeView, setActiveView ] = useState(getInitialState());
    const [ userVms, setUserVms ] = useState([]);
    const [ userRip, setUserRip ] = useState([]);
    const [ extConnectionsAreLoaded, setExtConnectionsAreLoaded ] = useState(false);
    const [ fetchInventory, setFetchInventory ] = useState(false);
    const [ fetchReservedList, setFetchReservedList ] = useState(false);
    const [ vmListIsLoaded, setVmListIsLoaded ] = useState(false);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ showLoading, setShowLoading ] = useState(false);

    const [ sortBy, setSortBy ] = useState('default');
    const [ sortOrder, setSortOrder ] = useState('desc');

    const hasRendered = useRef(false);

    useEffect(() => {
        if (hasRendered.current) {
            setShowLoading(prev => !prev);
            const timeout = setTimeout(() => {
                setFetchInventory(prev => !prev);
            }, 1500);
            return () => clearTimeout(timeout);
        } else {
            hasRendered.current = true;
        }
    }, [searchTerm]);

    useEffect(() => {
        if (activeView === 'sessions' && token) {
            setExtConnectionsAreLoaded(false);
            fetchActiveSshSessions();
        }
    }, [activeView, token]);

    useEffect(() => {
        updateUserInfo();
    }, []);

    useEffect(() => {
        setSelectedView('direct');
    }, [setSelectedView]);

    useEffect(() => {
        setActiveView(getInitialState());
    }, [query.toString()]);

    const handleSshListRefresh = () => {
        setExtConnectionsAreLoaded(false);
        if (token) {
            fetchActiveSshSessions();
        }
    }

    const handleChildStateChange = (newState) => {
        setVmListIsLoaded(newState);
    }

    const handleSortBySelect = e => {
        setSortBy(e.target.value);
        if (e.target.value === 'default') {
            setSortOrder('desc');
        }
        setFetchInventory(prev => !prev);
    }

    const handleSortOrderChange = () => {
        if (sortOrder === 'desc') {
            setSortOrder('asc');
        } else if (sortOrder === 'asc') {
            setSortOrder('desc');
        }
        setFetchInventory(prev => !prev);
    }

    const handleSearchTermChange = e => {
        if (e.target.value.length < 100) {
            setSearchTerm(e.target.value);
        }
    }

    async function fetchActiveSshSessions() {
        try {
            const vm_response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const rip_response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/rip`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (vm_response.ok && rip_response.ok) {
                const vm_resp_json = await vm_response.json();
                const rip_resp_json = await rip_response.json();

                setUserVms(vm_resp_json['virtual_machines']);
                setUserRip(rip_resp_json['resources_in_progress']);

                setExtConnectionsAreLoaded(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <DirectContainer>
            <SelectorContainer>
                <SelectorButton
                    outline={activeView === 'vms'}
                    onClick={() => setActiveView('vms')}
                >
                    <SelectorIcon>
                        <FontAwesomeIcon icon={faServer} />
                    </SelectorIcon>
                    <SelectorText>
                        On-demand GPUs
                    </SelectorText>
                </SelectorButton>
                <SelectorButton
                    outline={activeView === 'reserved'}
                    onClick={() => setActiveView('reserved')}
                >
                    <SelectorIcon>
                        <FontAwesomeIcon icon={faFileSignature} />
                    </SelectorIcon>
                    <SelectorText>
                        Reserved GPUs
                    </SelectorText>
                </SelectorButton>
                <SelectorButton
                    outline={activeView === 'sessions'}
                    onClick={() => setActiveView('sessions')}
                >
                    <SelectorIcon>
                        <FontAwesomeIcon icon={faBrowser} />
                    </SelectorIcon>
                    <SelectorText>
                        Your Instances
                    </SelectorText>
                </SelectorButton>
            </SelectorContainer>
            {activeView === 'vms' && (
                <ContentContainer>
                    <TitleAndControlsHorizontalContainer>
                        <TitleDescriptionContainer>
                            <ContentTitleContainer>
                                <ContentTitle>Available Servers</ContentTitle>
                                <RefreshIcon onClick={() => setFetchInventory(prev => !prev)}>
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                </RefreshIcon>
                            </ContentTitleContainer>
                            <ContentDescription center={true}>
                                <CDText>
                                    Configure a Virtual Machine from the selection below.
                                </CDText>
                            </ContentDescription>
                        </TitleDescriptionContainer>
                        <SortingAndFilteringControls>
                            <InputLabel htmlFor="sort-by-select">Sort by: </InputLabel>
                            <DropDownList id="sort-by-select"
                                          value={sortBy}
                                          onChange={handleSortBySelect}
                            >
                                <option value="default">Most Popular</option>
                                <option value="price">Hourly Price</option>
                                <option value="storage">Available Storage</option>
                                <option value="cpus">Available CPUs</option>
                                <option value="ram">Available RAM</option>
                                <option value="location">Location</option>
                            </DropDownList>
                            <SortOrderButton onClick={handleSortOrderChange}
                                             inactive={sortBy === 'default'}
                            >
                                {sortOrder === 'desc' && (
                                    <FontAwesomeIcon icon={faArrowDownWideShort} />
                                )}
                                {sortOrder === 'asc' && (
                                    <FontAwesomeIcon icon={faArrowUpShortWide} />
                                )}
                            </SortOrderButton>
                            {/*<SortOrderButton>*/}
                            {/*    <FontAwesomeIcon icon={faSliders} />*/}
                            {/*</SortOrderButton>*/}
                        </SortingAndFilteringControls>
                    </TitleAndControlsHorizontalContainer>
                    <LightTextInput
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        id="gpu-search-bar"
                        placeholder="Search for a VALDI GPU"
                    />
                    {discountRate > 0 && vmListIsLoaded && (
                        <DiscountRateCallout>
                            <DiscountRateGradientText>
                                {discountRate * 100}% discount applied!
                                <FontAwesomeIcon icon={faWandMagicSparkles} style={{marginLeft: "5px"}} />
                            </DiscountRateGradientText>
                        </DiscountRateCallout>
                    )}
                    <LiveInventory
                        token={token}
                        triggerFetch={fetchInventory}
                        showLoading={showLoading}
                        onStateChange={handleChildStateChange}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        gpuType={searchTerm}
                    />
                </ContentContainer>
            )}
            {activeView === 'reserved' && (
                <ContentContainer>
                    <ContentTitleContainer>
                        <ContentTitle>Available Reservations</ContentTitle>
                        <RefreshIcon onClick={() => setFetchReservedList(prev => !prev)}>
                            <FontAwesomeIcon icon={faArrowsRotate} />
                        </RefreshIcon>
                    </ContentTitleContainer>
                    <ContentDescription>
                        Request a reservation for any number of the available inventory listed below. All configurations
                        are available for monthly subscriptions, while some can also be rented on demand.
                    </ContentDescription>
                    <ReservedInventory token={token} triggerFetch={fetchReservedList} />
                </ContentContainer>
            )}
            {activeView === 'sessions' && (
                <ContentContainer>
                    <ContentTitleContainer>
                        <ContentTitle>Your Virtual Machines</ContentTitle>
                        <RefreshIcon onClick={handleSshListRefresh}>
                            <FontAwesomeIcon icon={faArrowsRotate} />
                        </RefreshIcon>
                    </ContentTitleContainer>
                    <ContentDescription>
                        Running or stopped VMs accrue costs until they are terminated. All locally stored data is
                        removed when the session is terminated. Be sure to back up your important data.
                    </ContentDescription>
                    <ServerList>
                        {!extConnectionsAreLoaded && (
                            <ServerListItemPlaceholder>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            </ServerListItemPlaceholder>
                        )}
                        {extConnectionsAreLoaded && userVms.length === 0 && userRip.length === 0 && (
                            <ServerListItemPlaceholder>You have no virtual machines.</ServerListItemPlaceholder>
                        )
                        }
                        {extConnectionsAreLoaded && userRip.length !== 0 && (
                            userRip.map(rip => {
                                return (
                                    <NonLinkServerListItem key={rip['process_id']}>
                                        <ServerInfo>
                                            {rip['user_provided_name'] ? (
                                                <ListItemName>
                                                    {rip['user_provided_name']}
                                                </ListItemName>
                                            ) : (
                                                <UnnamedItemName>
                                                    Unnamed
                                                </UnnamedItemName>
                                            )}
                                            <ListItemDescription>
                                                {rip['gpu_count']}x {rip['gpu_type']} | {rip['vcpus']} vCPUs | {rip['ram']} GB RAM | {rip['storage']} GB storage
                                            </ListItemDescription>
                                        </ServerInfo>
                                        <SessionLabels>
                                            {rip['status'] === 'preparing' && (
                                                <SessionStatusTag bgColor="#f5cc00" color="#000000">
                                                    Requested
                                                </SessionStatusTag>
                                            )}
                                            {rip['status'] === 'provisioning' && (
                                                <SessionStatusTag bgColor="#2effb2" color="#000000">
                                                    Configuring
                                                </SessionStatusTag>
                                            )}
                                        </SessionLabels>
                                    </NonLinkServerListItem>
                                )
                            })
                        )}
                        {extConnectionsAreLoaded && userVms.length !== 0 && (
                            userVms.map(vm => {
                                return (
                                    <ServerListItem key={vm['server']} to={`/dashboard/direct/vm/instance/${vm['server']}`}>
                                        <ServerInfo>
                                            {vm['user_provided_name'] ? (
                                                <ListItemName>
                                                    {vm['user_provided_name']}
                                                </ListItemName>
                                            ) : (
                                                <UnnamedItemName>
                                                    Unnamed
                                                </UnnamedItemName>
                                            )}
                                            <ListItemDescription>
                                                {vm['gpu_count']}x {vm['pretty_gpu_name']} | {vm['vcpus']} vCPUs | {vm['ram']} GB RAM | {vm['storage']} GB storage
                                            </ListItemDescription>
                                        </ServerInfo>
                                        <SessionLabels>
                                            {vm['status'] === 'running' && (
                                                <SessionStatusTag bgColor="#00c424" color="#000000">
                                                    Running
                                                </SessionStatusTag>
                                            )}
                                            {vm['status'] === 'stopped' && (
                                                <SessionStatusTag bgColor="#cfcfcf" color="#000000">
                                                    Stopped
                                                </SessionStatusTag>
                                            )}
                                            {vm['status'] === 'stopping' && (
                                                <SessionStatusTag bgColor="#cfcfcf" color="#000000">
                                                    Stopping
                                                </SessionStatusTag>
                                            )}
                                            {vm['status'] === 'requested' && (
                                                <SessionStatusTag bgColor="#f5cc00" color="#000000">
                                                    Requested
                                                </SessionStatusTag>
                                            )}
                                            {vm['status'] === 'scheduled' && (
                                                <SessionStatusTag bgColor="#2ed5ff" color="#000000">
                                                    Scheduled
                                                </SessionStatusTag>
                                            )}
                                            {vm['status'] === 'network_ready' && (
                                                <SessionStatusTag bgColor="#2effb2" color="#000000">
                                                    Configuring
                                                </SessionStatusTag>
                                            )}
                                            {vm['status'] === 'starting' && (
                                                <SessionStatusTag bgColor="#2effb2" color="#000000">
                                                    Starting
                                                </SessionStatusTag>
                                            )}
                                            {vm['status'] === 'terminating' && (
                                                <SessionStatusTag bgColor="#ff0000" color="#ffffff">
                                                    Terminating
                                                </SessionStatusTag>
                                            )}
                                            {vm['status'] === 'error' && (
                                                <SessionStatusTag bgColor="#ed0000" color="#ffffff">
                                                    Error
                                                </SessionStatusTag>
                                            )}
                                        </SessionLabels>
                                    </ServerListItem>
                                )
                            })
                        )}
                    </ServerList>
                </ContentContainer>
            )}
        </DirectContainer>
    );
}

export default PortalDirectHome;