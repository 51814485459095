import styled from "styled-components";
import { useState, useEffect, useRef } from "react"
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faTriangleExclamation,
    faSpinnerThird,
    faPaperPlane,
    faCircleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import { faWifiSlash } from "@fortawesome/pro-light-svg-icons";

const VerifyContainer = styled.div`
  background-color: #1a1c47;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 100px 25px;
  @media screen and (max-width: 550px) {
    padding: 0;
  }
`;

const LoadingCover = styled.div`
  background-color: #1a1c47;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  font-size: 3rem;
`;

const Message = styled.div`
  text-align: center;
  padding: 50px;
  max-width: 450px;
  background-color: #121331;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  @media screen and (max-width: 550px) {
    max-width: 100%;
    min-height: 100vh;
  }
`;

const MessageTitle = styled.div`
  font-size: 2rem;
  font-weight: 500;
  margin: 20px 0;
`;

const MessageText = styled.div`
  font-size: 1rem;
  font-weight: 300;
`;

const Button = styled.div`
  padding: 8px 20px;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  margin: 30px auto 0 auto;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const ModalBackgroundCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 101vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 3rem;
  backdrop-filter: blur(1px);
`;

const ResponseModal = styled.div`
  position: absolute;
  align-self: center;
  z-index: 10;
  padding: 40px 25px;
  border-radius: 10px;
  background-color: #121331;
  font-size: 1.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResponseModalTitle = styled.div`
  font-size: 2rem;
  font-weight: 400;
  margin: 15px 0;
`;

const ResponseModalText = styled.div`
  font-size: 1rem;
  font-weight: 300;
  max-width: 300px;
`;

const ResponseModalButton = styled.div`
  padding: 8px 20px;
  margin: 25px auto 0 auto;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputLabel = styled.label`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7); 
  margin-bottom: 5px;
`;

const TextInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 15px;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #1a1c47;
  color: #fff;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
`;

const InputErrorText = styled.div`
  font-size: 0.7rem;
  color: red;
  font-weight: 300;
  margin-top: -12px;
  margin-left: auto;
  visibility: ${props => props.visible ? "visible" : "hidden"};
`;

const FormSubmitButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  padding: 10px 20px;
  margin: 15px auto 0 auto;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const VerifyEmail = () => {
    const [ waitingForEndpoint, setWaitingForEndpoint ] = useState(true);
    const [ successfulResponse, setSuccessfulResponse ] = useState(false);
    const [ failureResponse, setFailureResponse ] = useState(false);
    const [ networkError, setNetworkError ] = useState(false);

    const [ resendVerificationEmailModalIsShown, setResendVerificationEmailModalIsShown ] = useState(false);
    const [ resendVerificationEmailSentModalIsShown, setResendVerificationEmailSentModalIsShown ] = useState(false);
    const [ resendVerificationEmailSendingErrorModalIsShown, setResendVerificationEmailSendingErrorModalIsShown ] = useState(false);
    const [ resendEmailTarget, setResendEmailTarget ] = useState('');
    const [ resendEmailTargetError, setResendEmailTargetError ] = useState(false);
    const [ resendEmailTargetIsEmpty, setResendEmailTargetIsEmpty ] = useState(false);
    const [ resendEmailTargetIsInvalid, setResendEmailTargetIsInvalid ] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const resendTargetFieldRef = useRef();

    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidUsernameCheck = /([^A-Za-z0-9.\_])/g;

    useEffect(() => {
        if (params.has('token')) {
            const verificationToken = params.get('token');
            if (verificationToken.length === 0) {
                history.push('/');
            } else {
                fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/verification?token=${verificationToken}`)
                    .then(response => {
                        if (response['status'] < 299) {
                            setSuccessfulResponse(true);
                        } else {
                            setFailureResponse(true);
                        }
                    })
                    .catch(() => {
                        setNetworkError(true);
                    })
                    .finally(() => {
                        setWaitingForEndpoint(false);
                    });
            }
        } else {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        if (resendVerificationEmailModalIsShown) {
            resendTargetFieldRef.current.focus();
        }
    }, [resendTargetFieldRef, resendVerificationEmailModalIsShown]);

    const handleResendEmailTargetInput = e => {
        setResendEmailTargetError(false);
        setResendEmailTargetIsEmpty(false);
        setResendEmailTargetIsInvalid(false);
        setResendEmailTarget(e.target.value);
    }

    const handleResendEmail = e => {
        e.preventDefault();
        const requestResend = payload => {
            setResendVerificationEmailModalIsShown(false);
            setWaitingForEndpoint(true);
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/verification/resend?${payload}`)
                .then(response => {
                    if (response['status'] < 299) {
                        setResendVerificationEmailSentModalIsShown(true);
                    } else {
                        setResendVerificationEmailSendingErrorModalIsShown(true);
                    }
                })
                .catch(() => setNetworkError(true))
                .finally(() => setWaitingForEndpoint(false));
        }

        if (!resendEmailTarget) {
            setResendEmailTargetError(true);
            setResendEmailTargetIsEmpty(true);
        } else if (resendEmailTarget.includes('@')) {
            if (isValidEmail.test(resendEmailTarget)) {
                requestResend(`email=${resendEmailTarget}`);
            } else {
                setResendEmailTargetError(true);
                setResendEmailTargetIsInvalid(true);
            }
        } else {
            if (resendEmailTarget.length < 2 || resendEmailTarget.length > 32 || invalidUsernameCheck.test(resendEmailTarget)) {
                setResendEmailTargetError(true);
                setResendEmailTargetIsInvalid(true);
            } else {
                requestResend(`username=${resendEmailTarget}`);
            }
        }
    }

    const dismissResendVerificationEmailModal = () => {
        setResendVerificationEmailModalIsShown(false);
        setResendEmailTarget('');
        setResendEmailTargetError(false);
        setResendEmailTargetIsEmpty(false);
        setResendEmailTargetIsInvalid(false);
    }

    const dismissResendVerificationEmailSendingErrorModal = () => {
        setResendVerificationEmailSendingErrorModalIsShown(false);
        setResendVerificationEmailModalIsShown(true);
    }

    return (
        <VerifyContainer>
            {waitingForEndpoint && <LoadingCover><FontAwesomeIcon icon={faSpinnerThird} spin /></LoadingCover>}
            {successfulResponse && (
                <Message>
                    <FontAwesomeIcon icon={faCircleCheck} style={{fontSize: "6rem", color: "#05cd00"}} />
                    <MessageTitle>
                        You're all set!
                    </MessageTitle>
                    <MessageText>
                        Thanks for verifying. Please check your email for further instructions.
                    </MessageText>
                    <Link to="/login">
                        <Button>
                            Okay
                        </Button>
                    </Link>
                </Message>
            )}
            {failureResponse && (
                <Message>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{fontSize: "6rem", color: "#f5a142"}} />
                    <MessageTitle>
                        Something's wrong.
                    </MessageTitle>
                    <MessageText>
                        The link you clicked is invalid — it might be expired. Request a new one below.
                    </MessageText>
                    <Button onClick={() => setResendVerificationEmailModalIsShown(true)}>
                        Resend Email
                    </Button>
                </Message>
            )}
            {networkError && (
                <Message>
                    <FontAwesomeIcon icon={faWifiSlash} style={{fontSize: "6rem", color: "red"}} />
                    <MessageTitle>
                        Uh-oh.
                    </MessageTitle>
                    <MessageText>
                        We're having trouble connecting. Check your internet and reload the page.
                    </MessageText>
                </Message>
            )}
            {resendVerificationEmailSentModalIsShown ? (
                <>
                    <ModalBackgroundCover/>
                    <ResponseModal>
                        <FontAwesomeIcon icon={faPaperPlane} style={{fontSize: "4rem"}} />
                        <ResponseModalTitle>
                            Sent!
                        </ResponseModalTitle>
                        <ResponseModalText>
                            Click the link in the email we just sent to verify your account before logging in.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => history.push('/login')}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (resendVerificationEmailSendingErrorModalIsShown && (
                <>
                    <ModalBackgroundCover onClick={dismissResendVerificationEmailSendingErrorModal} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: "4rem", color: "red"}} />
                        <ResponseModalTitle>
                            Not found.
                        </ResponseModalTitle>
                        <ResponseModalText>
                            We couldn't find an inactive account with that username or password. Please try again.
                        </ResponseModalText>
                        <ResponseModalButton onClick={dismissResendVerificationEmailSendingErrorModal}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ))}
            {resendVerificationEmailModalIsShown && (
                <>
                    <ModalBackgroundCover onClick={dismissResendVerificationEmailModal} />
                    <ResponseModal>
                        <ResponseModalTitle style={{margin: "0"}}>
                            Resend
                        </ResponseModalTitle>
                        <ResponseModalText style={{padding: "20px 0"}}>
                            Enter your email or username to resend the verification email.
                        </ResponseModalText>
                        <LoginForm style={{textAlign: "left"}} onSubmit={handleResendEmail}>
                            <InputLabel htmlFor="resend-email-target">Email or Username</InputLabel>
                            <TextInput id="resend-email-target"
                                       ref={resendTargetFieldRef}
                                       value={resendEmailTarget}
                                       onChange={handleResendEmailTargetInput}
                            />
                            <InputErrorText visible={resendEmailTargetError}>
                                {resendEmailTargetIsEmpty ? (
                                    `Please enter your email or username.`
                                ) : (
                                    resendEmailTargetIsInvalid ? (
                                        `This is not a valid email or username.`
                                    ) : `There is no error.`
                                )}
                            </InputErrorText>
                            <FormSubmitButton type="submit" value="Send" />
                        </LoginForm>
                    </ResponseModal>
                </>
            )}
        </VerifyContainer>
    );
}

export default VerifyEmail;