import styled from "styled-components";
import BWSLogo from "./assets/logo-valdi-storj.png";
import { Link } from "react-router-dom";

const MobileNavContainer = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  padding: 0 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #121331;
  color: #fff;
  z-index: 40;
  margin-top: ${props => `${props.topMarginOffset}px`};
  @media screen and (max-width: 750px) {
    display: flex;
  }
  @media screen and (max-width: 400px) {
    padding: 0 25px;
  }
`;

const Logo = styled.div`
  width: 120px;
  margin-top: 6px;
  & img {
    width: 100%;
  }
`;

export const HamburgerMenu = styled.div`
  padding: 10px;
  margin-right: -10px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  & div {
    background-color: #fff;
    width: 20px;
    height: 2px;
    border-radius: 1px;
  }
  & div:first-child {
    transform: ${params => params.menuOpen && "translateY(7px) rotate(45deg)"};
  }
  & div:nth-child(2) {
    margin: 5px 0;
    visibility: ${params => params.menuOpen ? "hidden": "visible"};
  }
  & div:last-child {
    transform: ${params => params.menuOpen && "translateY(-7px) rotate(-45deg)"};
  }
`;

const PortalMobileNav = ({ mobileMenuIsOpen, setMobileMenuIsOpen, bannerHeight }) => {
    return (
        <MobileNavContainer topMarginOffset={bannerHeight}>
            <Link to="/dashboard">
                <Logo>
                    <img src={BWSLogo} alt="" />
                </Logo>
            </Link>
            <HamburgerMenu onClick={() => setMobileMenuIsOpen(prev => !prev)} menuOpen={mobileMenuIsOpen}>
                <div />
                <div />
                <div />
            </HamburgerMenu>
        </MobileNavContainer>
    );
}

export default PortalMobileNav;