import styled from "styled-components";
import { ReactComponent as IconEducators } from "./assets/icon-educators.svg";
import { ReactComponent as IconResearchers } from "./assets/icon-researchers.svg";
import { ReactComponent as IconStartups } from "./assets/icon-startups.svg";
import { ReactComponent as IconPrivate } from "./assets/icon-private.svg";
import { ReactComponent as IconInstant } from "./assets/icon-instant.svg";
import { ReactComponent as IconReliable } from "./assets/icon-reliable.svg"
import { ReactComponent as IconAffordable } from "./assets/icon-affordable.svg";
import { ReactComponent as IconElastic } from "./assets/icon-elastic.svg";
import { ReactComponent as IconGlobal } from "./assets/icon-global.svg";
import { Button } from "./SharedStyles";
import { Link } from "react-router-dom";

const LearnMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1c47;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 50px;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  max-width: 1136px;
  padding: 0 32px;
  width: 100%;
`;

const ItemsGrid = styled.div`
  display: flex;
  gap: ${props => props.gap && props.gap};
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 70px;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AltGridItemBorder = styled.div`
  border-radius: 11px;
  background: linear-gradient(to right, #2bdffd, #a020f0);
  position: relative;
  padding: 0;
  &:before {
    content: "";
    position: absolute;
    border-radius: 10px;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: -1px;
    background: inherit;
    opacity: 0;
  }
  &:hover:before {
    opacity: 1;
  }
`;

const AltGridItem = styled.div`
  background-color: #121331;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  width: 330px;
  height: 300px;
  &:hover ${AltGridItemBorder} {
    opacity: 1;
  }
  position: relative;
  z-index: 2;
`;

const AltGridItemIcon = styled.div`
  background-color: #1a1c47;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AltGridItemTitle = styled.div`
  font-size: 1.7rem;
  font-weight: 700;
  margin: 20px 0 15px 0;
`;

const AltGridItemText = styled.div`
  font-size: 1rem;
`;

const ItemText = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
`;

const MissionStatementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 180px;
  @media screen and (max-width: 1085px) {
    flex-direction: column;
  }
`;

const MissionStatement = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1085px) {
    align-items: center;
    text-align: center;
  }
`;

const StatementBold = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 40px;
`;

const StatementRegular = styled.div`
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 40px;
`;

const LandingLearnMore = () => {
    return (
        <LearnMore>
            <ContentContainer>
                <ItemsGrid gap="150px">
                    <GridItem>
                        <IconEducators />
                        <ItemText>
                            Educators
                        </ItemText>
                    </GridItem>
                    <GridItem>
                        <IconStartups />
                        <ItemText>
                            Startups
                        </ItemText>
                    </GridItem>
                    <GridItem>
                        <IconResearchers />
                        <ItemText>
                            Researchers
                        </ItemText>
                    </GridItem>
                </ItemsGrid>
                <ItemsGrid gap="40px">
                    <AltGridItemBorder>
                        <AltGridItem>
                            <AltGridItemIcon>
                                <IconPrivate />
                            </AltGridItemIcon>
                            <AltGridItemTitle>
                                PRIVATE
                            </AltGridItemTitle>
                            <AltGridItemText>
                                Strictest standards including GDPR compliance
                            </AltGridItemText>
                        </AltGridItem>
                    </AltGridItemBorder>
                    <AltGridItemBorder>
                        <AltGridItem>
                            <AltGridItemIcon>
                                <IconReliable />
                            </AltGridItemIcon>
                            <AltGridItemTitle>
                                RELIABLE
                            </AltGridItemTitle>
                            <AltGridItemText>
                                Tier 3 data centers in US, UK, and EU prioritizing running on renewables
                            </AltGridItemText>
                        </AltGridItem>
                    </AltGridItemBorder>
                    <AltGridItemBorder>
                        <AltGridItem>
                            <AltGridItemIcon>
                                <IconAffordable />
                            </AltGridItemIcon>
                            <AltGridItemTitle>
                                AFFORDABLE
                            </AltGridItemTitle>
                            <AltGridItemText>
                                Up to 50% cheaper than traditional cloud providers
                            </AltGridItemText>
                        </AltGridItem>
                    </AltGridItemBorder>
                    <AltGridItemBorder>
                        <AltGridItem>
                            <AltGridItemIcon>
                                <IconElastic />
                            </AltGridItemIcon>
                            <AltGridItemTitle>
                                ELASTIC
                            </AltGridItemTitle>
                            <AltGridItemText>
                                Flexible pay-as-you-go configurations
                            </AltGridItemText>
                        </AltGridItem>
                    </AltGridItemBorder>
                    <AltGridItemBorder>
                        <AltGridItem>
                            <AltGridItemIcon>
                                <IconInstant />
                            </AltGridItemIcon>
                            <AltGridItemTitle>
                                INSTANT
                            </AltGridItemTitle>
                            <AltGridItemText>
                                Fully automated onboarding via Stripe
                            </AltGridItemText>
                        </AltGridItem>
                    </AltGridItemBorder>
                    <AltGridItemBorder>
                        <AltGridItem>
                            <AltGridItemIcon>
                                <IconGlobal />
                            </AltGridItemIcon>
                            <AltGridItemTitle>
                                GLOBAL
                            </AltGridItemTitle>
                            <AltGridItemText>
                                VALDI offices in US (HQ), EU, and Middle East
                            </AltGridItemText>
                        </AltGridItem>
                    </AltGridItemBorder>
                </ItemsGrid>
                <MissionStatementContainer>
                    <MissionStatement>
                        <StatementBold>
                            We believe that AI will help accelerate humankind's advancement. To make sure that happens
                            equitably, VALDI enables access to reliable and economical cloud computing resources.
                        </StatementBold>
                        <StatementRegular>
                            VALDI makes it simple to access any computer you need at the most reasonable price.
                        </StatementRegular>
                        <Link to="/signup">
                            <Button>
                                Get Started
                            </Button>
                        </Link>
                    </MissionStatement>
                </MissionStatementContainer>
            </ContentContainer>
        </LearnMore>
    );
}

export default LandingLearnMore;