import styled from "styled-components";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";


const CheckoutContainer = styled.div`
  position: relative;
  background-color: #1a1c47;
  min-height: 100vh;
  display: flex;
  padding: 0 40px 30px 50px;
  align-items: start;
  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  @media screen and (max-width: 550px) {
    padding: 0;
  }
`;

const FormContainer = styled.div`
  position: relative;
  background-color: #121331;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 550px) {
    width: 100%;
    border-radius: 0;
    min-height: 100vh;
    padding: 50px;
    min-width: 0;
    top: -30px;
  }
`;

const Logo = styled.div`
  font-size: 6rem;
  color: #05cd00;
`;

const Title = styled.div`
  font-size: 1.4rem;
  max-width: 292px;
  text-align: center;
  margin-bottom: 30px;
`;

const Content = styled.div`
  font-size: 0.9rem;
`;

const Button = styled(Link)`
  border-radius: 10px;
  margin-top: 40px;
  padding: 10px 40px;
  color: #fff;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const PortalPaymentMethodComplete = ({ token, setSelectedView }) => {
    useEffect(() => {
        setSelectedView('pm-confirm');
    }, []);

    return (
        <CheckoutContainer>
            <FormContainer>
                <Logo>
                    <FontAwesomeIcon icon={faCircleCheck} />
                </Logo>
                <Title>
                    The payment method has been successfully added.
                </Title>
                <Content>
                    You can now add funds to your account.
                </Content>
                <Button to="/dashboard/billing">
                    Okay
                </Button>
            </FormContainer>
        </CheckoutContainer>
    );
}
export default PortalPaymentMethodComplete;