import { useEffect } from "react";
import styled from "styled-components";
import { InputField } from "./SharedStyles";

export const PortalSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1c47;
  width: 100%;
  height: 100%;
  padding: 0 50px 30px 50px;
  @media screen and (max-width: 435px) {
    padding: 0 25px 30px 25px;
  }
  @media screen and (max-width: 365px) {
    padding: 0 10px 30px 10px;
  }
`;

export const TitleText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  align-self: flex-start;
`;

export const SettingsSection = styled.div`
  background-color: #292c6e;
  padding: 20px 30px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
`;

export const EntryGroup = styled.div`
  margin: 30px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const EntryTitle = styled.div`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: -3px;
`;

export const EntryValue = styled.div`
  font-size: 0.95rem;
  display: flex;
`;

export const AccountBalanceValue = styled.div`
  font-size: 1.4rem;
`;

export const AccountBalanceDollars = styled.span`
  font-size: 2.0rem;
`;

export const AccountBalanceCents = styled.span`
  font-size: 0.8rem;
`;

export const SettingsSectionGroup = styled.div`
  margin-bottom: 40px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TransactionsContainer = styled.div`
  width: 100%;
`;

export const TransactionsLoadingContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
  width: 100%;
`;

export const TransactionsList = styled.div`
  width: 100%;
`;

export const NonInteractiveTransactionRow = styled.div`
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -3px;
`;

export const TransactionRow = styled(NonInteractiveTransactionRow)`
  margin-top: 0;
  &:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    cursor: pointer;
  }
`;

export const PagingControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
`;

export const PagingController = styled.div`
  padding: 5px;
  cursor: pointer;
`;

export const DisabledPagingController = styled(PagingController)`
  cursor: not-allowed;
  color: rgba(255, 255, 255, 0.5);
`;

export const NarrowInputField = styled(InputField)`
  padding: 5px 0 5px 10px;
  margin-right: 5px;
`;

const PortalSettings = ({
                            setSelectedView,
                            token,
                            username,
                            email,
                            locale,
                            discountRate,
                            updateUserInfo
}) => {
    useEffect(() => {
        updateUserInfo();
    }, []);

    useEffect(() => {
        setSelectedView('account');
    }, [setSelectedView]);

    return (
        <PortalSettingsContainer>
            <SettingsSectionGroup>
                <TitleText>
                    Account Settings
                </TitleText>
                <SettingsSection>
                    <EntryGroup>
                        <EntryTitle>
                            Your Account Email
                        </EntryTitle>
                        <EntryValue>
                            {email}
                        </EntryValue>
                    </EntryGroup>
                    <EntryGroup>
                        <EntryTitle>
                            Username
                        </EntryTitle>
                        <EntryValue>
                            {username}
                        </EntryValue>
                    </EntryGroup>
                    <EntryGroup>
                        <EntryTitle>
                            Location
                        </EntryTitle>
                        <EntryValue>
                            {locale}
                        </EntryValue>
                    </EntryGroup>
                    <EntryGroup>
                        <EntryTitle>
                            Password
                        </EntryTitle>
                        <EntryValue>
                            ********
                        </EntryValue>
                    </EntryGroup>
                    {discountRate > 0 && (
                        <EntryGroup>
                            <EntryTitle>
                                GPU Discount Rate
                            </EntryTitle>
                            <EntryValue>
                                {discountRate * 100}%
                            </EntryValue>
                        </EntryGroup>
                    )}
                </SettingsSection>
            </SettingsSectionGroup>
        </PortalSettingsContainer>
    )
}

export default PortalSettings;