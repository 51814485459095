import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCopy } from "@fortawesome/pro-light-svg-icons";
import { faCircleNotch, faCheck } from "@fortawesome/pro-regular-svg-icons";
import {
    DetailContainer,
    DetailPanel,
    DetailInfo,
    SubSectionTitle,
    SectionDivider,
    BackButton,
    InfoGroup,
    InfoName,
    InfoData,
    InputSuperGroup,
    InputGroupHorizontal,
    InputField,
    InputLabel,
    DropDownListAlt,
    PricingBlock,
    TotalPrice,
    NewBadge,
    ConnectButtonWithDisabling,
    ConfirmModalBackgroundCover,
    ConfirmModal,
    ModalTitle,
    ModalText,
    ModalTotalPrice,
    ModalFinePrint,
    LaunchButton,
    CodeBlock,
    ConnectButton,
    ClipboardIcon,
    InputFieldWithFeedback,
    InputFeedbackText,
    SubSectionDivider,
    KeyPairDescription,
    InputFieldContainer,
    CustomTooltip,
    DisabledDropDownList,
    PlaceholderContainer
} from "./SharedStyles";
import { generateRandomString } from "./utilities";

const SubText = styled.div`
  font-size: 0.8rem;
  max-width: 420px;
  & span {
    font-weight: 700;
  }
`;

const PriceWithTagContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
`;

const AllInclusiveBadge = styled(NewBadge)`
  width: 100px;
`;

const ShiftedClipboardIcon = styled(ClipboardIcon)`
  top: 7px;
`;

const PortalDirectTaigaCloudServer = ({ token, serverInfo = {}, providerId }) => {
    const [ sshKeys, setSshKeys ] = useState([]);
    const [ sshKeysAreLoaded, setSshKeysAreLoaded ] = useState(false);
    const [ vmName, setVmName ] = useState('');
    const [ nameLength, setNameLength ] = useState(0);
    const [ selectedSshKey, setSelectedSshKey ] = useState('');
    const [ selectedSshKeyName, setSelectedSshKeyName ] = useState('');
    const [ confirmModalIsShown, setConfirmModalIsShown ] = useState(false);
    const [ vmIsProvisioning, setVmIsProvisioning ] = useState(false);
    const [ errorProvisioningVm, setErrorProvisioningVm ] = useState(false);
    const [ provisioningSucceeded, setProvisioningSucceeded ] = useState(false);
    const [ insufficientFunds, setInsufficientFunds ] = useState(false);
    const [ serverIp, setServerIp ] = useState('');
    const [ codeWasCopied, setCodeWasCopied ] = useState(false);

    const maxNameLength = 50;
    const nameInputRef = useRef();

    useEffect(() => {
        if (nameInputRef) {
            nameInputRef.current.focus();
        }
    }, [nameInputRef]);

    useEffect(() => {
        if (token) {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/sshkeys`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(data => {
                    if ('ssh_keys' in data) {
                        setSshKeys(data['ssh_keys']);
                        setSshKeysAreLoaded(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (codeWasCopied) {
            const clipboardTimeout = setTimeout(() => setCodeWasCopied(false), 2000)
            return () => clearTimeout(clipboardTimeout);
        }
    }, [codeWasCopied]);

    const handleSshKeySelection = e => {
        setSelectedSshKey(e.target.value);
        const sshKey = sshKeys.find(key => key.ssh_key_id === e.target.value);
        setSelectedSshKeyName(sshKey['name']);
    }

    const handleFormSubmit = e => {
        e.preventDefault();
        setConfirmModalIsShown(false);
        setVmIsProvisioning(true);
        setProvisioningSucceeded(false);

        let payload = {
            provider_id: providerId,
            name: `valdi-customer-${generateRandomString(6)}`,
            details: {
                'ssh_key_id': selectedSshKey,
                'flavor_id': serverInfo['flavor_id'],
                'ipv4': true
            }
        };

        if (vmName !== '') {
            payload['user_provided_name'] = vmName;
        }

        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/provision`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (response.status === 200 || response.status === 403) {
                    return response.json();
                } else {
                    throw new Error('Non-200 response')
                }
            })
            .then(data => {
                if (data) {
                    if ('ip' in data) {
                        setServerIp(data['ip']);
                        setProvisioningSucceeded(true);
                    } else if ('detail' in data) {
                        if (data['detail'] === 'Insufficient user balance') {
                            setInsufficientFunds(true);
                        }
                    } else {
                        setErrorProvisioningVm(true);
                    }
                } else {
                    setErrorProvisioningVm(true);
                }
            })
            .catch(error => {
                setErrorProvisioningVm(true);
                console.log(error);
            })
            .finally(() => setVmIsProvisioning(false))
    }

    const copyToClipboard = () => {
        let connectionDetails = '';
        if (selectedSshKeyName.includes(' ')) {
            connectionDetails = `ssh -i "${selectedSshKeyName}.pem" ubuntu@${serverIp}`;
        } else {
            connectionDetails = `ssh -i ${selectedSshKeyName}.pem ubuntu@${serverIp}`;
        }
        navigator.clipboard.writeText(connectionDetails);
        setCodeWasCopied(true);
    }

    const handleVmNameEntry = e => {
        const enteredText = e.target.value;
        const currentLength = enteredText.length;
        if (currentLength <= maxNameLength) {
            setNameLength(currentLength);
            setVmName(enteredText);
        }
    }

    return (
        Object.keys(serverInfo).length === 0 ? (
            <></>
        ) : (
            <DetailContainer>
                <DetailPanel>
                    <Link to="/dashboard/direct?active=vms">
                        <BackButton>
                            <FontAwesomeIcon icon={faAngleLeft} />
                            <span>Back</span>
                        </BackButton>
                    </Link>
                    <SubSectionTitle>
                        Device Details
                    </SubSectionTitle>
                    <DetailInfo>
                        <InfoGroup>
                            <InfoName>
                                GPU type
                            </InfoName>
                            <InfoData>
                                NVIDIA {serverInfo['gpu_type']}
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                CPU model
                            </InfoName>
                            <InfoData>
                                AMD EPYC 7402P
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                Location
                            </InfoName>
                            <InfoData>
                                Norway 🇳🇴
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                Transfer Speed
                            </InfoName>
                            <InfoData>
                                10 Gbps
                            </InfoData>
                        </InfoGroup>
                    </DetailInfo>
                    <SectionDivider />
                    <SubSectionTitle>
                        Server Specifications
                    </SubSectionTitle>
                    <InputSuperGroup onSubmit={handleFormSubmit}>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="vm-name">VM name: </InputLabel>
                            <InputFieldWithFeedback>
                                <InputField id="vm-name"
                                            customWidth="100%"
                                            value={vmName}
                                            onChange={handleVmNameEntry}
                                            placeholder="Enter a name for your virtual machine"
                                            ref={nameInputRef}
                                />
                                <InputFeedbackText visible={true}>{nameLength} / {maxNameLength}</InputFeedbackText>
                            </InputFieldWithFeedback>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="num-gpus">Number of GPUs: </InputLabel>
                            <InputFieldContainer>
                                <InputField id="num-gpus"
                                            customWidth="60px"
                                            value={serverInfo['gpu_count']}
                                            disabled={true}
                                />
                                <CustomTooltip>
                                    This server features all-inclusive pricing, so the server specifications cannot be
                                    changed.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="cpu-cores">Number of vCPUs: </InputLabel>
                            <InputFieldContainer>
                                <InputField
                                    id="cpu-cores"
                                    customWidth="60px"
                                    value={serverInfo['cpu_core_count']}
                                    disabled={true}
                                />
                                <CustomTooltip>
                                    This server features all-inclusive pricing, so the server specifications cannot be
                                    changed.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="ram-amount">Amount of RAM (GB): </InputLabel>
                            <InputFieldContainer>
                                <InputField id="ram-amount"
                                            customWidth="70px"
                                            value={serverInfo['main_ram']}
                                            disabled={true}
                                />
                                <CustomTooltip>
                                    This server features all-inclusive pricing, so the server specifications cannot be
                                    changed.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="amt-storage">Local Storage Size (GB): </InputLabel>
                            <InputFieldContainer>
                                <InputField id="amt-storage"
                                            customWidth="80px"
                                            value={serverInfo['storage']}
                                            disabled={true}
                                />
                                <CustomTooltip>
                                    This server features all-inclusive pricing, so the server specifications cannot be
                                    changed.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="os-select">Operating System: </InputLabel>
                            <InputFieldContainer>
                                <DisabledDropDownList value="Ubuntu 22.04 LTS"
                                                      id="os-select"
                                                      disabled={true}
                                >
                                    <option value="Ubuntu 22.04 LTS">Ubuntu 22.04 LTS</option>
                                </DisabledDropDownList>
                                <CustomTooltip>
                                    Only Ubuntu 22.04 LTS is currently available on this server. CUDA and NVIDIA drivers
                                    come preinstalled.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        {confirmModalIsShown && (
                            <>
                                <ConfirmModalBackgroundCover onClick={() => setConfirmModalIsShown(false)} />
                                <ConfirmModal>
                                    <ModalTitle>
                                        Launch Virtual Machine
                                    </ModalTitle>
                                    <ModalText>
                                        Please confirm that you would like to provision this VM.
                                    </ModalText>
                                    <ModalTotalPrice>
                                        ${parseFloat(serverInfo['running_cost']).toFixed(2)}<span>/hour</span>
                                    </ModalTotalPrice>
                                    <ModalFinePrint>
                                        Your virtual machine will accrue pro-rated hourly charges for as long as it is
                                        running. Hourly charges equal
                                        ${parseFloat(serverInfo['stopped_cost']).toFixed(2)}<span>/hour</span> while the VM
                                        is stopped. Once the VM is terminated, all data stored on the server will be lost.
                                        Please back up your data.
                                    </ModalFinePrint>
                                    <LaunchButton value="Launch virtual machine" type="submit" />
                                </ConfirmModal>
                            </>
                        )}
                    </InputSuperGroup>
                    <SectionDivider />
                    <SubSectionTitle>
                        SSH Key Pair
                    </SubSectionTitle>
                    <KeyPairDescription>
                        This server requires SSH key-based authentication. Ensure that you have access to the selected
                        key before you launch the VM.
                    </KeyPairDescription>
                    <InputGroupHorizontal>
                        <InputLabel htmlFor="key-select">Key pair: </InputLabel>
                        <InputFieldContainer>
                            <DropDownListAlt value={selectedSshKey}
                                             disabled={sshKeys.length === 0}
                                             onChange={handleSshKeySelection}
                                             id="key-select"
                            >
                                {sshKeysAreLoaded ? (
                                    sshKeys.length === 0 ? (
                                        <option>No SSH keys available.</option>
                                    ) : (
                                        <>
                                            <option value="">Select a key...</option>
                                            {sshKeys.map(sshkey => {
                                                return (
                                                    <option
                                                        value={sshkey['ssh_key_id']}
                                                        key={sshkey['ssh_key_id']}
                                                    >
                                                        {sshkey['name']}
                                                    </option>
                                                );
                                            })}
                                        </>
                                    )
                                ) : (
                                    <option>Keys loading...</option>
                                )}
                            </DropDownListAlt>
                        </InputFieldContainer>
                    </InputGroupHorizontal>
                    <SectionDivider />
                    <SubSectionTitle>
                        Networking
                    </SubSectionTitle>
                    <PlaceholderContainer>
                        This server has ports 22, 80, and 443 open by default. Additional ports can be configured using
                        ufw once the VM is provisioned.
                    </PlaceholderContainer>
                    <SectionDivider />
                    <SubSectionTitle>
                        Pricing
                    </SubSectionTitle>
                    <PricingBlock>
                        <PriceWithTagContainer>
                            <TotalPrice>
                                ${parseFloat(serverInfo['running_cost']).toFixed(2)}<span>/hour</span>
                            </TotalPrice>
                            <AllInclusiveBadge>
                                ALL-INCLUSIVE
                            </AllInclusiveBadge>
                        </PriceWithTagContainer>
                        <SubSectionDivider />
                        <SubText>
                            This server features all-inclusive pricing. There are no additional charges for CPUs, RAM,
                            and local storage. <span>When the server is stopped, costs will accrue at
                            ${parseFloat(serverInfo['stopped_cost']).toFixed(2)}/hour.</span>
                        </SubText>
                    </PricingBlock>
                    <ConnectButtonWithDisabling disabled={selectedSshKey === ''}
                                                onClick={() => setConfirmModalIsShown(true)}
                    >
                        Launch virtual machine
                    </ConnectButtonWithDisabling>
                </DetailPanel>
                {vmIsProvisioning && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            </ModalTitle>
                        </ConfirmModal>
                    </>
                )}
                {provisioningSucceeded && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal maxWidth="500px">
                            <ModalTitle>
                                Your VM is provisioning
                            </ModalTitle>
                            <ModalText>
                                Connect to your server with the following command:
                                <CodeBlock>
                                    {selectedSshKeyName.includes(' ') ? (
                                        <div>ssh \<br/>-i "{selectedSshKeyName}.pem" \<br/>ubuntu@{serverIp}</div>
                                    ) : (
                                        <div>ssh \<br/>-i {selectedSshKeyName}.pem \<br/>ubuntu@{serverIp}</div>
                                    )}
                                    {codeWasCopied ? (
                                        <ShiftedClipboardIcon><FontAwesomeIcon icon={faCheck} /></ShiftedClipboardIcon>
                                    ) : (
                                        <ShiftedClipboardIcon onClick={copyToClipboard}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </ShiftedClipboardIcon>
                                    )}
                                </CodeBlock>
                            </ModalText>
                            <ModalFinePrint>
                                It may take a few minutes for the virtual machine to become available. You can check the
                                status under "Your Virtual Machines".
                            </ModalFinePrint>
                            <Link to="/dashboard/direct?active=sessions">
                                <ConnectButton>
                                    Okay
                                </ConnectButton>
                            </Link>
                        </ConfirmModal>
                    </>
                )}
                {errorProvisioningVm && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Error
                            </ModalTitle>
                            <ModalText>
                                There was a problem provisioning the virtual machine. Please try again later.
                            </ModalText>
                            <Link to="/dashboard/direct?active=vms">
                                <ConnectButton>
                                    Okay
                                </ConnectButton>
                            </Link>
                        </ConfirmModal>
                    </>
                )}
                {insufficientFunds && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Insufficient Balance
                            </ModalTitle>
                            <ModalText>
                                You need a balance of at least $10.00 USD to launch a virtual machine. Please deposit
                                funds and try again.
                            </ModalText>
                            <Link to="/dashboard/billing">
                                <ConnectButton>
                                    Deposit funds
                                </ConnectButton>
                            </Link>
                        </ConfirmModal>
                    </>
                )}
            </DetailContainer>
        )
    );
}

export default PortalDirectTaigaCloudServer;