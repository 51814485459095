import styled from "styled-components";
import JainPhoto from "./assets/image-profile-jain.jpg";
import ZhengPhoto from "./assets/image-profile-zheng.jpg";
import HarvillaPhoto from "./assets/image-profile-harvilla.jpg";
import RivierPhoto from "./assets/image-profile-thibault-rivier.jpeg";
import { ContentContainer } from "./LandingLearnMore";
import { useEffect } from "react";

export const LandingContainerDark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #121331;
  width: 100%;
`;

export const SectionTitle = styled.div`
  font-size: 2.5rem;
  margin: 80px 0;
  text-align: center;
  font-weight: 700;
`;

const MemberGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 160px;
  gap: 60px;
`;

const MemberItem = styled.div`
  text-align: center;
`;

const MemberPhoto = styled.img`
  width: 260px;
  height: 260px;
  border-radius: 50%;
  object-fit: cover;
`;

const MemberName = styled.div`
  background: linear-gradient(to right, #9438c2, #ff1f82);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  font-weight: 800;
`;

const MemberTitle = styled.div`
  color: #d1d1d1;
`;

const LandingTeam = ({ scrollToRef, setActiveView }) => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setActiveView('team');
                }
            },
            { threshold: 0.3 }
        );

        if (scrollToRef.current) {
            observer.observe(scrollToRef.current);
        }

        return () => {
            if (scrollToRef.current) {
                observer.unobserve(scrollToRef.current);
            }
        }
    }, []);

    return (
        <LandingContainerDark ref={scrollToRef}>
            <ContentContainer>
                <SectionTitle>
                    VALDI Team
                </SectionTitle>
                <MemberGrid>
                    <MemberItem>
                        <MemberPhoto src={JainPhoto} />
                        <MemberName>Nikhil Jain</MemberName>
                        <MemberTitle>Co-Founder & CEO</MemberTitle>
                    </MemberItem>
                    <MemberItem>
                        <MemberPhoto src={HarvillaPhoto} />
                        <MemberName>Mark Harvilla, PhD</MemberName>
                        <MemberTitle>Co-Founder & CTO</MemberTitle>
                    </MemberItem>
                    <MemberItem>
                        <MemberPhoto src={RivierPhoto} />
                        <MemberName>Thibault Rivier</MemberName>
                        <MemberTitle>Advisor, EMEA</MemberTitle>
                    </MemberItem>
                </MemberGrid>
            </ContentContainer>
        </LandingContainerDark>
    );
}

export default LandingTeam;