import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import PortalMenu from "./PortalMenu";
import PortalTopNavbar from "./PortalTopNavbar";
import PortalReferrals from "./PortalReferrals";
import PortalSettings from "./PortalSettings";
import PortalBilling from "./PortalBilling";
import PortalFooter from "./PortalFooter";
import PortalMobileNav from "./PortalMobileNav";
import PortalHome from "./PortalHome";
import PortalDirectHome from "./PortalDirectHome";
import PortalDirectServer from "./PortalDirectServer";
import PortalDirectVirtualMachine from "./PortalDirectVirtualMachine";
import PortalPaymentMethod from "./PortalPaymentMethod";
import PortalPaymentMethodComplete from "./PortalPaymentMethodComplete";
import PortalAdmin from "./PortalAdmin";
import PortalAdminUser from "./PortalAdminUser";
import PortalAdminVM from "./PortalAdminVM";
import PortalSshKeys from "./PortalSshKeys";
import PortalStorage from "./PortalStorage";
import PortalStorageVolume from "./PortalStorageVolume";
import AnnouncementBanner from "./AnnouncementBanner";

const PortalContainer = styled.div`
  display: flex;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const PortalWorkingArea = styled.div`
  background-color: #1a1c47;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-left: 254px;
  margin-top: ${props => `${props.topMarginOffset}px`};
  @media screen and (max-width: 1355px) {
    padding-left: 110px;
  }
  @media screen and (max-width: 750px) {
    padding-left: 0;
    padding-top: 105px;
  }
`;

const PortalHomeContainer = styled.div`
    height: 100%;
`;

const TopBarNotification = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #2bdffd;
  color: #000;
  padding: 2px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  font-size: 0.8rem;
  font-weight: 500;
  & div:nth-child(2) {
    text-align: center;
  }
  & div:last-child {
    cursor: pointer;
    font-size: 0.9rem;
  }
`;

const BannerContainer = styled.div`
  position: fixed;
  z-index: 4;
  width: 100%;
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Portal = (
    { token, loading, showLogoutModal, bannerIsShown, updateBannerHeight, bannerHeight, setBannerIsShown }
) => {
    const [ activeView, setActiveView ] = useState('home');
    const [ username, setUsername ] = useState();
    const [ email, setEmail ] = useState();
    const [ locale, setLocale ] = useState();
    const [ discountRate, setDiscountRate ] = useState(0);
    const [ mobileMenuIsOpen, setMobileMenuIsOpen ] = useState(false);
    const [ userIsAdmin, setUserIsAdmin ] = useState(false);
    const [ notificationBarIsShown, setNotificationBarIsShown ] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!loading) {
            if (!token) {
                history.push('/');
            } else {
                updateUserInfo();
            }
        }
    }, [loading, token]);

    useEffect(() => {
        setMobileMenuIsOpen(false);
        document.documentElement.scrollTop = 0;
    }, [activeView]);

    const updateUserInfo = () => {
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data['username']) {
                    setUsername(data['username']);
                }
                if (data['email']) {
                    setEmail(data['email']);
                }
                if (data['country']) {
                    setLocale(data['country']);
                }
                if (data['role'] && data['role'] === 'admin') {
                    setUserIsAdmin(true);
                }
                if (data['discount_rate']) {
                    setDiscountRate(parseFloat(data['discount_rate']));
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <PortalContainer>
            {notificationBarIsShown && (
                <TopBarNotification>
                    <div/>
                    <div>
                        Some VM deployments are temporarily disabled for maintenance.
                        Existing VMs are not impacted.
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faXmark} onClick={() => setNotificationBarIsShown(false)} />
                    </div>
                </TopBarNotification>
            )}
            {bannerIsShown && (
                <BannerContainer>
                    <AnnouncementBanner updateBannerHeight={updateBannerHeight}
                                        setBannerIsShown={setBannerIsShown}
                    />
                </BannerContainer>
            )}
            <PortalMobileNav
                mobileMenuIsOpen={mobileMenuIsOpen}
                setMobileMenuIsOpen={setMobileMenuIsOpen}
                bannerHeight={bannerHeight}
            />
            <PortalMenu selectedView={activeView}
                        mobileMenuIsOpen={mobileMenuIsOpen}
                        showLogoutModal={showLogoutModal}
                        bannerHeight={bannerHeight}
                        isAdmin={userIsAdmin}
            />
            <PortalWorkingArea topMarginOffset={bannerHeight}>
                <PortalTopNavbar selectedView={activeView}
                                 username={username}
                                 showLogoutModal={showLogoutModal}
                />
                <Switch>
                    {userIsAdmin && (
                        <Route exact path="/dashboard/admin/vm/instance/:server">
                            <PortalAdminVM setSelectedView={setActiveView}
                                           token={token}
                            />
                        </Route>
                    )}
                    {userIsAdmin && (
                        <Route exact path="/dashboard/admin/user/:email">
                            <PortalAdminUser setSelectedView={setActiveView}
                                             token={token}
                            />
                        </Route>
                    )}
                    {userIsAdmin && (
                        <Route exact path="/dashboard/admin">
                            <PortalAdmin setSelectedView={setActiveView}
                                         token={token}
                            />
                        </Route>
                    )}
                    <Route exact path="/dashboard/account">
                        <PortalSettings setSelectedView={setActiveView}
                                        token={token}
                                        username={username}
                                        email={email}
                                        locale={locale}
                                        discountRate={discountRate}
                                        updateUserInfo={updateUserInfo}
                        />
                    </Route>
                    <Route exact path="/dashboard/billing">
                        <Elements stripe={stripePromise}>
                            <PortalBilling setSelectedView={setActiveView}
                                           token={token}
                            />
                        </Elements>
                    </Route>
                    <Route exact path="/dashboard/billing/payment-method">
                        <PortalPaymentMethod setSelectedView={setActiveView}
                                             token={token}
                        />
                    </Route>
                    <Route exact path="/dashboard/billing/payment-method/confirmation">
                        <PortalPaymentMethodComplete setSelectedView={setActiveView} />
                    </Route>
                    <Route exact path="/dashboard/referrals">
                        <PortalReferrals setSelectedView={setActiveView}
                                         token={token}
                        />
                    </Route>
                    <Route exact path="/dashboard/keys">
                        <PortalSshKeys setSelectedView={setActiveView}
                                       token={token}
                        />
                    </Route>
                    <Route exact path="/dashboard/storage">
                        <PortalStorage setSelectedView={setActiveView}
                                       token={token}
                        />
                    </Route>
                    <Route exact path="/dashboard/storage/volume/:volumeId"
                           render={({ match, location }) => {
                               const volumeId = match.params.volumeId;
                               return (
                                   <PortalStorageVolume volumeId={volumeId}
                                                        subPath=''
                                                        token={token}
                                                        setSelectedView={setActiveView}
                                                        location={location}
                                   />
                               )
                           }}
                    />
                    <Route path="/dashboard/storage/volume/:volumeId/*"
                           render={({ match, location}) => {
                               const volumeId = match.params.volumeId;
                               const fullPath = match.url;
                               const subPath = fullPath.split(`/dashboard/storage/volume/${volumeId}/`)[1] || '';
                               return (
                                   <PortalStorageVolume volumeId={volumeId}
                                                        subPath={subPath}
                                                        token={token}
                                                        setSelectedView={setActiveView}
                                                        location={location}
                                   />
                               )
                           }}
                    />
                    <Route exact path="/dashboard/direct">
                        <PortalDirectHome setSelectedView={setActiveView}
                                          token={token}
                                          discountRate={discountRate}
                                          updateUserInfo={updateUserInfo}
                        />
                    </Route>
                    <Route exact path="/dashboard/direct/vm/instance/:server">
                        <PortalDirectVirtualMachine setSelectedView={setActiveView}
                                                    token={token}
                        />
                    </Route>
                    <Route exact path="/dashboard/direct/vm/:providerId/:serverId">
                        <PortalDirectServer setSelectedView={setActiveView}
                                            token={token}
                        />
                    </Route>
                    <Route path="/dashboard">
                        <PortalHomeContainer>
                            <PortalHome setSelectedView={setActiveView}
                                        token={token}
                                        loading={loading}
                            />
                        </PortalHomeContainer>
                    </Route>
                </Switch>
                <PortalFooter />
            </PortalWorkingArea>
        </PortalContainer>
    );
}

export default Portal;