import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    DirectContainer,
    ContentContainer,
    ContentTitleContainer,
    ContentTitle,
    ContentDescription,
    ServerList,
    EmptyKeyPlaceholder,
    LargeIcon,
    NoKeysDescription,
    GenerateKeyButton,
    LargeGenerateKeyButton,
    KeyName,
    KeyDetails,
    RefreshIcon,
    ServerListItem,
    ConfirmModalBackgroundCover,
    ConfirmModal,
    ModalTitle,
    ModalText,
    CancelButton,
    TerminateButton,
    InputContainer,
    LightBgTextInput,
    ConnectButtonWithDisabling,
    ConnectButton,
    CodeBlock,
    ClipboardIcon
} from "./SharedStyles";
import { InputLabel } from "./Signup";
import { faCircleNotch, faDatabase, faCalculator } from "@fortawesome/pro-regular-svg-icons";
import { faTrashCan, faDownload, faCopy, faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseTimestamp } from "./utilities";
import StorageCalculator from "./StorageCalculator";

const DecoratedLink = styled.a`
  text-decoration: underline dotted;
  &:hover {
    text-decoration: none;
  }
`;

const CodeBlockTitle = styled.div`
  font-size: 0.6rem;
  text-align: left;
  margin-left: 8px;
  margin-top: 14px;
  margin-bottom: -17px;
`;

const NarrowCodeBlock = styled(CodeBlock)`
  white-space: normal;
  overflow-x: visible;
  overflow-wrap: anywhere;
`;

const WidthRestrictedCode = styled.div`
  width: 85%;
`;

const MarginedCancelButton = styled(CancelButton)`
  margin-top: 12px;
  margin-bottom: 10px;
  opacity: 0.8;
`;

const FieldDisclaimer = styled.div`
  text-align: right;
  font-size: 0.6rem;
  opacity: 0.8;
  margin-top: -11px;
`;

const LargeCenteredGenerateKeyButton = styled(LargeGenerateKeyButton)`
  margin: 20px 0 0 0;
`;

const MessageBox = styled.div`
  width: 100%;
  padding: 10px 20px;
  margin: 20px 0;
  border-radius: 10px;
  background-color: #292c6e;
  color: #fff;
  font-size: 0.8rem;
  display: flex;
  gap: 5px;
`;

const MessageTitle = styled.div`
  font-weight: 700;
`;

const MessageContent = styled.div`
`;

const CalculatorIcon = styled.div`
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  padding: 5px 5px;
  position: relative;
`;

const CenteredContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 44;
`;

const ContentDescriptionNoSpacing = styled(ContentDescription)`
  display: block;
`;

const PortalStorage = ({ setSelectedView, token }) => {
    const [ volumesAreLoading, setVolumesAreLoading ] = useState(true);
    const [ volumes, setVolumes ] = useState([]);
    const [ deleteVolumeModalIsVisible, setDeleteVolumeModalIsVisible ] = useState(false);
    const [ createVolumeModalIsVisible, setCreateVolumeModalIsVisible ] = useState(false);
    const [ volumeIsCreating, setVolumeIsCreating ] = useState(false);
    const [ volumeCreated, setVolumeCreated ] = useState(false);
    const [ volumeFailedToCreate, setVolumeFailedToCreate ] = useState(false);
    const [ volumeStagedForDeletion, setVolumeStagedForDeletion ] = useState('');
    const [ volumeIsDeleting, setVolumeIsDeleting ] = useState(false);
    const [ volumeDeleted, setVolumeDeleted ] = useState(false);
    const [ volumeFailedToDelete, setVolumeFailedToDelete ] = useState(false);
    const [ newVolumeName, setNewVolumeName ] = useState('');
    const [ newVolumeDetails, setNewVolumeDetails ] = useState({});
    const [ accessKeyWasCopied, setAccessKeyWasCopied ] = useState(false);
    const [ secretAccessKeyWasCopied, setSecretAccessKeyWasCopied ] = useState(false);
    const [ insufficientFunds, setInsufficientFunds ] = useState(false);
    const [ calculatorIsShown, setCalculatorIsShown ] = useState(false);

    const inputNameRef = useRef();

    useEffect(() => {
        setSelectedView('storage');
    }, [setSelectedView]);

    useEffect(() => {
        if (token) {
            fetchVolumes();
        }
    }, [token]);

    useEffect(() => {
        if (accessKeyWasCopied) {
            const accessKeyTimeout = setTimeout(() => {
                setAccessKeyWasCopied(false);
            }, 2000);
            return () => clearTimeout(accessKeyTimeout);
        }
    }, [accessKeyWasCopied]);

    useEffect(() => {
        if (secretAccessKeyWasCopied) {
            const secretKeyTimeout = setTimeout(() => {
                setSecretAccessKeyWasCopied(false);
            }, 2000);
            return () => clearTimeout(secretKeyTimeout);
        }
    }, [secretAccessKeyWasCopied]);

    useEffect(() => {
        if (createVolumeModalIsVisible) {
            inputNameRef.current.focus();
        }
    }, [createVolumeModalIsVisible, inputNameRef]);

    function fetchVolumes() {
        setVolumesAreLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/volume`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    if ('volumes' in data) {
                        setVolumes(data['volumes']);
                    }
                }
            })
            .catch(error => console.log(error))
            .finally(() => setVolumesAreLoading(false))
    }

    function createVolume() {
        setCreateVolumeModalIsVisible(false);
        setVolumeIsCreating(true);
        const payload = {
            bucket_name: newVolumeName
        };
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/volume`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 403) {
                    setInsufficientFunds(true);
                } else {
                    setVolumeFailedToCreate(true);
                }
            })
            .then(data => {
                if (data) {
                    if ('volume_id' in data) {
                        setVolumeCreated(true);
                        setNewVolumeDetails(data);
                    } else {
                        setVolumeFailedToCreate(true);
                    }
                } else {
                    setVolumeFailedToCreate(true);
                }
            })
            .catch(error => {
                console.log(error);
                setVolumeFailedToCreate(true);
            })
            .finally(() => setVolumeIsCreating(false));
    }

    function deleteVolume() {
        setDeleteVolumeModalIsVisible(false);
        setVolumeIsDeleting(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/volume/${volumeStagedForDeletion}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setVolumeFailedToDelete(true);
                }
            })
            .then(data => {
                if (data) {
                    if ('message' in data) {
                        if (data['message'] === 'Successfully deleted') {
                            setVolumeDeleted(true);
                        } else {
                            setVolumeFailedToDelete(true);
                        }
                    } else {
                        setVolumeFailedToDelete(true);
                    }
                } else {
                    setVolumeFailedToDelete(true);
                }
            })
            .catch(error => {
                setVolumeFailedToDelete(true);
                console.log(error);
            })
            .finally(() => setVolumeIsDeleting(false))
    }

    function downloadVolumeAccessCredentials() {
        const textToEmbed = `Access Key\n${newVolumeDetails['access_key_id']}\n\nSecret Access Key\n${newVolumeDetails['secret_access_key']}`;
        const blob = new Blob([textToEmbed], { type: 'text/plain' });
        const downloadUrl = URL.createObjectURL(blob);

        const accessCredentialsDownloadLink = document.createElement('a');
        accessCredentialsDownloadLink.href = downloadUrl;
        accessCredentialsDownloadLink.download = `${newVolumeDetails['bucket_name']}.txt`;
        document.body.appendChild(accessCredentialsDownloadLink);
        accessCredentialsDownloadLink.click();

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(accessCredentialsDownloadLink);
        }, 500);
    }

    const updateVolumeName = e => {
        const regex = /^[a-z0-9][a-z0-9-.]*$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            setNewVolumeName(e.target.value);
        }
    }

    const displayDeleteVolumeModal = (e, volumeId) => {
        e.preventDefault();
        e.stopPropagation();
        setVolumeStagedForDeletion(volumeId);
        setDeleteVolumeModalIsVisible(true);
    }

    const exitDeleteVolumeModal = () => {
        setVolumeStagedForDeletion('');
        setDeleteVolumeModalIsVisible(false);
    }

    const exitCreateVolumeModal = () => {
        setNewVolumeName('');
        setCreateVolumeModalIsVisible(false);
    }

    const exitFailedCreateVolumeModal = () => {
        setNewVolumeName('');
        setVolumeFailedToCreate(false);
    }

    const dismissSuccessfulCreationModal = () => {
        setVolumeCreated(false);
        setNewVolumeName('');
        fetchVolumes();
    }

    const dismissSuccessfulDeletionModal = () => {
        setVolumeDeleted(false);
        fetchVolumes();
    }

    const copyAccessKeyToClipboard = () => {
        navigator.clipboard.writeText(newVolumeDetails['access_key_id']);
        setAccessKeyWasCopied(true);
    }

    const copySecretAccessKeyToClipboard = () => {
        navigator.clipboard.writeText(newVolumeDetails['secret_access_key']);
        setSecretAccessKeyWasCopied(true);
    }

    return (
        <DirectContainer>
            <ContentContainer>
                <ContentTitleContainer>
                    <ContentTitle>
                        Your Detachable Volumes
                    </ContentTitle>
                    <GenerateKeyButton onClick={() => setCreateVolumeModalIsVisible(true)}>
                        Create new
                    </GenerateKeyButton>
                    <CalculatorIcon onClick={() => setCalculatorIsShown(true)}>
                        <FontAwesomeIcon icon={faCalculator} />
                    </CalculatorIcon>
                </ContentTitleContainer>
                <ContentDescriptionNoSpacing>
                    Your detachable volumes are listed below. You can upload files directly through the UI or mount
                    a volume as a filesystem on one of your VALDI VMs. Check the <DecoratedLink href="https://docs.valdi.ai/cli/reference/#mounting-a-volume" target="_blank">docs</DecoratedLink> for instructions.
                </ContentDescriptionNoSpacing>
            </ContentContainer>
            <MessageBox>
                <MessageTitle>
                    Pricing:
                </MessageTitle>
                <MessageContent>
                    $0.01/GB-month of data stored and $0.01/GB of data downloaded (egress)
                </MessageContent>
            </MessageBox>
            <ServerList>
                {volumesAreLoading ? (
                    <EmptyKeyPlaceholder>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </EmptyKeyPlaceholder>
                ) : (
                    volumes.length === 0 ? (
                        <EmptyKeyPlaceholder>
                            <LargeIcon>
                                <FontAwesomeIcon icon={faDatabase} />
                            </LargeIcon>
                            <NoKeysDescription>
                                You do not have any detachable volumes yet. Create one by clicking the button below.
                            </NoKeysDescription>
                            <LargeCenteredGenerateKeyButton onClick={() => setCreateVolumeModalIsVisible(true)}>
                                Create volume
                            </LargeCenteredGenerateKeyButton>
                        </EmptyKeyPlaceholder>
                    ) : (
                        volumes.map(volume => {
                            return (
                                <ServerListItem
                                    key={volume['volume_id']}
                                    to={
                                    {
                                        pathname: `/dashboard/storage/volume/${volume['volume_id']}`,
                                        state: {type: 'PRE'}
                                    }
                                }>
                                    <div>
                                        <KeyName>
                                            {volume['bucket_name']}
                                        </KeyName>
                                        <KeyDetails>
                                            Created on {parseTimestamp(volume['created_at']).replace(',', ' at')}
                                        </KeyDetails>
                                    </div>
                                    <RefreshIcon onClick={e => displayDeleteVolumeModal(e, volume['volume_id'])}>
                                        <FontAwesomeIcon icon={faTrashCan} />
                                    </RefreshIcon>
                                </ServerListItem>
                            )
                        })
                    )
                )}
            </ServerList>
            {createVolumeModalIsVisible && (
                <>
                    <ConfirmModalBackgroundCover onClick={exitCreateVolumeModal} />
                    <ConfirmModal maxWidth="400px">
                        <ModalTitle>
                            Create new volume
                        </ModalTitle>
                        <InputContainer>
                            <InputLabel htmlFor="volume-name-input">New volume name</InputLabel>
                            <LightBgTextInput id="volume-name-input"
                                              onChange={updateVolumeName}
                                              value={newVolumeName}
                                              ref={inputNameRef}
                            />
                            <FieldDisclaimer>Lowercase letters, numbers, hypens, and periods only</FieldDisclaimer>
                        </InputContainer>
                        <ConnectButtonWithDisabling disabled={newVolumeName === ''}
                                                    onClick={createVolume}
                        >
                            Create
                        </ConnectButtonWithDisabling>
                    </ConfirmModal>
                </>
            )}
            {deleteVolumeModalIsVisible && (
                <>
                    <ConfirmModalBackgroundCover onClick={exitDeleteVolumeModal} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Are you sure?
                        </ModalTitle>
                        <ModalText>
                            Please confirm that you wish to delete this volume. It must be empty.
                            <br /><br />
                            This action cannot be undone.
                        </ModalText>
                        <CancelButton onClick={exitDeleteVolumeModal}>Cancel</CancelButton>
                        <TerminateButton onClick={deleteVolume}>Delete volume</TerminateButton>
                    </ConfirmModal>
                </>
            )}
            {volumeIsDeleting && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </ModalTitle>
                    </ConfirmModal>
                </>
            )}
            {volumeDeleted && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setVolumeDeleted(false)} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Success
                        </ModalTitle>
                        <ModalText>
                            The volume has been successfully deleted.
                        </ModalText>
                        <ConnectButton onClick={dismissSuccessfulDeletionModal}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {volumeFailedToDelete && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setVolumeFailedToDelete(false)} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an issue with deleting the volume. Please ensure the volume is empty before
                            deleting it.
                        </ModalText>
                        <ConnectButton onClick={() => setVolumeFailedToDelete(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {volumeIsCreating && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </ModalTitle>
                    </ConfirmModal>
                </>
            )}
            {volumeCreated && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Success
                        </ModalTitle>
                        <ModalText>
                            Congratulations! Your volume was successfully created.
                            <br /><br />
                            Please download or copy the Access Key and Secret Access Key. You will need them
                            in order to mount the volume to a VM.
                            <CodeBlockTitle>Access Key</CodeBlockTitle>
                            <NarrowCodeBlock>
                                <WidthRestrictedCode>
                                    {newVolumeDetails['access_key_id']}
                                </WidthRestrictedCode>
                                {accessKeyWasCopied ? (
                                    <ClipboardIcon style={
                                        {
                                            marginTop: "2px"
                                        }
                                    }>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </ClipboardIcon>
                                ) : (
                                    <ClipboardIcon
                                        onClick={copyAccessKeyToClipboard}
                                        style={
                                            {
                                                marginTop: "2px"
                                            }
                                        }>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </ClipboardIcon>
                                )}
                            </NarrowCodeBlock>
                            <CodeBlockTitle>Secret Access Key</CodeBlockTitle>
                            <NarrowCodeBlock>
                                <WidthRestrictedCode>
                                    {newVolumeDetails['secret_access_key']}
                                </WidthRestrictedCode>
                                {secretAccessKeyWasCopied ? (
                                    <ClipboardIcon style={
                                        {
                                            marginTop: "11px"
                                        }
                                    }>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </ClipboardIcon>
                                ) : (
                                    <ClipboardIcon
                                        onClick={copySecretAccessKeyToClipboard}
                                        style={
                                        {
                                            marginTop: "11px"
                                        }
                                    }>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </ClipboardIcon>
                                )}
                            </NarrowCodeBlock>
                        </ModalText>
                        <ConnectButton onClick={downloadVolumeAccessCredentials}>
                            Download credentials <FontAwesomeIcon icon={faDownload} />
                        </ConnectButton>
                        <MarginedCancelButton onClick={dismissSuccessfulCreationModal}>
                            Done
                        </MarginedCancelButton>
                    </ConfirmModal>
                </>
            )}
            {volumeFailedToCreate && (
                <>
                    <ConfirmModalBackgroundCover onClick={exitFailedCreateVolumeModal} />
                    <ConfirmModal maxWidth="300px">
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an unexpected issue with creating the volume. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={exitFailedCreateVolumeModal}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {insufficientFunds && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Insufficient Balance
                        </ModalTitle>
                        <ModalText>
                            You need a balance of at least $10.00 USD to create a storage volume. Please deposit funds
                            and try again.
                        </ModalText>
                        <Link to="/dashboard/account"><ConnectButton>Deposit funds</ConnectButton></Link>
                    </ConfirmModal>
                </>
            )}
            {calculatorIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setCalculatorIsShown(false)} />
                    <CenteredContainer>
                        <StorageCalculator />
                    </CenteredContainer>
                </>
            )}
        </DirectContainer>
    );
}

export default PortalStorage;