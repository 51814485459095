import  styled from "styled-components";
import {
    ServerList,
    ServerListItemPlaceholder,
    Tooltip,
    ServerInfo,
    ListItemName,
    ListItemDescription,
    ServerTags,
    ServerPrice,
    QuantityTag,
    ConfirmModalBackgroundCover,
    ConfirmModal,
    ModalTitle,
    ModalText,
    ModalFinePrint,
    ConnectButton
} from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import {
    faCircleNotch,
    faCheck
} from "@fortawesome/pro-regular-svg-icons";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"

const ServerLocation = styled.div`
  padding: 5px 10px;
  width: 240px;
  background-color: #1a1c47;
  border-radius: 5px;
  color: #fff;
  font-size: 0.7rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 980px) {
    display: none;
  }
`;

const ServerLocationMobile = styled(ServerLocation)`
  display: none;
  width: 100px;
  @media screen and (max-width: 980px) {
    display: block;
  }
`;

const FormattedTooltip = styled(Tooltip)`
  width: 170px;
  & div:first-child {
    font-weight: 600;
    font-size: 0.7rem;
  }
`;

const LoadingListItem = styled.div`
  background-color: #292c6e;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
`;

const OnDemandPrice = styled(ServerPrice)`
  background: #1a1c47;
`;

const ReserveButton = styled(ServerPrice)`
  cursor: pointer;
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
  }
`;

const ReserveButtonLink = styled.a`
  padding: 5px 10px;
  width: 140px;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  border-radius: 5px;
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
  }
`;

const ReservedButton = styled(ReserveButton)`
  cursor: auto;
  background: #00bb00;
  &:hover {
    background: #00bb00;
  }
  color: #ffffff;
`;

const ReservedInventory = ({ token = null, triggerFetch = false }) => {
    const [ inventory, setInventory ] = useState([]);
    const [ inventoryIsLoaded, setInventoryIsLoaded ] = useState(false);
    const [ errorLoadingInventory, setErrorLoadingInventory ] = useState(false);
    const [ reservationModalIsShown, setReservationModalIsShown ] = useState(false);
    const [ configStagedForReservation, setConfigStagedForReservation ] = useState({});
    const [ reservationIsSubmitting, setReservationIsSubmitting ] = useState(false);
    const [ reservationSubmittedSuccessfully, setReservationSubmittedSuccessfully ] = useState(false);
    const [ errorSubmittingReservation, setErrorSubmittingReservation ] = useState(false);

    async function fetchInventory(signal) {
        setInventoryIsLoaded(false);
        try {
            const headers = {};
            if (token) {
                headers['Authorization'] = `Bearer ${token}`
            }

            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory`, {
                method: 'GET',
                headers: headers,
                signal: signal
            });

            if (response.ok) {
                const data = await response.json();
                setInventory(data['inventory']);
            } else {
                setErrorLoadingInventory(true);
            }
        } catch (error) {
            console.log(error);
            setErrorLoadingInventory(true);
        } finally {
            setInventoryIsLoaded(true);
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchInventory(controller.signal);

        return () => controller.abort();
    }, [triggerFetch]);

    const handleDisplayReservationModal = server => {
        setConfigStagedForReservation(server);
        setReservationModalIsShown(true);
    }

    const handleDismissReservationModal = () => {
        setReservationModalIsShown(false);
        setConfigStagedForReservation({});
    }

    async function handleReservationSubmit() {
        setReservationModalIsShown(false);
        setReservationIsSubmitting(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    configuration_id: configStagedForReservation['configuration_id']
                })
            });

            if (response.ok) {
                setReservationSubmittedSuccessfully(true);
            } else {
                setErrorSubmittingReservation(true);
            }
        } catch (error) {
            console.log(error);
            setErrorSubmittingReservation(true);
        } finally {
            setReservationIsSubmitting(false);
        }
    }

    const dismissSuccessfulReservationModal = () => {
        setConfigStagedForReservation({});
        setReservationSubmittedSuccessfully(false);
        fetchInventory();
    }

    const dismissErrorReservationModal = () => {
        setConfigStagedForReservation({});
        setErrorSubmittingReservation(false);
    }

    return (
        <>
            <ServerList>
                {inventoryIsLoaded ?
                    (
                        errorLoadingInventory ? (
                            <ServerListItemPlaceholder>Error loading inventory.</ServerListItemPlaceholder>
                        ) : (
                            inventory.length === 0 ? (
                                <ServerListItemPlaceholder>No reserved inventory available.</ServerListItemPlaceholder>
                            ) : (
                                inventory.map(server => {
                                    return (
                                        <ServerListItemPlaceholder key={server['configuration_id']}>
                                            <ServerInfo>
                                                <ListItemName>
                                                    {server['gpu_count']}x {server['gpu_type']}
                                                </ListItemName>
                                                <ListItemDescription>
                                                    {parseInt(server['vcpus']) === 1 ? (
                                                        <>
                                                            {server['cpu_type']} CPU | {server['ram']} GB RAM | {server['storage']} GB NVMe SSD
                                                        </>
                                                    ) : (
                                                        <>
                                                            {server['vcpus']} {server['cpu_type']} CPUs | {server['ram']} GB RAM | {server['storage']} GB NVMe SSD
                                                        </>
                                                    )}

                                                </ListItemDescription>
                                            </ServerInfo>
                                            <ServerTags>
                                                {server['hourly_rate'] && (
                                                    <OnDemandPrice>
                                                        ${parseFloat(server['hourly_rate']).toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})}/GPU-hr
                                                    </OnDemandPrice>
                                                )}
                                                <OnDemandPrice>
                                                    ${parseFloat(server['monthly_rate']).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})}/GPU-mo
                                                </OnDemandPrice>
                                                <QuantityTag>
                                                    {server['quantity']} available
                                                </QuantityTag>
                                                {server['user_requested'] ? (
                                                    <ReservedButton>
                                                        <FontAwesomeIcon icon={faCheck} style={{marginRight: "5px"}} />
                                                        Requested
                                                    </ReservedButton>
                                                ) : (
                                                    !token ? (
                                                        <ReserveButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLScW2hv9xtznmTnzKrql3r4o0MFJY9hiRvCbj2tSbfzra0E3hQ/viewform" target="_blank" rel="noreferrer">
                                                            Reserve now
                                                        </ReserveButtonLink>
                                                    ) : (
                                                        <ReserveButton onClick={() => handleDisplayReservationModal(server)}>
                                                            Reserve now
                                                        </ReserveButton>
                                                    )
                                                )}
                                            </ServerTags>
                                        </ServerListItemPlaceholder>
                                    )
                                })
                            )
                        )
                    ) : (
                        <SkeletonTheme baseColor="#1a1c47" highlightColor="#121331">
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                            <LoadingListItem>
                                <Skeleton count={2} />
                            </LoadingListItem>
                        </SkeletonTheme>
                    )
                }
            </ServerList>
            {reservationModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={handleDismissReservationModal} />
                    <ConfirmModal>
                        <ModalTitle>
                            Confirm your request
                        </ModalTitle>
                        <ModalText>
                            Please confirm that you are interested in reserving the {configStagedForReservation['gpu_type']}.
                            <br /><br />
                            <span>You will not be charged.</span>
                        </ModalText>
                        <ModalFinePrint>
                            A member of the VALDI team will reach out to you by email upon receipt.
                        </ModalFinePrint>
                        <ConnectButton onClick={handleReservationSubmit}>
                            Submit
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {reservationIsSubmitting && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </ConfirmModal>
                </>
            )}
            {reservationSubmittedSuccessfully && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Success
                        </ModalTitle>
                        <ModalText>
                            Your request for the {configStagedForReservation['gpu_type']} has been received.
                        </ModalText>
                        <ModalFinePrint>
                            A VALDI representative will reach out to you by email soon.
                        </ModalFinePrint>
                        <ConnectButton onClick={dismissSuccessfulReservationModal}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {errorSubmittingReservation && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            An unexpected error occurred when trying to submit your request. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={dismissErrorReservationModal}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
        </>
    );
}

export default ReservedInventory;