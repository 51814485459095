import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    SettingsSectionGroup,
    TitleText,
    SettingsSection,
    EntryGroup,
    EntryValue,
    TransactionsContainer,
    TransactionsLoadingContainer,
    TransactionsList,
    TransactionRow,
    NonInteractiveTransactionRow,
    PagingControls,
    DisabledPagingController,
    NarrowInputField,
    PagingController
} from "./PortalSettings";
import {
    SelectorContainer,
    SelectorButton,
    SelectorIcon,
    SelectorText
} from "./PortalDirectHome";
import {
    InputLabel,
    RegistrationDate,
    StatsContainer,
    StatBoxContainer,
    StatBox,
    StatTitle,
    StatValue
} from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleNotch,
    faAngleLeft,
    faAngleRight,
    faCheck,
    faUsers,
    faServer,
    faList,
    faHand,
    faMoneyBillTrendUp
} from "@fortawesome/pro-regular-svg-icons";
import PortalAdminRevenue from "./PortalAdminRevenue";
import PortalAdminInventory from "./PortalAdminInventory";
import PortalAdminRequests from "./PortalAdminRequests";
import { formatDateTime } from "./utilities";

const AdminContainer = styled.div`
  background-color: #1a1c47;
  width: 100%;
  height: 100%;
  padding: 0 40px 30px 50px;
  display: flex;
  max-width: 1300px;
  flex-direction: column;
  gap: 50px;
  align-items: flex-start;
  @media screen and (max-width: 400px) {
    padding: 0 25px 30px 25px;
  }
  @media screen and (max-width: 350px) {
    padding: 0 10px 30px 10px;
  }
`;

const LargerTitleText = styled(TitleText)`
  font-size: 1.4rem;
  font-weight: 500;
`;

const UserRow = styled(TransactionRow)`
  flex-wrap: wrap;
  height: auto;
  padding: 10px 0;
  gap: 10px;
`;

const UserIdentifier = styled.div`
  font-size: 1rem;
  overflow-wrap: anywhere;
`;

const UserInfoGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
`;

const UserStatusBadge = styled.div`
  font-size: 0.65rem;
  color: #000;
  background-color: #00cc00;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VmRunningIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #00cc00;
`;

const VmStoppedIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #cfcfcf;
`;

const PortalAdmin = ({ setSelectedView, token }) => {
    const [ numUsers, setNumUsers ] = useState(0);
    const [ numUsersIsLoading, setNumUsersIsLoading ] = useState(true);
    const [ numUsersLast24, setNumUsersLast24 ] = useState(0);
    const [ numUsersLast24IsLoading, setNumUsersLast24IsLoading ] = useState(true);
    const [ userPageIsLoading, setUserPageIsLoading ] = useState(true);
    const [ numUserPages, setNumUserPages ] = useState(0);
    const [ currentPageOfUsers, setCurrentPageOfUsers ] = useState([]);
    const [ targetUserPageNumber, setTargetUserPageNumber ] = useState('1');
    const [ currentTimestamp, setCurrentTimestamp ] = useState(new Date());
    const [ activeView, setActiveView ] = useState('users');
    const [ vmPageIsLoading, setVmPageIsLoading ] = useState(true);
    const [ currentPageOfVms, setCurrentPageOfVms ] = useState([]);
    const [ targetVmPageNumber, setTargetVmPageNumber ] = useState('1');
    const [ numVmPages, setNumVmPages ] = useState(0);
    const [ numVms, setNumVms ] = useState(0);
    const [ numVmsIsLoading, setNumVmsIsLoading ] = useState(true);

    useEffect(() => {
        setSelectedView('admin');
    }, [setSelectedView]);

    useEffect(() => {
        if (token && currentTimestamp) {
            if (activeView === 'users') {
                fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/users/count`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            setNumUsers(data['num_users']);
                            setNumUserPages(Math.ceil(data['num_users'] / 10));
                            setNumUsersIsLoading(false);
                        }
                    })
                    .catch(error => console.log(error));

                let date = new Date();
                date.setHours(date.getHours() - 24);
                const isoTimestamp = date.toISOString();

                fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/users/count?since=${isoTimestamp}&until=${currentTimestamp.toISOString()}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            setNumUsersLast24(data['num_users']);
                            setNumUsersLast24IsLoading(false);
                        }
                    })
                    .catch(error => console.log(error));
                fetchUsers();
            } else if (activeView === 'vms') {
                fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/vms/count?vm_status=running,stopped`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data && 'num_vms' in data) {
                            setNumVms(data['num_vms']);
                            setNumVmPages(Math.ceil(data['num_vms'] / 10));
                            setNumVmsIsLoading(false);
                        }
                    })
                    .catch(error => console.log(error));
                fetchVms();
            }
        }
    }, [token, currentTimestamp, activeView]);

    useEffect(() => {
        if (token && targetUserPageNumber) {
            fetchUsers(10, parseInt(targetUserPageNumber)-1);
        }
    }, [token, targetUserPageNumber, currentTimestamp]);

    useEffect(() => {
        if (token && targetVmPageNumber) {
            fetchVms(10, parseInt(targetVmPageNumber)-1);
        }
    }, [token, targetVmPageNumber, currentTimestamp]);

    function fetchUsers(pageSize = 10, pageNumber = 0) {
        setUserPageIsLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/users?page_size=${pageSize}&page=${pageNumber}&max_timestamp=${currentTimestamp.toISOString()}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && 'users' in data) {
                    setCurrentPageOfUsers(data['users']);
                    setUserPageIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function fetchVms(pageSize = 10, pageNumber = 0) {
        setVmPageIsLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/admin/vms?page_size=${pageSize}&page=${pageNumber}&max_timestamp=${currentTimestamp.toISOString()}&vm_status=running,stopped`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && 'vms' in data) {
                    setCurrentPageOfVms(data['vms']);
                    setVmPageIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function handleUserPageNumberChange(e) {
        if (!e.target.value) {
            setTargetUserPageNumber(e.target.value);
        } else {
            if (e.target.value >= 1 && e.target.value <= numUserPages) {
                setTargetUserPageNumber(e.target.value);
            }
        }
    }

    function handleUserPageIncrement(direction) {
        if (direction === 'up') {
            if (parseInt(targetUserPageNumber) < numUserPages) {
                setTargetUserPageNumber(prev => String(parseInt(prev) + 1));
            }
        } else if (direction === 'down') {
            if (parseInt(targetUserPageNumber) > 1) {
                setTargetUserPageNumber(prev => String(parseInt(prev) - 1));
            }
        }
    }

    function handleVmPageIncrement(direction) {
        if (direction === 'up') {
            if (parseInt(targetVmPageNumber) < numVmPages) {
                setTargetVmPageNumber(prev => String(parseInt(prev) + 1));
            }
        } else if (direction === 'down') {
            if (parseInt(targetVmPageNumber) > 1) {
                setTargetVmPageNumber(prev => String(parseInt(prev) -1));
            }
        }
    }

    function handleVmPageNumberChange(e) {
        if (!e.target.value) {
            setTargetVmPageNumber(e.target.value);
        } else {
            if (e.target.value >= 1 && e.target.value <= numVmPages) {
                setTargetVmPageNumber(e.target.value);
            }
        }
    }

    function prettifyGpuName(gpuName) {
        if (gpuName !== null && typeof gpuName !== 'undefined') {
            const replacements = {
                "quadro": "Quadro ",
                "geforce": "GeForce ",
                "a": "A",
                "l": "L",
                "rtx": "RTX ",
                "super": "Super",
                "gtx": "GTX ",
                "lhr": " LHR",
                "-pcie-": " ",
                "ti": " Ti",
                "gb": "GB"
            }

            let prettyGpu = gpuName;
            for (let [oldStr, newStr] of Object.entries(replacements)) {
                prettyGpu = prettyGpu.replace(new RegExp(oldStr, 'g'), newStr);
            }
            return prettyGpu;
        } else {
            return null;
        }
    }

    return (
        <AdminContainer>
            <SelectorContainer>
                <SelectorButton
                    outline={activeView === 'users'}
                    onClick={() => setActiveView('users')}
                >
                    <SelectorIcon>
                        <FontAwesomeIcon icon={faUsers} />
                    </SelectorIcon>
                    <SelectorText>
                        Users
                    </SelectorText>
                </SelectorButton>
                <SelectorButton
                    outline={activeView === 'vms'}
                    onClick={() => setActiveView('vms')}
                >
                    <SelectorIcon>
                        <FontAwesomeIcon icon={faServer} />
                    </SelectorIcon>
                    <SelectorText>
                        Virtual Machines
                    </SelectorText>
                </SelectorButton>
                <SelectorButton
                    outline={activeView === 'revenue'}
                    onClick={() => setActiveView('revenue')}
                >
                    <SelectorIcon>
                        <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                    </SelectorIcon>
                    <SelectorText>
                        Revenues
                    </SelectorText>
                </SelectorButton>
                <SelectorButton
                    outline={activeView === 'inventory'}
                    onClick={() => setActiveView('inventory')}
                >
                    <SelectorIcon>
                        <FontAwesomeIcon icon={faList} />
                    </SelectorIcon>
                    <SelectorText>
                        Inventory
                    </SelectorText>
                </SelectorButton>
                <SelectorButton
                    outline={activeView === 'requests'}
                    onClick={() => setActiveView('requests')}
                >
                    <SelectorIcon>
                        <FontAwesomeIcon icon={faHand} />
                    </SelectorIcon>
                    <SelectorText>
                        Server Requests
                    </SelectorText>
                </SelectorButton>
            </SelectorContainer>
            {activeView === 'users' && (
                <>
                    <StatsContainer>
                        <SettingsSectionGroup>
                            <LargerTitleText>
                                User Statistics
                            </LargerTitleText>
                            <StatBoxContainer>
                                <StatBox>
                                    <StatTitle>
                                        New users (last 24 hrs.)
                                    </StatTitle>
                                    {numUsersLast24IsLoading ? (
                                        <StatValue>
                                            -
                                        </StatValue>
                                    ) : (
                                        <StatValue>
                                            {numUsersLast24}
                                        </StatValue>
                                    )}
                                </StatBox>
                                <StatBox>
                                    <StatTitle>
                                        Total users (all time)
                                    </StatTitle>
                                    {numUsersIsLoading ? (
                                        <StatValue>
                                            -
                                        </StatValue>
                                    ) : (
                                        <StatValue>
                                            {numUsers}
                                        </StatValue>
                                    )}
                                </StatBox>
                            </StatBoxContainer>
                        </SettingsSectionGroup>
                    </StatsContainer>
                    <SettingsSectionGroup>
                        <LargerTitleText>
                            Users
                        </LargerTitleText>
                        <SettingsSection>
                            <EntryGroup>
                                <EntryValue>
                                    <TransactionsContainer>
                                        {userPageIsLoading ? (
                                            <TransactionsLoadingContainer>
                                                <FontAwesomeIcon icon={faCircleNotch} spin />
                                            </TransactionsLoadingContainer>
                                        ) : (
                                            numUserPages === 0 ? (
                                                <TransactionsList>
                                                    <NonInteractiveTransactionRow>
                                                        No users.
                                                    </NonInteractiveTransactionRow>
                                                </TransactionsList>
                                            ) : (
                                                <TransactionsList>
                                                    {currentPageOfUsers.map(user => {
                                                        return (
                                                            <Link to={`/dashboard/admin/user/${encodeURIComponent(user['email'])}`} key={user['username']}>
                                                                <UserRow>
                                                                    <UserInfoGroup>
                                                                        <UserIdentifier>
                                                                            {user['email']}
                                                                        </UserIdentifier>
                                                                        {user['is_admin_approved'] && <UserStatusBadge><FontAwesomeIcon icon={faCheck} /></UserStatusBadge>}
                                                                    </UserInfoGroup>
                                                                    <RegistrationDate>
                                                                        Joined on {formatDateTime(user['registration_date'])}
                                                                    </RegistrationDate>
                                                                </UserRow>
                                                            </Link>
                                                        )
                                                    })}
                                                </TransactionsList>
                                            )
                                        )}
                                        <PagingControls>
                                            {targetUserPageNumber === '1' || !targetUserPageNumber ? (
                                                <DisabledPagingController>
                                                    <FontAwesomeIcon icon={faAngleLeft} />
                                                </DisabledPagingController>
                                            ) : (
                                                <PagingController onClick={() => handleUserPageIncrement('down')}>
                                                    <FontAwesomeIcon icon={faAngleLeft} />
                                                </PagingController>
                                            )}
                                            <NarrowInputField id="page-input"
                                                              type="number"
                                                              customWidth="40px"
                                                              value={targetUserPageNumber}
                                                              min="1"
                                                              disabled={numUserPages === 0}
                                                              onChange={handleUserPageNumberChange}
                                            />
                                            <InputLabel htmlFor="page-input">
                                                of {numUserPages === 0 ? '1' : numUserPages}
                                            </InputLabel>
                                            {(parseInt(targetUserPageNumber) === numUserPages || numUserPages === 0) ? (
                                                <DisabledPagingController>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </DisabledPagingController>
                                            ) : (
                                                <PagingController onClick={() => handleUserPageIncrement('up')}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </PagingController>
                                            )}
                                        </PagingControls>
                                    </TransactionsContainer>
                                </EntryValue>
                            </EntryGroup>
                        </SettingsSection>
                    </SettingsSectionGroup>
                </>
            )}
            {activeView === 'vms' && (
                <>
                    <StatsContainer>
                        <SettingsSectionGroup>
                            <LargerTitleText>
                                Global VM Statistics
                            </LargerTitleText>
                            <StatBoxContainer>
                                <StatBox>
                                    <StatTitle>
                                        Running VMs
                                    </StatTitle>
                                    {numVmsIsLoading ? (
                                        <StatValue>
                                            -
                                        </StatValue>
                                    ) : (
                                        <StatValue>
                                            {numVms}
                                        </StatValue>
                                    )}
                                </StatBox>
                            </StatBoxContainer>
                        </SettingsSectionGroup>
                    </StatsContainer>
                    <SettingsSectionGroup>
                        <LargerTitleText>
                            Virtual Machines
                        </LargerTitleText>
                        <SettingsSection>
                            <EntryGroup>
                                <EntryValue>
                                    <TransactionsContainer>
                                        {vmPageIsLoading ? (
                                            <TransactionsLoadingContainer>
                                                <FontAwesomeIcon icon={faCircleNotch} spin />
                                            </TransactionsLoadingContainer>
                                        ) : (
                                            numVmPages === 0 ? (
                                                <TransactionsList>
                                                    <NonInteractiveTransactionRow>
                                                        No virtual machines.
                                                    </NonInteractiveTransactionRow>
                                                </TransactionsList>
                                            ) : (
                                                <TransactionsList>
                                                    {currentPageOfVms.map(vm => {
                                                        return (
                                                            <Link to={
                                                                {
                                                                    pathname: `/dashboard/admin/vm/instance/${vm['server']}`,
                                                                    state: {userEmail: vm['email']}
                                                                }}
                                                                  key={vm['server']}
                                                            >
                                                                <UserRow>
                                                                    <UserInfoGroup>
                                                                        <UserIdentifier>
                                                                            {`${vm['gpu_count']}x ${prettifyGpuName(vm['gpu_model'])}`}
                                                                        </UserIdentifier>
                                                                        {vm['status'] === 'running' && <VmRunningIndicator />}
                                                                        {vm['status'] === 'stopped' && <VmStoppedIndicator />}
                                                                    </UserInfoGroup>
                                                                    <RegistrationDate>
                                                                        {vm['email']}
                                                                    </RegistrationDate>
                                                                </UserRow>
                                                            </Link>
                                                        )
                                                    })}
                                                </TransactionsList>
                                            )
                                        )}
                                        <PagingControls>
                                            {targetVmPageNumber === '1' || !targetUserPageNumber ? (
                                                <DisabledPagingController>
                                                    <FontAwesomeIcon icon={faAngleLeft} />
                                                </DisabledPagingController>
                                            ) : (
                                                <PagingController onClick={() => handleVmPageIncrement('down')}>
                                                    <FontAwesomeIcon icon={faAngleLeft} />
                                                </PagingController>
                                            )}
                                            <NarrowInputField id="vm-page-input"
                                                              type="number"
                                                              customWidth="40px"
                                                              value={targetVmPageNumber}
                                                              min="1"
                                                              disabled={numVmPages === 0}
                                                              onChange={handleVmPageNumberChange}
                                            />
                                            <InputLabel htmlFor="vm-page-input">
                                                of {numVmPages === 0 ? '1' : numVmPages}
                                            </InputLabel>
                                            {(parseInt(targetVmPageNumber) === numVmPages || numVmPages === 0) ? (
                                                <DisabledPagingController>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </DisabledPagingController>
                                            ) : (
                                                <PagingController onClick={() => handleVmPageIncrement('up')}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </PagingController>
                                            )}
                                        </PagingControls>
                                    </TransactionsContainer>
                                </EntryValue>
                            </EntryGroup>
                        </SettingsSection>
                    </SettingsSectionGroup>
                </>
            )}
            {activeView === 'revenue' && (
                <PortalAdminRevenue token={token} />
            )}
            {activeView === 'inventory' && (
                <PortalAdminInventory token={token} />
            )}
            {activeView === 'requests' && (
                <PortalAdminRequests token={token} />
            )}
        </AdminContainer>
    );
}

export default PortalAdmin;