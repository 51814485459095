import { Link } from "react-router-dom";
import styled from "styled-components";
import BWSLogo from "./assets/logo-valdi-storj.png";
import LogoNoText from "./assets/image-bws-logo-no-text.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewBadge } from "./SharedStyles";
import {
    faHouse,
    faSignOutAlt,
    faDatabase,
    faBook,
    faUser,
    faHandshake,
    faScrewdriverWrench,
    faArrowUpRightFromSquare,
    faComputer,
    faKey,
    faCreditCard
} from "@fortawesome/pro-light-svg-icons";

const MenuContainer = styled.div`
  background-color: #121331;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: fixed;
  width: 254px;
  margin-top: ${props => `${props.topMarginOffset}px`};
  @media screen and (max-width: 1355px) {
    width: 110px;
    align-items: center;
  }
  @media screen and (max-width: 750px) {
    width: 100%;
    z-index: 30;
    align-items: flex-start;
    display: ${params => params.mobileMenuIsOpen};
  }
`;

const Logo = styled.div`
  width: 120px;
  margin: 28px 10px 10px 10px;
  & img {
    width: 100%;
  }
`;

const LogoSmall = styled.div`
  width: 35px;
  margin: -44px 10px 10px 10px;
  & img {
    width: 100%;
  }
`;

const LinkWrapper = styled.div`
  @media screen and (max-width: 1355px) {
    visibility: hidden;  
  }
`;

const LinkWrapperSmall = styled.div`
  visibility: hidden;
  @media screen and (max-width: 1355px) and (min-width: 750px) {  
    visibility: visible;  
  }
`;

const Menu = styled.div`
  margin-top: ${props => `${65 + props.topMarginOffset}px`};
  @media screen and (max-width: 750px) {
    width: 100%;
    margin-top: 0;
  }
`;

const MobileOnlyMenuItems = styled.div`
  display: none;
  @media screen and (max-width: 750px) {
    display: initial;
  }
`;

const MenuItem = styled.div`
  position: relative;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 10px;
  padding: 15px 25px 15px 25px;
  margin: 4px 0;
  background-color: ${props => props.isActive && '#1a1c47'};
  &:hover {
    cursor: pointer;
    background-color: rgba(26, 28, 71, 0.6);
  }
  @media screen and (max-width: 1355px) and (min-width: 750px) {
    &:hover::after {
      content: attr(value);
      position: absolute;
      border-radius: 5px;
      font-size: 0.9rem;
      font-weight: 300;
      left: 108%;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fff;
      //background-color: #292c6e;
      padding: 10px;
      color: #000;
      white-space: nowrap;
    }
  }
  
  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

const MenuItemText = styled.div`
  display: inline;
  margin-left: 10px;
  @media screen and (max-width: 1355px) and (min-width: 750px) {
    display: none;
  }
`;

const OutsideLinkIcon = styled.div`
  display: inline;
  opacity: 0.5;
  font-size: 0.85rem;
  margin-left: 10px;
  margin-top: 2px;
  @media screen and (max-width: 1355px) and (min-width: 750px) {
    display: none;
  }
`;

const PositionedNewBadge = styled(NewBadge)`
  display: inline;
  position: absolute;
  right: 53px;
  top: 15px;
  @media screen and (max-width: 1355px) and (min-width: 750px) {
    display: none;
  }
  @media (max-width: 750px) {
    position: static;
    margin-left: 10px;
  }
`;

const VersionTag = styled.div`
  position: absolute;
  opacity: 0.8;
  font-size: 0.7rem;
  bottom: ${props => `${props.topMarginOffset + 30}px`};
  left: 50%;
  transform: translateX(-50%);
`;


const PortalMenu = ({ selectedView, mobileMenuIsOpen, showLogoutModal, bannerHeight, isAdmin }) => {
    return (
        <MenuContainer mobileMenuIsOpen={mobileMenuIsOpen ? "flex" : "none"} topMarginOffset={bannerHeight}>
            <LinkWrapper>
                <Link to="/">
                    <Logo>
                        <img src={BWSLogo} alt="" />
                    </Logo>
                </Link>
            </LinkWrapper>
            <LinkWrapperSmall>
                <Link to="/">
                    <LogoSmall>
                        <img src={LogoNoText} alt="" />
                    </LogoSmall>
                </Link>
            </LinkWrapperSmall>
            <Menu topMarginOffset={bannerHeight}>
                <Link to="/dashboard/home">
                    <MenuItem isActive={selectedView === 'home'} value="Home">
                        <FontAwesomeIcon icon={faHouse} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            Home
                        </MenuItemText>
                    </MenuItem>
                </Link>
                <Link to="/dashboard/direct">
                    <MenuItem isActive={selectedView === 'direct' || selectedView === 'server' || selectedView === 'vm' || selectedView === 'server-session' || selectedView === 'vm-session'} value="Cloud GPUs">
                        <FontAwesomeIcon icon={faComputer} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            Cloud GPUs
                        </MenuItemText>
                    </MenuItem>
                </Link>
                <Link to="/dashboard/keys">
                    <MenuItem isActive={selectedView === 'keys'} value="SSH Keys">
                        <FontAwesomeIcon icon={faKey} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            SSH Keys
                        </MenuItemText>
                    </MenuItem>
                </Link>
                <Link to="/dashboard/storage">
                    <MenuItem isActive={selectedView === 'storage'} value="Storage">
                        <FontAwesomeIcon icon={faDatabase} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            Storage
                        </MenuItemText>
                        <PositionedNewBadge>NEW</PositionedNewBadge>
                    </MenuItem>
                </Link>
                <a href="https://docs.valdi.ai" target="_blank" rel="noreferrer">
                    <MenuItem isActive={selectedView === 'docs'} value="Docs">
                        <FontAwesomeIcon icon={faBook} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            Docs
                        </MenuItemText>
                        <OutsideLinkIcon>
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </OutsideLinkIcon>
                    </MenuItem>
                </a>
                <Link to="/dashboard/referrals">
                    <MenuItem isActive={selectedView === 'referrals'} value="Referrals">
                        <FontAwesomeIcon icon={faHandshake} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            Referrals
                        </MenuItemText>
                    </MenuItem>
                </Link>
                <Link to="/dashboard/account">
                    <MenuItem isActive={selectedView === 'account' || selectedView === 'payment-method' || selectedView === 'pm-confirm'} value="Account">
                        <FontAwesomeIcon icon={faUser} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            Account
                        </MenuItemText>
                    </MenuItem>
                </Link>
                <Link to="/dashboard/billing">
                    <MenuItem isActive={selectedView === 'billing'} value="Billing">
                        <FontAwesomeIcon icon={faCreditCard} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            Billing
                        </MenuItemText>
                    </MenuItem>
                </Link>
                {isAdmin && (
                    <Link to="/dashboard/admin">
                        <MenuItem isActive={selectedView === 'admin' || selectedView === 'admin-customer' || selectedView === 'admin-vm-session'} value="Admin">
                            <FontAwesomeIcon icon={faScrewdriverWrench} color="#fff" style={{width: "20px"}} />
                            <MenuItemText>
                                Admin
                            </MenuItemText>
                        </MenuItem>
                    </Link>
                )}
                <MobileOnlyMenuItems>
                    <MenuItem value="Log Out" onClick={() => showLogoutModal(true)}>
                        <FontAwesomeIcon icon={faSignOutAlt} color="#fff" style={{width: "20px"}} />
                        <MenuItemText>
                            Log Out
                        </MenuItemText>
                    </MenuItem>
                </MobileOnlyMenuItems>
            </Menu>
            <VersionTag topMarginOffset={bannerHeight}>
                {process.env.REACT_APP_VERSION_TAG.slice(0, 8)}
            </VersionTag>
        </MenuContainer>
    );
}

export default PortalMenu;