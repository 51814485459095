import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
    DetailContainer,
    DetailPanel,
    DetailInfo,
    InfoGroup,
    InfoName,
    InfoData,
    BackButton,
    ConfirmModal,
    ModalTitle,
    ModalText,
    ModalFinePrint,
    CodeBlock,
    ClipboardIcon,
    ConfirmModalBackgroundCover,
    ConnectButton,
    InputSuperGroup,
    InputField,
    InputGroupHorizontal,
    InputLabel,
    SubSectionTitle,
    SectionDivider,
    DropDownListAlt,
    PricingBlock,
    TotalPrice,
    ModalTotalPrice,
    ConnectButtonWithDisabling,
    LaunchButton,
    InputFieldWithFeedback,
    InputFeedbackText,
    InputQualifier,
    PricingRow,
    PricingCategoryTitle,
    PricingCalc,
    SubSectionDivider,
    NetworkingContainer,
    PortSelectorRowSuper,
    PortSelectorRow,
    DropDownListAltNoMargin,
    InputFieldNoMargin,
    RowDeleteButton,
    PortSelectorGroup,
    PortSelectorInputLabel,
    AddSelectorRowButton,
    AddSelectorIcon,
    AddSelectorText
} from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faCheck, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft, faCopy, faXmark } from "@fortawesome/pro-light-svg-icons";
import { generateRandomString, checkInputValidity, checkInputInRange, insertFlagEmoji } from "./utilities";

const BoldCodeBlockTitle = styled.span`
  font-weight: 700;
`;

const PortalDirectTensorDockServer = ({ token, serverInfo = {}, providerId }) => {
    const [ vmName, setVmName ] = useState('');
    const [ nameLength, setNameLength ] = useState(0);
    const [ numGpus, setNumGpus ] = useState('');
    const [ numCpus, setNumCpus ] = useState('');
    const [ amtRam, setAmtRam ] = useState('');
    const [ amtStorage, setAmtStorage ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ cpusOutOfRange, setCpusOutOfRange ] = useState(false);
    const [ gpusOutOfRange, setGpusOutOfRange ] = useState(false);
    const [ ramOutOfRange, setRamOutOfRange ] = useState(false);
    const [ storageOutOfRange, setStorageOutOfRange ] = useState(false);
    const [ confirmModalIsShown, setConfirmModalIsShown ] = useState(false);
    const [ vmIsProvisioning, setVmIsProvisioning ] = useState(false);
    const [ provisioningSucceeded, setProvisioningSucceeded ] = useState(false);
    const [ provisioningFailed, setProvisioningFailed ] = useState(false);
    const [ insufficientFunds, setInsufficientFunds ] = useState(false);
    const [ ipAddress, setIpAddress ] = useState('');
    const [ codeWasCopied, setCodeWasCopied ] = useState(false);
    const [ totalCost, setTotalCost ] = useState(0.0);
    const [ os, setOs ] = useState('Ubuntu 20.04 LTS');
    const [ minCpus, setMinCpus ] = useState(0);
    const [ minRam, setMinRam ] = useState(0);
    const [ minStorage, setMinStorage ] = useState(0);
    const [ availablePorts, setAvailablePorts ] = useState([]);
    const [ externalPortsList, setExternalPortsList ] = useState([]);
    const [ internalPortsList, setInternalPortsList ] = useState([]);
    const [ maxCpus, setMaxCpus ] = useState(100);
    const [ maxRam, setMaxRam ] = useState(100);
    const [ maxStorage, setMaxStorage ] = useState(100);
    const [ possibleGpuCounts, setPossibleGpuCounts ] = useState([]);

    const maxNameLength = 50;
    const nameInputRef = useRef();

    useEffect(() => {
        if (Object.keys(serverInfo).length !== 0) {
            const gpuRestrictions = Object.keys(serverInfo['restrictions']);
            setPossibleGpuCounts(gpuRestrictions);
            setAvailablePorts(serverInfo['available_port']);
            setExternalPortsList([serverInfo['available_port'][0]]);
            setInternalPortsList(
                [22].concat(new Array(serverInfo['available_port'].length-1).fill(1))
            );
            setNumGpus(gpuRestrictions[0]);
            setMinCpus(serverInfo['restrictions'][gpuRestrictions[0]]['cpu']['min']);
            setNumCpus(serverInfo['restrictions'][gpuRestrictions[0]]['cpu']['min']);
            setMinRam(serverInfo['restrictions'][gpuRestrictions[0]]['ram']['min']);
            setAmtRam(serverInfo['restrictions'][gpuRestrictions[0]]['ram']['min']);
            setMinStorage(serverInfo['restrictions'][gpuRestrictions[0]]['storage']['min']);
            setAmtStorage(serverInfo['restrictions'][gpuRestrictions[0]]['storage']['min']);
            setMaxCpus(serverInfo['restrictions'][gpuRestrictions[0]]['cpu']['max']);
            setMaxRam(serverInfo['restrictions'][gpuRestrictions[0]]['ram']['max']);
            setMaxStorage(serverInfo['restrictions'][gpuRestrictions[0]]['storage']['max']);
        }
    }, []);

    useEffect(() => {
        if (nameInputRef) {
            nameInputRef.current.focus();
        }
    }, [nameInputRef]);

    useEffect(() => {
        if (codeWasCopied) {
            const copyReset = setTimeout(() => {
                setCodeWasCopied(false);
            }, 2000);
            return () => clearTimeout(copyReset);
        }
    }, [codeWasCopied]);

    useEffect(() => {
        if (Object.keys(serverInfo).length !== 0) {
            setTotalCost(
                numCpus*serverInfo['cpu_cost'] +
                numGpus*parseFloat(serverInfo['gpu_cost']) +
                amtRam*serverInfo['ram_cost'] +
                amtStorage*serverInfo['storage_cost']
            );
        }
    }, [serverInfo, numCpus, numGpus, amtStorage, amtRam]);

    useEffect(() => {
        if (checkInputInRange(numCpus, minCpus, maxCpus)) {
            setCpusOutOfRange(false);
        } else {
            setCpusOutOfRange(true);
        }
        if (checkInputInRange(amtRam, minRam, maxRam)) {
            setRamOutOfRange(false);
        } else {
            setRamOutOfRange(true);
        }
        if (checkInputInRange(amtStorage, minStorage, maxStorage)) {
            setStorageOutOfRange(false);
        } else {
            setStorageOutOfRange(true);
        }
    }, [maxCpus, maxRam, maxStorage]);

    function handleCpuEntry(e) {
        if (checkInputValidity(e.target.value)) {
            setNumCpus(e.target.value);
            if (checkInputInRange(e.target.value, minCpus, maxCpus)) {
                setCpusOutOfRange(false);
            } else {
                setCpusOutOfRange(true);
            }
            if (e.target.value % 2 !== 0) {
                setCpusOutOfRange(true);
            }
        }
    }

    function handleRamEntry(e) {
        if (checkInputValidity(e.target.value)) {
            setAmtRam(e.target.value);
            if (checkInputInRange(e.target.value, minRam, maxRam)) {
                setRamOutOfRange(false);
            } else {
                setRamOutOfRange(true);
            }
        }
    }

    function handleGpuEntry(e) {
        if (checkInputValidity(e.target.value)) {
            setNumGpus(e.target.value);
            if (checkInputInRange(e.target.value, 1, serverInfo['gpu_count'])) {
                setGpusOutOfRange(false);

                const newMinCpus = serverInfo['restrictions'][String(e.target.value)]['cpu']['min'];
                const newMaxCpus = serverInfo['restrictions'][String(e.target.value)]['cpu']['max'];
                const newMinRam = serverInfo['restrictions'][String(e.target.value)]['ram']['min'];
                const newMaxRam = serverInfo['restrictions'][String(e.target.value)]['ram']['max'];
                const newMinStorage = serverInfo['restrictions'][String(e.target.value)]['storage']['min'];
                const newMaxStorage = serverInfo['restrictions'][String(e.target.value)]['storage']['max'];

                setMinCpus(newMinCpus);
                setMinRam(newMinRam);
                setMinStorage(newMinStorage);
                setMaxCpus(newMaxCpus);
                setMaxRam(newMaxRam);
                setMaxStorage(newMaxStorage);

                if (numCpus < newMinCpus) {
                    setNumCpus(newMinCpus);
                }
                if (amtRam < newMinRam) {
                    setAmtRam(newMinRam);
                }
                if (amtStorage < newMinStorage) {
                    setAmtStorage(newMinStorage);
                }
            } else {
                setGpusOutOfRange(true);
            }
        }
    }

    function handleStorageEntry(e) {
        if (checkInputValidity(e.target.value)) {
            setAmtStorage(e.target.value);
            if (checkInputInRange(e.target.value, minStorage, maxStorage)) {
                setStorageOutOfRange(false);
            } else {
                setStorageOutOfRange(true);
            }
        }
    }

    const copyToClipboard = () => {
        let connectionDetails = '';
        if (os !== 'Windows 10') {
            connectionDetails = `ssh -p ${externalPortsList[0]} ${process.env.REACT_APP_VM_USER}@${ipAddress}\n${password}`;
        } else if (os === 'Windows 10') {
            connectionDetails = `IP Address: ${ipAddress}\n
            Port: ${externalPortsList[0]}\n
            Username: ${process.env.REACT_APP_VM_USER}\n
            Password: ${password}`;
        }
        navigator.clipboard.writeText(connectionDetails);
        setCodeWasCopied(true);
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        setConfirmModalIsShown(false);
        setVmIsProvisioning(true);
        const password = generateRandomString(12, false);
        const nameCode = generateRandomString(6, false);
        setPassword(password);

        let internal_ports = `{${internalPortsList.slice(0, externalPortsList.length).join(', ')}}`;
        let external_ports = `{${externalPortsList.join(', ')}}`;

        const payload = {
            name: `valdi-customer-${nameCode}`,
            provider_id: providerId,
            details: {
                gpu_model: serverInfo['gpu_code'],
                operating_system: os,
                internal_ports: internal_ports,
                external_ports: external_ports,
                gpu_count: parseInt(numGpus),
                hostnode: serverInfo['hostnode'],
                storage: parseInt(amtStorage),
                vcpus: parseInt(numCpus),
                ram: parseInt(amtRam),
                password: password
            }
        };

        if (vmName !== '') {
            payload['user_provided_name'] = vmName;
        }

        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/provision`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (response['status'] === 403) {
                    return response.json();
                }
                else if (response['status'] > 299) {
                    setProvisioningFailed(true);
                    setVmIsProvisioning(false);
                } else {
                    return response.json();
                }
            })
            .then(data => {
                if (data && data['server']) {
                    setIpAddress(data['ip']);
                    setProvisioningSucceeded(true);
                    setVmIsProvisioning(false);
                } else if (data && data['detail'] === 'Insufficient user balance') {
                    setInsufficientFunds(true);
                    setVmIsProvisioning(false);
                } else {
                    setProvisioningFailed(true);
                    setVmIsProvisioning(false);
                }
            })
            .catch(error => {
                console.log(error);
                setProvisioningFailed(true);
                setVmIsProvisioning(false);
            })
    }

    const handleDropDownListSelection = e => {
        if (e.target.value === 'Windows 10') {
            setExternalPortsList([availablePorts[0]]);
            setInternalPortsList([3389].concat(new Array(availablePorts.length-1).fill(1)));
        } else if (e.target.value === 'TensorML 20 PyTorch' || e.target.value === 'TensorML 20 TensorFlow') {
            setExternalPortsList([availablePorts[0], availablePorts[1]]);
            setInternalPortsList([22, 8888].concat(new Array(availablePorts.length-2).fill(1)));
        } else if (e.target.value === 'TensorML 20 Everything') {
            setExternalPortsList([availablePorts[0], availablePorts[1]]);
            setInternalPortsList([22, 8888].concat(new Array(availablePorts.length-2).fill(1)));
        } else {
            setExternalPortsList([availablePorts[0]]);
            setInternalPortsList([22].concat(new Array(availablePorts.length-1).fill(1)));
        }
        setOs(e.target.value);
    }

    const handleExternalPortSelection = (e, i) => {
        setExternalPortsList(prev => {
            const newState = [...prev];
            newState[i] = parseInt(e.target.value);
            return newState;
        });
    }

    const handleInternalPortSelection = (e, i) => {
        if (e.target.value === '' || (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 65535)) {
            if (e.target.value === '') {
                setInternalPortsList(prev => {
                    const newState = [...prev];
                    newState[i] = e.target.value;
                    return newState;
                });
            } else {
                setInternalPortsList(prev => {
                    const newState = [...prev];
                    newState[i] = parseInt(e.target.value);
                    return newState;
                });
            }
        }
    }

    const addPortSelectorRow = () => {
        const unusedAvailablePorts = availablePorts.filter(port => !externalPortsList.includes(port));
        setExternalPortsList(prev => [...prev, unusedAvailablePorts[0]]);
    }

    const removePortSelectorRow = i => {
        setInternalPortsList(prev => {
            let newState = [...prev.slice(0, i), ...prev.slice(i + 1)];
            newState.push(1);
            return newState;
        });
        setExternalPortsList(prev => [...prev.slice(0, i), ...prev.slice(i + 1)]);
    }

    const handleVmNameEntry = e => {
        const enteredText = e.target.value;
        const currentLength = enteredText.length;
        if (currentLength <= maxNameLength) {
            setNameLength(currentLength);
            setVmName(enteredText);
        }
    }

    return (
        Object.keys(serverInfo).length === 0 ? (
            <></>
        ) : (
            <DetailContainer>
                <DetailPanel>
                    <Link to="/dashboard/direct?active=vms">
                        <BackButton>
                            <FontAwesomeIcon icon={faAngleLeft} />
                            <span>Back</span>
                        </BackButton>
                    </Link>
                    <SubSectionTitle>
                        Device Details
                    </SubSectionTitle>
                    <DetailInfo>
                        <InfoGroup>
                            <InfoName>
                                GPU type
                            </InfoName>
                            <InfoData>
                                NVIDIA {serverInfo['gpu_type']}
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                CPU model
                            </InfoName>
                            <InfoData>
                                {serverInfo['cpu_type']}
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                Location
                            </InfoName>
                            <InfoData>
                                {serverInfo['location'].substring(serverInfo['location'].lastIndexOf(',') + 2)}
                                {insertFlagEmoji(serverInfo['location'])}
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                Transfer Speed
                            </InfoName>
                            <InfoData>
                                {serverInfo['download_bw'] ? (
                                    `${serverInfo['download_bw']} Mbps`
                                ) : (
                                    `500 Mbps`
                                )}
                            </InfoData>
                        </InfoGroup>
                    </DetailInfo>
                    <SectionDivider />
                    <SubSectionTitle>
                        Your Specifications
                    </SubSectionTitle>
                    <InputSuperGroup onSubmit={handleFormSubmit}>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="vm-name">VM Name: </InputLabel>
                            <InputFieldWithFeedback>
                                <InputField id="vm-name"
                                            customWidth="100%"
                                            value={vmName}
                                            onChange={handleVmNameEntry}
                                            placeholder="Enter a name for your virtual machine"
                                            ref={nameInputRef}
                                />
                                <InputFeedbackText visible={true}>{nameLength} / {maxNameLength}</InputFeedbackText>
                            </InputFieldWithFeedback>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="num-gpus">Number of GPUs: </InputLabel>
                            <DropDownListAlt value={numGpus}
                                             required={true}
                                             id="num-gpus"
                                             onChange={handleGpuEntry}
                                             customWidth="60px"
                            >
                                {possibleGpuCounts.map((count, index) => {
                                    return (
                                        <option value={count}
                                                key={index}
                                        >
                                            {count}
                                        </option>
                                    )
                                })}
                            </DropDownListAlt>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="cpu-cores">Number of vCPUs: </InputLabel>
                            <InputField id="cpu-cores"
                                        type="number"
                                        customWidth="60px"
                                        value={numCpus}
                                        onChange={handleCpuEntry}
                                        min={String(minCpus)}
                                        max={String(maxCpus)}
                                        error={cpusOutOfRange}
                                        step="2"
                            />
                            <InputQualifier>
                                Minimum {String(minCpus)}<br />Maximum {maxCpus} (even only)
                            </InputQualifier>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="ram-amount">Amount of RAM (GB): </InputLabel>
                            <InputField id="ram-amount"
                                        type="number"
                                        customWidth="70px"
                                        value={amtRam}
                                        onChange={handleRamEntry}
                                        min={String(minRam)}
                                        max={String(maxRam)}
                                        error={ramOutOfRange}
                            />
                            <InputQualifier>
                                Minimum {String(minRam)}<br />Maximum {maxRam}
                            </InputQualifier>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="amt-storage">Local Storage Size (GB): </InputLabel>
                            <InputField id="amt-storage"
                                        type="number"
                                        customWidth="80px"
                                        value={amtStorage}
                                        onChange={handleStorageEntry}
                                        min={String(minStorage)}
                                        max={String(maxStorage)}
                                        error={storageOutOfRange}
                            />
                            <InputQualifier>
                                Minimum {String(minStorage)}<br />Maximum {maxStorage}
                            </InputQualifier>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="os-select">Operating System: </InputLabel>
                            <DropDownListAlt value={os}
                                             required={true}
                                             id="os-select"
                                             onChange={handleDropDownListSelection}
                            >
                                <option value="Ubuntu 22.04 LTS">Ubuntu 22.04 LTS</option>
                                <option value="Ubuntu 20.04 LTS">Ubuntu 20.04 LTS</option>
                                <option value="TensorML 20 PyTorch" disabled={maxStorage < 40}>Ubuntu 20.04 + PyTorch + Jupyter (min. 40 GB SSD)</option>
                                <option value="TensorML 20 TensorFlow" disabled={maxStorage < 40}>Ubuntu 20.04 + TensorFlow + Jupyter (min. 40 GB SSD)</option>
                                <option value="TensorML 20 Everything" disabled={maxStorage < 60}>Ubuntu 20.04 + PyTorch + TensorFlow + MxNet + Jupyter (min. 60 GB SSD)</option>
                                <option value="Windows 10" disabled={maxStorage < 80 || maxRam < 8}>Windows 10 (min. 80 GB SSD, 8 GB RAM)</option>
                            </DropDownListAlt>
                        </InputGroupHorizontal>
                        {confirmModalIsShown && (
                            <>
                                <ConfirmModalBackgroundCover onClick={() => setConfirmModalIsShown(false)} />
                                <ConfirmModal>
                                    <ModalTitle>
                                        Launch Virtual Machine
                                    </ModalTitle>
                                    <ModalText>
                                        Please confirm that you would like to claim access to this VM.
                                    </ModalText>
                                    <ModalTotalPrice>
                                        ${parseFloat(totalCost.toFixed(5))}<span>/hour</span>
                                    </ModalTotalPrice>
                                    <ModalFinePrint>
                                        Your SSH/RDP session will accrue pro-rated hourly charges for as long as it is active.
                                        Once an SSH/RDP session is terminated, all data stored on the server will be lost.
                                        Please back up your data.
                                    </ModalFinePrint>
                                    <LaunchButton value="Launch virtual machine" type="submit" />
                                </ConfirmModal>
                            </>
                        )}
                    </InputSuperGroup>
                    <SectionDivider />
                    <SubSectionTitle>
                        Networking
                    </SubSectionTitle>
                    <NetworkingContainer>
                        {externalPortsList.map((externalPort, i) => {
                            if (i === 0) {
                                return (
                                    <PortSelectorRowSuper key={externalPort}>
                                        <PortSelectorRow>
                                            <PortSelectorGroup>
                                                <PortSelectorInputLabel>
                                                    External port
                                                </PortSelectorInputLabel>
                                                <DropDownListAltNoMargin value={externalPort}
                                                                         onChange={e => handleExternalPortSelection(e, i)}
                                                                         customWidth="100px"
                                                >
                                                    {availablePorts.map((availablePort, j) => {
                                                        if (externalPortsList.includes(availablePort)) {
                                                            return (
                                                                <option value={availablePort} key={j} disabled>
                                                                    {availablePort}
                                                                </option>
                                                            )
                                                        } else {
                                                            return (
                                                                <option value={availablePort} key={j}>
                                                                    {availablePort}
                                                                </option>
                                                            )
                                                        }
                                                    })}
                                                </DropDownListAltNoMargin>
                                            </PortSelectorGroup>
                                            <PortSelectorGroup>
                                                <PortSelectorInputLabel>
                                                    Internal port
                                                </PortSelectorInputLabel>
                                                <InputFieldNoMargin value={internalPortsList[i]}
                                                                    disabled
                                                                    customWidth="150px"
                                                                    error={internalPortsList[i] === ''}
                                                />
                                            </PortSelectorGroup>
                                        </PortSelectorRow>
                                    </PortSelectorRowSuper>
                                );
                            } else {
                                return (
                                    <PortSelectorRowSuper key={externalPort}>
                                        <PortSelectorRow>
                                            <DropDownListAltNoMargin value={externalPort}
                                                                     onChange={e => handleExternalPortSelection(e, i)}
                                                                     customWidth="100px"
                                            >
                                                {availablePorts.map((availablePort, k) => {
                                                    if (externalPortsList.includes(availablePort)) {
                                                        return (
                                                            <option value={availablePort} key={k} disabled>
                                                                {availablePort}
                                                            </option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option value={availablePort} key={k}>
                                                                {availablePort}
                                                            </option>
                                                        )
                                                    }
                                                })}
                                            </DropDownListAltNoMargin>
                                            <InputFieldNoMargin value={internalPortsList[i]}
                                                                type="number"
                                                                onChange={e => handleInternalPortSelection(e, i)}
                                                                customWidth="150px"
                                                                error={internalPortsList[i] === ''}
                                            />
                                            <RowDeleteButton onClick={() => removePortSelectorRow(i)}>
                                                <FontAwesomeIcon icon={faXmark} />
                                            </RowDeleteButton>
                                        </PortSelectorRow>
                                    </PortSelectorRowSuper>
                                )
                            }
                        })}
                        {externalPortsList.length < availablePorts.length && (
                            <PortSelectorRow>
                                <AddSelectorRowButton onClick={addPortSelectorRow}>
                                    <AddSelectorIcon>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </AddSelectorIcon>
                                    <AddSelectorText>
                                        Add additional port mapping
                                    </AddSelectorText>
                                </AddSelectorRowButton>
                            </PortSelectorRow>
                        )}
                    </NetworkingContainer>
                    <SectionDivider />
                    <SubSectionTitle>
                        Pricing
                    </SubSectionTitle>
                    <PricingBlock>
                        <TotalPrice>
                            ${parseFloat(totalCost.toFixed(5))}<span>/hour</span>
                        </TotalPrice>
                        <SubSectionDivider />
                        <PricingRow>
                            <PricingCategoryTitle>
                                GPU cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['gpu_cost']).toFixed(5))}<span>/GPU-hour</span> x {numGpus} = ${parseFloat((numGpus*parseFloat(serverInfo['gpu_cost'])).toFixed(5))}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                        <PricingRow>
                            <PricingCategoryTitle>
                                CPU cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['cpu_cost']).toFixed(5))}<span>/CPU-hour</span> x {numCpus} = ${parseFloat((numCpus*serverInfo['cpu_cost']).toFixed(5))}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                        <PricingRow>
                            <PricingCategoryTitle>
                                RAM cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['ram_cost']).toFixed(5))}<span>/GB-hour</span> x {amtRam} GB = ${parseFloat((amtRam*serverInfo['ram_cost']).toFixed(5))}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                        <PricingRow>
                            <PricingCategoryTitle>
                                Storage cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['storage_cost']).toFixed(5))}<span>/GB-hour</span> x {amtStorage} GB = ${parseFloat((amtStorage*serverInfo['storage_cost']).toFixed(5))}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                    </PricingBlock>
                    <ConnectButtonWithDisabling onClick={() => setConfirmModalIsShown(true)}
                                                disabled={!numCpus || !amtRam || !numGpus || !amtStorage || cpusOutOfRange || gpusOutOfRange || ramOutOfRange || storageOutOfRange || internalPortsList.includes('')}>
                        Launch virtual machine
                    </ConnectButtonWithDisabling>
                </DetailPanel>
                {vmIsProvisioning && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            </ModalTitle>
                        </ConfirmModal>
                    </>
                )}
                {provisioningSucceeded && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal maxWidth="400px">
                            <ModalTitle>
                                Your VM is provisioning
                            </ModalTitle>
                            {os !== 'Windows 10' && (
                                <ModalText>
                                    Connect to your server with the following command and password.<br /><br />
                                    <span>This is the only time you will be able to view this password, so please copy it.</span>
                                    <CodeBlock>
                                        <div>ssh -p {externalPortsList[0]} {process.env.REACT_APP_VM_USER}@{ipAddress}</div>
                                        <div>{password}</div>
                                        {codeWasCopied ? (
                                            <ClipboardIcon><FontAwesomeIcon icon={faCheck} /></ClipboardIcon>
                                        ) : (
                                            <ClipboardIcon onClick={copyToClipboard}><FontAwesomeIcon icon={faCopy} /></ClipboardIcon>
                                        )}
                                    </CodeBlock>
                                </ModalText>
                            )}
                            {os === 'Windows 10' && (
                                <ModalText>
                                    Connect to your server with Microsoft Remote Desktop using the following credentials.<br /><br />
                                    <span>This is the only time you will be able to view this password, so please copy it.</span>
                                    <CodeBlock>
                                        <div>
                                            <BoldCodeBlockTitle>IP Address: </BoldCodeBlockTitle>
                                            <span>{ipAddress}</span>
                                        </div>
                                        <div>
                                            <BoldCodeBlockTitle>Port: </BoldCodeBlockTitle>
                                            <span>{externalPortsList[0]}</span>
                                        </div>
                                        <div>
                                            <BoldCodeBlockTitle>Username: </BoldCodeBlockTitle>
                                            <span>{process.env.REACT_APP_VM_USER}</span>
                                        </div>
                                        <div>
                                            <BoldCodeBlockTitle>Password: </BoldCodeBlockTitle>
                                            <span>{password}</span>
                                        </div>
                                        {codeWasCopied ? (
                                            <ClipboardIcon><FontAwesomeIcon icon={faCheck} /></ClipboardIcon>
                                        ) : (
                                            <ClipboardIcon onClick={copyToClipboard}><FontAwesomeIcon icon={faCopy} /></ClipboardIcon>
                                        )}
                                    </CodeBlock>
                                </ModalText>
                            )}
                            <ModalFinePrint>
                                It may take a few minutes for the virtual machine to become available. You can check the
                                status under "Your Virtual Machines".
                            </ModalFinePrint>
                            <Link to="/dashboard/direct?active=sessions"><ConnectButton>Okay</ConnectButton></Link>
                        </ConfirmModal>
                    </>
                )}
                {provisioningFailed && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Error
                            </ModalTitle>
                            <ModalText>
                                There was a problem provisioning the virtual machine. Please try again later.
                            </ModalText>
                            <Link to="/dashboard/direct?active=vms"><ConnectButton>Okay</ConnectButton></Link>
                        </ConfirmModal>
                    </>
                )}
                {insufficientFunds && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Insufficient Balance
                            </ModalTitle>
                            <ModalText>
                                You need a balance of at least $10.00 USD to launch a virtual machine. Please deposit funds
                                and try again.
                            </ModalText>
                            <Link to="/dashboard/billing"><ConnectButton>Deposit funds</ConnectButton></Link>
                        </ConfirmModal>
                    </>
                )}
            </DetailContainer>
        )
    );
}

export default PortalDirectTensorDockServer;