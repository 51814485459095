import styled from "styled-components";
import BWSLogo from "./assets/image-bws-logo-no-text.png";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinnerThird,
    faEnvelopeDot,
    faFaceThinking,
    faPersonCircleCheck,
    faPaperPlane,
    faCircleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import {
    faEye,
    faEyeSlash,
    faWifiSlash
} from "@fortawesome/pro-light-svg-icons";
import { InputErrorText } from "./SharedStyles";

const LoginContainer = styled.div`
  background-color: #1a1c47;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 50px;
  @media screen and (max-width: 550px) {
    padding: 0;
  }
`;

const LoginPanel = styled.div`
  background-color: #121331;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  border-radius: 10px;
  min-width: 450px;
  @media screen and (max-width: 550px) {
    width: 100%;
    border-radius: 0;
    min-height: 100vh;
    justify-content: start;
    padding: 50px;
    min-width: 0;
  }
`;

const Logo = styled.div`
  width: 70px;
  height: 70px;
  & img {
    width: 100%;
  }
  margin: -10px 0 -30px 0;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 40px 0 30px 0;
`;

const TextInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 15px;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #1a1c47;
  color: #fff;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
`;

const InputFeedbackIcon = styled.div`
  position: absolute;
  top: 9px;
  right: 10px;
`;

const InputFeedbackBlock = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7); 
  margin-bottom: 5px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormSubmitButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  padding: 10px 20px;
  margin: 15px auto 0 auto;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const SmallTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
`

const SmallText = styled.div`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  &:first-child {
    margin-bottom: 5px;
  }
  & a:hover {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: ${params => params.internalLink ? "underline" : "none"};
  }
  cursor: ${params => params.internalLink ? "pointer" : "auto"};
`;

const LoadingCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 101vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 3rem;
  backdrop-filter: blur(1px);
`;

const ResponseModal = styled.div`
  position: absolute;
  align-self: center;
  z-index: 10;
  padding: 40px 25px;
  border-radius: 10px;
  background-color: #121331;
  font-size: 1.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResponseModalTitle = styled.div`
  font-size: 2rem;
  font-weight: 400;
  margin: 15px 0;
`;

const ResponseModalText = styled.div`
  font-size: 1rem;
  font-weight: 300;
  max-width: 300px;
`;

const ResponseModalButton = styled.div`
  padding: 8px 20px;
  margin: 25px auto 0 auto;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const Login = ({ updateAccessToken, updateRefreshToken, token, loading }) => {
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordIsVisible, setPasswordIsVisible ] = useState(false);
    const [ usernameError, setUsernameError ] = useState(false);
    const [ passwordError, setPasswordError ] = useState(false);
    const [ resetPasswordTarget, setResetPasswordTarget ] = useState('');
    const [ resetPasswordTargetError, setResetPasswordTargetError ] = useState(false);
    const [ resetPasswordTargetIsEmpty, setResetPasswordTargetIsEmpty ] = useState(false);
    const [ resetPasswordTargetIsInvalid, setResetPasswordTargetIsInvalid ] = useState(false);

    const [ waitingForEndpoint, setWaitingForEndpoint ] = useState(false);
    const [ credentialsErrorModalIsShown, setCredentialsErrorModalIsShown ] = useState(false);
    const [ emailNotVerifiedModalIsShown, setEmailNotVerifiedModalIsShown ] = useState(false);
    const [ adminNotApprovedErrorModalIsShown, setAdminNotApprovedErrorModalIsShown ] = useState(false);
    const [ networkErrorModalIsShown, setNetworkErrorModalIsShown ] = useState(false);
    const [ verificationEmailSentModalIsShown, setVerificationEmailSentModalIsShown ] = useState(false);
    const [ verificationEmailSendingErrorModalIsShown, setVerificationEmailSendingErrorModalIsShown ] = useState(false);
    const [ resetPasswordRequestModalIsShown, setResetPasswordRequestModalIsShown ] = useState(false);
    const [ resetPasswordRequestSentModalIsShown, setResetPasswordRequestSentModalIsShown ] = useState(false);
    const [ resetPasswordRequestSendingErrorModalIsShown, setResetPasswordRequestSendingErrorModalIsShown ] = useState(false);

    const inputFieldRef = useRef();
    const resetTargetFieldRef = useRef();
    const history = useHistory();
    
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidUsernameCheck = /([^A-Za-z0-9.\_])/g;

    useEffect(() => {
        if (!loading) {
            if (token) {
                history.push('/dashboard');
            }
        }
    }, [token, loading]);

    useEffect(() => {
        inputFieldRef.current.focus();
    }, [inputFieldRef]);

    useEffect(() => {
        if (resetPasswordRequestModalIsShown) {
            resetTargetFieldRef.current.focus();
        }
    }, [resetTargetFieldRef, resetPasswordRequestModalIsShown]);

    const handleUsernameInput = e => {
        setUsernameError(false);
        setUsername(e.target.value);
    }

    const handlePasswordInput = e => {
        setPasswordError(false);
        setPassword(e.target.value);
    }

    const handleResetPasswordTargetInput = e => {
        setResetPasswordTargetError(false);
        setResetPasswordTarget(e.target.value);
    }

    const dismissPasswordResetRequestModal = () => {
        setResetPasswordRequestModalIsShown(false);
        setResetPasswordTarget('');
    }

    const dismissResetPasswordRequestSentModal = () => {
        setResetPasswordRequestSentModalIsShown(false);
        setResetPasswordTarget('');
    }

    const dismissResetPasswordRequestSendingErrorModal = () => {
        setResetPasswordRequestSendingErrorModalIsShown(false);
        setResetPasswordTarget('');
    }

    const handleFormSubmit = e => {
        e.preventDefault();

        if (!username) {
            setUsernameError(true);
        }
        if (!password) {
            setPasswordError(true);
        }

        if (username && password) {
            setWaitingForEndpoint(true);
            let dataPayload;
            if (username.indexOf('@') > -1) {
                dataPayload = {
                    email: username.toLowerCase(),
                    password: password
                }
            } else {
                dataPayload = {
                    username: username.toLowerCase(),
                    password: password
                }
            }

            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataPayload)
            })
                .then(response => response.json())
                .then(data => {
                    if (data['access_token']) {
                        updateAccessToken(data['access_token']);
                        updateRefreshToken(data['refresh_token']);
                        history.push('/dashboard');
                    } else {
                        setWaitingForEndpoint(false);
                        if (data['detail'] === 'Account email not verified') {
                            setEmailNotVerifiedModalIsShown(true);
                        } else if (data['detail'] === 'Account not activated') {
                            setAdminNotApprovedErrorModalIsShown(true);
                        } else if (data['detail'] === 'Invalid credentials.') {
                            setCredentialsErrorModalIsShown(true);
                        }
                    }
                })
                .catch(() => {
                    setNetworkErrorModalIsShown(true);
                    setWaitingForEndpoint(false);
                });
        }
    }

    const handleResendEmail = () => {
        setEmailNotVerifiedModalIsShown(false);
        setWaitingForEndpoint(true);

        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/verification/resend?${isValidEmail.test(username) ? `email=${encodeURIComponent(username.toLowerCase())}` : `username=${username.toLowerCase()}`}`)
            .then(response => {
                if (response['status'] < 299) {
                    setVerificationEmailSentModalIsShown(true);
                } else {
                    setVerificationEmailSendingErrorModalIsShown(true);
                }
            })
            .catch(() => {
                setNetworkErrorModalIsShown(true);
            })
            .finally(() => {
                setWaitingForEndpoint(false);
            });
    }

    const handleRequestPasswordReset = e => {
        e.preventDefault();
        const requestReset = payload => {
            setResetPasswordRequestModalIsShown(false);
            setWaitingForEndpoint(true);
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/send_reset_password_email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if (response['status'] < 299) {
                        setResetPasswordRequestSentModalIsShown(true);
                    } else {
                        setResetPasswordRequestSendingErrorModalIsShown(true);
                    }
                })
                .catch(() => setNetworkErrorModalIsShown(true))
                .finally(() => setWaitingForEndpoint(false));
        }

        if (!resetPasswordTarget) {
            setResetPasswordTargetError(true);
            setResetPasswordTargetIsEmpty(true);
        } else if (resetPasswordTarget.includes('@')) {
            if (isValidEmail.test(resetPasswordTarget)) {
                requestReset({ email: resetPasswordTarget.toLowerCase() });
            } else {
                setResetPasswordTargetError(true);
                setResetPasswordTargetIsInvalid(true);
            }
        } else {
            if (resetPasswordTarget.length < 2 || resetPasswordTarget.length > 32 || invalidUsernameCheck.test(resetPasswordTarget)) {
                setResetPasswordTargetError(true);
                setResetPasswordTargetIsInvalid(true);
            } else {
                requestReset({ username: resetPasswordTarget.toLowerCase() });
            }
        }
    }

    return (
        <LoginContainer>
            <LoginPanel>
                <Link to="/">
                    <Logo>
                        <img src={BWSLogo} alt="" />
                    </Logo>
                </Link>
                <Title>
                    Log In to VALDI
                </Title>
                <LoginForm onSubmit={handleFormSubmit}>
                    <InputLabel htmlFor="username-input">Email or Username</InputLabel>
                    <TextInput onChange={handleUsernameInput}
                               id="username-input"
                               ref={inputFieldRef}
                               value={username}
                    />
                    <InputErrorText visible={usernameError}>Please enter your email or username.</InputErrorText>
                    <InputLabel htmlFor="password-input">Password</InputLabel>
                    <InputFeedbackBlock>
                        <TextInput type={passwordIsVisible ? "text" : "password"} 
                                   onChange={handlePasswordInput} 
                                   id="password-input"
                                   value={password}
                        />
                        <InputFeedbackIcon>
                            {password && (
                                passwordIsVisible ? (
                                    <FontAwesomeIcon icon={faEyeSlash}
                                                     onClick={() => setPasswordIsVisible(false)}
                                                     style={{cursor: "pointer"}}
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={faEye}
                                                     onClick={() => setPasswordIsVisible(true)}
                                                     style={{cursor: "pointer", marginRight: "1px"}}
                                    />
                                )
                            )}
                        </InputFeedbackIcon>
                    </InputFeedbackBlock>
                    <InputErrorText visible={passwordError}>Please enter a password.</InputErrorText>
                    <FormSubmitButton type="submit" value="Log In" />
                </LoginForm>
                <SmallTextContainer>
                    <SmallText
                        onClick={() => setResetPasswordRequestModalIsShown(true)}
                        internalLink={true}
                    >
                        Forgot password?
                    </SmallText>
                    <SmallText>
                        <Link to="/signup">
                            Create an account
                        </Link>
                    </SmallText>
                </SmallTextContainer>
            </LoginPanel>
            {waitingForEndpoint && <LoadingCover><FontAwesomeIcon icon={faSpinnerThird} spin /></LoadingCover>}
            {credentialsErrorModalIsShown ? (
                <>
                    <LoadingCover onClick={() => setCredentialsErrorModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faFaceThinking} style={{fontSize: "4rem", color: "#f9da53"}} />
                        <ResponseModalTitle>
                            Hmm...
                        </ResponseModalTitle>
                        <ResponseModalText>
                            Those credentials aren't correct.<br/>Give it another shot.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => setCredentialsErrorModalIsShown(false)}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (emailNotVerifiedModalIsShown ? (
                <>
                    <LoadingCover onClick={() => setEmailNotVerifiedModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faEnvelopeDot} style={{fontSize: "4rem"}} />
                        <ResponseModalTitle>
                            Email Not Verified
                        </ResponseModalTitle>
                        <ResponseModalText>
                            You need to verify ownership of your email address before you can log in. Click the link
                            in the email you received when signing up.
                            <br />
                            <br />
                            If you can't find the email, or if the link has expired, you can request a new one below.
                        </ResponseModalText>
                        <ResponseModalButton onClick={handleResendEmail}>Resend Email</ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (adminNotApprovedErrorModalIsShown ? (
                <>
                    <LoadingCover onClick={() => setAdminNotApprovedErrorModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faPersonCircleCheck} style={{fontSize: "4rem"}} />
                        <ResponseModalTitle>
                            Approval Required
                        </ResponseModalTitle>
                        <ResponseModalText>
                            To manage demand, VALDI is currently granting access to users on a case-by-case basis.
                            <br />
                            <br />
                            If you haven't already, please fill out the form below to help us expedite the approval
                            process.
                        </ResponseModalText>
                        <a href='https://forms.gle/viRGFyDhiL5arZmK8'>
                            <ResponseModalButton>Fill out the form</ResponseModalButton>
                        </a>
                    </ResponseModal>
                </>
            ) : (networkErrorModalIsShown && (
                <>
                    <LoadingCover onClick={() => setNetworkErrorModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faWifiSlash} style={{fontSize: "4rem", color: "red"}} />
                        <ResponseModalTitle>
                            Uh-oh!
                        </ResponseModalTitle>
                        <ResponseModalText>
                            We're having trouble sending your information. Please check your internet connection.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => setNetworkErrorModalIsShown(false)}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ))))}
            {verificationEmailSentModalIsShown ? (
                <>
                    <LoadingCover onClick={() => setVerificationEmailSentModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faPaperPlane} style={{fontSize: "4rem"}} />
                        <ResponseModalTitle>
                            Sent!
                        </ResponseModalTitle>
                        <ResponseModalText>
                            Click the link in the email we just sent to verify your account before logging in.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => setVerificationEmailSentModalIsShown(false)}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (verificationEmailSendingErrorModalIsShown && (
                <>
                    <LoadingCover onClick={() => setVerificationEmailSendingErrorModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: "4rem", color: "red"}} />
                        <ResponseModalTitle>
                            We hit a snag.
                        </ResponseModalTitle>
                        <ResponseModalText>
                            There was an issue trying to resend the email. Please try again later.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => setVerificationEmailSendingErrorModalIsShown(false)}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ))}
            {resetPasswordRequestModalIsShown && (
                <>
                    <LoadingCover onClick={dismissPasswordResetRequestModal} />
                    <ResponseModal>
                        <ResponseModalTitle style={{margin: "0"}}>
                            Forgot?
                        </ResponseModalTitle>
                        <ResponseModalText style={{padding: "20px 0"}}>
                            Enter your email or username to request a password reset via email.
                        </ResponseModalText>
                        <LoginForm style={{textAlign: "left"}} onSubmit={handleRequestPasswordReset}>
                            <InputLabel htmlFor="reset-password-target">Email or Username</InputLabel>
                            <TextInput id="reset-password-target"
                                       ref={resetTargetFieldRef}
                                       value={resetPasswordTarget}
                                       onChange={handleResetPasswordTargetInput}
                            />
                            <InputErrorText visible={resetPasswordTargetError}>
                                {resetPasswordTargetIsEmpty ? (
                                    `Please enter your email or username.`
                                ) : (resetPasswordTargetIsInvalid ? (
                                    `This is not a valid email or username.`
                                ) : `There is no error.`
                                )}
                            </InputErrorText>
                            <FormSubmitButton type="submit" value="Send" />
                        </LoginForm>
                    </ResponseModal>
                </>
            )}
            {resetPasswordRequestSentModalIsShown ? (
                <>
                    <LoadingCover onClick={dismissResetPasswordRequestSentModal} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faPaperPlane} style={{fontSize: "4rem"}} />
                        <ResponseModalTitle>
                            Sent!
                        </ResponseModalTitle>
                        <ResponseModalText>
                            Follow the link in the email we just sent to reset your password.
                        </ResponseModalText>
                        <ResponseModalButton onClick={dismissResetPasswordRequestSentModal}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (resetPasswordRequestSendingErrorModalIsShown && (
                <>
                    <LoadingCover onClick={dismissResetPasswordRequestSendingErrorModal}/>
                    <ResponseModal>
                        <FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: "4rem", color: "red"}} />
                        <ResponseModalTitle>
                            We've got issues.
                        </ResponseModalTitle>
                        <ResponseModalText>
                            There was an issue trying to reset your password. Please double-check your username or email and try again.
                        </ResponseModalText>
                        <ResponseModalButton onClick={dismissResetPasswordRequestSendingErrorModal}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ))}
        </LoginContainer>
    )
}

export default Login;