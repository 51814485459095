import { useState, useEffect } from "react";
import styled from "styled-components";
import {
    GenerateKeyButton,
    ServerList,
    ServerInfo,
    ServerListItemPlaceholder,
    ListItemName,
    ListItemDescription,
    ConfirmModal,
    ConfirmModalBackgroundCover,
    ModalTitle,
    ModalText,
    LightBgTextInput,
    BackButton,
    ConnectButton,
    DropDownList,
    InputContainer,
    ConnectButtonWithDisabling,
    ServerTags,
    RefreshIcon,
    ServerPrice,
    QuantityTag
} from "./SharedStyles";
import Switch from "react-switch";
import {
    TextInput,
    InputLabel
} from "./Signup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBars, faTrashCan, faPencil } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { replaceEmptyStringWithNull } from "./utilities";

const AddNewButton = styled(GenerateKeyButton)`
  margin-left: 0;
`;

const InventoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`;

const HeaderArea = styled.div`
`;

const FormTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

const AddNewForm = styled.form`
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FormContainer = styled.div`
  margin-top: 10px;
  background-color: #292c6e;
  border-radius: 10px;
  padding: 20px 30px;
  width: 100%;
`;

const PaddedConfirmModal = styled(ConfirmModal)`
  padding: 30px;
`;

const UnorderedList = styled.ul`
  margin-top: 15px;
  font-size: 0.9rem;
`;

const DropDownListContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const SmallAddButton = styled.div`
  background-color: #ff1f82;
  color: #ffffff;
  height: 40px;
  width: 40px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 31, 130, 0.9);
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SwitchTitle = styled.div`
  font-size: 0.6rem;
  opacity: 0.6;
  max-width: 40px;
  word-break: break-word;
  word-wrap: break-word;
  margin-bottom: 3px;
  text-align: center;
`;

const ClickAndDragIcon = styled.div`
  opacity: 0.4;
  font-size: 1.5rem;
  cursor: grab;
`;

const HighlightableServerListItem = styled(ServerListItemPlaceholder)`
  border-top: ${props => props.isHovering ? '2px solid rgba(255, 255, 255, 0.6)' : 'none'};
`;

const OnDemandPrice = styled(ServerPrice)`
  background: #1a1c47;
`;

const PortalAdminInventory = ({ token }) => {
    const [ addNewFormIsShown, setAddNewFormIsShown ] = useState(false);
    const [ newServerGpuType, setNewServerGpuType ] = useState('');
    const [ newServerGpuCount, setNewServerGpuCount ] = useState('');
    const [ newServerCpuType, setNewServerCpuType ] = useState('');
    const [ newServerCpuCount, setNewServerCpuCount ] = useState('');
    const [ newServerRam, setNewServerRam ] = useState('');
    const [ newServerStorage, setNewServerStorage ] = useState('');
    const [ newServerHourlyCost, setNewServerHourlyCost ] = useState('');
    const [ newServerMonthlyCost, setNewServerMonthlyCost ] = useState('');
    const [ newServerQuantity, setNewServerQuantity ] = useState('');
    const [ addNewConfirmationModalIsShown, setAddNewConfirmationModalIsShown ] = useState(false);
    const [ providers, setProviders ] = useState([]);
    const [ providersAreLoading, setProvidersAreLoading ] = useState(true);
    const [ selectedProviderId, setSelectedProviderId ] = useState('');
    const [ newProviderModalIsShown, setNewProviderModalIsShown ] = useState(false);
    const [ newProviderIsBeingAdded, setNewProviderIsBeingAdded ] = useState(false);
    const [ newServerIsBeingAdded, setNewServerIsBeingAdded ] = useState(false);
    const [ newProviderName, setNewProviderName ] = useState('');
    const [ errorAddingNewProvider, setErrorAddingNewProvider ] = useState(false);
    const [ inventoryIsLoading, setInventoryIsLoading ] = useState(true);
    const [ inventoryList, setInventoryList ] = useState([]);
    const [ errorLoadingInventory, setErrorLoadingInventory ] = useState(false);
    const [ errorAddingInventory, setErrorAddingInventory ] = useState(false);
    const [ draggingIndex, setDraggingIndex ] = useState(null);
    const [ hoverIndex, setHoverIndex ] = useState(null);
    const [ configIdBeingDragged, setConfigIdBeingDragged ] = useState('');
    const [ serverStagedForDeletion, setServerStagedForDeletion ] = useState('');
    const [ deleteServerModalIsVisible, setDeleteServerModalIsVisible ] = useState(false);
    const [ serverIsBeingDeleted, setServerIsBeingDeleted ] = useState(false);
    const [ errorDeletingServer, setErrorDeletingServer ] = useState(false);
    const [ serversAreBeingReordered, setServersAreBeingReordered ] = useState(false);
    const [ errorReorderingServers, setErrorReorderingServers ] = useState(false);
    const [ serverVisibilityIsChanging, setServerVisibilityIsChanging ] = useState(false);
    const [ errorChangingVisibility, setErrorChangingVisibility ] = useState(false);
    const [ serverStagedForEditing, setServerStagedForEditing ] = useState({});
    const [ editServerModalIsVisible, setEditServerModalIsVisible ] = useState(false);
    const [ editServerConfirmationModalIsShown, setEditServerConfirmationModalIsShown ] = useState(false);
    const [ serverIsBeingEdited, setServerIsBeingEdited ] = useState(false);
    const [ errorEditingServer, setErrorEditingServer ] = useState(false);

    useEffect(() => {
        const fetchInitialProviders = async () => {
            await fetchProviders();
        }

        const fetchInitialInventory = async () => {
            await fetchInventory();
        }

        fetchInitialProviders();
        fetchInitialInventory();
    }, []);

    async function fetchInventory() {
        setInventoryIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                if ('inventory' in data) {
                    setInventoryList(data['inventory']);
                } else {
                    setErrorLoadingInventory(true);
                }
            } else {
                setErrorLoadingInventory(true);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setInventoryIsLoading(false);
        }
    }

    async function fetchProviders() {
        setProvidersAreLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/provider`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                if (data && 'providers' in data) {
                    setProviders(data['providers']);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setProvidersAreLoading(false);
        }
    }

    async function addNewProvider() {
        setNewProviderModalIsShown(false);
        setNewProviderIsBeingAdded(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/provider`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    provider_name: newProviderName
                })
            });
            if (!response.ok) {
                console.log(response);
                setErrorAddingNewProvider(true);
            } else {
                await fetchProviders();
            }
        } catch (error) {
            console.log(error);
            setErrorAddingNewProvider(true);
        } finally {
            setNewProviderIsBeingAdded(false);
            setNewProviderName('');
        }
    }

    const handleFormDismissal = () => {
        setAddNewFormIsShown(false);
    }

    const handleDropDownListSelect = e => {
        setSelectedProviderId(e.target.value);
    }

    async function handleFormSubmit() {
        setAddNewConfirmationModalIsShown(false);
        setNewServerIsBeingAdded(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/server`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(replaceEmptyStringWithNull({
                    provider_id: selectedProviderId,
                    quantity: newServerQuantity,
                    gpu_type: newServerGpuType,
                    cpu_type: newServerCpuType,
                    gpu_count: newServerGpuCount,
                    vcpus: newServerCpuCount,
                    ram: newServerRam,
                    storage: newServerStorage,
                    hourly_rate: newServerHourlyCost,
                    monthly_rate: newServerMonthlyCost
                }))
            });

            if (response.ok) {
                setAddNewFormIsShown(false);
                await fetchInventory();
            } else {
                setErrorAddingInventory(true);
            }
        } catch (error) {
            console.log(error);
            setErrorAddingInventory(true);
        } finally {
            setNewServerIsBeingAdded(false);
            setSelectedProviderId('');
            setNewServerQuantity('');
            setNewServerGpuType('');
            setNewServerCpuType('');
            setNewServerGpuCount('');
            setNewServerCpuCount('');
            setNewServerRam('');
            setNewServerStorage('');
            setNewServerHourlyCost('');
            setNewServerMonthlyCost('');
            setNewServerQuantity('');
        }
    }

    async function handleDeleteServer() {
        setDeleteServerModalIsVisible(false);
        setServerIsBeingDeleted(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/server/${serverStagedForDeletion}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                await fetchInventory();
            } else {
                setErrorDeletingServer(true);
            }
        } catch (error) {
            console.log(error);
            setErrorDeletingServer(true);
        } finally {
            setServerIsBeingDeleted(false);
            setServerStagedForDeletion('');
        }
    }

    async function handleServerReordering() {
        setServersAreBeingReordered(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/server/${configIdBeingDragged}/priority`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    'new_priority': hoverIndex + 1
                })
            });

            if (response.ok) {
                await fetchInventory();
            } else {
                setErrorReorderingServers(true);
            }
        } catch (error) {
            console.log(error);
            setErrorReorderingServers(true);
        } finally {
            setServersAreBeingReordered(false);
        }
    }

    async function handleServerVisibilityChange(configId) {
        setServerVisibilityIsChanging(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/server/${configId}/visibility`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                await fetchInventory();
            } else {
                setErrorChangingVisibility(true);
            }
        } catch (error) {
            console.log(error);
            setErrorChangingVisibility(true);
        } finally {
            setServerVisibilityIsChanging(false);
        }
    }

    const dismissNewProviderModal = () => {
        setNewProviderModalIsShown(false);
        setNewProviderName('');
    }

    const onDragStart = (index, configId) => {
        setDraggingIndex(index);
        setConfigIdBeingDragged(configId);
    }

    const onDragOver = (e, index) => {
        e.preventDefault();
        setHoverIndex(index);
    }

    async function onDragEnd() {
        if (hoverIndex != null && draggingIndex != null && hoverIndex !== draggingIndex) {
            await handleServerReordering();
        }
        setHoverIndex(null);
        setDraggingIndex(null);
        setConfigIdBeingDragged('');
    }

    const displayDeleteServerModal = configId => {
        setServerStagedForDeletion(configId);
        setDeleteServerModalIsVisible(true);
    }

    const displayEditServerModal = serverDetails => {
        const serverDetailsCopy = structuredClone(serverDetails);
        setServerStagedForEditing(serverDetailsCopy);
        setEditServerModalIsVisible(true);
    }

    const dismissDeleteServerModal = () => {
        setDeleteServerModalIsVisible(false);
        setServerStagedForDeletion('');
    }

    const dismissEditServerModal = () => {
        setEditServerModalIsVisible(false);
        setServerStagedForEditing({});
    }

    const handleEditFormChange = (name, value) => {
        setServerStagedForEditing(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    async function handleEditServerDetails() {
        setEditServerConfirmationModalIsShown(false);
        setServerIsBeingEdited(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/inventory/server/${serverStagedForEditing['configuration_id']}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(replaceEmptyStringWithNull({
                    quantity: serverStagedForEditing['quantity'],
                    gpu_type: serverStagedForEditing['gpu_type'],
                    cpu_type: serverStagedForEditing['cpu_type'],
                    gpu_count: serverStagedForEditing['gpu_count'],
                    vcpus: serverStagedForEditing['vcpus'],
                    ram: serverStagedForEditing['ram'],
                    storage: serverStagedForEditing['storage'],
                    hourly_rate: serverStagedForEditing['hourly_rate'],
                    monthly_rate: serverStagedForEditing['monthly_rate']
                }))
            });

            if (response.ok) {
                dismissEditServerModal();
                await fetchInventory();
            } else {
                setErrorEditingServer(true);
            }
        } catch (error) {
            setErrorEditingServer(true);
            console.log(error);
        } finally {
            setServerIsBeingEdited(false);
        }
    }

    return (
        <InventoryContainer>
            {addNewFormIsShown ? (
                <>
                    <HeaderArea>
                        <BackButton onClick={handleFormDismissal}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                            <span>Back</span>
                        </BackButton>
                    </HeaderArea>
                    <FormContainer>
                        <FormTitle>
                            Add new server
                        </FormTitle>
                        <AddNewForm>
                            <InputLabel htmlFor="new-server-gpu">GPU Chip (e.g., NVIDIA RTX 4090 48GB)</InputLabel>
                            <TextInput id="new-server-gpu"
                                       value={newServerGpuType}
                                       onChange={e => setNewServerGpuType(e.target.value)}
                            />
                            <InputLabel htmlFor="new-server-gpu-count">Number of GPUs per node</InputLabel>
                            <TextInput id="new-server-gpu-count"
                                       type="number"
                                       value={newServerGpuCount}
                                       onChange={e => setNewServerGpuCount(e.target.value)}
                            />
                            <InputLabel htmlFor="new-server-cpu">CPU Chip (e.g., AMD EPYC 9354)</InputLabel>
                            <TextInput id="new-server-cpu"
                                       value={newServerCpuType}
                                       onChange={e => setNewServerCpuType(e.target.value)}
                            />
                            <InputLabel htmlFor="new-server-cpu-count">Number of CPUs per node</InputLabel>
                            <TextInput id="new-server-cpu-count"
                                       type="number"
                                       value={newServerCpuCount}
                                       onChange={e => setNewServerCpuCount(e.target.value)}
                            />
                            <InputLabel htmlFor="new-server-ram">Amount of RAM (GB) per node</InputLabel>
                            <TextInput id="new-server-ram"
                                       type="number"
                                       value={newServerRam}
                                       onChange={e => setNewServerRam(e.target.value)}
                            />
                            <InputLabel htmlFor="new-server-storage">Amount of storage (GB) per node</InputLabel>
                            <TextInput id="new-server-storage"
                                       type="number"
                                       value={newServerStorage}
                                       onChange={e => setNewServerStorage(e.target.value)}
                            />
                            <InputLabel htmlFor="new-server-cost">All-inclusive hourly cost (leave this blank if server is not able to be on demand)</InputLabel>
                            <TextInput id="new-server-cost"
                                       type="number"
                                       value={newServerHourlyCost}
                                       onChange={e => setNewServerHourlyCost(e.target.value)}
                            />
                            <InputLabel htmlFor="new-server-monthly-subscription">All-inclusive monthly subscription cost</InputLabel>
                            <TextInput id="new-server-monthly-subscription"
                                       type="number"
                                       value={newServerMonthlyCost}
                                       onChange={e => setNewServerMonthlyCost(e.target.value)}
                            />
                            <InputLabel htmlFor="new-server-provider">Provider/Supplier</InputLabel>
                            <DropDownListContainer>
                                <DropDownList value={selectedProviderId}
                                              id="new-server-provider"
                                              required={true}
                                              onChange={handleDropDownListSelect}
                                >
                                    <option value=''>-</option>
                                    {!providersAreLoading && (
                                        providers.map(p => {
                                            return (
                                                <option key={p['provider_id']}
                                                        value={p['provider_id']}
                                                >
                                                    {p['provider_name']}
                                                </option>
                                            )
                                        })
                                    )}
                                </DropDownList>
                                <SmallAddButton onClick={() => setNewProviderModalIsShown(true)}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </SmallAddButton>
                            </DropDownListContainer>
                            <InputLabel htmlFor="new-server-qty">Quantity Available</InputLabel>
                            <TextInput id="new-server-qty"
                                       value={newServerQuantity}
                                       onChange={e => setNewServerQuantity(e.target.value)}
                            />
                        </AddNewForm>
                        <ConnectButton onClick={() => setAddNewConfirmationModalIsShown(true)}>
                            Add server
                        </ConnectButton>
                    </FormContainer>
                </>
            ) : (
                editServerModalIsVisible ? (
                    <>
                        <HeaderArea>
                            <BackButton onClick={dismissEditServerModal}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                                <span>Back</span>
                            </BackButton>
                        </HeaderArea>
                        <FormContainer>
                            <FormTitle>
                                Edit server details
                            </FormTitle>
                            <AddNewForm>
                                <InputLabel htmlFor="new-server-gpu">GPU Chip (e.g., NVIDIA RTX 4090 48GB)</InputLabel>
                                <TextInput id="new-server-gpu"
                                           value={serverStagedForEditing['gpu_type']}
                                           onChange={e => handleEditFormChange('gpu_type', e.target.value)}
                                />
                                <InputLabel htmlFor="new-server-gpu-count">Number of GPUs per node</InputLabel>
                                <TextInput id="new-server-gpu-count"
                                           type="number"
                                           value={serverStagedForEditing['gpu_count']}
                                           onChange={e => handleEditFormChange('gpu_count', e.target.value)}
                                />
                                <InputLabel htmlFor="new-server-cpu">CPU Chip (e.g., AMD EPYC 9354)</InputLabel>
                                <TextInput id="new-server-cpu"
                                           value={serverStagedForEditing['cpu_type']}
                                           onChange={e => handleEditFormChange('cpu_type', e.target.value)}
                                />
                                <InputLabel htmlFor="new-server-cpu-count">Number of CPUs per node</InputLabel>
                                <TextInput id="new-server-cpu-count"
                                           type="number"
                                           value={serverStagedForEditing['vcpus']}
                                           onChange={e => handleEditFormChange('vcpus', e.target.value)}
                                />
                                <InputLabel htmlFor="new-server-ram">Amount of RAM (GB) per node</InputLabel>
                                <TextInput id="new-server-ram"
                                           type="number"
                                           value={serverStagedForEditing['ram']}
                                           onChange={e => handleEditFormChange('ram', e.target.value)}
                                />
                                <InputLabel htmlFor="new-server-storage">Amount of storage (GB) per node</InputLabel>
                                <TextInput id="new-server-storage"
                                           type="number"
                                           value={serverStagedForEditing['storage']}
                                           onChange={e => handleEditFormChange('storage', e.target.value)}
                                />
                                <InputLabel htmlFor="new-server-cost">All-inclusive hourly cost (leave blank if server is not on-demand eligible)</InputLabel>
                                <TextInput id="new-server-cost"
                                           type="number"
                                           value={serverStagedForEditing['hourly_rate']}
                                           onChange={e => handleEditFormChange('hourly_rate', e.target.value)}
                                />
                                <InputLabel htmlFor="new-server-monthly-subscription">All-inclusive monthly subscription cost</InputLabel>
                                <TextInput id="new-server-monthly-subscription"
                                           type="number"
                                           value={serverStagedForEditing['monthly_rate']}
                                           onChange={e => handleEditFormChange('monthly_rate', e.target.value)}
                                />
                                <InputLabel htmlFor="new-server-monthly-subscription"></InputLabel>
                                <InputLabel htmlFor="new-server-qty">Quantity Available</InputLabel>
                                <TextInput id="new-server-qty"
                                           value={serverStagedForEditing['quantity']}
                                           onChange={e => handleEditFormChange('quantity', e.target.value)}
                                />
                            </AddNewForm>
                            <ConnectButton onClick={() => setEditServerConfirmationModalIsShown(true)}>
                                Update details
                            </ConnectButton>
                        </FormContainer>
                    </>
                ) : (
                    <>
                        <HeaderArea>
                            <AddNewButton onClick={() => setAddNewFormIsShown(true)}>
                                Add new
                            </AddNewButton>
                        </HeaderArea>
                        <ServerList>
                            {inventoryIsLoading ? (
                                <ServerListItemPlaceholder>
                                    <FontAwesomeIcon icon={faCircleNotch} spin />
                                </ServerListItemPlaceholder>
                            ) : (
                                errorLoadingInventory ? (
                                    <ServerListItemPlaceholder>Error.</ServerListItemPlaceholder>
                                ) : (
                                    inventoryList.length === 0 ? (
                                        <ServerListItemPlaceholder>No inventory.</ServerListItemPlaceholder>
                                    ) : (
                                        inventoryList.map((server, index) => {
                                            return (
                                                <HighlightableServerListItem key={server['configuration_id']}
                                                                             draggable
                                                                             onDragStart={() => onDragStart(index, server['configuration_id'])}
                                                                             onDragOver={e => onDragOver(e, index)}
                                                                             onDragEnd={onDragEnd}
                                                                             isHovering={hoverIndex === index}
                                                >
                                                    <ServerInfo>
                                                        <ListItemName>
                                                            {server['gpu_count']}x {server['gpu_type']}
                                                        </ListItemName>
                                                        <ListItemDescription>
                                                            {server['vcpus']} {server['cpu_type']} CPUs | {server['ram']} GB RAM | {server['storage']} GB NVMe SSD
                                                        </ListItemDescription>
                                                    </ServerInfo>
                                                    <ServerTags>
                                                        {server['hourly_rate'] && (
                                                            <OnDemandPrice>
                                                                ${parseFloat(server['hourly_rate']).toFixed(2)}/GPU-hr
                                                            </OnDemandPrice>
                                                        )}
                                                        <OnDemandPrice>
                                                            ${parseFloat(server['monthly_rate']).toFixed(0)}/GPU-mo
                                                        </OnDemandPrice>
                                                        <QuantityTag>
                                                            {server['quantity']} available
                                                        </QuantityTag>
                                                        <SwitchContainer>
                                                            <SwitchTitle>
                                                                Publicly Visible?
                                                            </SwitchTitle>
                                                            <Switch checked={server['visible']}
                                                                    height={20}
                                                                    width={40}
                                                                    onChange={() => handleServerVisibilityChange(server['configuration_id'])}
                                                            />
                                                        </SwitchContainer>
                                                        <RefreshIcon onClick={() => displayEditServerModal(server)}>
                                                            <FontAwesomeIcon icon={faPencil} />
                                                        </RefreshIcon>
                                                        <RefreshIcon onClick={() => displayDeleteServerModal(server['configuration_id'])}>
                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                        </RefreshIcon>
                                                        <ClickAndDragIcon>
                                                            <FontAwesomeIcon icon={faBars} />
                                                        </ClickAndDragIcon>
                                                    </ServerTags>
                                                </HighlightableServerListItem>
                                            )
                                        })
                                    )
                                )
                            )}
                        </ServerList>
                    </>
                )
            )}
            {addNewConfirmationModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setAddNewConfirmationModalIsShown(false)} />
                    <PaddedConfirmModal maxWidth="800px">
                        <ModalTitle>
                            Confirm new VM
                        </ModalTitle>
                        <UnorderedList>
                            <li>{newServerGpuCount}x {newServerGpuType}</li>
                            <li>{newServerCpuCount}x {newServerCpuType}</li>
                            <li>{newServerRam} GB RAM</li>
                            <li>{newServerStorage} GB</li>
                            <li>${newServerHourlyCost}/hour</li>
                            <li>${newServerMonthlyCost}/month</li>
                            <li>{newServerQuantity} available</li>
                        </UnorderedList>
                        <ConnectButton onClick={handleFormSubmit}>
                            Confirm
                        </ConnectButton>
                    </PaddedConfirmModal>
                </>
            )}
            {editServerConfirmationModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setEditServerConfirmationModalIsShown(false)} />
                    <PaddedConfirmModal maxWidth="800px">
                        <ModalTitle>
                            Confirm new details
                        </ModalTitle>
                        <UnorderedList>
                            <li>{serverStagedForEditing['gpu_count']}x {serverStagedForEditing['gpu_type']}</li>
                            <li>{serverStagedForEditing['vcpus']}x {serverStagedForEditing['cpu_type']}</li>
                            <li>{serverStagedForEditing['ram']} GB RAM</li>
                            <li>{serverStagedForEditing['storage']} GB</li>
                            <li>${serverStagedForEditing['hourly_rate']}/hour</li>
                            <li>${serverStagedForEditing['monthly_rate']}/month</li>
                            <li>{serverStagedForEditing['quantity']} available</li>
                        </UnorderedList>
                        <ConnectButton onClick={handleEditServerDetails}>
                            Confirm
                        </ConnectButton>
                    </PaddedConfirmModal>
                </>
            )}
            {newProviderModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissNewProviderModal} />
                    <ConfirmModal>
                        <ModalTitle>
                            Add new provider
                        </ModalTitle>
                        <InputContainer>
                            <InputLabel htmlFor="new-provider-entry">New Provider Name</InputLabel>
                            <LightBgTextInput id="new-provider-entry"
                                              value={newProviderName}
                                              onChange={e => setNewProviderName(e.target.value)}
                            />
                        </InputContainer>
                        <ConnectButtonWithDisabling disabled={newProviderName === ''}
                                                    onClick={addNewProvider}
                        >
                            Submit
                        </ConnectButtonWithDisabling>
                    </ConfirmModal>
                </>
            )}
            {deleteServerModalIsVisible && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissDeleteServerModal} />
                    <ConfirmModal>
                        <ModalTitle>
                            Are you sure?
                        </ModalTitle>
                        <ModalText>
                            Are you sure that you want to delete this server from the inventory?
                        </ModalText>
                        <ConnectButton onClick={handleDeleteServer}>
                            Confirm
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {errorAddingNewProvider && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setErrorAddingNewProvider(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an error adding a new provider. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setErrorAddingNewProvider(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {errorAddingInventory && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setErrorAddingInventory(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an error adding a new server. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setErrorAddingInventory(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {errorDeletingServer && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setErrorDeletingServer(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an error deleting the server. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setErrorDeletingServer(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {errorReorderingServers && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setErrorReorderingServers(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an error reordering the server list. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setErrorReorderingServers(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {errorChangingVisibility && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setErrorChangingVisibility(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an error changing the public visibility of the server. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setErrorChangingVisibility(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {errorEditingServer && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setErrorEditingServer(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an error editing the server details. Please try again later.
                        </ModalText>
                        <ConnectButton onClick={() => setErrorEditingServer(false)}>Okay</ConnectButton>
                    </ConfirmModal>
                </>
            )}
                {(newProviderIsBeingAdded || newServerIsBeingAdded || serverIsBeingDeleted || serversAreBeingReordered || serverVisibilityIsChanging || serverIsBeingEdited) && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </ModalTitle>
                    </ConfirmModal>
                </>
            )}
        </InventoryContainer>
    );
}

export default PortalAdminInventory;