import { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTerminal,
    faComputer,
    faDatabase,
    faBook,
    faUser,
    faHandshake,
    faArrowUpRightFromSquare,
    faKey,
    faCreditCard
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { DashboardContainer, DashboardPanel, NewBadge } from "./SharedStyles";


const PanelTitleText = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`;

const NavGrid = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
  @media screen and (max-width: 850px) {
    justify-content: center;
  }
`;

const NavItem = styled(Link)`
  display: flex;
  border-radius: 10px;
  align-items: center;
  width: 300px;
  background-color: #1a1c47;
  padding: 20px;
  position: relative;
  @media screen and (max-width: 460px) {
    width: auto;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 2px #fff;
  }
`;

const NavItemOutsideLink = styled.a`
  display: flex;
  border-radius: 10px;
  align-items: center;
  width: 300px;
  background-color: #1a1c47;
  padding: 20px;
  position: relative;
  @media screen and (max-width: 460px) {
    width: auto;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 2px #fff;
  }
`;

const NavItemIcon = styled.div`
  font-size: 1.9rem;
  margin-right: 12px;
`;

const NavItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavItemName = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
`;

const NavItemDescription = styled.div`
  font-size: 0.7rem;
`;

const NavItemOutsideLinkIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 9px;
  font-size: 0.9rem;
  opacity: 0.7;
`;

const PositionedNewBadge = styled(NewBadge)`
  position: absolute;
  right: 12px;
  top: 9px;
`;

const PortalHome = ({ setSelectedView }) => {
    useEffect(() => {
        setSelectedView('home');
    }, [setSelectedView]);

    return (
        <DashboardContainer>
            <DashboardPanel>
                <PanelTitleText>
                    VALDI Services
                </PanelTitleText>
                <NavGrid>
                    <NavItem to="/dashboard/direct">
                        <NavItemIcon>
                            <FontAwesomeIcon icon={faComputer} />
                        </NavItemIcon>
                        <NavItemDetails>
                            <NavItemName>
                                Cloud GPUs
                            </NavItemName>
                            <NavItemDescription>
                                Connect to virtual machines on VALDI servers via SSH or RDP.
                            </NavItemDescription>
                        </NavItemDetails>
                    </NavItem>
                    <NavItem to="/dashboard/keys">
                        <NavItemIcon>
                            <FontAwesomeIcon icon={faKey} />
                        </NavItemIcon>
                        <NavItemDetails>
                            <NavItemName>
                                SSH Keys
                            </NavItemName>
                            <NavItemDescription>
                                Generate SSH key pairs for securely accessing your virtual machines.
                            </NavItemDescription>
                        </NavItemDetails>
                    </NavItem>
                    <NavItem to="/dashboard/storage">
                        <NavItemIcon>
                            <FontAwesomeIcon icon={faDatabase} />
                        </NavItemIcon>
                        <NavItemDetails>
                            <NavItemName>
                                Storage
                            </NavItemName>
                            <NavItemDescription>
                                Create volumes that can be mounted to any VALDI VM for persistent storage.
                            </NavItemDescription>
                        </NavItemDetails>
                        <PositionedNewBadge>
                            NEW
                        </PositionedNewBadge>
                    </NavItem>
                    <NavItemOutsideLink href="https://docs.valdi.ai" target="_blank" rel="noreferrer">
                        <NavItemIcon>
                            <FontAwesomeIcon icon={faBook} />
                        </NavItemIcon>
                        <NavItemDetails>
                            <NavItemName>
                                Docs
                            </NavItemName>
                            <NavItemDescription>
                                Read and contribute to the VALDI documentation.
                            </NavItemDescription>
                        </NavItemDetails>
                        <NavItemOutsideLinkIcon>
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </NavItemOutsideLinkIcon>
                    </NavItemOutsideLink>
                    <NavItem to="/dashboard/referrals">
                        <NavItemIcon>
                            <FontAwesomeIcon icon={faHandshake} />
                        </NavItemIcon>
                        <NavItemDetails>
                            <NavItemName>
                                Referrals
                            </NavItemName>
                            <NavItemDescription>
                                Refer new users and earn bonus funds for free computing.
                            </NavItemDescription>
                        </NavItemDetails>
                    </NavItem>
                    <NavItem to="/dashboard/billing">
                        <NavItemIcon>
                            <FontAwesomeIcon icon={faCreditCard} />
                        </NavItemIcon>
                        <NavItemDetails>
                            <NavItemName>
                                Billing
                            </NavItemName>
                            <NavItemDescription>
                                Add or remove payment methods, deposit funds, and configure autopay.
                            </NavItemDescription>
                        </NavItemDetails>
                    </NavItem>
                    <NavItem to="/dashboard/account">
                        <NavItemIcon>
                            <FontAwesomeIcon icon={faUser} />
                        </NavItemIcon>
                        <NavItemDetails>
                            <NavItemName>
                                Your Account
                            </NavItemName>
                            <NavItemDescription>
                                View and configure your account settings.
                            </NavItemDescription>
                        </NavItemDetails>
                    </NavItem>
                </NavGrid>
            </DashboardPanel>
        </DashboardContainer>
    )
}

export default PortalHome;
