import styled from "styled-components";
import ValdiLogo from "./assets/logo-valdi-storj.png";
import { Link } from "react-router-dom";
import { HamburgerMenu } from "./PortalMobileNav";
import {
    Button,
    SecondaryButtonBorder,
    SecondaryButton
} from "./SharedStyles";
import AnnouncementBanner from "./AnnouncementBanner";

const HeaderSuperContainer = styled.div`
  position: fixed;
  z-index: 4;
  width: 100%;
`;

const HeaderMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 4;
  height: 120px;
  background-color: #121331;
  width: 100%;
  @media screen and (max-width: 560px) {
    height: 80px;
  }
  @media screen and (max-width: 360px) {
    height: 60px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1136px;
  padding: 0 32px;
  width: 100%;
`;

const Logo = styled.div` 
  width: 150px;
  & img {
    width: 100%;
  }
  @media screen and (max-width: 560px) {
    width: 110px;
  }
  @media screen and (max-width: 360px) {
    width: 100px;
  }
  @media screen and (max-width: 319px) {
    width: 80px;
  }
  margin-top: 9px;
`;

const Nav = styled.div`
  display: flex;
  font-size: 0.9rem;
  width: 100%;
  justify-content: space-around;
  padding: 0 40px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const NavActiveIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-top: 4px;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  opacity: ${props => props.active ? 1 : 0};
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
  cursor: pointer;
  &:hover ${NavActiveIndicator} {
    opacity: 1;
  }
`;

const ExternalNavItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
  cursor: pointer;
  &:hover ${NavActiveIndicator} {
    opacity: 1;
  } 
`;

const Links = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    display: none;
  }
  @media screen and (max-width: 560px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 360px) {
    font-size: 0.7rem;
  }
`;

const HamburgerMenuAlt = styled(HamburgerMenu)`
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

const LandingHeader = (
    { menuIsOpen, setMenuIsOpen, activeView, bannerIsShown, updateBannerHeight, setBannerIsShown }
) => {
    return (
        <HeaderSuperContainer>
            {bannerIsShown && (
                <AnnouncementBanner updateBannerHeight={updateBannerHeight}
                                    setBannerIsShown={setBannerIsShown}
                />
            )}
            <HeaderMain>
                <HeaderContent>
                    <Link to="/" onClick={() => setMenuIsOpen(false)}>
                        <Logo>
                            <img src={ValdiLogo} alt="" />
                        </Logo>
                    </Link>
                    <Nav>
                        <NavItem to="/compute">
                            <div>GPUs</div>
                            <NavActiveIndicator active={activeView === 'gpu'} />
                        </NavItem>
                        <NavItem to="/storage">
                            <div>Storage</div>
                            <NavActiveIndicator active={activeView === 'storage'} />
                        </NavItem>
                        <ExternalNavItem href="https://docs.valdi.ai" target="_blank">
                            <div>Docs</div>
                            <NavActiveIndicator active={activeView === 'docs'} />
                        </ExternalNavItem>
                        <ExternalNavItem href="mailto:contact@valdi.ai">
                            <div>Contact</div>
                            <NavActiveIndicator active={activeView === 'contact'} />
                        </ExternalNavItem>
                    </Nav>
                    <Links>
                        <Link to="/login">
                            <SecondaryButtonBorder>
                                <SecondaryButton>
                                    Log In
                                </SecondaryButton>
                            </SecondaryButtonBorder>
                        </Link>
                        <Link to="/signup">
                            <Button>
                                Get Started
                            </Button>
                        </Link>
                    </Links>
                    <HamburgerMenuAlt
                        onClick={() => setMenuIsOpen(prev => !prev)}
                        menuOpen={menuIsOpen}
                    >
                        <div />
                        <div />
                        <div />
                    </HamburgerMenuAlt>
                </HeaderContent>
            </HeaderMain>
        </HeaderSuperContainer>
    );
}

export default LandingHeader;