import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBell, faSignOutAlt } from "@fortawesome/pro-solid-svg-icons";

const NavbarContainer = styled.div`
  background-color: #1a1c47;
  padding: 30px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  @media screen and (max-width: 750px) {
    display: none;
  }
`;

const SectionTitle = styled.div`
  font-size: 2.2rem;
  font-weight: 600;
`;

const SectionTitleDim = styled.span`
  opacity: 0.5;
`;

const SectionDivider = styled.span`
  font-size: 1.8rem;
  font-weight: 200;
  margin: 0 20px;
`;

const SectionSubtitle = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
`;

const NavbarActionItems = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const NavbarActionItem = styled.div`
  position: relative;
  display: flex;
  font-size: 1.3rem;
  padding: 15px 20px;
  margin-right: 4px;
  border-radius: 10px;
  background-color: ${props => props.isSelected ? '#121331': 'none'};
  &:hover {
    background-color: #121331;
    cursor: pointer;
  }
`;

const Username = styled.div`
  font-size: 0.9rem;
  margin-top: 1px;
  margin-right: 8px;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${params => params.zIndex ? params.zIndex : 20};
  background-color: ${params => params.bgColor ? params.bgColor : "transparent"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileMenu = styled.div`
  background-color: #121331;
  border-radius: 10px;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 25;
  padding: 15px 10px;
`;

const ProfileMenuItem = styled.div`
  font-size: 0.9rem;
  font-weight: 300;
  padding: 10px 20px;
  min-width: 150px;
  border-radius: 5px;
  margin-bottom: 3px;
  white-space: nowrap;
  &:hover {
    background-color: rgba(26, 28, 71, 0.6);
  }
`;

const NotificationsMenu = styled.div`
  background-color: #121331;
  border-radius: 10px;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 25;
  font-size: 0.8rem;
  font-weight: 200;
  text-align: center;
  min-width: 300px;
  padding: 25px 0;
`;

const PortalTopNavbar = ({ selectedView, username, showLogoutModal }) => {
    const [ selectedItem, setSelectedItem ] = useState();
    const [ sectionTitle, setSectionTitle ] = useState('');

    useEffect(() => {
        setSelectedItem(null);
        if (selectedView === 'home') {
            setSectionTitle('Welcome')
        } else if (selectedView === 'containers') {
            setSectionTitle('VALDI Containers')
        } else if (selectedView === 'cloud') {
            setSectionTitle('VALDI Cloud')
        } else if (selectedView === 'wallet') {
            setSectionTitle('Wallet')
        } else if (selectedView === 'explorer') {
            setSectionTitle('Blockchain')
        } else if (selectedView === 'account') {
            setSectionTitle('Your Account')
        } else if (selectedView === 'billing') {
            setSectionTitle('Account Billing')
        } else if (selectedView === 'network') {
            setSectionTitle('Network')
        } else if (selectedView === 'direct') {
            setSectionTitle('Cloud GPUs')
        } else if (selectedView === 'storage') {
            setSectionTitle('Storage')
        } else if (selectedView === 'volume') {
            setSectionTitle('Your Storage Volume')
        } else if (selectedView === 'repository') {
            setSectionTitle('Your Repository')
        } else if (selectedView === 'service') {
            setSectionTitle('Your Service')
        } else if (selectedView === 'server') {
            setSectionTitle('Server Details')
        } else if (selectedView === 'vm') {
            setSectionTitle('Configure Virtual Machine')
        } else if (selectedView === 'server-session') {
            setSectionTitle('Your Server')
        } else if (selectedView === 'vm-session') {
            setSectionTitle('Your Virtual Machine')
        } else if (selectedView === 'payment-method') {
            setSectionTitle('Enter payment method')
        } else if (selectedView === 'pm-confirm') {
            setSectionTitle('Payment method added')
        } else if (selectedView === 'referrals') {
            setSectionTitle('Referrals')
        } else if (selectedView === 'admin') {
            setSectionTitle('Admin Console')
        } else if (selectedView === 'admin-customer') {
            setSectionTitle('VALDI User')
        } else if (selectedView === 'admin-vm-session') {
            setSectionTitle('User Virtual Machine')
        } else if (selectedView === 'keys') {
            setSectionTitle('SSH Keys');
        }
    }, [selectedView]);

    return (
        <NavbarContainer>
            <SectionTitle>{sectionTitle}</SectionTitle>
            <NavbarActionItems>
                <NavbarActionItem
                    style={{marginRight: "5px"}}
                    onClick={() => setSelectedItem('account')}
                    isSelected={selectedItem === 'account'}
                >
                    <Username>{username ? username : '-'}</Username>
                    <FontAwesomeIcon icon={faUser} />
                    {selectedItem === 'account' &&
                        <ProfileMenu>
                            <Link to='/dashboard/account'>
                                <ProfileMenuItem>
                                    <FontAwesomeIcon icon={faUser} style={{marginLeft: "-1px", marginRight: "3px"}} /> Your Account
                                </ProfileMenuItem>
                            </Link>
                            <ProfileMenuItem onClick={() => showLogoutModal(true)}>
                                <FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: "5px"}} />Log Out
                            </ProfileMenuItem>
                        </ProfileMenu>}
                </NavbarActionItem>
                <NavbarActionItem
                    onClick={() => setSelectedItem('notifications')}
                    isSelected={selectedItem === 'notifications'}
                >
                    <FontAwesomeIcon icon={faBell} />
                    {selectedItem === 'notifications' &&
                        <NotificationsMenu>No new notifications.</NotificationsMenu>}
                </NavbarActionItem>
            </NavbarActionItems>
            {selectedItem && <Cover onClick={() => setSelectedItem(null)} />}
        </NavbarContainer>
    );
}

export default PortalTopNavbar;