import { useEffect } from "react";

const RedditPixel = () => {
    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_dhhohfqy2613', {"optOut":false,"useDecimalCurrencyValues":true});rdt('track', 'PageVisit');`
            document.head.appendChild(script);
        }
    }, []);
    return null;
}

export default RedditPixel;