import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReservedInventory from "./ReservedInventory";
import LiveInventory from "./LiveInventory";
import styled from "styled-components";
import {
    LandingComputeContainer,
    InventoryContainer,
    DirectContainer,
    HeroMessage,
    HeroMessageHighlightLine,
    HeroMessageRegularLine,
    HeroSubtext
} from "./SharedStyles";
import {
    SelectorButton,
    SelectorIcon,
    SelectorText
} from "./PortalDirectHome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faServer } from "@fortawesome/pro-light-svg-icons";

const HeroMessageBoldLarger = styled.div`
  font-size: 5.5rem;
  font-weight: 800;
  @media screen and (max-width: 1004px) {
    font-size: calc(4rem * 1.225);
  }
  @media screen and (max-width: 768px) {
    font-size: calc(3rem * 1.225);
  }
  @media screen and (max-width: 591px) {
    font-size: calc(2.5rem * 1.225);
  }
  @media screen and (max-width: 509px) {
    font-size: calc(2.4rem * 1.225);
  }
  @media screen and (max-width: 492px) {
    font-size: calc(2.15rem * 1.225);
  }
  @media screen and (max-width: 452px) {
    font-size: calc(2rem * 1.225);
  }
  @media screen and (max-width: 427px) {
    font-size: calc(1.9rem * 1.225);
  }
  @media screen and (max-width: 411px) {
    font-size: calc(1.85rem * 1.225);
  }
  @media screen and (max-width: 380px ) {
    font-size: calc(1.8rem * 1.225);
  }
  @media screen and (max-width: 371px) {
    font-size: calc(1.73rem * 1.225);
  }
  @media screen and (max-width: 360px) {
    font-size: calc(1.7rem * 1.225);
  }
  @media screen and (max-width: 354px) {
    font-size: calc(1.6rem * 1.225);
  }
  @media screen and (max-width: 337px) {
    font-size: calc(1.3rem * 1.225);
  }
  @media screen and (max-width: 286px) {
    font-size: calc(1.25rem * 1.225);
  }
`;

const Selectors = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 35px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LandingCompute = ({ setActiveView, bannerHeight }) => {
    const [ stockType, setStockType ] = useState('ondemand');
    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        setActiveView('gpu');
    }, [setActiveView]);

    useEffect(() => {
        const type = query.get('type');
        if (type === 'reserved') {
            setStockType(type);
        } else {
            setStockType('ondemand');
        }
    }, []);

    return (
        <LandingComputeContainer>
            <DirectContainer>
                <InventoryContainer topMarginOffset={bannerHeight}>
                    <HeroMessage>
                        <HeroMessageBoldLarger>
                            <HeroMessageHighlightLine>
                                So many servers.
                            </HeroMessageHighlightLine>
                            <HeroMessageRegularLine>
                                So little cost.
                            </HeroMessageRegularLine>
                        </HeroMessageBoldLarger>
                        <HeroSubtext>
                            VALDI's extensive inventory boasts cutting-edge chips like the H100, A100, A6000, and
                            4090. Scroll down to see our real-time, up-to-date stock.
                        </HeroSubtext>
                        <Selectors>
                            <SelectorButton onClick={() => setStockType('ondemand')}
                                            outline={stockType === 'ondemand'}
                            >
                                <SelectorIcon>
                                    <FontAwesomeIcon icon={faBolt} />
                                </SelectorIcon>
                                <SelectorText>
                                    On-demand stock
                                </SelectorText>
                            </SelectorButton>
                            <SelectorButton onClick={() => setStockType('reserved')}
                                            outline={stockType === 'reserved'}
                            >
                                <SelectorIcon>
                                    <FontAwesomeIcon icon={faServer} />
                                </SelectorIcon>
                                <SelectorText>
                                    Reserved stock
                                </SelectorText>
                            </SelectorButton>
                        </Selectors>
                    </HeroMessage>
                    {stockType === 'ondemand' && (
                        <LiveInventory />
                    )}
                    {stockType === 'reserved' && (
                        <ReservedInventory />
                    )}
                </InventoryContainer>
            </DirectContainer>
        </LandingComputeContainer>
    )
}

export default LandingCompute;