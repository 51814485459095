import styled from "styled-components";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import './App.css';
import Login from "./Login";
import Signup from "./Signup";
import Portal from "./Portal";
import VerifyEmail from "./VerifyEmail";
import ResetPassword from "./ResetPassword";
import Landing from "./Landing";
import GoogleAnalytics from "./GoogleAnalytics";
import RedditPixel from "./RedditPixel";
import BWSLogo from "./assets/image-bws-logo-no-text.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { Helmet } from "react-helmet";
import ValdiLogo from "./assets/valdi-logo.png";

const LoadingCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #121331;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  width: 40px;
  height: 40px;
  & img {
    width: 100%;
  }
`;

const LogoutConfirmationModal = styled.div`
  background-color: #292c6e;
  padding: 40px 25px;
  border-radius: 10px;
  font-size: 1.4rem;
  color: #fff;
  z-index: 45;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const ModalText = styled.div`
  font-size: 1rem;
  font-weight: 300;
  max-width: 300px;
  text-align: center;
`;

const ModalButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
`;

const ModalButton = styled.div`
  padding: 8px 20px;
  background: ${params => params.bgColor ? params.bgColor : "linear-gradient(to right, #9438c2, #ff1f82)"};
  font-size: 1.1rem;
  color: #fff;
  border: ${params => params.customOutline && params.customOutline};
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  &:hover {
    background: ${params => params.hoverBg ? params.hoverBg : "linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9))"};
    cursor: pointer;
  }
`;

const AppCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${params => params.zIndex ? params.zIndex : 20};
  background-color: ${params => params.bgColor ? params.bgColor : "transparent"};
`;

const StagingTag = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-top-right-radius: 10px;
`;

const MaintenancePage = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #1a1c47;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MaintenanceMainHeading = styled.div`
  font-size: 1.4rem;
  margin-bottom: 15px;
  max-width: 350px;
  text-align: center;
`;

const MaintenanceSubTitle = styled.div`
  font-weight: 300;
  max-width: 400px;
  text-align: center;
  line-height: 2;
`;

const MaintenanceLogo = styled.div`
  width: 175px;
  height: 175px;
  & img {
    width: 100%;
  }
`;

function App() {
    const [ appIsLoading, setAppIsLoading ] = useState(true);
    const [ refreshToken, setRefreshToken ] = useState(() => {
        if (localStorage.getItem('refresh-token') !== null) {
            return localStorage.getItem('refresh-token');
        } else {
            return null;
        }
    });
    const [ accessToken, setAccessToken ] = useState();
    const [ logoutModalIsShown, setLogoutModalIsShown ] = useState(false);
    const [ maintenanceMode, setMaintenanceMode ] = useState(false);
    const [ announcementBannerIsShown, setAnnouncementBannerIsShown ] = useState(false);
    const [ bannerHeight, setBannerHeight ] = useState(0);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/maintenance`)
            .then(response => response.json())
            .then(data => {
                const maintenanceModeOn = data['maintenance_mode_on'];
                setMaintenanceMode(maintenanceModeOn);
                if (maintenanceModeOn) {
                    setAppIsLoading(false);
                } else {
                    const getNewAccessToken = () => {
                        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account/refresh_token`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                token: refreshToken
                            })
                        })
                            .then(response => {
                                if (response['status'] < 299) {
                                    return response.json();
                                } else {
                                    setAccessToken(null);
                                    setAppIsLoading(false);
                                }
                            })
                            .then(data => {
                                if (data['access_token']) {
                                    setAccessToken(data['access_token']);
                                    setAppIsLoading(false);
                                }
                            })
                            .catch(() => {
                                setAccessToken(null);
                                setAppIsLoading(false);
                            });
                    }

                    let accessTokenPoller;
                    if (refreshToken) {
                        localStorage.setItem('refresh-token', refreshToken);
                        if (!accessToken) {
                            getNewAccessToken();
                        }
                        accessTokenPoller = setInterval(getNewAccessToken, 270000);
                    } else {
                        localStorage.removeItem('refresh-token');
                        setAppIsLoading(false);
                    }

                    return () => clearInterval(accessTokenPoller);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }, [refreshToken]);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://desk.zoho.com/portal/api/web/inapp/936256000000299114?orgId=840099190";
        script.type = 'text/javascript';
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const handleLogout = () => {
        setLogoutModalIsShown(false);
        setRefreshToken(null);
        setAccessToken(null);
    }

    return (
        <BrowserRouter>
            <GoogleAnalytics />
            <RedditPixel />
            <Helmet>
                <title>VALDI | Affordable, reliable GPUs</title>
                <meta name="title" content="VALDI | Affordable, reliable GPUs" />
                <meta name="description" content="Built by AI engineers for AI engineers. Get a GPU running in seconds." />

                <meta property="og:type" content="website" />
                {process.env.REACT_APP_ENVIRONMENT === 'production' && (
                    <meta property="og:url" content="https://valdi.ai" />
                )}
                {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
                    <meta property="og:url" content="https://stage.valdi.ai" />
                )}
                <meta property="og:title" content="VALDI | Affordable, reliable GPUs" />
                <meta property="og:description" content="Built by AI engineers for AI engineers. Get a GPU running in seconds." />
                {process.env.REACT_APP_ENVIRONMENT === 'production' && (
                    <meta property="og:image" content="https://valdi.ai/valdi-link-preview.png" />
                )}
                {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
                    <meta property="og:image" content="https://stage.valdi.ai/valdi-link-preview.png" />
                )}

                <meta property="twitter:card" content="summary_large_image" />
                {process.env.REACT_APP_ENVIRONMENT === 'production' && (
                    <meta property="twitter:url" content="https://valdi.ai" />
                )}
                {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
                    <meta property="twitter:url" content="https://stage.valdi.ai" />
                )}
                <meta property="twitter:title" content="VALDI | Affordable, reliable GPUs" />
                <meta property="twitter:description" content="Built by AI engineers for AI engineers. Get a GPU running in seconds." />
                {process.env.REACT_APP_ENVIRONMENT === 'production' && (
                    <meta property="twitter:image" content="https://valdi.ai/valdi-link-preview.png" />
                )}
                {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
                    <meta property="twitter:image" content="https://stage.valdi.ai/valdi-link-preview.png" />
                )}
            </Helmet>
            {process.env.REACT_APP_ENVIRONMENT === 'staging' && <StagingTag>STAGING</StagingTag>}
            {process.env.REACT_APP_ENVIRONMENT === 'local' && <StagingTag>LOCAL</StagingTag>}
            {appIsLoading && (
                <LoadingCover>
                    <LoaderContainer>
                        <Logo>
                            <img src={BWSLogo} alt="" />
                        </Logo>
                        <FontAwesomeIcon icon={faSpinnerThird} spin />
                    </LoaderContainer>
                </LoadingCover>
            )}
            {maintenanceMode ? (
                <MaintenancePage>
                    <MaintenanceLogo>
                        <img src={ValdiLogo} alt="" />
                    </MaintenanceLogo>
                    <MaintenanceMainHeading>
                        VALDI is currently undergoing maintenance.
                    </MaintenanceMainHeading>
                    <MaintenanceSubTitle>
                        All virtual machines remain online with data intact. Please check back later.
                    </MaintenanceSubTitle>
                </MaintenancePage>
            ) : (
                <Switch>
                    <Route path="/reset-password">
                        <ResetPassword />
                    </Route>
                    <Route path="/verify-email">
                        <VerifyEmail />
                    </Route>
                    <Route path="/signup">
                        <Signup token={accessToken}
                                loading={appIsLoading}
                        />
                    </Route>
                    <Route path="/login">
                        <Login updateAccessToken={setAccessToken}
                               updateRefreshToken={setRefreshToken}
                               token={accessToken}
                               loading={appIsLoading}
                        />
                    </Route>
                    <Route path="/dashboard">
                        <Portal token={accessToken}
                                showLogoutModal={setLogoutModalIsShown}
                                loading={appIsLoading}
                                bannerIsShown={announcementBannerIsShown}
                                updateBannerHeight={setBannerHeight}
                                bannerHeight={bannerHeight}
                                setBannerIsShown={setAnnouncementBannerIsShown}
                        />
                    </Route>
                    <Route path="/">
                        <Landing token={accessToken}
                                 loading={appIsLoading}
                                 bannerIsShown={announcementBannerIsShown}
                                 updateBannerHeight={setBannerHeight}
                                 bannerHeight={bannerHeight}
                                 setBannerIsShown={setAnnouncementBannerIsShown}
                        />
                    </Route>
                </Switch>
            )}
            {logoutModalIsShown &&
                <>
                    <AppCover bgColor="rgba(0, 0, 0, 0.6)"
                           zIndex="41"
                           onClick={() => setLogoutModalIsShown(false)}
                    />
                    <LogoutConfirmationModal>
                        <ModalText>
                            Are you sure you want to log out?
                        </ModalText>
                        <ModalButtons>
                            <ModalButton bgColor="transparent"
                                         hoverBg="rgba(255, 255, 255, 0.1)"
                                         customOutline="1px solid #fff"
                                         onClick={() => setLogoutModalIsShown(false)}
                            >
                                Cancel
                            </ModalButton>
                            <ModalButton onClick={handleLogout}>
                                Log Out
                            </ModalButton>
                        </ModalButtons>
                    </LogoutConfirmationModal>
                </>
            }
        </BrowserRouter>
    );
}

export default App;
