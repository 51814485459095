import styled from "styled-components";
import { useEffect, useState } from "react";
import { useHistory, Link, Switch, Route } from "react-router-dom";
import LandingHeader from "./LandingHeader";
import LandingMain from "./LandingMain";
import LandingCompute from "./LandingCompute";
import LandingStorage from "./LandingStorage";
import LandingFooter from "./LandingFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faComputer, faDatabase, faBook, faComment } from "@fortawesome/pro-light-svg-icons";
import { SecondaryButtonBorder, Button, SecondaryButton} from "./SharedStyles";
import { faLinkedin, faMailchimp, faMedium, faXTwitter } from "@fortawesome/free-brands-svg-icons";

const LandingWrapper = styled.div`
`;

const MobileMenu = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #121331;
  display: flex;
  flex-direction: column;
  padding: 150px 50px 0 35px;
  margin-top: ${props => `${props.topMarginOffset}px`};
`;

const MenuItem = styled.div`
  background-color: ${props => props.isActive && '#1a1c47'};
  border-radius: 10px;
  padding: 15px 25px 15px 25px;
  margin: 4px 0;
  &:hover {
    cursor: pointer;
    background-color: rgba(26, 28, 71, 0.6);
  }
`;

const MenuItemText = styled.div`
  display: inline;
  margin-left: 10px;
`;

const WideSecondaryButtonBorder = styled(SecondaryButtonBorder)`
  margin-right: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
`;

const WideSecondaryButton = styled(SecondaryButton)`
  width: 100%;
  display: flex;
`;

const WideButton = styled(Button)`
  width: 100%;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  opacity: 0.7;
  font-size: 1.5rem;
  position: absolute;
  bottom: 20px;
  width: 100%;
  max-width: 200px;
  align-self: center;
`;

const Landing = ({ loading, token, bannerIsShown, updateBannerHeight, bannerHeight, setBannerIsShown }) => {
    const [ hamburgerMenuIsOpen, setHamburgerMenuIsOpen ] = useState(false);
    const [ activeView, setActiveView ] = useState('home');
    const history = useHistory();

    useEffect(() => {
        if (!loading) {
            if (token) {
                history.push('/dashboard');
            }
        }
    }, [loading, token]);

    useEffect(() => {
        setHamburgerMenuIsOpen(false);
        document.documentElement.scrollTop = 0;
    }, [activeView]);

    useEffect(() => {
        if (hamburgerMenuIsOpen) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
        }
    }, [hamburgerMenuIsOpen])

    return(
        <LandingWrapper>
            {hamburgerMenuIsOpen && (
                <MobileMenu topMarginOffset={bannerHeight}>
                    <Link to="/">
                        <MenuItem isActive={activeView === 'home'}>
                            <FontAwesomeIcon icon={faHouse} style={{width: "20px"}} />
                            <MenuItemText>
                                Home
                            </MenuItemText>
                        </MenuItem>
                    </Link>
                    <Link to="/compute">
                        <MenuItem isActive={activeView === 'gpu'}>
                            <FontAwesomeIcon icon={faComputer} style={{width: "20px"}} />
                            <MenuItemText>
                                GPUs
                            </MenuItemText>
                        </MenuItem>
                    </Link>
                    <Link to="/storage">
                        <MenuItem isActive={activeView === 'storage'}>
                            <FontAwesomeIcon icon={faDatabase} style={{width: "20px"}} />
                            <MenuItemText>
                                Storage
                            </MenuItemText>
                        </MenuItem>
                    </Link>
                    <a href="https://docs.valdi.ai" target="_blank" onClick={() => setHamburgerMenuIsOpen(false)}>
                        <MenuItem isActive={activeView === 'docs'}>
                            <FontAwesomeIcon icon={faBook} style={{width: "20px"}} />
                            <MenuItemText>
                                Docs
                            </MenuItemText>
                        </MenuItem>
                    </a>
                    <a href="mailto:contact@valdi.ai" onClick={() => setHamburgerMenuIsOpen(false)}>
                        <MenuItem isActive={activeView === 'contact'}>
                            <FontAwesomeIcon icon={faComment} style={{width: "20px"}} />
                            <MenuItemText>
                                Contact
                            </MenuItemText>
                        </MenuItem>
                    </a>
                    <Link to="/login">
                        <WideSecondaryButtonBorder>
                            <WideSecondaryButton>
                                Log In
                            </WideSecondaryButton>
                        </WideSecondaryButtonBorder>
                    </Link>
                    <Link to="/signup">
                        <WideButton>
                            Get Started
                        </WideButton>
                    </Link>
                    <SocialLinks>
                        <a href="https://www.linkedin.com/company/valdilabs/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="http://eepurl.com/isSfIA" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faMailchimp} />
                        </a>
                        <a href="https://medium.com/@valdilabs" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faMedium} />
                        </a>
                        <a href="https://x.com/ValdiLabs" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} />
                        </a>
                    </SocialLinks>
                </MobileMenu>
            )}
            <LandingHeader
                activeView={activeView}
                setMenuIsOpen={setHamburgerMenuIsOpen}
                menuIsOpen={hamburgerMenuIsOpen}
                bannerIsShown={bannerIsShown}
                updateBannerHeight={updateBannerHeight}
                setBannerIsShown={setBannerIsShown}
            />
            <Switch>
                <Route exact path="/compute">
                    <LandingCompute setActiveView={setActiveView}
                                    bannerHeight={bannerHeight}
                    />
                </Route>
                <Route exact path="/storage">
                    <LandingStorage setActiveView={setActiveView}
                                    bannerHeight={bannerHeight}
                    />
                </Route>
                <Route exact path="/">
                    <LandingMain setActiveView={setActiveView}
                                 bannerHeight={bannerHeight}
                    />
                </Route>
                <Route path="*">
                    <LandingMain setActiveView={setActiveView}
                                 bannerHeight={bannerHeight}
                    />
                </Route>
            </Switch>
            <LandingFooter />
        </LandingWrapper>
    );
}


export default Landing;