import styled from "styled-components";
import { useState, useEffect } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

const PaymentDetailsForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.button`
  font-family: inherit;
  padding: 10px 20px;
  margin: 15px auto 0 auto;
  font-size: 1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  font-size: 0.7rem;
  color: red;
  margin-top: 20px;
  max-width: 200px;
  text-align: center;
`;

const PortalPaymentMethodForm = ({ setWaitingForResponse }) => {
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ baseUrl, setBaseUrl ] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        const url = window.location.href;
        const path = window.location.pathname;
        const regex = new RegExp(path + '$', 'i');
        setBaseUrl((url.split('?')[0]).replace(regex, ''));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setWaitingForResponse(true);
        if (!stripe || !elements) {
            setWaitingForResponse(false);
            return;
        }

        stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${baseUrl}/dashboard/billing/payment-method/confirmation`
            }
        })
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error.message);
                }
            })
            .finally(() => {
                setWaitingForResponse(false);
            })
    }

    return (
        <PaymentDetailsForm onSubmit={handleSubmit}>
            <PaymentElement />
            <SubmitButton disabled={!stripe}>
                Submit
            </SubmitButton>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </PaymentDetailsForm>
    );
}

export default PortalPaymentMethodForm;