import styled from "styled-components";
import BWSLogo from "./assets/image-bws-logo-no-text.png";
import { useState, useEffect, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifiSlash } from "@fortawesome/pro-light-svg-icons";
import {
    faCheck,
    faXmark,
    faCircleCheck,
    faTriangleExclamation,
    faSpinnerThird
} from "@fortawesome/pro-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { DropDownList, InputErrorText } from "./SharedStyles";


const SignupContainer = styled.div`
  position: relative;
  background-color: #1a1c47;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 50px;
  @media screen and (max-width: 550px) {
    padding: 0;
  }
`;

const SignupPanel = styled.div`
  background-color: #121331;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  border-radius: 10px;
  min-width: 450px;
  @media screen and (max-width: 550px) {
    width: 100%;
    border-radius: 0;
    min-height: 100vh;
    justify-content: start;
    padding: 50px;
    min-width: 0;
  }
`;

const Logo = styled.div`
  width: 70px;
  height: 70px;
  & img {
    width: 100%;
  }
  margin: -10px 0 -30px 0;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 40px 0 30px 0;
`;

export const TextInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: 1rem;
  margin-bottom: 15px;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #1a1c47;
  color: #fff;
  width: 100%;
  &:focus {
    outline: ${props => props.errorOutline ? "2px solid red": "2px solid rgba(255, 255, 255, 0.9)"};
  }
  outline: ${props => props.errorOutline ? "2px solid red" : "none"};
`;

const InputFeedbackIcon = styled.div`
  position: absolute;
  top: 9px;
  right: 10px;
`;

const InputFeedbackIcon2 = styled.div`
  position: absolute;
  top: 9px;
  right: 20px;
`;

const InputFeedbackBlock = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const Checkbox = styled.input`
  position: relative;
  top: 2px;
  margin-right: 5px;
  outline: ${props => props.errorOutline ? "3px solid red" : "none"};
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  &:hover {
    cursor: pointer;
  }
`;

export const InputLabel = styled.label`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.9);
  & a {
    text-decoration: underline dotted;
  }
`;

export const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormSubmitButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  padding: 10px 20px;
  margin: 15px auto 0 auto;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const SmallTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
`

const SmallText = styled.div`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  &:first-child {
    margin-bottom: 5px;
  }
  & a:hover {
    text-decoration: underline;
  }
`;

const LoadingCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 101vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 3rem;
  backdrop-filter: blur(1px);
`;

const ResponseModal = styled.div`
  position: fixed;
  top: 25%;
  align-self: center;
  padding: 40px 15px;
  z-index: 11;
  border-radius: 10px;
  background-color: #121331;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ResponseModalTitle = styled.div`
  font-size: 2rem;
  font-weight: 400;
  margin: 15px 0;
`;

const ResponseModalText = styled.div`
  font-size: 1rem;
  font-weight: 300;
  max-width: 300px;
`;

const ResponseModalButton = styled.div`
  padding: 8px 20px;
  margin: 25px auto 0 auto;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.9);
  }
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const Signup = ({ token, loading }) => {
    const [ email, setEmail ] = useState("");
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ passwordRepeat, setPasswordRepeat ] = useState("");
    const [ country, setCountry ] = useState("");
    const [ countries, setCountries ] = useState();
    const [ userAgreesToTerms, setUserAgreesToTerms ] = useState(false);
    const [ passwordIsVisible, setPasswordIsVisible ] = useState(false);
    const [ rPasswordIsVisible, setRPasswordIsVisible ] = useState(false);


    const [ waitingForEmailValidation, setWaitingForEmailValidation ] = useState(false);
    const [ emailValidationReceived, setEmailValidationReceived ] = useState(false);
    const [ emailError, setEmailError ] = useState(false);
    const [ emailIsTaken, setEmailIsTaken ] = useState(false);
    const [ emailIsEmpty, setEmailIsEmpty ] = useState(false);
    const [ emailIsMalformed, setEmailIsMalformed ] = useState(false);

    const [ waitingForUsernameValidation, setWaitingForUsernameValidation ] = useState(false);
    const [ usernameValidationReceived, setUsernameValidationReceived ] = useState(false);
    const [ usernameError, setUsernameError ] = useState(false);
    const [ usernameIsTaken, setUsernameIsTaken ] = useState(false);
    const [ usernameIsEmpty, setUsernameIsEmpty ] = useState(false);
    const [ usernameIsMalformed, setUsernameIsMalformed ] = useState(false);
    const [ usernameIsBadLength, setUsernameIsBadLength ] = useState(false);

    const [ passwordValidationReceived, setPasswordValidationReceived ] = useState(false);
    const [ passwordError, setPasswordError ] = useState(false);
    const [ passwordIsEmpty, setPasswordIsEmpty ] = useState(false);
    const [ passwordIsMalformed, setPasswordIsMalformed ] = useState(false);
    const [ passwordIsTooShort, setPasswordIsTooShort ] = useState(false);

    const [ rPasswordValidationReceived, setRPasswordValidationReceived ] = useState(false);
    const [ rPasswordError, setRPasswordError ] = useState(false);
    const [ rPasswordIsEmpty, setRPasswordIsEmpty ] = useState(false);
    const [ rPasswordIsMismatched, setRPasswordIsMismatched ] = useState(false);

    const [ countryError, setCountryError ] = useState(false);
    const [ agreementError, setAgreementError ] = useState(false);

    const [ referralCode, setReferralCode ] = useState('');
    const [ referralCodeError, setReferralCodeError ] = useState(false);

    const [ waitingForEndpoint, setWaitingForEndpoint ] = useState(false);
    const [ successModalIsShown, setSuccessModalIsShown ] = useState(false);
    const [ emailErrorModalIsShown, setEmailErrorModalIsShown ] = useState(false);
    const [ usernameErrorModalIsShown, setUsernameErrorModalIsShown ] = useState(false);
    const [ networkErrorModalIsShown, setNetworkErrorModalIsShown ] = useState(false);
    const [ unexpectedErrorModalIsShown, setUnexpectedErrorModalIsShown ] = useState(false);

    const inputFieldRef = useRef();
    const history = useHistory();
    let query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        if (!loading) {
            if (token) {
                history.push('/dashboard');
            }
        }
    }, [token, loading]);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        const urlRefCode = query.get('ref');
        if (urlRefCode !== null) {
            setReferralCode(urlRefCode);
        }
    }, []);

    useEffect(() => {
        inputFieldRef.current.focus();
    }, [inputFieldRef]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/validation/countries`)
            .then(response => response.json())
            .then(data => {
                if (data && !data['error'] && data['countries']) {
                    setCountries(data['countries']);
                }
            })
            .catch(error => console.log(error));
    }, [])

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        }
    }

    const validateEmail = emailInput => {
        setWaitingForEmailValidation(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/validation/email/${emailInput.toLowerCase()}`)
            .then(response => {
                if (response['status'] < 299) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then(data => {
                if (data) {
                    if ('is_unique' in data) {
                        setEmailError(!data['is_unique']);
                        setEmailIsTaken(!data['is_unique']);
                        setEmailValidationReceived(true);
                        setWaitingForEmailValidation(false);
                    }
                }
            });
    }

    const debouncedValidateEmail = useCallback(debounce(validateEmail, 500), []);

    const handleEmailInput = e => {
        const emailInput = e.target.value;

        setEmailError(false);
        setEmailIsEmpty(false);
        setEmailIsTaken(false);
        setEmailIsMalformed(false);
        setEmailValidationReceived(false);

        setEmail(emailInput);

        const validCheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailInput) {
            if (validCheck.test(emailInput)) {
                debouncedValidateEmail(emailInput);
            } else {
                setEmailError(true);
                setEmailIsMalformed(true);
            }
        }
    }

    const validateUsername = usernameInput => {
        setWaitingForUsernameValidation(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/validation/username/${usernameInput.toLowerCase()}`)
            .then(response => {
                if (response['status'] < 299) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then(data => {
                if (data) {
                    if ('is_unique' in data) {
                        setUsernameError(!data['is_unique']);
                        setUsernameIsTaken(!data['is_unique']);
                        setUsernameValidationReceived(true);
                        setWaitingForUsernameValidation(false);
                    }
                }
            });
    }

    const debouncedValidateUsername = useCallback(debounce(validateUsername, 500), []);

    const handleUsernameInput = e => {
        const usernameInput = e.target.value;

        setUsernameError(false);
        setUsernameIsEmpty(false);
        setUsernameIsTaken(false);
        setUsernameIsMalformed(false);
        setUsernameIsBadLength(false);
        setUsernameValidationReceived(false);

        setUsername(usernameInput);

        const invalidCheck = /([^A-Za-z0-9.\_])/g;
        if (usernameInput) {
            if (usernameInput.length < 2 || usernameInput.length > 32) {
                setUsernameError(true);
                setUsernameIsBadLength(true);
            }
            else if (usernameInput[0] === '.' || usernameInput[0] === '_' || invalidCheck.test(usernameInput)) {
                setUsernameError(true);
                setUsernameIsMalformed(true);
            } else {
                debouncedValidateUsername(usernameInput);
            }
        }
    }

    const handlePasswordInput = e => {
        const passwordInput = e.target.value;

        setPasswordError(false);
        setPasswordIsEmpty(false);
        setPasswordIsMalformed(false);
        setPasswordIsTooShort(false);
        setPasswordValidationReceived(false);

        setRPasswordError(false);
        setRPasswordIsMismatched(false);
        setRPasswordValidationReceived(false);

        setPassword(passwordInput);

        const capLetterCheck = /[A-Z]/g;
        const lowerLetterCheck = /[a-z]/g;
        const numCheck = /[0-9]/g;
        const specCheck = /[!@#$%^&*()]/g;
        if (passwordInput) {
            setPasswordValidationReceived(true);
            if (passwordInput.length < 8) {
                setPasswordError(true);
                setPasswordIsTooShort(true);
            } else if (!capLetterCheck.test(passwordInput) || !lowerLetterCheck.test(passwordInput) || !numCheck.test(passwordInput) || !specCheck.test(passwordInput)) {
                setPasswordError(true);
                setPasswordIsMalformed(true);
            }
        }
        if (passwordRepeat) {
            setRPasswordValidationReceived(true);
            if (passwordRepeat !== passwordInput) {
                setRPasswordError(true);
                setRPasswordIsMismatched(true);
            }
        }
    }

    const handlePasswordRepeatInput = e => {
        const rPasswordInput = e.target.value;

        setRPasswordError(false);
        setRPasswordIsEmpty(false);
        setRPasswordIsMismatched(false);
        setRPasswordValidationReceived(false);

        setPasswordRepeat(rPasswordInput);

        if (rPasswordInput) {
            setRPasswordValidationReceived(true);
            if (rPasswordInput !== password) {
                setRPasswordError(true);
                setRPasswordIsMismatched(true);
            }
        }
    }

    const handleDropDownListSelection = e => {
        setCountryError(false);
        setCountry(e.target.value)
    }

    const handleReferralCodeInput = e => {
        setReferralCodeError(false);
        if (e.target.value) {
            setReferralCode(e.target.value);
        } else {
            setReferralCode('');
        }
    }

    const handleCheckboxClick = () => {
        setAgreementError(false);
        setUserAgreesToTerms(prevState => !prevState)
    }

    const handleFormSubmit = e => {
        e.preventDefault();

        if (!email) {
            setEmailError(true);
            setEmailIsEmpty(true);
        }
        if (!username) {
            setUsernameError(true);
            setUsernameIsEmpty(true);
        }
        if (!password) {
            setPasswordError(true);
            setPasswordIsEmpty(true);
        }
        if (!passwordRepeat) {
            setRPasswordError(true);
            setRPasswordIsEmpty(true);
        }
        if (!country) {
            setCountryError(true);
        }
        if (!userAgreesToTerms) {
            setAgreementError(true);
        }

        if (email && username && password && passwordRepeat && country && userAgreesToTerms) {
            if (!emailError && !usernameError && !passwordError && !rPasswordError && !countryError && !agreementError) {
                setWaitingForEndpoint(true);
                let payload = {
                    email: email.toLowerCase(),
                    username: username.toLowerCase(),
                    password: password,
                    country: country
                };
                if (referralCode.length > 0) {
                    payload['referred_by'] = referralCode
                }
                fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/account`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => {
                        return response.json().then(jsonResponse => {
                            if (response.status === 201) {
                                if (jsonResponse['message'] === 'Successfully created user') {
                                    setSuccessModalIsShown(true);
                                    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                                        window.gtag(
                                            'event',
                                            'conversion',
                                            {
                                                'send_to': 'AW-11280245656/RcLvCNmPiswYEJjH64Iq'
                                            }
                                        );
                                    }
                                }
                            } else if (response.status === 404) {
                                if (jsonResponse['detail'] === 'Invalid referral code.') {
                                    setReferralCodeError(true);
                                } else {
                                    setUnexpectedErrorModalIsShown(true);
                                }
                            } else if (response.status === 409) {
                                if (jsonResponse['detail'] === 'An account with this email address already exists.') {
                                    setEmailErrorModalIsShown(true);
                                } else if (jsonResponse['detail'] === 'An account with this username already exists.') {
                                    setUsernameErrorModalIsShown(true);
                                } else {
                                    setUnexpectedErrorModalIsShown(true);
                                }
                            } else {
                                setUnexpectedErrorModalIsShown(true);
                            }
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        setNetworkErrorModalIsShown(true);
                    })
                    .finally(() => setWaitingForEndpoint(false));
            }
        }
    }

    return (
        <SignupContainer>
            <SignupPanel>
                <Link to="/">
                    <Logo>
                        <img src={BWSLogo} alt="" />
                    </Logo>
                </Link>
                <Title>
                    Sign Up for VALDI
                </Title>
                <SignupForm onSubmit={handleFormSubmit}>
                    <InputLabel htmlFor="email-input">Email</InputLabel>
                    <InputFeedbackBlock>
                        <TextInput value={email}
                                   type="email"
                                   onChange={handleEmailInput}
                                   id="email-input"
                                   ref={inputFieldRef}
                        />
                        <InputFeedbackIcon>
                            {waitingForEmailValidation && <FontAwesomeIcon icon={faSpinnerThird} spin/>}
                            {emailValidationReceived && (
                                emailIsTaken ? (
                                    <FontAwesomeIcon icon={faXmark} style={{color: "red"}} />
                                ) : (
                                    <FontAwesomeIcon icon={faCheck} style={{color: "05cd00"}} />
                                )
                            )}
                        </InputFeedbackIcon>
                    </InputFeedbackBlock>
                    <InputErrorText visible={emailError}>
                        {emailIsEmpty ? (
                            `Please enter an email address.`
                        ) : (emailIsTaken ? (
                            `This email address is already being used.`
                        ) : (emailIsMalformed ? (
                            `Please enter a valid email address.`
                        ) : `There is no error.`))
                        }
                    </InputErrorText>

                    <InputLabel htmlFor="username-input">Username</InputLabel>
                    <InputFeedbackBlock>
                        <TextInput value={username}
                                   onChange={handleUsernameInput}
                                   id="username-input"
                        />
                        <InputFeedbackIcon>
                            {waitingForUsernameValidation && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            {usernameValidationReceived && (
                                usernameIsTaken ? (
                                    <FontAwesomeIcon icon={faXmark} style={{color: "red"}} />
                                ) : (
                                    <FontAwesomeIcon icon={faCheck} style={{color: "05cd00"}} />
                                )
                            )}
                        </InputFeedbackIcon>
                    </InputFeedbackBlock>
                    <InputErrorText visible={usernameError}>
                        {usernameIsEmpty ? (
                            `Please enter a username.`
                        ) : (usernameIsTaken ? (
                            `This username is already being used.`
                        ) : (usernameIsMalformed ? (
                            `Special characters not allowed, username can't start with _ or .`
                        ) : (usernameIsBadLength ? (
                            `Username must be 2-32 characters long.`
                        ) : `There is no error.`)))
                        }
                    </InputErrorText>

                    <InputLabel htmlFor="password-input">Create Password</InputLabel>
                    <InputFeedbackBlock>
                        <TextInput value={password}
                                   type={passwordIsVisible ? "text" : "password"}
                                   onChange={handlePasswordInput}
                                   id="password-input"
                        />
                        <InputFeedbackIcon>
                            {passwordValidationReceived && (
                                passwordError ? (
                                    <FontAwesomeIcon icon={faXmark} style={{color: "red"}} />
                                ) : (
                                    <FontAwesomeIcon icon={faCheck} style={{color: "05cd00"}} />
                                )
                            )}
                        </InputFeedbackIcon>
                        <InputFeedbackIcon2>
                            {passwordValidationReceived && (
                                passwordIsVisible ? (
                                    <FontAwesomeIcon icon={faEyeSlash}
                                                     onClick={() => setPasswordIsVisible(false)}
                                                     style={{
                                                         cursor: "pointer",
                                                         marginRight: "15px",
                                                         color: "rgba(255, 255, 255, 0.9)"
                                                     }}
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={faEye}
                                                     onClick={() => setPasswordIsVisible(true)}
                                                     style={{
                                                         cursor: "pointer",
                                                         marginRight: "16px",
                                                         color: "rgba(255, 255, 255, 0.9)"
                                                     }}
                                    />
                                )
                            )}
                        </InputFeedbackIcon2>
                    </InputFeedbackBlock>
                    <InputErrorText visible={passwordError}>
                        {passwordIsEmpty ? (
                            `Please create a password.`
                        ) : (passwordIsTooShort ? (
                            `Password must be at least 8 characters long.`
                        ) : (passwordIsMalformed ? (
                                `Must include at least one A-z, #, and special character.`
                        ) : `There is no error.`))
                        }
                    </InputErrorText>

                    <InputLabel htmlFor="repeat-password-input">Repeat Password</InputLabel>
                    <InputFeedbackBlock>
                        <TextInput value={passwordRepeat}
                                   type={rPasswordIsVisible ? "text" : "password"}
                                   onChange={handlePasswordRepeatInput}
                                   id="repeat-password-input"
                        />
                        <InputFeedbackIcon>
                            {rPasswordValidationReceived && (
                                rPasswordError ? (
                                    <FontAwesomeIcon icon={faXmark} style={{color: "red"}} />
                                ) : (
                                    <FontAwesomeIcon icon={faCheck} style={{color: "05cd00"}} />
                                )
                            )}
                        </InputFeedbackIcon>
                        <InputFeedbackIcon2>
                            {rPasswordValidationReceived && (
                                rPasswordIsVisible ? (
                                    <FontAwesomeIcon icon={faEyeSlash}
                                                     onClick={() => setRPasswordIsVisible(false)}
                                                     style={{
                                                         cursor: "pointer",
                                                         marginRight: "15px",
                                                         color: "rgba(255, 255, 255, 0.9)"
                                                     }}
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={faEye}
                                                     onClick={() => setRPasswordIsVisible(true)}
                                                     style={{
                                                         cursor: "pointer",
                                                         marginRight: "16px",
                                                         color: "rgba(255, 255, 255, 0.9)"
                                                     }}
                                    />
                                )
                            )}
                        </InputFeedbackIcon2>
                    </InputFeedbackBlock>
                    <InputErrorText visible={rPasswordError}>
                        {rPasswordIsMismatched ? (
                            `Passwords don't match.`
                        ) : (rPasswordIsEmpty ? (
                            `Please repeat your password.`
                        ) : `There is no error.`)}
                    </InputErrorText>

                    <InputLabel htmlFor="country-list-select">Country of Residence</InputLabel>
                    <DropDownList value={country}
                                  onChange={handleDropDownListSelection}
                                  required={true}
                                  id="country-list-select"
                    >
                        {countries && (
                            <>
                                <option value="" />
                                {countries.map((country, index) => <option key={index} value={country['code']}>{country['name']}</option>)}
                            </>
                        )}
                    </DropDownList>
                    <InputErrorText
                        visible={countryError}
                        style={{marginTop: "2px"}}
                    >Please select your country of residence.</InputErrorText>

                    <InputLabel htmlFor="referral-input">Referral Code (Optional)</InputLabel>
                    <InputFeedbackBlock>
                        <TextInput value={referralCode}
                                   onChange={handleReferralCodeInput}
                                   id="referral-input"
                                   errorOutline={referralCodeError}
                        />
                    </InputFeedbackBlock>
                    <InputErrorText visible={referralCodeError}>Invalid referral code</InputErrorText>

                    <CheckboxLabel>
                        <Checkbox checked={userAgreesToTerms}
                                  type="checkbox"
                                  onChange={handleCheckboxClick}
                                  id="legal-agreement-checkbox"
                                  errorOutline={agreementError}
                        />
                        I agree to the VALDI <a href="https://docs.valdi.ai/legal/terms-of-service/" target="_blank" rel="noreferrer">Terms of Service</a> and <a href="https://docs.valdi.ai/legal/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>.
                    </CheckboxLabel>

                    <FormSubmitButton type="submit" value="Sign Up" />
                </SignupForm>
                <SmallTextContainer>
                    <SmallText>
                        <Link to="/login">
                            Sign in with an existing account
                        </Link>
                    </SmallText>
                </SmallTextContainer>
            </SignupPanel>
            {waitingForEndpoint && <LoadingCover><FontAwesomeIcon icon={faSpinnerThird} spin /></LoadingCover>}
            {successModalIsShown ? (
                <>
                    <LoadingCover />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faCircleCheck} style={{fontSize: "4rem", color: "#05cd00"}} />
                        <ResponseModalTitle>
                            Success!
                        </ResponseModalTitle>
                        <ResponseModalText>
                            Thanks for creating your VALDI account. You must verify your email before logging in.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => {history.push(`/login`)}}>Okay</ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (emailErrorModalIsShown ? (
                <>
                    <LoadingCover onClick={() => setEmailErrorModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{fontSize: "4rem", color: "#f5a142"}} />
                        <ResponseModalTitle>
                            Oops.
                        </ResponseModalTitle>
                        <ResponseModalText>
                            An account with that email address already exists. You can log in below.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => {history.push(`/login`)}}>Log In</ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (usernameErrorModalIsShown ? (
                <>
                    <LoadingCover onClick={() => setUsernameErrorModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{fontSize: "4rem", color: "#f5a142"}} />
                        <ResponseModalTitle>
                            Taken!
                        </ResponseModalTitle>
                        <ResponseModalText>
                            That username is already being used. Please choose a different one.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => setUsernameErrorModalIsShown(false)}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (networkErrorModalIsShown ? (
                <>
                    <LoadingCover onClick={() => setNetworkErrorModalIsShown(false)} />
                    <ResponseModal>
                        <FontAwesomeIcon icon={faWifiSlash} style={{fontSize: "4rem", color: "red"}} />
                        <ResponseModalTitle>
                            Uh-oh!
                        </ResponseModalTitle>
                        <ResponseModalText>
                            We're having trouble sending your information. Please check your internet connection.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => setNetworkErrorModalIsShown(false)}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            ) : (unexpectedErrorModalIsShown && (
                <>
                    <LoadingCover onClick={() => setUnexpectedErrorModalIsShown(false)} />
                    <ResponseModal>
                        <ResponseModalTitle>
                            Error
                        </ResponseModalTitle>
                        <ResponseModalText>
                            An unexpected error occurred. Please try again later.
                        </ResponseModalText>
                        <ResponseModalButton onClick={() => setUnexpectedErrorModalIsShown(false)}>
                            Okay
                        </ResponseModalButton>
                    </ResponseModal>
                </>
            )))))}
        </SignupContainer>
    )
}

export default Signup;