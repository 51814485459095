import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faXmark } from "@fortawesome/pro-light-svg-icons";
import { faCircleNotch, faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
    DetailContainer,
    DetailPanel,
    DetailInfo,
    SubSectionTitle,
    SectionDivider,
    BackButton,
    InfoGroup,
    InfoName,
    InfoData,
    InputSuperGroup,
    InputGroupHorizontal,
    InputField,
    InputLabel,
    Tooltip,
    DropDownListAlt,
    PricingBlock,
    TotalPrice,
    ConnectButtonWithDisabling,
    ConfirmModalBackgroundCover,
    ConfirmModal,
    ModalTitle,
    ModalText,
    ModalTotalPrice,
    ModalFinePrint,
    LaunchButton,
    ConnectButton,
    InputFieldWithFeedback,
    InputFeedbackText,
    InputQualifier,
    PricingRow,
    PricingCalc,
    PricingCategoryTitle,
    SubSectionDivider,
    NetworkingContainer,
    PortSelectorRowSuper,
    PortSelectorRow,
    PortSelectorGroup,
    PortSelectorInputLabel,
    DropDownListAltNoMargin,
    InputFieldNoMargin,
    RowDeleteButton,
    AddSelectorRowButton,
    AddSelectorIcon,
    AddSelectorText,
    KeyPairDescription,
    InputFieldContainer,
    CustomTooltip
} from "./SharedStyles";
import { checkInputValidity, checkInputInRange } from "./utilities";

const BasicUl = styled.ul`
  list-style-position: inside;
`;

const PortalDirectGenesisCloudServer = ({ token, serverInfo = {}, providerId }) => {
    const [ sshKeys, setSshKeys ] = useState([]);
    const [ sshKeysAreLoaded, setSshKeysAreLoaded ] = useState(false);
    const [ vmName, setVmName ] = useState('');
    const [ nameLength, setNameLength ] = useState(0);
    const [ selectedSshKey, setSelectedSshKey ] = useState('');
    const [ selectedSshKeyName, setSelectedSshKeyName ] = useState('');
    const [ confirmModalIsShown, setConfirmModalIsShown ] = useState(false);
    const [ vmIsProvisioning, setVmIsProvisioning ] = useState(false);
    const [ errorProvisioningVm, setErrorProvisioningVm ] = useState(false);
    const [ provisioningSucceeded, setProvisioningSucceeded ] = useState(false);
    const [ insufficientFunds, setInsufficientFunds ] = useState(false);
    const [ codeWasCopied, setCodeWasCopied ] = useState(false);
    const [ amtStorage, setAmtStorage ] = useState(0);
    const [ storageOutOfRange, setStorageOutOfRange ] = useState(false);
    const [ os, setOs ] = useState('');
    const [ totalCost, setTotalCost ] = useState(parseFloat(serverInfo['running_cost']));
    const [ secGroupRules, setSecGroupRules ] = useState([
        {
            protocol: 'tcp',
            port_range_min: 22,
            port_range_max: 22,
            direction: 'ingress'
        }
    ]);
    const [ secGroupErrorResponse, setSecGroupErrorResponse ] = useState(false);
    const [ storageSizeError, setStorageSizeError ] = useState(false);

    const maxNameLength = 50;
    const minStorage = 20;
    const maxStorage = 2500;
    const nameInputRef = useRef();

    useEffect(() => {
        if (nameInputRef) {
            nameInputRef.current.focus();
        }
    }, [nameInputRef]);

    useEffect(() => {
        if (token) {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/sshkeys`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(data => {
                    if ('ssh_keys' in data) {
                        setSshKeys(data['ssh_keys']);
                        setSshKeysAreLoaded(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (codeWasCopied) {
            const clipboardTimeout = setTimeout(() => setCodeWasCopied(false), 2000)
            return () => clearTimeout(clipboardTimeout);
        }
    }, [codeWasCopied]);

    const handleSshKeySelection = e => {
        setSelectedSshKey(e.target.value);
        const sshKey = sshKeys.find(key => key.ssh_key_id === e.target.value);
        setSelectedSshKeyName(sshKey['name']);
    }

    async function handleFormSubmit(e) {
        e.preventDefault();
        setConfirmModalIsShown(false);
        setVmIsProvisioning(true);
        setProvisioningSucceeded(false);

        let payload = {
            provider_id: providerId,
            details: {
                'image_id': os,
                'ssh_key_id': selectedSshKey,
                'config': serverInfo['shape_code'],
                'sec_group_rules': secGroupRules,
                'additional_storage': amtStorage
            }
        };

        if (vmName !== '') {
            payload['user_provided_name'] = vmName;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/provision`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            if (response.ok) {
                setProvisioningSucceeded(true);
            } else {
                const json_response = await response.json()
                if (response.status === 400 && 'detail' in json_response && json_response['detail'] === 'Additional storage must be at least 20 GB') {
                    setStorageSizeError(true);
                } else if ((response.status === 400 && 'detail' in json_response && json_response['detail'] === 'Invalid firewall rule') || response.status  === 422) {
                    setSecGroupErrorResponse(true);
                } else if (response.status === 403 && 'detail' in json_response && json_response['detail'] === 'Insufficient user balance') {
                    setInsufficientFunds(true);
                } else {
                    setErrorProvisioningVm(true);
                }
            }
        } catch (error) {
            console.log(error);
            setErrorProvisioningVm(true);
        } finally {
            setVmIsProvisioning(false);
        }
    }

    const handleVmNameEntry = e => {
        const enteredText = e.target.value;
        const currentLength = enteredText.length;
        if (currentLength <= maxNameLength) {
            setNameLength(currentLength);
            setVmName(enteredText);
        }
    }

    const handleStorageEntry = e => {
        if (checkInputValidity(e.target.value)) {
            if (e.target.value === '') {
                setAmtStorage(e.target.value);
                setTotalCost(parseFloat(serverInfo['running_cost']));
                setStorageOutOfRange(false);
            } else {
                const enteredValue = parseFloat(e.target.value);
                setAmtStorage(enteredValue);
                setTotalCost(parseFloat(serverInfo['running_cost']) + parseFloat(serverInfo['storage_cost'])*enteredValue);
                if (enteredValue === 0.0 || checkInputInRange(e.target.value, minStorage, maxStorage)) {
                    setStorageOutOfRange(false);
                } else {
                    setStorageOutOfRange(true);
                }
            }
        }
    }

    const handleSecGroupProtocolChange = (e, i) => {
        setSecGroupRules(prevRules => {
            const newRules = [...prevRules];
            newRules[i] = {...newRules[i], 'protocol': e.target.value}
            return newRules;
        });
    }

    const handleSecGroupPortChange = (e, i, min_or_max) => {
        const nonNegativeIntRegEx = /^\d+$/;

        let newValue = '';
        if (nonNegativeIntRegEx.test(e.target.value)) {
            newValue = parseInt(e.target.value);
        }

        setSecGroupRules(prevRules => {
            const newRules = [...prevRules];
            if (min_or_max === 'min') {
                newRules[i] = {...newRules[i], 'port_range_min': newValue};
            } else if (min_or_max === 'max') {
                newRules[i] = {...newRules[i], 'port_range_max': newValue};
            }
            return newRules;
        })
    }

    const addSecGroupRuleRow = () => {
        setSecGroupRules(prevRules => {
            const newRules = [...prevRules];
            const newRule = {
                protocol: 'tcp',
                port_range_min: '',
                port_range_max: '',
                direction: 'ingress'
            }
            newRules.push(newRule);
            return newRules;
        })
    }

    const removeSecGroupRuleRow = i => {
        setSecGroupRules(prevRules => {
            let newRules = [...prevRules];
            newRules.splice(i, 1);
            return newRules;
        });
    }

    return (
        Object.keys(serverInfo).length === 0 ? (
            <></>
        ) : (
            <DetailContainer>
                <DetailPanel>
                    <Link to="/dashboard/direct?active=vms">
                        <BackButton>
                            <FontAwesomeIcon icon={faAngleLeft} />
                            <span>Back</span>
                        </BackButton>
                    </Link>
                    <SubSectionTitle>
                        Device Details
                    </SubSectionTitle>
                    <DetailInfo>
                        <InfoGroup>
                            <InfoName>
                                GPU type
                            </InfoName>
                            <InfoData>
                                {serverInfo['gpu_type']}
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                CPU model
                            </InfoName>
                            <InfoData>
                                {serverInfo['cpu_type']}
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                Location
                            </InfoName>
                            <InfoData>
                                Iceland 🇮🇸
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                Transfer Speed
                            </InfoName>
                            <InfoData>
                                1 Gbps
                            </InfoData>
                        </InfoGroup>
                    </DetailInfo>
                    <SectionDivider />
                    <SubSectionTitle>
                        Server Specifications
                    </SubSectionTitle>
                    <InputSuperGroup onSubmit={handleFormSubmit}>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="vm-name">VM name: </InputLabel>
                            <InputFieldWithFeedback>
                                <InputField id="vm-name"
                                            customWidth="100%"
                                            value={vmName}
                                            onChange={handleVmNameEntry}
                                            placeholder="Enter a name for your virtual machine"
                                            ref={nameInputRef}
                                />
                                <InputFeedbackText visible={true}>{nameLength} / {maxNameLength}</InputFeedbackText>
                            </InputFieldWithFeedback>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="num-gpus">Number of GPUs: </InputLabel>
                            <InputFieldContainer>
                                <InputField id="num-gpus"
                                            customWidth="60px"
                                            value={serverInfo['gpu_count']}
                                            disabled={true}
                                />
                                <CustomTooltip>
                                    This server has a fixed configuration, so the specifications cannot be changed.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="cpu-cores">Number of vCPUs: </InputLabel>
                            <InputFieldContainer>
                                <InputField
                                    id="cpu-cores"
                                    customWidth="60px"
                                    value={serverInfo['cpu_core_count']}
                                    disabled={true}
                                />
                                <CustomTooltip>
                                    This server has a fixed configuration, so the specifications cannot be changed.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="ram-amount">Amount of RAM (GB): </InputLabel>
                            <InputFieldContainer>
                                <InputField id="ram-amount"
                                            customWidth="70px"
                                            value={serverInfo['main_ram']}
                                            disabled={true}
                                />
                                <CustomTooltip>
                                    This server has a fixed configuration, so the specifications cannot be changed.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="amt-storage">Local Storage Size (GB): </InputLabel>
                            <InputFieldContainer>
                                <InputField id="amt-storage"
                                            customWidth="80px"
                                            value={serverInfo['storage']}
                                            disabled={true}
                                />
                                <CustomTooltip>
                                    This server has a fixed configuration, so the specifications cannot be changed.
                                    Additional storage can be added below.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="addl-storage">Additional Storage Volume Size in GB (optional): </InputLabel>
                            <InputField id="addl-storage"
                                        customWidth="80px"
                                        type="number"
                                        value={amtStorage}
                                        min={String(0)}
                                        max={String(maxStorage)}
                                        onChange={handleStorageEntry}
                                        error={storageOutOfRange}
                            />
                            <InputQualifier>
                                Minimum {String(minStorage)} (or 0)<br />Maximum {String(maxStorage)}
                            </InputQualifier>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="os-select">Operating System: </InputLabel>
                            <DropDownListAlt value={os}
                                             id="os-select"
                                             required={true}
                                             onChange={e => setOs(e.target.value)}
                            >
                                <option value="">Choose an image</option>
                                {serverInfo['available_images'].map(image => {
                                    return (
                                        <option value={image['id']}  key={image['id']}>{image['name']}</option>
                                    )
                                })}
                            </DropDownListAlt>
                        </InputGroupHorizontal>
                        {confirmModalIsShown && (
                            <>
                                <ConfirmModalBackgroundCover onClick={() => setConfirmModalIsShown(false)} />
                                <ConfirmModal>
                                    <ModalTitle>
                                        Launch Virtual Machine
                                    </ModalTitle>
                                    <ModalText>
                                        Please confirm that you would like to provision this VM.
                                    </ModalText>
                                    <ModalTotalPrice>
                                        ${parseFloat(totalCost.toFixed(5))}<span>/hour</span>
                                    </ModalTotalPrice>
                                    <ModalFinePrint>
                                        Your virtual machine will accrue pro-rated hourly charges for as long as it is
                                        running. Hourly charges equal
                                        ${parseFloat((parseFloat(serverInfo['stopped_cost']) + amtStorage*parseFloat(serverInfo['storage_cost'])).toFixed(5))}<span>/hour</span> while the VM
                                        is stopped. Once the VM is terminated, all data stored on the server will be lost.
                                        Please back up your data.
                                    </ModalFinePrint>
                                    <LaunchButton value="Launch virtual machine" type="submit" />
                                </ConfirmModal>
                            </>
                        )}
                    </InputSuperGroup>
                    <SectionDivider />
                    <SubSectionTitle>
                        SSH Key Pair
                    </SubSectionTitle>
                    <KeyPairDescription>
                        This server requires SSH key-based authentication. Ensure that you have access to the selected
                        key before you launch the VM.
                    </KeyPairDescription>
                    <InputGroupHorizontal>
                        <InputLabel htmlFor="key-select">Key pair: </InputLabel>
                        <InputFieldContainer>
                            <DropDownListAlt value={selectedSshKey}
                                             disabled={sshKeys.length === 0}
                                             onChange={handleSshKeySelection}
                                             id="key-select"
                            >
                                {sshKeysAreLoaded ? (
                                    sshKeys.length === 0 ? (
                                        <option>No SSH keys available.</option>
                                    ) : (
                                        <>
                                            <option value="">Select a key...</option>
                                            {sshKeys.map(sshkey => {
                                                return (
                                                    <option
                                                        value={sshkey['ssh_key_id']}
                                                        key={sshkey['ssh_key_id']}
                                                    >
                                                        {sshkey['name']}
                                                    </option>
                                                );
                                            })}
                                        </>
                                    )
                                ) : (
                                    <option>Keys loading...</option>
                                )}
                            </DropDownListAlt>
                        </InputFieldContainer>
                    </InputGroupHorizontal>
                    <SectionDivider />
                    <SubSectionTitle>
                        Networking
                    </SubSectionTitle>
                    <KeyPairDescription>
                        Specify the port ranges and protocols to allow for incoming traffic. To open a single port, set
                        "Port range start" and "Port range end" to the same value.
                    </KeyPairDescription>
                    <NetworkingContainer>
                        {secGroupRules.map((rule, i) => {
                            if (i === 0) {
                                return (
                                    <PortSelectorRowSuper key={i}>
                                        <PortSelectorRow>
                                            <PortSelectorGroup>
                                                <PortSelectorInputLabel>
                                                    Protocol
                                                </PortSelectorInputLabel>
                                                <DropDownListAltNoMargin value={secGroupRules[i]['protocol']}
                                                                         onChange={e => handleSecGroupProtocolChange(e, i)}
                                                                         customWidth="100px"
                                                >
                                                    <option value="tcp">TCP</option>
                                                    <option value="udp">UDP</option>
                                                </DropDownListAltNoMargin>
                                            </PortSelectorGroup>
                                            <PortSelectorGroup>
                                                <PortSelectorInputLabel>
                                                    Port range start
                                                </PortSelectorInputLabel>
                                                <InputFieldNoMargin value={secGroupRules[i]['port_range_min']}
                                                                    customWidth="100px"
                                                                    onChange={e => handleSecGroupPortChange(e,i, 'min')}
                                                />
                                            </PortSelectorGroup>
                                            <PortSelectorGroup>
                                                <PortSelectorInputLabel>
                                                    Port range end
                                                </PortSelectorInputLabel>
                                                <InputFieldNoMargin value={secGroupRules[i]['port_range_max']}
                                                                    customWidth="100px"
                                                                    onChange={e => handleSecGroupPortChange(e, i, 'max')}
                                                />
                                            </PortSelectorGroup>
                                        </PortSelectorRow>
                                    </PortSelectorRowSuper>
                                );
                            } else {
                                return (
                                    <PortSelectorRowSuper key={i}>
                                        <PortSelectorRow>
                                            <DropDownListAltNoMargin value={secGroupRules[i]['protocol']}
                                                                     onChange={e => handleSecGroupProtocolChange(e, i)}
                                                                     customWidth="100px"
                                            >
                                                <option value="tcp">TCP</option>
                                                <option value="udp">UDP</option>
                                            </DropDownListAltNoMargin>
                                            <InputFieldNoMargin value={secGroupRules[i]['port_range_min']}
                                                                customWidth="100px"
                                                                onChange={e => handleSecGroupPortChange(e,i, 'min')}
                                            />
                                            <InputFieldNoMargin value={secGroupRules[i]['port_range_max']}
                                                                customWidth="100px"
                                                                onChange={e => handleSecGroupPortChange(e, i, 'max')}
                                            />
                                            <RowDeleteButton onClick={() => removeSecGroupRuleRow(i)}>
                                                <FontAwesomeIcon icon={faXmark} />
                                            </RowDeleteButton>
                                        </PortSelectorRow>
                                    </PortSelectorRowSuper>
                                )
                            }
                        })}
                        <PortSelectorRow>
                            <AddSelectorRowButton customWidth="330px" onClick={addSecGroupRuleRow}>
                                <AddSelectorIcon>
                                    <FontAwesomeIcon icon={faPlus} />
                                </AddSelectorIcon>
                                <AddSelectorText>
                                    Add additional firewall rule
                                </AddSelectorText>
                            </AddSelectorRowButton>
                        </PortSelectorRow>
                    </NetworkingContainer>
                    <SectionDivider />
                    <SubSectionTitle>
                        Pricing
                    </SubSectionTitle>
                    <PricingBlock>
                        <TotalPrice>
                            ${parseFloat(totalCost.toFixed(5)).toString()}<span>/hour</span>
                        </TotalPrice>
                        <SubSectionDivider />
                        <PricingRow>
                            <PricingCategoryTitle>
                                Base VM Cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['running_cost']).toFixed(3)).toString()}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                        <PricingRow>
                            <PricingCategoryTitle>
                                Additional Storage Cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['storage_cost']).toFixed(5))}<span>/GB-hour</span> x {amtStorage} GB = ${parseFloat((amtStorage*parseFloat(serverInfo['storage_cost'])).toFixed(5)).toString()}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                    </PricingBlock>
                    <ConnectButtonWithDisabling disabled={selectedSshKey === '' || os === '' || storageOutOfRange}
                                                onClick={() => setConfirmModalIsShown(true)}
                    >
                        Launch virtual machine
                    </ConnectButtonWithDisabling>
                </DetailPanel>
                {vmIsProvisioning && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            </ModalTitle>
                        </ConfirmModal>
                    </>
                )}
                {provisioningSucceeded && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal maxWidth="350px">
                            <ModalTitle>
                                Your VM is provisioning
                            </ModalTitle>
                            <ModalText>
                                It may take 5-10 minutes for your virtual machine to be accessible. You can view the
                                deployment status under "Your Virtual Machines".<br /><br />Once it's ready, use your
                                SSH key "{selectedSshKeyName}" to log in as username <b>ubuntu</b> (or <b>debian</b> for
                                Debian deployments) with the IP address shown in the dashboard.
                            </ModalText>
                            <Link to="/dashboard/direct?active=sessions">
                                <ConnectButton>
                                    Okay
                                </ConnectButton>
                            </Link>
                        </ConfirmModal>
                    </>
                )}
                {errorProvisioningVm && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Error
                            </ModalTitle>
                            <ModalText>
                                An unexpected issue occurred when provisioning the virtual machine. Please try again
                                later.
                            </ModalText>
                            <Link to="/dashboard/direct?active=vms">
                                <ConnectButton>
                                    Okay
                                </ConnectButton>
                            </Link>
                        </ConfirmModal>
                    </>
                )}
                {insufficientFunds && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Insufficient Balance
                            </ModalTitle>
                            <ModalText>
                                You need a balance of at least $10.00 USD to launch a virtual machine. Please deposit
                                funds and try again.
                            </ModalText>
                            <Link to="/dashboard/billing">
                                <ConnectButton>
                                    Deposit funds
                                </ConnectButton>
                            </Link>
                        </ConfirmModal>
                    </>
                )}
                {secGroupErrorResponse && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal maxWidth="500px">
                            <ModalTitle>
                                Error
                            </ModalTitle>
                            <ModalText>
                                There is a problem with your networking rules. Note that:
                                <br/><br/>
                                <BasicUl>
                                    <li>Valid ports are between 1 and 65535.</li>
                                    <li><b>Port range end</b> cannot be less than <b>port range start</b>.</li>
                                    <li>To specify individual ports, set <b>port range start</b> and <b>port range end</b> to the same value.</li>
                                </BasicUl>
                                <br/>
                                Please check and try again.
                            </ModalText>
                            <ConnectButton onClick={() => setSecGroupErrorResponse(false)}>
                                Okay
                            </ConnectButton>
                        </ConfirmModal>
                    </>
                )}
                {storageSizeError && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Insufficient storage
                            </ModalTitle>
                            <ModalText>
                                You must add at least 20 GB in additional storage, or none at all. Please revise your
                                configuration and try again.
                            </ModalText>
                            <ConnectButton onClick={() => setStorageSizeError(false)}>
                                Okay
                            </ConnectButton>
                        </ConfirmModal>
                    </>
                )}
            </DetailContainer>
        )
    );
}

export default PortalDirectGenesisCloudServer;