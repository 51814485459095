import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
    DetailContainer,
    DetailPanel,
    DetailInfo,
    InfoGroup,
    InfoName,
    InfoData,
    BackButton,
    ConfirmModal,
    ModalTitle,
    ModalText,
    ModalFinePrint,
    ConfirmModalBackgroundCover,
    ConnectButton,
    InputSuperGroup,
    InputField,
    InputGroupHorizontal,
    InputLabel,
    SubSectionTitle,
    SectionDivider,
    DropDownListAlt,
    PricingBlock,
    TotalPrice,
    ModalTotalPrice,
    ConnectButtonWithDisabling,
    LaunchButton,
    InputFieldWithFeedback,
    InputFeedbackText,
    InputQualifier,
    PricingRow,
    PricingCategoryTitle,
    PricingCalc,
    SubSectionDivider,
    PlaceholderContainer,
    KeyPairDescription,
    InputFieldContainer,
    CustomTooltip,
    DisabledDropDownList
} from "./SharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-light-svg-icons";
import {
    generateRandomString,
    checkInputValidity,
    checkInputInRange,
    createArrayOfIntegers,
    insertFlagEmoji
} from "./utilities";


const PortalDirectCudoCloudServer = ({ token, serverInfo = {}, providerId }) => {
    const [ vmName, setVmName ] = useState('');
    const [ nameLength, setNameLength ] = useState(0);
    const [ numGpus, setNumGpus ] = useState(0);
    const [ numCpus, setNumCpus ] = useState(0);
    const [ amtRam, setAmtRam ] = useState(0);
    const [ amtStorage, setAmtStorage ] = useState(0);
    const [ cpusOutOfRange, setCpusOutOfRange ] = useState(false);
    const [ gpusOutOfRange, setGpusOutOfRange ] = useState(false);
    const [ ramOutOfRange, setRamOutOfRange ] = useState(false);
    const [ storageOutOfRange, setStorageOutOfRange ] = useState(false);
    const [ confirmModalIsShown, setConfirmModalIsShown ] = useState(false);
    const [ vmIsProvisioning, setVmIsProvisioning ] = useState(false);
    const [ provisioningSucceeded, setProvisioningSucceeded ] = useState(false);
    const [ provisioningFailed, setProvisioningFailed ] = useState(false);
    const [ insufficientFunds, setInsufficientFunds ] = useState(false);
    const [ codeWasCopied, setCodeWasCopied ] = useState(false);
    const [ totalCost, setTotalCost ] = useState(0.0);
    const [ minCpus, setMinCpus ] = useState(0);
    const [ maxCpus, setMaxCpus ] = useState(100);
    const [ minRam, setMinRam ] = useState(4);
    const [ maxRam, setMaxRam ] = useState(100);
    const [ maxStorage, setMaxStorage ] = useState(100);
    const [ possibleGpuCounts, setPossibleGpuCounts ] = useState([]);
    const [ sshKeys, setSshKeys ] = useState([]);
    const [ sshKeysAreLoaded, setSshKeysAreLoaded ] = useState(false);
    const [ selectedSshKey, setSelectedSshKey ] = useState('');
    const [ selectedSshKeyName, setSelectedSshKeyName ] = useState('');

    const minStorage = 16;

    const maxNameLength = 50;
    const nameInputRef = useRef();

    useEffect(() => {
        if (Object.keys(serverInfo).length !== 0) {
            const gpuRestrictions = createArrayOfIntegers(parseInt(serverInfo['gpu_count']));
            setPossibleGpuCounts(gpuRestrictions);

            setNumGpus(gpuRestrictions[0]);

            const newMinCpus = Math.max(
                parseInt(serverInfo['min_vcpu_per_gpu'])*gpuRestrictions[0],
                parseInt(serverInfo['min_vcpu_per_memory_gib'])*minRam
            );
            const newMaxCpus = Math.min(
                parseInt(serverInfo['max_vcpu_per_gpu'])*gpuRestrictions[0],
                parseInt(serverInfo['max_vcpu_per_memory_gib'])*minRam
            );

            setMinCpus(newMinCpus);
            setNumCpus(newMinCpus);
            setMaxCpus(newMaxCpus);

            const computedMaxRam = Math.min(
                parseInt(serverInfo['main_ram']),
                parseInt(parseFloat(parseInt(serverInfo['gpu_count'])*parseInt(serverInfo['max_vcpu_per_gpu']))/parseFloat(serverInfo['min_vcpu_per_memory_gib']))
            );

            setMaxRam(computedMaxRam);
            setMaxStorage(parseInt(serverInfo['storage']));

            setAmtRam(4);
            setAmtStorage(16);
        }

    }, []);

    useEffect(() => {
        if (token) {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/sshkeys`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(data => {
                    if ('ssh_keys' in data) {
                        setSshKeys(data['ssh_keys']);
                        setSshKeysAreLoaded(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (nameInputRef) {
            nameInputRef.current.focus();
        }
    }, [nameInputRef]);

    useEffect(() => {
        if (codeWasCopied) {
            const copyReset = setTimeout(() => {
                setCodeWasCopied(false);
            }, 2000);
            return () => clearTimeout(copyReset);
        }
    }, [codeWasCopied]);

    useEffect(() => {
        if (Object.keys(serverInfo).length !== 0) {
            setTotalCost(
                numCpus*parseFloat(serverInfo['cpu_cost']) +
                numGpus*parseFloat(serverInfo['gpu_cost']) +
                amtRam*parseFloat(serverInfo['ram_cost']) +
                amtStorage*parseFloat(serverInfo['storage_cost']) +
                parseFloat(serverInfo['ip_cost'])
            );
        }
    }, [serverInfo, numCpus, numGpus, amtStorage, amtRam]);

    useEffect(() => {
        if (checkInputInRange(numCpus, minCpus, maxCpus)) {
            setCpusOutOfRange(false);
        } else {
            setCpusOutOfRange(true);
        }
    }, [numCpus, minCpus, maxCpus]);

    function handleCpuEntry(e) {
        if (checkInputValidity(e.target.value)) {
            setNumCpus(e.target.value);
            if (checkInputInRange(e.target.value, minCpus, maxCpus)) {
                setCpusOutOfRange(false);
            } else {
                setCpusOutOfRange(true);
            }
        }
    }

    function handleRamEntry(e) {
        if (checkInputValidity(e.target.value)) {
            const newRamAmount = e.target.value;
            setAmtRam(newRamAmount);
            if (newRamAmount !== '') {
                if (newRamAmount >= minRam && newRamAmount <= maxRam) {
                    setRamOutOfRange(false);

                    let newMinCpus = Math.max(
                        parseInt(Math.round(parseFloat(serverInfo['min_vcpu_per_gpu'])*numGpus)),
                        parseInt(Math.round(parseFloat(serverInfo['min_vcpu_per_memory_gib'])*newRamAmount))
                    );
                    let newMaxCpus = Math.min(
                        parseInt(Math.round(parseFloat(serverInfo['max_vcpu_per_gpu'])*numGpus)),
                        parseInt((Math.round(parseFloat(serverInfo['max_vcpu_per_memory_gib'])*newRamAmount)))
                    );

                    if (newMinCpus > newMaxCpus) {
                        let newNumGpus = parseInt(Math.round(parseFloat(newMinCpus) / parseFloat(serverInfo['max_vcpu_per_gpu'])));
                        if (newNumGpus === 0) {
                            newNumGpus = 1;
                        }
                        setNumGpus(newNumGpus);
                        newMinCpus = Math.max(
                            parseInt(serverInfo['min_vcpu_per_gpu'])*newNumGpus,
                            parseInt(parseFloat(serverInfo['min_vcpu_per_memory_gib'])*newRamAmount)
                        );
                        newMaxCpus = Math.min(
                            parseInt(serverInfo['max_vcpu_per_gpu'])*newNumGpus,
                            parseInt(parseFloat(serverInfo['max_vcpu_per_memory_gib'])*newRamAmount)
                        );
                    }

                    setMinCpus(newMinCpus);
                    setMaxCpus(newMaxCpus);

                    if (numCpus < newMinCpus) {
                        setNumCpus(newMinCpus);
                    } else if (numCpus > newMaxCpus) {
                        setNumCpus(newMaxCpus);
                    }
                } else {
                    setRamOutOfRange(true);
                }
            } else {
                setRamOutOfRange(true);
            }
        }
    }

    function handleGpuEntry(e) {
        if (checkInputValidity(e.target.value)) {
            const newNumGpus = e.target.value;
            let direction;
            if (newNumGpus > numGpus) {
                direction = 'increase';
            } else {
                direction = 'decrease';
            }
            setNumGpus(newNumGpus);
            setGpusOutOfRange(false);

            let newMinCpus = Math.max(
                parseInt(serverInfo['min_vcpu_per_gpu'])*e.target.value,
                parseInt(parseFloat(serverInfo['min_vcpu_per_memory_gib'])*amtRam)
            );
            let newMaxCpus = Math.min(
                parseInt(serverInfo['max_vcpu_per_gpu'])*e.target.value,
                parseInt(parseFloat(serverInfo['max_vcpu_per_memory_gib'])*amtRam)
            );

            if (newMinCpus > newMaxCpus) {
                let newRamAmount;
                if (direction === 'decrease') {
                    newRamAmount = parseInt(Math.ceil(parseFloat(newMaxCpus) / parseFloat(serverInfo['max_vcpu_per_memory_gib'])));
                } else if (direction === 'increase') {
                    newRamAmount = parseInt(Math.floor(parseFloat(newMinCpus) / parseFloat(serverInfo['max_vcpu_per_memory_gib'])));
                }
                setAmtRam(newRamAmount);
                newMinCpus = Math.max(
                    parseInt(serverInfo['min_vcpu_per_gpu'])*newNumGpus,
                    parseInt(parseFloat(serverInfo['min_vcpu_per_memory_gib'])*newRamAmount)
                );
                newMaxCpus = Math.min(
                    parseInt(serverInfo['max_vcpu_per_gpu'])*newNumGpus,
                    parseInt(parseFloat(serverInfo['max_vcpu_per_memory_gib'])*newRamAmount)
                );
            }

            setMinCpus(newMinCpus);
            setMaxCpus(newMaxCpus);

            if (numCpus < newMinCpus) {
                setNumCpus(newMinCpus);
            } else if (numCpus > newMaxCpus) {
                setNumCpus(newMaxCpus);
            }
        }
    }

    function handleStorageEntry(e) {
        if (checkInputValidity(e.target.value)) {
            setAmtStorage(e.target.value);
            if (checkInputInRange(e.target.value, minStorage, maxStorage)) {
                setStorageOutOfRange(false);
            } else {
                setStorageOutOfRange(true);
            }
        }
    }

    async function handleFormSubmit(e) {
        e.preventDefault();
        setConfirmModalIsShown(false);
        setVmIsProvisioning(true);
        const nameCode = generateRandomString(6, false);

        const payload = {
            name: `valdi-customer-${nameCode}`,
            provider_id: providerId,
            details: {
                data_center_id: serverInfo['data_center_id'],
                machine_type: serverInfo['machine_type'],
                ssh_key_id: selectedSshKey,
                boot_disk_size: amtStorage,
                vcpus: numCpus,
                ram: amtRam,
                gpu_count: numGpus
            }
        };

        if (vmName !== '') {
            payload['user_provided_name'] = vmName;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/v1/vm/provision`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'POST',
                body: JSON.stringify(payload)
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setProvisioningSucceeded(true);
            } else {
                setProvisioningFailed(true);
            }
        } catch (error) {
            setProvisioningFailed(true);
            console.log(error);
        } finally {
            setVmIsProvisioning(false);
        }
    }

    const handleVmNameEntry = e => {
        const enteredText = e.target.value;
        const currentLength = enteredText.length;
        if (currentLength <= maxNameLength) {
            setNameLength(currentLength);
            setVmName(enteredText);
        }
    }

    const handleSshKeySelection = e => {
        setSelectedSshKey(e.target.value);
        const sshKey = sshKeys.find(key => key.ssh_key_id === e.target.value);
        setSelectedSshKeyName(sshKey['name']);
    }

    return (
        Object.keys(serverInfo).length === 0 ? (
            <></>
        ) : (
            <DetailContainer>
                <DetailPanel>
                    <Link to="/dashboard/direct?active=vms">
                        <BackButton>
                            <FontAwesomeIcon icon={faAngleLeft} />
                            <span>Back</span>
                        </BackButton>
                    </Link>
                    <SubSectionTitle>
                        Device Details
                    </SubSectionTitle>
                    <DetailInfo>
                        <InfoGroup>
                            <InfoName>
                                GPU type
                            </InfoName>
                            <InfoData>
                                NVIDIA {serverInfo['gpu_type']}
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                CPU model
                            </InfoName>
                            <InfoData>
                                Intel
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                Location
                            </InfoName>
                            <InfoData>
                                {serverInfo['location']}
                                {insertFlagEmoji(serverInfo['location'])}
                            </InfoData>
                        </InfoGroup>
                        <InfoGroup>
                            <InfoName>
                                Transfer Speed
                            </InfoName>
                            <InfoData>
                                10 Gbps
                            </InfoData>
                        </InfoGroup>
                    </DetailInfo>
                    <SectionDivider />
                    <SubSectionTitle>
                        Your Specifications
                    </SubSectionTitle>
                    <InputSuperGroup onSubmit={handleFormSubmit}>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="vm-name">VM Name: </InputLabel>
                            <InputFieldWithFeedback>
                                <InputField id="vm-name"
                                            customWidth="100%"
                                            value={vmName}
                                            onChange={handleVmNameEntry}
                                            placeholder="Enter a name for your virtual machine"
                                            ref={nameInputRef}
                                />
                                <InputFeedbackText visible={true}>{nameLength} / {maxNameLength}</InputFeedbackText>
                            </InputFieldWithFeedback>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="num-gpus">Number of GPUs: </InputLabel>
                            <DropDownListAlt value={numGpus}
                                             required={true}
                                             id="num-gpus"
                                             onChange={handleGpuEntry}
                                             customWidth="60px"
                            >
                                {possibleGpuCounts.map((count, index) => {
                                    return (
                                        <option value={count}
                                                key={index}
                                        >
                                            {count}
                                        </option>
                                    )
                                })}
                            </DropDownListAlt>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="cpu-cores">Number of vCPUs: </InputLabel>
                            <InputField id="cpu-cores"
                                        type="number"
                                        customWidth="60px"
                                        value={numCpus}
                                        onChange={handleCpuEntry}
                                        min={String(minCpus)}
                                        max={String(maxCpus)}
                                        error={cpusOutOfRange}
                                        step="2"
                            />
                            <InputQualifier>
                                Minimum {String(minCpus)}<br />Maximum {maxCpus}
                            </InputQualifier>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="ram-amount">Amount of RAM (GiB): </InputLabel>
                            <InputField id="ram-amount"
                                        type="number"
                                        customWidth="70px"
                                        value={amtRam}
                                        onChange={handleRamEntry}
                                        min={String(minRam)}
                                        max={String(maxRam)}
                                        error={ramOutOfRange}
                            />
                            <InputQualifier>
                                Minimum {String(minRam)}<br />Maximum {maxRam}
                            </InputQualifier>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="amt-storage">Local Storage Size (GiB): </InputLabel>
                            <InputField id="amt-storage"
                                        type="number"
                                        customWidth="80px"
                                        value={amtStorage}
                                        onChange={handleStorageEntry}
                                        min={String(minStorage)}
                                        max={String(maxStorage)}
                                        error={storageOutOfRange}
                            />
                            <InputQualifier>
                                Minimum {String(minStorage)}<br />Maximum {maxStorage}
                            </InputQualifier>
                        </InputGroupHorizontal>
                        <InputGroupHorizontal>
                            <InputLabel htmlFor="os-select">Operating System: </InputLabel>
                            <InputFieldContainer>
                                <DisabledDropDownList value="Ubuntu 22.04 LTS"
                                                      id="os-select"
                                                      disabled={true}
                                >
                                    <option value="Ubuntu 22.04 LTS">Ubuntu 22.04 LTS</option>
                                </DisabledDropDownList>
                                <CustomTooltip>
                                    Only Ubuntu 22.04 LTS is currently available on this server. CUDA and NVIDIA drivers
                                    come preinstalled.
                                </CustomTooltip>
                            </InputFieldContainer>
                        </InputGroupHorizontal>
                        {confirmModalIsShown && (
                            <>
                                <ConfirmModalBackgroundCover onClick={() => setConfirmModalIsShown(false)} />
                                <ConfirmModal>
                                    <ModalTitle>
                                        Launch Virtual Machine
                                    </ModalTitle>
                                    <ModalText>
                                        Please confirm that you would like to claim access to this VM.
                                    </ModalText>
                                    <ModalTotalPrice>
                                        ${parseFloat(totalCost.toFixed(5))}<span>/hour</span>
                                    </ModalTotalPrice>
                                    <ModalFinePrint>
                                        Your SSH/RDP session will accrue pro-rated hourly charges for as long as it is active.
                                        Once an SSH/RDP session is terminated, all data stored on the server will be lost.
                                        Please back up your data.
                                    </ModalFinePrint>
                                    <LaunchButton value="Launch virtual machine" type="submit" />
                                </ConfirmModal>
                            </>
                        )}
                    </InputSuperGroup>
                    <SectionDivider />
                    <SubSectionTitle>
                        SSH Key Pair
                    </SubSectionTitle>
                    <KeyPairDescription>
                        This server requires SSH key-based authentication. Ensure that you have access to the selected
                        key before you launch the VM.
                    </KeyPairDescription>
                    <InputGroupHorizontal>
                        <InputLabel htmlFor="key-select">Key pair: </InputLabel>
                        <InputFieldContainer>
                            <DropDownListAlt value={selectedSshKey}
                                             disabled={sshKeys.length === 0}
                                             onChange={handleSshKeySelection}
                                             id="key-select"
                            >
                                {sshKeysAreLoaded ? (
                                    sshKeys.length === 0 ? (
                                        <option>No SSH keys available.</option>
                                    ) : (
                                        <>
                                            <option value="">Select a key...</option>
                                            {sshKeys.map(sshkey => {
                                                return (
                                                    <option
                                                        value={sshkey['ssh_key_id']}
                                                        key={sshkey['ssh_key_id']}
                                                    >
                                                        {sshkey['name']}
                                                    </option>
                                                );
                                            })}
                                        </>
                                    )
                                ) : (
                                    <option>Keys loading...</option>
                                )}
                            </DropDownListAlt>
                        </InputFieldContainer>
                    </InputGroupHorizontal>
                    <SectionDivider />
                    <SubSectionTitle>
                        Networking
                    </SubSectionTitle>
                    <PlaceholderContainer>
                        This server has all ports open by default. Network access management can be configured using
                        ufw once the VM is provisioned.
                    </PlaceholderContainer>
                    <SectionDivider />
                    <SubSectionTitle>
                        Pricing
                    </SubSectionTitle>
                    <PricingBlock>
                        <TotalPrice>
                            ${parseFloat(totalCost.toFixed(5))}<span>/hour</span>
                        </TotalPrice>
                        <SubSectionDivider />
                        <PricingRow>
                            <PricingCategoryTitle>
                                GPU cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['gpu_cost']).toFixed(5))}<span>/GPU-hour</span> x {numGpus} = ${parseFloat((numGpus*parseFloat(serverInfo['gpu_cost'])).toFixed(5))}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                        <PricingRow>
                            <PricingCategoryTitle>
                                CPU cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['cpu_cost']).toFixed(5))}<span>/CPU-hour</span> x {numCpus} = ${parseFloat((numCpus*serverInfo['cpu_cost']).toFixed(5))}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                        <PricingRow>
                            <PricingCategoryTitle>
                                RAM cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['ram_cost']).toFixed(5))}<span>/GiB-hour</span> x {amtRam} GiB = ${parseFloat((amtRam*serverInfo['ram_cost']).toFixed(5))}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                        <PricingRow>
                            <PricingCategoryTitle>
                                Storage cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(parseFloat(serverInfo['storage_cost']).toFixed(5))}<span>/GiB-hour</span> x {amtStorage} GiB = ${parseFloat((amtStorage*serverInfo['storage_cost']).toFixed(5))}<span>/hour</span>
                            </PricingCalc>
                        </PricingRow>
                        <PricingRow>
                            <PricingCategoryTitle>
                                IP address cost:
                            </PricingCategoryTitle>
                            <PricingCalc>
                                ${parseFloat(serverInfo['ip_cost']).toFixed(5)}
                            </PricingCalc>
                        </PricingRow>
                    </PricingBlock>
                    <ConnectButtonWithDisabling onClick={() => setConfirmModalIsShown(true)}
                                                disabled={!numCpus || !amtRam || !numGpus || !amtStorage || cpusOutOfRange || gpusOutOfRange || ramOutOfRange || storageOutOfRange || selectedSshKey === ''}>
                        Launch virtual machine
                    </ConnectButtonWithDisabling>
                </DetailPanel>
                {vmIsProvisioning && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            </ModalTitle>
                        </ConfirmModal>
                    </>
                )}
                {provisioningSucceeded && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal maxWidth="350px">
                            <ModalTitle>
                                Your VM is provisioning
                            </ModalTitle>
                            <ModalText>
                                It may take 5-10 minutes for your virtual machine to be accessible. You can view the
                                deployment status under "Your Virtual Machines".<br /><br />Once it's ready, use your
                                SSH key "{selectedSshKeyName}" to log in as username <b>root</b> with the IP address
                                shown in the dashboard.
                            </ModalText>
                            <Link to="/dashboard/direct?active=sessions">
                                <ConnectButton>
                                    Okay
                                </ConnectButton>
                            </Link>
                        </ConfirmModal>
                    </>
                )}
                {provisioningFailed && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Error
                            </ModalTitle>
                            <ModalText>
                                There was a problem provisioning the virtual machine. Please try again later.
                            </ModalText>
                            <Link to="/dashboard/direct?active=vms"><ConnectButton>Okay</ConnectButton></Link>
                        </ConfirmModal>
                    </>
                )}
                {insufficientFunds && (
                    <>
                        <ConfirmModalBackgroundCover />
                        <ConfirmModal>
                            <ModalTitle>
                                Insufficient Balance
                            </ModalTitle>
                            <ModalText>
                                You need a balance of at least $10.00 USD to launch a virtual machine. Please deposit funds
                                and try again.
                            </ModalText>
                            <Link to="/dashboard/billing"><ConnectButton>Deposit funds</ConnectButton></Link>
                        </ConfirmModal>
                    </>
                )}
            </DetailContainer>
        )
    );
}

export default PortalDirectCudoCloudServer;