import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GoogleAnalytics = () => {
    const location = useLocation();
    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            const gaId = 'G-DGB65SCW31';
            const awId = 'AW-11280245656';
            window.gtag('config', gaId, {
                page_path: location.pathname + location.search
            });
            window.gtag('config', awId);
        }
    }, [location]);
    return null;
}

export default GoogleAnalytics;