export function generateRandomString(length, allChars = true) {
    let chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    if (allChars) {
        chars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%'
    }
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length))
    }
    return result
}

export function parseTimestamp(t, appendZ = true) {
    let dateandtime;
    if (appendZ) {
        dateandtime = new Date(t + 'Z');
    } else {
        dateandtime = new Date(t);
    }
    return dateandtime.toLocaleString();
}

export function parseFileSize(s) {
    if (s >= 1e12) {
        return `${(s / 1e12).toFixed(1)} TB`;
    } else if (s >= 1e9) {
        return `${(s / 1e9).toFixed(1)} GB`;
    } else if (s >= 1e6) {
        return `${(s / 1e6).toFixed(1)} MB`;
    } else if (s >= 1e3) {
        return `${(s / 1e3).toFixed(1)} KB`;
    } else {
        return `${s} B`;
    }
}

export function urlSafeBase64Encode(s) {
    const standardBase64Encoded = btoa(s);
    return standardBase64Encoded
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
}

export function getDateString(offset = 0) {
    const currentDate = new Date();
    let calculatedDate;
    if (offset === 0) {
        calculatedDate = currentDate;
    } else {
        calculatedDate = new Date(currentDate)
        calculatedDate.setDate(currentDate.getDate() + offset)
    }
    const year = calculatedDate.getFullYear();
    let month = calculatedDate.getMonth() + 1;
    let day = calculatedDate.getDate();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return year + '-' + month + '-' + day;
}

export function formatDateTime(datetimeStr) {
    // Remove fractional seconds if present, as they are not supported by JavaScript's Date constructor
    datetimeStr = datetimeStr.replace(/\.\d+/, '');
    const dateObj = new Date(datetimeStr + 'Z'); // 'Z' is appended to make sure the datetime string is treated as UTC
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };

    const formattedDateStr = new Intl.DateTimeFormat('en-US', options).format(dateObj);
    return formattedDateStr;
}

export function replaceEmptyStringWithNull(obj) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === '') {
            obj[key] = null;
        }
    });
    return obj;
}

export function checkInputValidity(v) {
    const regex = /^[0-9]*$/;
    return regex.test(v) || v === "";
}

export function checkInputInRange(v, min, max) {
    if (v === "") {
        return false;
    } else return v >= min && v <= max;
}

export function createArrayOfIntegers(n) {
    return Array.from({ length: n }, (_, i) => i + 1);
}

export function createIntegerArrayWithSpacing(n, m, d) {
    let x = [];
    for (let i = n; i < m; i+=d) {
        x.push(i);
    }
    return x;
}

export function insertFlagEmoji(locationString) {
    if (locationString.includes('United States') || locationString.includes('USA')) {
        return " 🇺🇸";
    } else if (locationString.includes('United Kingdom') || locationString.includes('UK')) {
        return " 🇬🇧";
    } else if (locationString.includes('India')) {
        return " 🇮🇳";
    } else if (locationString.includes('Sweden')) {
        return " 🇸🇪";
    } else if (locationString.includes('Canada')) {
        return " 🇨🇦";
    } else if (locationString.includes('Spain')) {
        return " 🇪🇸";
    } else if (locationString.includes('Norway')) {
        return " 🇳🇴";
    } else if (locationString.includes('Poland')) {
        return " 🇵🇱";
    } else if (locationString.includes('Estonia')) {
        return " 🇪🇪";
    } else if (locationString.includes('Ukraine')) {
        return " 🇺🇦";
    } else if (locationString.includes('Romania')) {
        return " 🇷🇴";
    } else if (locationString.includes('Czech')) {
        return " 🇨🇿";
    } else if (locationString.includes('France')) {
        return " 🇫🇷";
    } else if (locationString.includes('Germany')) {
        return " 🇩🇪";
    } else if (locationString.includes('Netherlands')) {
        return " 🇳🇱";
    } else if (locationString.includes('Iceland')) {
        return " 🇮🇸";
    } else {
        return "";
    }
}