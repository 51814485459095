import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useStripe } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import { faAngleLeft, faAngleRight, faCircleNotch, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faCcDinersClub,
    faCcJcb,
    faStripe
} from "@fortawesome/free-brands-svg-icons";
import { ConfirmModalBackgroundCover, ConfirmModal, ModalTitle, ModalText, ConnectButton } from "./SharedStyles";
import { CancelButton, TerminateButton } from "./SharedStyles";
import { SignupForm, TextInput, FormSubmitButton } from "./Signup";
import { InputField, InputLabel, DropDownList, InputErrorText } from "./SharedStyles";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer,
    CartesianGrid,
    Tooltip,
    Label
} from "recharts";
import { format } from "date-fns";
import { getDateString } from "./utilities";
import Switch from "react-switch";

export const PortalSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1c47;
  width: 100%;
  height: 100%;
  padding: 0 50px 30px 50px;
  @media screen and (max-width: 435px) {
    padding: 0 25px 30px 25px;
  }
  @media screen and (max-width: 365px) {
    padding: 0 10px 30px 10px;
  }
`;

export const TitleText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  align-self: flex-start;
`;

export const SettingsSection = styled.div`
  background-color: #292c6e;
  padding: 20px 30px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
`;

export const EntryGroup = styled.div`
  margin: 30px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const CustomEntryGroup = styled.div`
  margin: 5px 0;
`;

export const EntryTitle = styled.div`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: -3px;
`;

const EntryLabel = styled.label`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: -3px;
`;

export const EntryValue = styled.div`
  font-size: 0.95rem;
  display: flex;
`;

export const AccountBalanceValue = styled.div`
  font-size: 1.4rem;
`;

export const AccountBalanceDollars = styled.span`
  font-size: 2.0rem;
`;

export const AccountBalanceCents = styled.span`
  font-size: 0.8rem;
`;

export const SettingsSectionGroup = styled.div`
  margin-bottom: 40px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Button = styled(Link)`
  width: 100px;
  text-align: center;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-top: 5px;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const NonLinkButton = styled.div`
  width: 100px;
  text-align: center;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-top: -27px;
  background: linear-gradient(to right, #9438c2, #ff1f82);
  &:hover {
    background: linear-gradient(to right, rgba(148, 56, 194, 0.9), rgba(255, 31, 130, 0.9));
    color: #ffffff;
    cursor: pointer;
  }
`;

const BiggerNonLinkButton = styled(NonLinkButton)`
  margin: 0;
  width: auto;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 10px;
`;

const PaymentMethodsContainer = styled.div`
  border-radius: 5px;
  width: 100%;
`;

const PaymentMethodRowNonInteractive = styled.div`
  flex-wrap: wrap;
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  &:last-child {
    border: 0;
  }
  justify-content: space-between;
  align-items: center;
`;

const PaymentMethodRow = styled(PaymentMethodRowNonInteractive)`
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    &:last-child {
      border: 0;
      cursor: auto;
    }
  }
`;

const CreditCardSuperGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CreditCardGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 140px;
  @media screen and (max-width: 450px) {
    width: auto;
  }
`;

const CreditCardLogo = styled.div`
  font-size: 1.45rem;
`;

const CreditCardDetails = styled.div`
  font-size: 0.85rem;
  margin-left: 6px;
  margin-bottom: 3px;
`;

const CreditCardExpiration = styled.div`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
`;

const CreditCardSelected = styled.div`
  font-size: 0.6rem;
  font-weight: 600;
  background-color: #fff;
  visibility: ${props => props.visibility && props.visibility};
  border-radius: 3px;
  padding: 2px 5px;
  color: #a020f0;
`;

const TrashCan = styled.div`
  font-size: 0.7rem;
  padding: 5px;
  color: rgba(255, 255, 255, 0.5);
  &:hover {
    cursor: pointer;
    color: #fff;
  }
`;

const CustomTextInput = styled(TextInput)`
  font-size: 0.8rem;
  background-color: #292c6e;
  margin-bottom: 0;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const InputFinePrint = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 0.6rem;
  opacity: 0.7;
  margin-bottom: 15px;
`;

const LeftAlignedInputFinePrint = styled(InputFinePrint)`
  justify-content: flex-start;
  margin-top: 2px;
`;

const CustomDropDownList = styled(DropDownList)`
  font-size: 0.8rem;
  background-color: #292c6e;
`;

const BadgeArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const BadgeText = styled.div`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  margin-right: 5px;
  margin-bottom: 3px;
`;

const Badge = styled.div`
  font-size: 2.5rem;
  color: rgba(255, 255, 255, 0.9);
`;

const CustomFormSubmitButton = styled(FormSubmitButton)`
  opacity: ${props => props.disabled && '0.5'};
  &:hover {
    cursor: ${props => props.disabled && 'not-allowed'};
  }
`;

const CustomConfirmModal = styled(ConfirmModal)`
  padding-bottom: 0;
  @media screen and (max-width: 600px) {
    max-width: none;
    width: 50%;
  }
  @media screen and (max-width: 500px) {
    width: 65%;
  }
  @media screen and (max-width: 400px) {
    width: 80%;
  }
`;

const FeedbackModal = styled(CustomConfirmModal)`
  padding-bottom: 20px;
`;

const SecondLayerBackgroundCover = styled(ConfirmModalBackgroundCover)`
  z-index: 45;
  background-color: rgba(255, 255, 255, 0.15);
`;

const SecondLayerConfirmModal = styled(ConfirmModal)`
  z-index: 46;
  padding: 30px 50px;
  @media screen and (max-width: 600px) {
    max-width: none;
    width: 50%;
  }
  @media screen and (max-width: 500px) {
    width: 65%;
  }
  @media screen and (max-width: 400px) {
    width: 80%;
  }
`;

const CustomModalText = styled(ModalText)`
  display: flex;
  flex-direction: column;
  & span {
    font-weight: 700;
  }
  gap: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 0 20px 0;
`;

const NonCenteredModalText = styled(ModalText)`
  text-align: left;
`;

const CustomModalTextSubSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const SubsectionName = styled.div`
  font-size: 0.6rem;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
`;

const SubsectionValue = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const SubsectionValueDetails = styled.div`
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 5px;
`;

export const TransactionsContainer = styled.div`
  width: 100%;
`;

export const TransactionsLoadingContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
  width: 100%;
`;

export const TransactionsList = styled.div`
  width: 100%;
`;

export const NonInteractiveTransactionRow = styled.div`
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -3px;
`;

export const TransactionRow = styled(NonInteractiveTransactionRow)`
  margin-top: 0;
  &:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    cursor: pointer;
  }
`;

const TransactionAmount = styled.div`
  font-size: 0.85rem;
  width: 100px;
`;

const TransactionType = styled.div`
  font-size: 0.85rem;
  width: 100px;
  text-align: center;
`;

const TransactionTimestamp = styled.div`
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.5);
  width:  100px;
  text-align: end;
`;

export const PagingControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
`;

export const PagingController = styled.div`
  padding: 5px;
  cursor: pointer;
`;

export const DisabledPagingController = styled(PagingController)`
  cursor: not-allowed;
  color: rgba(255, 255, 255, 0.5);
`;

export const NarrowInputField = styled(InputField)`
  padding: 5px 0 5px 10px;
  margin-right: 5px;
`;

const SwitchContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const SwitchMessage = styled.div`
  margin-left: 10px;
  font-size:  0.8rem;
  color: ${props => props.error ? 'red' : '#fff'};
`;

const ModifiedInputErrorText = styled(InputErrorText)`
  margin-bottom: 2px;
  text-align: left;
  margin-left: 0;
  margin-right: auto;
`;

const ChartArea = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const ChartTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
`;

const AutopayBlock = styled.div`
  background-color: #1a1c47;
  padding: 5px 15px 15px 15px;
  border-radius: 10px;
  outline: 1px solid rgba(255, 255, 255, 0.1);
`;

const BarChartTooltip = styled.div`
  outline: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #000;
`;

const TooltipLabel = styled.div`
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.5);
`;

const TooltipValue = styled.div``;

const PortalBilling = ({
                            setSelectedView,
                            token
                        }) => {
    const [ paymentMethodsAreLoading, setPaymentMethodsAreLoading ] = useState(true);
    const [ userBalanceIsLoading, setUserBalanceIsLoading ] = useState(true);
    const [ defaultPaymentMethod, setDefaultPaymentMethod ] = useState('');
    const [ defaultPaymentIsLoaded, setDefaultPaymentIsLoaded ] = useState(false);
    const [ paymentMethods, setPaymentMethods ] = useState([]);
    const [ userBalance, setUserBalance ] = useState(0);
    const [ deleteModalIsShown, setDeleteModalIsShown ] = useState(false);
    const [ cardStagedForDeletion, setCardStagedForDeletion ] = useState({});
    const [ paymentMethodRemovalSucceeded, setPaymentMethodRemovalSucceeded ] = useState(false);
    const [ paymentMethodRemovalFailed, setPaymentMethodRemovalFailed ] = useState(false);
    const [ cardIsDeleting, setCardIsDeleting ] = useState(false);
    const [ depositFundsModalIsShown, setDepositFundsModalIsShown ] = useState(false);
    const [ cardStagedForPayment, setCardStagedForPayment ] = useState('');
    const [ stagedCardDetails, setStagedCardDetails ] = useState({});
    const [ depositAmount, setDepositAmount ] = useState('');
    const [ finalPaymentConfirmationIsShown, setFinalPaymentConfirmationIsShown ] = useState(false);
    const [ paymentIsProcessing, setPaymentIsProcessing ] = useState(false);
    const [ paymentProcessed, setPaymentProcessed ] = useState(false);
    const [ paymentFailed, setPaymentFailed ] = useState(false);
    const [ paymentIsDelayed, setPaymentIsDelayed ] = useState(false);
    const [ paymentWasCanceled, setPaymentWasCanceled ] = useState(false);
    const [ paymentWasDeclined, setPaymentWasDeclined ] = useState(false);
    const [ transactionsAreLoading, setTransactionsAreLoading ] = useState(true);
    const [ numTransactionPages, setNumTransactionPages ] = useState(0);
    const [ currentPageOfTransactions, setCurrentPageOfTransactions ] = useState([]);
    const [ targetTxPageNumber, setTargetTxPageNumber ] = useState('1');
    const [ currentTimestamp, setCurrentTimestamp ] = useState(new Date().toISOString());
    const [ transactionDetailIsShown, setTransactionDetailIsShown ] = useState(false);
    const [ transactionDetailIsLoading, setTransactionDetailIsLoading ] = useState(false);
    const [ transactionDetail, setTransactionDetail ] = useState({});
    const [ autopayIsLoading, setAutopayIsLoading ] = useState(true);
    const [ autopayDetails, setAutopayDetails ] = useState({});
    const [ errorFetchingAutopay, setErrorFetchingAutopay ] = useState(false);
    const [ autopayIsEnabled, setAutopayIsEnabled ] = useState(false);
    const [ enableAutopayPromptIsShown, setEnableAutopayPromptIsShown ] = useState(false);
    const [ disableAutopayPromptIsShown, setDisableAutopayPromptIsShown ] = useState(false);
    const [ autopayBalanceThreshold, setAutopayBalanceThreshold ] = useState('');
    const [ autopayDepositAmount, setAutopayDepositAmount ] = useState('');
    const [ cardStagedForAutopay, setCardStagedForAutopay ] = useState('');
    const [ enableAutopayError, setEnableAutopayError ] = useState(false);
    const [ disableAutopayError, setDisableAutopayError ] = useState(false);
    const [ autopayIsBeingEnabled, setAutopayIsBeingEnabled ] = useState(false);
    const [ autopayIsBeingDisabled, setAutopayIsBeingDisabled ] = useState(false);
    const [ balanceThresholdIsInvalid, setBalanceThresholdIsInvalid ] = useState(false);
    const [ autopayDepositAmountIsInvalid, setAutopayDepositAmountIsInvalid ] = useState(false);
    const [ usageIsLoading, setUsageIsLoading ] = useState(false);
    const [ usageFailedToLoad, setUsageFailedToLoad ] = useState(false);
    const [ usageData, setUsageData ] = useState([]);

    const stripe = useStripe();

    useEffect(() => {
        setSelectedView('billing');
    }, [setSelectedView]);

    useEffect(() => {
        if (token) {
            fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/info`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response['status'] <= 299) {
                        return response.json();
                    }
                })
                .then(data => {
                    if (data) {
                        setDefaultPaymentMethod(data['invoice_settings']['default_payment_method']);
                        setDefaultPaymentIsLoaded(true);
                    }
                })
                .catch(error => console.log(error));
            fetchUserBalance();
            fetchPaymentMethods();
            initializeTransactions();
            fetchTransactions();
            (async () => {
                await fetchAutopay();
                await fetchUsage();
            })();
        }
    }, [token]);

    useEffect(() => {
        if (token && targetTxPageNumber) {
            fetchTransactions(5, parseInt(targetTxPageNumber)-1);
        }
    }, [targetTxPageNumber, currentTimestamp, token]);

    function currencyDisplay(amount) {
        let negativeBalance = false;
        if (amount < 0) {
            negativeBalance = true
        }

        // convert to dollars and cents
        let absoluteAmount = Math.abs(amount);
        let dollarAmount = Math.floor(absoluteAmount);
        let centAmount = 100*(absoluteAmount - dollarAmount);

        // format cents to always have two digits
        centAmount = centAmount < 10 ? '0' + centAmount : String(centAmount);

        // format dollar amount with commas every three digits
        dollarAmount = dollarAmount.toLocaleString();

        return (
            <AccountBalanceValue>
                {negativeBalance ? (
                    <AccountBalanceDollars>-${dollarAmount}</AccountBalanceDollars>
                ) : (
                    <AccountBalanceDollars>${dollarAmount}</AccountBalanceDollars>
                )}
                <AccountBalanceCents>.{centAmount.slice(0,2)}</AccountBalanceCents>
            </AccountBalanceValue>
        );
    }

    function formatTimestamp(isoTimestamp) {
        if (isoTimestamp.slice(-1) !== 'Z') {
            isoTimestamp += 'Z';
        }
        const date = new Date(isoTimestamp);
        return format(date, 'MMM d, yyyy h:mm:ss a');
    }

    function formatCardBrand(cardBrand) {
        if (cardBrand === 'visa') {
            return 'VISA';
        } else if (cardBrand === 'discover') {
            return 'Discover';
        } else if (cardBrand === 'mastercard') {
            return 'MasterCard';
        } else if (cardBrand === 'amex') {
            return 'American Express';
        } else if (cardBrand === 'diners') {
            return 'Diners Club';
        } else if (cardBrand === 'jcb') {
            return 'JCB';
        } else if (cardBrand === 'unionpay') {
            return 'Union Pay';
        } else {
            return cardBrand;
        }
    }

    function stageCardForDeletion(cardDetails) {
        setCardStagedForDeletion(cardDetails);
        setDeleteModalIsShown(true);
    }

    function unstageCardForDeletion() {
        setCardStagedForDeletion({});
        setDeleteModalIsShown(false);
    }

    async function fetchAutopay() {
        try {
            setAutopayIsLoading(true);
            const autopayResponse = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/autopay`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (autopayResponse.ok) {
                const autopayData = await autopayResponse.json();
                if (autopayData.status === 'active') {
                    const pmResponse = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/payment-method/${autopayData.payment_method_id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (pmResponse.ok) {
                        const pmData = await pmResponse.json();
                        if ('card' in pmData && 'brand' in pmData['card'] && 'last4' in pmData['card']) {
                            setAutopayIsEnabled(true);
                            setAutopayDetails({
                                ...autopayData,
                                'card': {
                                    'brand': pmData['card'].brand,
                                    'last4': pmData['card'].last4
                                }
                            });
                        } else {
                            console.log('Missing fields in payment method response');
                            setErrorFetchingAutopay(true);
                        }
                    } else {
                        console.log('Error fetching payment method');
                        setErrorFetchingAutopay(true);
                    }
                } else if (autopayData.status === 'inactive') {
                    setAutopayIsEnabled(false);
                    setAutopayDetails({status: 'inactive'});
                } else {
                    console.log('Invalid response from autopay endpoint');
                    setErrorFetchingAutopay(true);
                }
            } else if (autopayResponse.status === 404) {
                const autopayData = await autopayResponse.json();
                if ('detail' in autopayData && autopayData['detail'] === 'Record not found') {
                    setAutopayIsEnabled(false);
                } else {
                    console.log('Unexpected 404 response');
                    setErrorFetchingAutopay(true);
                }
            } else {
                console.log(`Unexpected response with status code: ${autopayResponse.status}`);
                setErrorFetchingAutopay(true);
            }
        } catch (error) {
            console.log(error);
            setErrorFetchingAutopay(true);
        } finally {
            setAutopayIsLoading(false);
        }
    }

    async function fetchUsage() {
        try {
            setUsageIsLoading(true);
            const usageResponse = await fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/usage/daily?since=${encodeURIComponent(getDateString(-6))}&until=${encodeURIComponent(getDateString(1))}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (usageResponse.ok) {
                const usageJson = await usageResponse.json();
                if ('usage' in usageJson) {
                    const usageDataList = usageJson['usage'].map(entry => {
                        return {
                            ...entry,
                            total_spend: parseFloat(entry['total_spend'])
                        }
                    });
                    setUsageData(usageDataList);
                } else {
                    setUsageFailedToLoad(true);
                }
            } else {
                setUsageFailedToLoad(true);
            }
        } catch (error) {
            console.log(error);
            setUsageFailedToLoad(true);
        } finally {
            setUsageIsLoading(false);
        }
    }

    function fetchPaymentMethods() {
        setPaymentMethodsAreLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/payment-method`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] <= 299) {
                    return response.json();
                }
            })
            .then(data => {
                if (data) {
                    setPaymentMethods(data['data']);
                    setPaymentMethodsAreLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function removePaymentMethod(paymentMethodId) {
        setDeleteModalIsShown(false);
        setCardIsDeleting(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/payment-method/${paymentMethodId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] <= 299) {
                    setCardIsDeleting(false);
                    setPaymentMethodRemovalSucceeded(true);
                    setCardStagedForDeletion({});
                }
                else {
                    setCardIsDeleting(false);
                    setPaymentMethodRemovalFailed(true);
                    setCardStagedForDeletion({});
                }
            })
            .catch(
                error => {
                    setCardIsDeleting(false);
                    setPaymentMethodRemovalFailed(true);
                    setCardStagedForDeletion({});
                    console.log(error)
                });
    }

    function fetchUserBalance() {
        setUserBalanceIsLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/balance`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] <= 299) {
                    return response.json();
                }
            })
            .then(data => {
                if (data) {
                    setUserBalance(data['balance']);
                    setUserBalanceIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function initializeTransactions(pageSize = 5) {
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/transaction/count?page_size=${pageSize}&max_timestamp=${encodeURIComponent(currentTimestamp)}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] <= 299) {
                    return response.json();
                }
            })
            .then(data => {
                if (data) {
                    setNumTransactionPages(data['num_pages']);
                }
            })
            .catch(error => console.log(error));
    }

    function fetchTransactions(pageSize = 5, pageNumber = 0) {
        setTransactionsAreLoading(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/transaction?page_size=${pageSize}&page=${pageNumber}&max_timestamp=${encodeURIComponent(currentTimestamp)}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] <= 299) {
                    return response.json();
                }
            })
            .then(data => {
                if (data) {
                    setCurrentPageOfTransactions(data['transactions']);
                    setTransactionsAreLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function handleTransactionDetailDisplay(tx_id) {
        setTransactionDetailIsLoading(true);
        setTransactionDetailIsShown(true);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/transaction/${tx_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response['status'] <= 299) {
                    return response.json();
                }
            })
            .then(data => {
                if (data) {
                    setTransactionDetail(data);
                    setTransactionDetailIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }

    function handleTransactionDetailDisplayDismissal() {
        setTransactionDetailIsShown(false);
        setTransactionDetail({});
    }

    function handleTargetTxPageNumberChange(e) {
        if (!e.target.value) {
            setTargetTxPageNumber(e.target.value);
        } else {
            if (e.target.value >= 1 && e.target.value <= numTransactionPages) {
                setTargetTxPageNumber(e.target.value);
            }
        }
    }

    function handleTxPageIncrement(direction) {
        if (direction === 'up') {
            if (parseInt(targetTxPageNumber) < numTransactionPages) {
                setTargetTxPageNumber(prev => String(parseInt(prev) + 1));
            }
        } else if (direction === 'down') {
            if (parseInt(targetTxPageNumber) > 1) {
                setTargetTxPageNumber(prev => String(parseInt(prev) - 1));
            }
        }
    }

    const handleDepositAmountInput = e => {
        const pattern = /^([1-9]\d*(\.\d{0,2})?)?$/;
        if (pattern.test(e.target.value)) {
            setDepositAmount(e.target.value);
        }
    }

    const handleAutopayBalanceThresholdInput = e => {
        const pattern = /^([1-9]\d*(\.\d{0,2})?)?$/;
        if (pattern.test(e.target.value)) {
            setAutopayBalanceThreshold(e.target.value);
            if (parseFloat(e.target.value) >= userBalance) {
                setBalanceThresholdIsInvalid(true);
            } else {
                setBalanceThresholdIsInvalid(false);
            }
        }
    }

    const handleAutopayDepositAmountInput = e => {
        const pattern = /^([1-9]\d*(\.\d{0,2})?)?$/;
        if (pattern.test(e.target.value)) {
            setAutopayDepositAmount(e.target.value);
            if (parseFloat(e.target.value) < 50 || parseFloat(e.target.value) > 2500) {
                setAutopayDepositAmountIsInvalid(true);
            } else {
                setAutopayDepositAmountIsInvalid(false);
            }
        }
    }

    const stageCardForPayment = e => {
        setCardStagedForPayment(e.target.value);
    }

    const stageCardForAutopay = e => {
        setCardStagedForAutopay(e.target.value);
    }

    const handleFormSubmit = e => {
        e.preventDefault();
        const cardDetails = paymentMethods.find(method => method['id'] === cardStagedForPayment)
        if (cardDetails) {
            setStagedCardDetails(cardDetails);
        }
        setFinalPaymentConfirmationIsShown(true);
    }

    const handleAutopaySubmit = e => {
        e.preventDefault();
        setAutopayIsBeingEnabled(true);
        setEnableAutopayPromptIsShown(false);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/autopay`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                payment_method_id: cardStagedForAutopay,
                currency: 'usd',
                balance_threshold: parseFloat(autopayBalanceThreshold),
                top_up_amount: parseFloat(autopayDepositAmount)
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`Unexpected error enabling autopay: ${response.status}`);
                }
            })
            .then(data => {
                if (data.message === 'Successfully activated') {
                    const paymentMethodDetails = paymentMethods.filter(pm => pm.id === cardStagedForAutopay);
                    setAutopayDetails({
                        status: 'active',
                        balance_threshold: autopayBalanceThreshold,
                        top_up_amount: autopayDepositAmount,
                        card: {
                            brand: paymentMethodDetails[0]['card']['brand'],
                            last4: paymentMethodDetails[0]['card']['last4']
                        }
                    });
                    setAutopayIsEnabled(true);
                } else {
                    throw new Error(`Unexpected response when enabling autopay`);
                }
            })
            .catch(error => {
                setEnableAutopayError(true);
                console.log(error)
            })
            .finally(() => {
                setAutopayBalanceThreshold('');
                setAutopayDepositAmount('');
                setCardStagedForAutopay('');
                setAutopayIsBeingEnabled(false);
            });
    }

    const handleDisableAutopay = () => {
        setAutopayIsBeingDisabled(true);
        setDisableAutopayPromptIsShown(false);
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/autopay`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`Unexpected error disabling autopay: ${response.status}`);
                }
            })
            .then(data => {
                if (data.message === 'Successfully deactivated') {
                    setAutopayDetails({
                        status: 'inactive'
                    });
                    setAutopayIsEnabled(false);
                } else {
                    throw new Error(`Unexpected response when disabling autopay`);
                }
            })
            .catch(error => {
                setDisableAutopayError(true);
                console.log(error);
            })
            .finally(() => {
                setAutopayIsBeingDisabled(false);
            });
    }

    const formatCurrency = v => {
        const formattedValue = new Intl.NumberFormat(
            'en-US',
            {
                style: 'currency',
                currency: 'USD'
            }).format(v);
        return formattedValue;
    }

    async function handlePaymentAction(clientSecret, paymentMethodId) {
        try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
                clientSecret,
                {
                    payment_method: paymentMethodId
                }
            );

            if (error) {
                console.error('Error confirming card payment: ', error);
                setPaymentIsProcessing(false);
                setPaymentFailed(true);
                return;
            }

            if (paymentIntent.status === 'succeeded') {
                setPaymentIsProcessing(false);
                setPaymentProcessed(true);
            } else {
                setPaymentIsProcessing(false);
                setPaymentFailed(true);
            }
        } catch (err) {
            console.error('Exception during payment handling: ', err);
            setPaymentIsProcessing(false);
            setPaymentFailed(true);
        }
    }

    const sendPayment = () => {
        setDepositFundsModalIsShown(false);
        setFinalPaymentConfirmationIsShown(false);
        setPaymentIsProcessing(true);
        const payload = {
            payment_method_id: cardStagedForPayment,
            amount: Math.floor(100*parseFloat(depositAmount)),
            currency: 'usd'
        };
        fetch(`${process.env.REACT_APP_PORTAL_BASE_URL}/billing/payment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (response['status'] === 200) {
                    return response.json();
                } else {
                    setPaymentIsProcessing(false);
                    setPaymentFailed(true);
                }
            })
            .then(data => {
                if (data.hasOwnProperty('status')) {
                    if (data['status'] === 'requires_action') {
                        handlePaymentAction(data['client_secret'], data['payment_method']);
                    } else if (data['status'] === 'succeeded') {
                        setPaymentIsProcessing(false);
                        setPaymentProcessed(true);
                    } else if (data['status'] === 'processing') {
                        setPaymentIsProcessing(false);
                        setPaymentIsDelayed(true);
                    } else if (data['status'] === 'requires_payment_method') {
                        setPaymentIsProcessing(false);
                        setPaymentWasDeclined(true);
                    } else if (data['status'] === 'canceled') {
                        setPaymentIsProcessing(false);
                        setPaymentWasCanceled(true);
                    }
                } else if (data.hasOwnProperty('message')) {
                    setPaymentIsProcessing(false);
                    setPaymentProcessed(true);
                }
            })
            .catch(error => {
                setPaymentIsProcessing(false);
                setPaymentFailed(true);
            });
    }

    const dismissPaymentRemovalSucceededModal = () => {
        setPaymentMethodRemovalSucceeded(false);
        fetchPaymentMethods();
    }

    const dismissSuccessfulDepositModal = () => {
        setPaymentProcessed(false);
        setPaymentIsDelayed(false);
        setDepositAmount('');
        setStagedCardDetails({});
        setCardStagedForPayment('');
        fetchUserBalance();
        setCurrentTimestamp(new Date().toISOString());
        if (targetTxPageNumber === '1') {
            fetchTransactions();
        } else {
            setTargetTxPageNumber('1');
        }
    }

    function handleAutopaySwitch() {
        if (autopayIsEnabled) {
            setDisableAutopayPromptIsShown(true);
        } else {
            handleDisplayEnableAutopayPrompt();
        }
    }

    function handleDisplayEnableAutopayPrompt() {
        setAutopayIsEnabled(true);
        setEnableAutopayPromptIsShown(true);
    }

    function handleDismissEnableAutopayPrompt () {
        setAutopayIsEnabled(false);
        setEnableAutopayPromptIsShown(false);
        setAutopayBalanceThreshold('');
        setAutopayDepositAmount('');
        setCardStagedForAutopay('');
    }

    function handleDisplayDisableAutopayPrompt() {

    }

    function handleDismissDisableAutopayPrompt() {

    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <BarChartTooltip>
                    <TooltipLabel>
                        {label}
                    </TooltipLabel>
                    <TooltipValue>
                        ${parseFloat(parseFloat(payload[0].value).toFixed(2))}
                    </TooltipValue>
                </BarChartTooltip>
            );
        } else {
            return null;
        }
    }

    return (
        <PortalSettingsContainer>
            <SettingsSectionGroup>
                <TitleText>
                    Billing
                </TitleText>
                <SettingsSection>
                    <EntryGroup>
                        <EntryTitle>
                            Balance
                        </EntryTitle>
                        {userBalanceIsLoading ? (
                            <AccountBalanceValue>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            </AccountBalanceValue>
                        ) : (
                            currencyDisplay(userBalance)
                        )}
                    </EntryGroup>
                    <NonLinkButton onClick={() => setDepositFundsModalIsShown(true)}>
                        Add funds
                    </NonLinkButton>
                    <EntryGroup>
                        <EntryTitle>
                            Payment Methods
                        </EntryTitle>
                        <EntryValue>
                            <PaymentMethodsContainer>
                                {paymentMethodsAreLoading ? (
                                    <FontAwesomeIcon icon={faCircleNotch} spin />
                                ) : (
                                    paymentMethods.length === 0 ? (
                                        <PaymentMethodRowNonInteractive>
                                            No payment methods added.
                                        </PaymentMethodRowNonInteractive>
                                    ) : (
                                        paymentMethods.map(card => {
                                            return (
                                                <PaymentMethodRowNonInteractive key={card['id']}>
                                                    <CreditCardSuperGroup>
                                                        <CreditCardGroup>
                                                            <CreditCardLogo>
                                                                {card['card']['brand'] === 'visa' ? (
                                                                    <FontAwesomeIcon icon={faCcVisa} />
                                                                ) : (
                                                                    card['card']['brand'] === 'mastercard' ? (
                                                                        <FontAwesomeIcon icon={faCcMastercard} />
                                                                    ) : (
                                                                        card['card']['brand'] === 'discover' ? (
                                                                            <FontAwesomeIcon icon={faCcDiscover} />
                                                                        ) : (
                                                                            card['card']['brand'] === 'amex' ? (
                                                                                <FontAwesomeIcon icon={faCcAmex} />
                                                                            ) : (
                                                                                card['card']['brand'] === 'diners' ? (
                                                                                    <FontAwesomeIcon icon={faCcDinersClub} />
                                                                                ) : (
                                                                                    card['card']['brand'] === 'jcb' ? (
                                                                                        <FontAwesomeIcon icon={faCcJcb} />
                                                                                    ) : (
                                                                                        <FontAwesomeIcon icon={faCreditCard} />
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )}
                                                            </CreditCardLogo>
                                                            <CreditCardDetails>
                                                                ending in {card['card']['last4']}
                                                            </CreditCardDetails>
                                                        </CreditCardGroup>
                                                        <CreditCardExpiration>
                                                            exp. {card['card']['exp_month']}/{card['card']['exp_year']}
                                                        </CreditCardExpiration>
                                                    </CreditCardSuperGroup>
                                                    <TrashCan onClick={() => stageCardForDeletion(card)}>
                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                    </TrashCan>
                                                </PaymentMethodRowNonInteractive>
                                            )
                                        })
                                    )
                                )}
                                <PaymentMethodRow>
                                    <Button to="/dashboard/billing/payment-method">Add card</Button>
                                </PaymentMethodRow>
                            </PaymentMethodsContainer>
                        </EntryValue>
                    </EntryGroup>
                    <EntryGroup>
                        <AutopayBlock>
                            <EntryLabel htmlFor="autopay-switch">
                                Autopay
                            </EntryLabel>
                            <EntryValue>
                                {autopayIsLoading ? (
                                    <FontAwesomeIcon icon={faCircleNotch} spin />
                                ) : (
                                    <SwitchContainer>
                                        <Switch
                                            checked={autopayIsEnabled}
                                            onChange={handleAutopaySwitch}
                                            height={20}
                                            width={40}
                                            uncheckedIcon={false}
                                            id="autopay-switch"
                                            disabled={userBalance <= 0}
                                        />
                                        {autopayIsEnabled ? (
                                            ('balance_threshold' in autopayDetails
                                                && 'top_up_amount' in autopayDetails) && (
                                                <SwitchMessage>
                                                    ${parseFloat(autopayDetails.top_up_amount).toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})} will be deposited when your balance falls below ${parseFloat(autopayDetails.balance_threshold).toLocaleString('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2})} using your {(autopayDetails['card'].brand).toUpperCase()} ending in {autopayDetails['card'].last4}.
                                                </SwitchMessage>
                                            )
                                        ) : (
                                            errorFetchingAutopay ? (
                                                <SwitchMessage error={true}>
                                                    <FontAwesomeIcon icon={faXmark} /> There was an error fetching autopay settings.
                                                </SwitchMessage>
                                            ) : (
                                                userBalance <= 0 ? (
                                                    <SwitchMessage>
                                                        You can enable autopay once you deposit some funds.
                                                    </SwitchMessage>
                                                ) : (
                                                    <SwitchMessage>
                                                        Autopay is disabled.
                                                    </SwitchMessage>
                                                )
                                            )
                                        )}
                                    </SwitchContainer>
                                )}
                            </EntryValue>
                        </AutopayBlock>
                    </EntryGroup>
                    <EntryGroup>
                        <EntryValue>
                            {usageIsLoading ? (
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                            ) : (
                                usageFailedToLoad ? (
                                    <SwitchMessage error={true}>
                                        <FontAwesomeIcon icon={faXmark} /> Usage data failed to load.
                                    </SwitchMessage>
                                ) : (
                                    <ChartArea>
                                        <ChartTitle>Your Daily Spend</ChartTitle>
                                        <ResponsiveContainer width='100%' height={300}>
                                            <BarChart data={usageData}>
                                                <Bar dataKey='total_spend' fill='#05daff' minPointSize={3} activeBar={false} />
                                                <CartesianGrid strokeDasharray='3 3' stroke="rgba(255, 255, 255, 0.2)" />
                                                <XAxis dataKey='date' stroke="#c9c9c9" tick={{ fontSize: '0.9rem' }} />
                                                <YAxis
                                                    dataKey='total_spend'
                                                    type="number"
                                                    stroke="#c9c9c9"
                                                    tick={{ fontSize: '0.9rem' }}
                                                    tickFormatter={value => `$${value.toLocaleString()}`}
                                                />
                                                <Tooltip
                                                    cursor={{ fill: 'none' }}
                                                    content={<CustomTooltip />}
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </ChartArea>
                                )
                            )}
                        </EntryValue>
                    </EntryGroup>
                    <EntryGroup>
                        <EntryTitle>
                            Transactions
                        </EntryTitle>
                        <EntryValue>
                            <TransactionsContainer>
                                {transactionsAreLoading ? (
                                    <TransactionsLoadingContainer>
                                        <FontAwesomeIcon icon={faCircleNotch} spin />
                                    </TransactionsLoadingContainer>
                                ) : (
                                    numTransactionPages === 0 ? (
                                        <TransactionsList>
                                            <NonInteractiveTransactionRow>
                                                No transactions yet.
                                            </NonInteractiveTransactionRow>
                                        </TransactionsList>
                                    ) : (
                                        <TransactionsList>
                                            {currentPageOfTransactions.map(tx => {
                                                return (
                                                    <TransactionRow key={tx['transaction_id']}
                                                                    onClick={() => handleTransactionDetailDisplay(tx['transaction_id'])}
                                                    >
                                                        {tx['type'] === 'vm_usage' && (
                                                            <TransactionAmount>
                                                                -${parseFloat(tx['amount']).toFixed(6)}
                                                            </TransactionAmount>
                                                        )}
                                                        {tx['type'] === 'deposit' && (
                                                            <TransactionAmount>
                                                                ${parseFloat(tx['amount']).toFixed(2)}
                                                            </TransactionAmount>
                                                        )}
                                                        {tx['type'] === 'debit_adjustment' && (
                                                            <TransactionAmount>
                                                                -${parseFloat(tx['amount']).toFixed(2)}
                                                            </TransactionAmount>
                                                        )}
                                                        {tx['type'] === 'credit_adjustment' && (
                                                            <TransactionAmount>
                                                                ${parseFloat(tx['amount']).toFixed(2)}
                                                            </TransactionAmount>
                                                        )}
                                                        {tx['type'] === 'referral_bonus' && (
                                                            <TransactionAmount>
                                                                ${parseFloat(tx['amount']).toFixed(2)}
                                                            </TransactionAmount>
                                                        )}
                                                        {tx['type'] === 'storage_usage' && (
                                                            <TransactionAmount>
                                                                -${parseFloat(tx['amount']).toFixed(6)}
                                                            </TransactionAmount>
                                                        )}
                                                        {tx['type'] === 'data_egress' && (
                                                            <TransactionAmount>
                                                                -${parseFloat(tx['amount']).toFixed(6)}
                                                            </TransactionAmount>
                                                        )}
                                                        {tx['type'] === 'vm_usage' && (
                                                            <TransactionType>
                                                                VM Usage
                                                            </TransactionType>
                                                        )}
                                                        {tx['type'] === 'deposit' && (
                                                            <TransactionType>
                                                                Deposit
                                                            </TransactionType>
                                                        )}
                                                        {tx['type'] === 'debit_adjustment' && (
                                                            <TransactionType>
                                                                Admin Debit
                                                            </TransactionType>
                                                        )}
                                                        {tx['type'] === 'credit_adjustment' && (
                                                            <TransactionType>
                                                                Admin Credit
                                                            </TransactionType>
                                                        )}
                                                        {tx['type'] === 'referral_bonus' && (
                                                            <TransactionType>
                                                                Referral Bonus
                                                            </TransactionType>
                                                        )}
                                                        {tx['type'] === 'storage_usage' && (
                                                            <TransactionType>
                                                                Storage Usage
                                                            </TransactionType>
                                                        )}
                                                        {tx['type'] === 'data_egress' && (
                                                            <TransactionType>
                                                                Data Egress
                                                            </TransactionType>
                                                        )}
                                                        <TransactionTimestamp>
                                                            {formatTimestamp(tx['timestamp'])}
                                                        </TransactionTimestamp>
                                                    </TransactionRow>
                                                )
                                            })
                                            }
                                        </TransactionsList>
                                    )
                                )}
                                <PagingControls>
                                    {targetTxPageNumber === '1' || !targetTxPageNumber ? (
                                        <DisabledPagingController>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </DisabledPagingController>
                                    ) : (
                                        <PagingController onClick={() => handleTxPageIncrement('down')}>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </PagingController>
                                    )}
                                    <NarrowInputField id="page-input"
                                                      type="number"
                                                      customWidth="40px"
                                                      value={targetTxPageNumber}
                                                      onChange={handleTargetTxPageNumberChange}
                                                      min="1"
                                                      disabled={numTransactionPages === 0}
                                    />
                                    <InputLabel htmlFor="page-input">
                                        of {numTransactionPages === 0 ? '1' : numTransactionPages}
                                    </InputLabel>
                                    {(parseInt(targetTxPageNumber) === numTransactionPages || numTransactionPages === 0) ? (
                                        <DisabledPagingController>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </DisabledPagingController>
                                    ) : (
                                        <PagingController onClick={() => handleTxPageIncrement('up')}>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </PagingController>
                                    )}
                                </PagingControls>
                            </TransactionsContainer>
                        </EntryValue>
                    </EntryGroup>
                </SettingsSection>
            </SettingsSectionGroup>
            {deleteModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={unstageCardForDeletion} />
                    <ConfirmModal>
                        <ModalTitle>
                            Remove?
                        </ModalTitle>
                        <ModalText>
                            Are you sure you want to remove the {cardStagedForDeletion['card']['brand'].toUpperCase()} card ending in {cardStagedForDeletion['card']['last4']} as a payment method?
                        </ModalText>
                        <CancelButton onClick={unstageCardForDeletion}>Cancel</CancelButton>
                        <TerminateButton onClick={() => removePaymentMethod(cardStagedForDeletion['id'])}>
                            Yes, remove.
                        </TerminateButton>
                    </ConfirmModal>
                </>
            )}
            {cardIsDeleting && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </ConfirmModal>
                </>
            )}
            {paymentMethodRemovalFailed && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setPaymentMethodRemovalFailed(false)} />
                    <ConfirmModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            Temporarily unable to remove this payment method.<br /><br /> If this payment method is
                            attached to a currently active autopay, you must disable autopay before removing the payment
                            method.
                        </ModalText>
                        <ConnectButton onClick={() => setPaymentMethodRemovalFailed(false)}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {paymentMethodRemovalSucceeded && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissPaymentRemovalSucceededModal} />
                    <ConfirmModal>
                        <ModalTitle>
                            Success
                        </ModalTitle>
                        <ModalText>
                            The payment method has been successfully removed from your account.
                        </ModalText>
                        <ConnectButton onClick={dismissPaymentRemovalSucceededModal}>
                            Okay
                        </ConnectButton>
                    </ConfirmModal>
                </>
            )}
            {depositFundsModalIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setDepositFundsModalIsShown(false)} />
                    <CustomConfirmModal>
                        <ModalTitle>
                            Add Funds
                        </ModalTitle>
                        <ModalText>
                            Enter the amount in USD you would like to deposit.
                        </ModalText>
                        <SignupForm onSubmit={handleFormSubmit}>
                            <CustomTextInput value={depositAmount}
                                             type="text"
                                             onChange={handleDepositAmountInput}
                                             placeholder="Deposit amount (USD)"
                            />
                            <InputFinePrint>min $10, max $2500</InputFinePrint>
                            <CustomDropDownList required={true}
                                                value={cardStagedForPayment}
                                                onChange={stageCardForPayment}
                            >
                                <>
                                    <option value="">Select payment method</option>
                                    {!paymentMethodsAreLoading && (
                                        paymentMethods.length !== 0 && (
                                            paymentMethods.map(paymentMethod => {
                                                return (
                                                    <option value={paymentMethod['id']} key={paymentMethod['id']}>
                                                        {paymentMethod['card']['brand'].toUpperCase()} ending in {paymentMethod['card']['last4']}
                                                    </option>
                                                )
                                            })
                                        )
                                    )}
                                </>
                            </CustomDropDownList>
                            <CustomFormSubmitButton type="submit"
                                                    value="Deposit"
                                                    disabled={cardStagedForPayment === '' ||
                                                        depositAmount === '' ||
                                                        parseFloat(depositAmount) < 10 ||
                                                        parseFloat(depositAmount) > 2500}
                            />
                            <BadgeArea>
                                <BadgeText>Processed by</BadgeText>
                                <Badge><FontAwesomeIcon icon={faStripe} /></Badge>
                            </BadgeArea>
                        </SignupForm>
                    </CustomConfirmModal>
                </>
            )}
            {finalPaymentConfirmationIsShown && (
                <>
                    <SecondLayerBackgroundCover onClick={() => setFinalPaymentConfirmationIsShown(false)} />
                    <SecondLayerConfirmModal>
                        <ModalTitle>
                            Confirm Deposit
                        </ModalTitle>
                        <CustomModalText>
                            <CustomModalTextSubSection>
                                <SubsectionName>
                                    Amount
                                </SubsectionName>
                                <SubsectionValue>
                                    {formatCurrency(parseFloat(depositAmount).toFixed(2))}
                                </SubsectionValue>
                            </CustomModalTextSubSection>
                            <CustomModalTextSubSection>
                                <SubsectionName>
                                    Using
                                </SubsectionName>
                                <SubsectionValue>
                                    {stagedCardDetails['card']['brand'] === 'visa' ? (
                                        <FontAwesomeIcon icon={faCcVisa} />
                                    ) : (
                                        stagedCardDetails['card']['brand'] === 'mastercard' ? (
                                            <FontAwesomeIcon icon={faCcMastercard} />
                                        ) : (
                                            stagedCardDetails['card']['brand'] === 'discover' ? (
                                                <FontAwesomeIcon icon={faCcDiscover} />
                                            ) : (
                                                stagedCardDetails['card']['brand'] === 'amex' ? (
                                                    <FontAwesomeIcon icon={faCcAmex} />
                                                ) : (
                                                    stagedCardDetails['card']['brand'] === 'diners' ? (
                                                        <FontAwesomeIcon icon={faCcDinersClub} />
                                                    ) : (
                                                        stagedCardDetails['card']['brand'] === 'jcb' ? (
                                                            <FontAwesomeIcon icon={faCcJcb} />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faCreditCard} />
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )}
                                    <SubsectionValueDetails>
                                        ending in {stagedCardDetails['card']['last4']}
                                    </SubsectionValueDetails>
                                </SubsectionValue>
                            </CustomModalTextSubSection>
                        </CustomModalText>
                        <BiggerNonLinkButton onClick={sendPayment}>Confirm</BiggerNonLinkButton>
                    </SecondLayerConfirmModal>
                </>
            )}
            {(paymentIsProcessing || autopayIsBeingEnabled || autopayIsBeingDisabled) && (
                <>
                    <ConfirmModalBackgroundCover />
                    <ConfirmModal>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </ConfirmModal>
                </>
            )}
            {enableAutopayError && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setEnableAutopayError(false)} />
                    <FeedbackModal>
                        <ModalTitle>
                            Error :-(
                        </ModalTitle>
                        <ModalText>
                            There was an error enabling autopay. Please try again later or contact us at
                            support@valdi.ai.
                        </ModalText>
                        <BiggerNonLinkButton onClick={() => setEnableAutopayError(false)}>
                            Okay
                        </BiggerNonLinkButton>
                    </FeedbackModal>
                </>
            )}
            {paymentProcessed && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissSuccessfulDepositModal} />
                    <FeedbackModal>
                        <ModalTitle>
                            Success
                        </ModalTitle>
                        <ModalText>
                            Your deposit has been successfully processed and will be reflected in your account balance
                            immediately.
                        </ModalText>
                        <BiggerNonLinkButton onClick={dismissSuccessfulDepositModal}>
                            Okay
                        </BiggerNonLinkButton>
                    </FeedbackModal>
                </>
            )}
            {paymentFailed && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setPaymentFailed(false)} />
                    <FeedbackModal>
                        <ModalTitle>
                            Error
                        </ModalTitle>
                        <ModalText>
                            There was an error processing your payment. You have not been charged. Please try again later.
                        </ModalText>
                        <BiggerNonLinkButton onClick={() => setPaymentFailed(false)}>
                            Okay
                        </BiggerNonLinkButton>
                    </FeedbackModal>
                </>
            )}
            {paymentIsDelayed && (
                <>
                    <ConfirmModalBackgroundCover onClick={dismissSuccessfulDepositModal} />
                    <FeedbackModal>
                        <ModalTitle>
                            Processing
                        </ModalTitle>
                        <ModalText>
                            Your payment is processing. The deposit will reflect in your account balance soon.
                        </ModalText>
                        <BiggerNonLinkButton onClick={dismissSuccessfulDepositModal}>
                            Okay
                        </BiggerNonLinkButton>
                    </FeedbackModal>
                </>
            )}
            {paymentWasDeclined && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setPaymentWasDeclined(false)}/>
                    <FeedbackModal>
                        <ModalTitle>
                            Declined
                        </ModalTitle>
                        <ModalText>
                            Your card was declined. Please contact your bank for more information.
                        </ModalText>
                        <BiggerNonLinkButton onClick={() => setPaymentWasDeclined(false)}>
                            Okay
                        </BiggerNonLinkButton>
                    </FeedbackModal>
                </>
            )}
            {paymentWasCanceled && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setPaymentWasCanceled(false)} />
                    <FeedbackModal>
                        <ModalTitle>
                            Canceled
                        </ModalTitle>
                        <ModalText>
                            The payment has been canceled. You have not been charged.
                        </ModalText>
                        <BiggerNonLinkButton onClick={() => setPaymentWasCanceled(false)}>
                            Okay
                        </BiggerNonLinkButton>
                    </FeedbackModal>
                </>
            )}
            {enableAutopayPromptIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={handleDismissEnableAutopayPrompt} />
                    <CustomConfirmModal>
                        <ModalTitle>
                            Enable Autopay
                        </ModalTitle>
                        <ModalText>
                            Specify your autopay settings below.
                        </ModalText>
                        <SignupForm onSubmit={handleAutopaySubmit}>
                            <ModifiedInputErrorText visible={balanceThresholdIsInvalid}>
                                This must be less than your current balance.
                            </ModifiedInputErrorText>
                            <CustomTextInput value={autopayBalanceThreshold}
                                             type="text"
                                             onChange={handleAutopayBalanceThresholdInput}
                                             placeholder="Balance threshold (USD)"
                                             errorOutline={balanceThresholdIsInvalid}
                            />
                            <LeftAlignedInputFinePrint>
                                The account balance below which we will deposit additional funds automatically. We
                                recommend setting this equal to at least your average daily spend.
                            </LeftAlignedInputFinePrint>
                            <ModifiedInputErrorText visible={autopayDepositAmountIsInvalid}>
                                This must be at least $50, no more than $2500.
                            </ModifiedInputErrorText>
                            <CustomTextInput value={autopayDepositAmount}
                                             type="text"
                                             onChange={handleAutopayDepositAmountInput}
                                             placeholder="Auto-deposit amount (USD)"
                                             errorOutline={autopayDepositAmountIsInvalid}
                            />
                            <LeftAlignedInputFinePrint>
                                The amount to deposit when the balance falls below the minimum set above. It must be at
                                least $50 and no more than $2500.
                            </LeftAlignedInputFinePrint>
                            <CustomDropDownList required={true}
                                                value={cardStagedForAutopay}
                                                onChange={stageCardForAutopay}
                            >
                                <>
                                    <option value="">Select payment method</option>
                                    {!paymentMethodsAreLoading && (
                                        paymentMethods.length !== 0 && (
                                            paymentMethods.map(paymentMethod => {
                                                return (
                                                    <option value={paymentMethod['id']} key={paymentMethod['id']}>
                                                        {paymentMethod['card']['brand'].toUpperCase()} ending in {paymentMethod['card']['last4']}
                                                    </option>
                                                )
                                            })
                                        )
                                    )}
                                </>
                            </CustomDropDownList>
                            <CustomFormSubmitButton type="submit"
                                                    value="Enable autopay"
                                                    disabled={cardStagedForAutopay === '' ||
                                                        autopayBalanceThreshold === '' ||
                                                        autopayDepositAmount === '' ||
                                                        balanceThresholdIsInvalid ||
                                                        autopayDepositAmountIsInvalid}
                                                    style={{ marginBottom: "25px" }}
                            />
                        </SignupForm>
                    </CustomConfirmModal>
                </>
            )}
            {disableAutopayPromptIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={() => setDisableAutopayPromptIsShown(false)} />
                    <FeedbackModal>
                        <ModalTitle>
                            Disable Autopay
                        </ModalTitle>
                        <ModalText>
                            Are you sure you want to disable autopay?
                            <br/><br/>
                            <span>
                                Remember, if your balance goes to $0, all of your VALDI resources will be deleted.
                            </span>
                        </ModalText>
                        <BiggerNonLinkButton onClick={handleDisableAutopay}>
                            Yes, I'm sure.
                        </BiggerNonLinkButton>
                    </FeedbackModal>
                </>
            )}
            {transactionDetailIsShown && (
                <>
                    <ConfirmModalBackgroundCover onClick={handleTransactionDetailDisplayDismissal} />
                    {transactionDetailIsLoading ? (
                        <FeedbackModal>
                            <FontAwesomeIcon icon={faCircleNotch} />
                        </FeedbackModal>
                    ) : (
                        <FeedbackModal maxWidth="600px">
                            <ModalTitle>
                                Transaction Details
                            </ModalTitle>
                            <NonCenteredModalText>
                                <CustomEntryGroup>
                                    <EntryTitle>
                                        Transaction ID
                                    </EntryTitle>
                                    <EntryValue>
                                        {transactionDetail['transaction_id']}
                                    </EntryValue>
                                </CustomEntryGroup>
                                <CustomEntryGroup>
                                    <EntryTitle>
                                        Type
                                    </EntryTitle>
                                    <EntryValue>
                                        {transactionDetail['type'] === 'vm_usage' && 'VM Usage'}
                                        {transactionDetail['type'] === 'deposit' && 'Deposit'}
                                        {transactionDetail['type'] === 'credit_adjustment' && 'Admin Credit'}
                                        {transactionDetail['type'] === 'debit_adjustment' && 'Admin Debit'}
                                        {transactionDetail['type'] === 'referral_bonus' && 'Referral Bonus'}
                                        {transactionDetail['type'] === 'storage_usage' && 'Storage Usage'}
                                        {transactionDetail['type'] === 'data_egress' && 'Data Egress'}
                                    </EntryValue>
                                </CustomEntryGroup>
                                <CustomEntryGroup>
                                    <EntryTitle>
                                        Amount
                                    </EntryTitle>
                                    <EntryValue>
                                        {transactionDetail['type'] === 'vm_usage' && `-$${parseFloat(transactionDetail['amount']).toFixed(8)}`}
                                        {transactionDetail['type'] === 'deposit' && `$${parseFloat(transactionDetail['amount']).toFixed(2)}`}
                                        {transactionDetail['type'] === 'credit_adjustment' && `$${parseFloat(transactionDetail['amount']).toFixed(2)}`}
                                        {transactionDetail['type'] === 'debit_adjustment' && `$${parseFloat(transactionDetail['amount']).toFixed(2)}`}
                                        {transactionDetail['type'] === 'referral_bonus' && `$${parseFloat(transactionDetail['amount']).toFixed(2)}`}
                                        {transactionDetail['type'] === 'storage_usage' && `-$${parseFloat(transactionDetail['amount']).toFixed(8)}`}
                                        {transactionDetail['type'] === 'data_egress' && `-$${parseFloat(transactionDetail['amount']).toFixed(8)}`}
                                    </EntryValue>
                                </CustomEntryGroup>
                                {transactionDetail['type'] === 'deposit' && (
                                    <CustomEntryGroup>
                                        <EntryTitle>
                                            Payment Method
                                        </EntryTitle>
                                        <EntryValue>
                                            {formatCardBrand(transactionDetail['card_brand'])} ending in {transactionDetail['card_last4']}
                                        </EntryValue>
                                    </CustomEntryGroup>
                                )}
                                {transactionDetail['type'] === 'vm_usage' && (
                                    <CustomEntryGroup>
                                        <EntryTitle>
                                            Virtual Machine ID
                                        </EntryTitle>
                                        <EntryValue>
                                            {transactionDetail['server']}
                                        </EntryValue>
                                    </CustomEntryGroup>
                                )}
                                {transactionDetail['type'] === 'referral_bonus' && (
                                    <CustomEntryGroup>
                                        <EntryTitle>
                                            For referring
                                        </EntryTitle>
                                        <EntryValue>
                                            {transactionDetail['memo']}
                                        </EntryValue>
                                    </CustomEntryGroup>
                                )}
                                {transactionDetail['type'] === 'storage_usage' && (
                                    <CustomEntryGroup>
                                        <EntryTitle>
                                            Volume ID
                                        </EntryTitle>
                                        <EntryValue>
                                            {transactionDetail['volume_id']}
                                        </EntryValue>
                                    </CustomEntryGroup>
                                )}
                                {transactionDetail['type'] === 'data_egress' && (
                                    <CustomEntryGroup>
                                        <EntryTitle>
                                            For downloading
                                        </EntryTitle>
                                        <EntryValue>
                                            {transactionDetail['memo']}
                                        </EntryValue>
                                    </CustomEntryGroup>
                                )}
                                <CustomEntryGroup>
                                    <EntryTitle>
                                        Date
                                    </EntryTitle>
                                    <EntryValue>
                                        {formatTimestamp(transactionDetail['timestamp'])}
                                    </EntryValue>
                                </CustomEntryGroup>
                            </NonCenteredModalText>
                            <BiggerNonLinkButton onClick={handleTransactionDetailDisplayDismissal}>
                                Close
                            </BiggerNonLinkButton>
                        </FeedbackModal>
                    )}
                </>
            )}
        </PortalSettingsContainer>
    )
}

export default PortalBilling;